<template>
  <div
    class="flex flex-col menu-width bg-white menu-border menu-z-index menu-transition"
    :class="{ 'menu-width': expanded, 'menu-width-closed': !expanded }"
  >
    <div class="px-4 pt-3 pb-2 flex justify-end">
      <i v-if="expanded" class="fas fa-chevrons-left cursor-pointer" @click="collapseMenu()"></i>
      <i v-else class="fas fa-chevrons-right cursor-pointer" @click="expandMenu()"></i>
    </div>

    <div class="px-4 pb-4" :class="{ 'section-titles-width': expanded }">
      <h5
        v-for="(section, index) in sections"
        :key="index"
        class="cursor-pointer my-1"
        :class="{ 'highlighted-section': visibleElements[index] }"
        @click="scrollToId(section.id)"
      >
        {{ expanded ? section.name : getFirstLetters(section.name) }}
        <i
          v-if="sectionHasWarnings(section.id)"
          class="fal fa-circle-exclamation text-medosync-dark-orange fa-sm px-1"
        ></i>
      </h5>
    </div>
  </div>
</template>

<script>
import _ from 'lodash';

export default {
  props: {
    sections: {
      required: true
    },
    warnings: {
      required: false,
      default: null
    }
  },
  data() {
    return {
      containerId: 'admission-form-container',
      expanded: true,
      visibleElements: [],
      skipListener: false
    };
  },
  watch: {
    containerId() {
      this.addListeners();
    }
  },
  mounted() {
    this.addListeners();
    this.forceMenuCollapse();
  },
  beforeUnmount() {
    this.removeListeners();
  },
  created() {
    window.addEventListener('resize', this.forceMenuCollapse);
  },
  unmounted() {
    window.removeEventListener('resize', this.forceMenuCollapse);
  },
  methods: {
    scrollToId(id) {
      var el = document.getElementById(id);
      el.scrollIntoView({ behavior: 'smooth' });
      this.skipListener = true;
      this.visibleElements = Array(this.sections.length).fill(false, 0);
      this.sections.find((section, index) => {
        if (section.id === id) {
          this.visibleElements[index] = true;
          return true;
        }
      });
    },
    elementsVisibleInViewport: _.debounce(function () {
      if (this.skipListener) {
        this.skipListener = false;
        return;
      }

      this.visibleElements = Array(this.sections.length).fill(false, 0);

      const { clientHeight } = document.getElementById(this.containerId);
      const clientTop = document.getElementById(this.containerId).getBoundingClientRect().top;
      for (let i = this.visibleElements.length - 1; i >= 0; i--) {
        const { top, bottom } = document.getElementById(this.sections[i].id).getBoundingClientRect();
        this.visibleElements[i] =
          (top >= clientTop && top <= clientHeight + clientTop) ||
          (bottom >= clientTop && bottom <= clientHeight + clientTop) ||
          (top <= clientTop && bottom >= clientHeight + clientTop);

        if (this.visibleElements[i]) {
          break;
        }
      }
    }, 300),
    addListeners() {
      this.visibleElements = Array(this.sections.length).fill(false, 0);
      this.elementsVisibleInViewport();
      document.getElementById(this.containerId).addEventListener('scroll', this.elementsVisibleInViewport);
    },
    removeListeners() {
      document.getElementById(this.containerId).removeEventListener('scroll', this.elementsVisibleInViewport);
    },
    getFirstLetters(str) {
      return str.substring(0, 8) + '...';
    },
    expandMenu() {
      this.expanded = true;
      this.$emit('set-form-overlay', true);
    },
    collapseMenu() {
      this.expanded = false;
      this.$emit('set-form-overlay', false);
    },
    forceMenuCollapse() {
      window.innerWidth < 1200 ? this.collapseMenu() : this.expandMenu();
    },
    sectionHasWarnings(sectionId) {
      if (!this.warnings) return false;
      return this.warnings.find(warning => warning.section === sectionId);
    }
  }
};
</script>

<style scoped>
.menu-z-index {
  z-index: 100;
}
.menu-width {
  width: 45%;
}
.menu-width-closed {
  width: 130px;
}
.section-titles-width {
  width: 100%;
  min-width: 200px;
}
.menu-border {
  border-right: 1px solid #000;
}
.highlighted-section {
  color: #002e7e;
  font-weight: 600;
}
</style>
