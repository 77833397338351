<template>
  <div :id="inputId" class="address-fields mt-3">
    <div class="flex flex-col">
      <div class="my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div class="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
          <div class="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">

            <table class="min-w-full divide-y divide-gray-200">
              <tbody>
                <tr id="addr-row" v-for="(line, idx) in localData" :key="idx">
                  <td class="px-2 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                    <input :id="inputId + '_line' + idx" type="text" :data-test-id="`${testId}address`"
                      class="custom-input shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                      v-model='localData[idx]' v-on:keydown.enter="addAfterLine(idx)"
                      v-on:keyup.enter="moveToNextField($event.target.value, idx)" ref="addrlines"
                      placeholder="Address line" :disabled="isTextBoxDisabled">
                  </td>
                  <td class="px-2 py-4 whitespace-nowrap text-sm text-gray-500 font-semibold">
                    <span :id="inputId + '_add_prev_line' + idx" @click='addBeforeLine(idx)'
                      title="Add an address line above this line">
                      <i class="fas fa-circle-arrow-up "
                      :class="{ 'text-gray-600': isTextBoxDisabled, 'text-green-600': !isTextBoxDisabled }"></i>
                    </span>
                    &nbsp;
                    <span :id="inputId + '_add_next_line' + idx" @click='addAfterLine(idx)'
                      title="Add an address line below this line">
                      <i class="fas fa-circle-arrow-down "
                      :class="{ 'text-gray-600': isTextBoxDisabled, 'text-green-600': !isTextBoxDisabled }"></i>
                    </span>
                    &nbsp;
                    &nbsp;
                    <span :id="inputId + '_remove_line' + idx" @click="removeLine(idx)" title="Remove this address line" data-test-id="remove-line">
                      <i class="fas fa-minus-circle"
                        :class="{ 'text-gray-600': isTextBoxDisabled, 'text-red-600': !isTextBoxDisabled }"></i>
                    </span>

                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex';
/*
Implements the `postalAddress` field of a CommonAddress structure.  Usually you should use the CommonAddress
component rather than this component.

modelValue should point to the postalAddress.
 */
export default {
  name: 'PostalAddress',
  props: {
    'modelValue': {},
    'fullWidth': { type: Boolean, required: false },
    'testId': { type: String, required: false },
    // Caller must provide an 'id' otherwise pressing enter to add new field will fail.
    'id': { type: String, required: true }
  },
  components: {
  },
  data() {
    return {
      inputId: this.id || `postal_address_${(Math.random() * 1000).toFixed()}`,
    }
  },
  computed: {
    ...mapState(['textBoxDisabled']),
    isTextBoxDisabled() {
      return this.textBoxDisabled;
    },
    localData: {
      get() {
        return this.modelValue
      },
      set(newValue) {
        this.$emit('update:modelValue', newValue)
      }
    }
  },
  methods: {
    ...mapMutations(['setTextBoxDisabled']),
    addBeforeLine(idx) {
      if (this.isTextBoxDisabled) {
        return;
      }
      //console.log("add before line at ", idx)
      this.localData.splice(idx, 0, "")
    },
    addAfterLine(idx) {
      if (this.isTextBoxDisabled) {
        return;
      }
      //console.log("add after line at ", idx)
      this.localData.splice(idx + 1, 0, "")
    },
    removeLine(idx) {
      //console.log("remove line at ", idx)
      if (this.isTextBoxDisabled) {
        return;
      }
      this.localData.splice(idx, 1);
    },
    moveToNextField(event, idx) {
      console.log("event =", event)
      console.log("idx = ", idx)
      console.log("moveToNextField refs:", this.$refs)
      const next_idx = idx + 1;
      if (next_idx < this.localData.length) {
        this.$refs.addrlines[next_idx].focus()
      }
    }

  },
}
</script>

<style lang="scss" scoped>
.full-width {
  width: 100% !important;
  padding: 0;
}

.title {
  text-align: left;
  font-size: 18px;
  font-weight: 400;
  font-style: italic;
  color: #0054ab;
}

.required {
  color: red;
}
</style>