<template>
  <div v-if="!detailed" class="p-8">
    <div class="flex s-report-title">
      <div class="col">
        <span class="cursor-pointer" @click="goBack()">
          <i class="fas fa-chevron-left mr-3"></i>
        </span>
        Outstanding Claims Pended
      </div>
      <div class="col-auto flex mb-1">
        <v-select
          class="year-select text-base ml-2 p-0 focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
          v-if="!isMedic && medics"
          label="username"
          placeholder="Select Consultant"
          :options="medics"
          v-model="selectedConsultant"
        ></v-select>
      </div>
    </div>
    <br />
    <div class="col-4 flex flex-col px-2">
      <h3 class="text-lg leading-6 font-medium text-gray-900 mr-5">Filter by Date:</h3>
      <div class="datePicker">
        <date-picker v-model:value="dateRange" type="date" range placeholder="Select date range"></date-picker>
      </div>
    </div>
    <div class="flex justify-end mt-4">
      <button
        type="button"
        @click="openDetailed()"
        class="inline-flex items-center rounded border border-transparent bg-blue px-2.5 py-1.5 text-xs font-medium text-white shadow-sm focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 mr-2"
      >
        VIEW DETAILED REPORT
      </button>
    </div>
    <SpinnerLoader v-if="isLoading" />
    <div v-else>
      <TableComponent
        :claimsProp="calculatedData"
        :tableNames="tableNames"
        :optionSearch="false"
        :optionResultPerPage="false"
      />
    </div>
  </div>
  <div v-else>
    <OutstandingDetailed
      type="pended"
      :preselected-consultant="selectedConsultant"
      @close-detailed="closeDetailed()"
      :dataSelectFrom="selectedDateFrom"
      :dataSelectTo="selectedDateTo"
    />
  </div>
  <ToastComponent ref="toast"></ToastComponent>
</template>

<script>
import { OpenAPIDateRange, OpenAPIJSON } from 'api';
import { api_store } from '@/store';
import { userStore } from '@/store/user';
import OutstandingDetailed from '@/views/finance/reporting/detailed/OutstandingDetailed.vue';
import StringUtility from 'utils/stringUtility';
import vSelect from 'vue-select';
import SortedArrayMap from 'collections/sorted-array-map';
import SpinnerLoader from '@/components/misc/SpinnerLoader.vue';
import TableComponent from '@/components/claim-forms/components/TableComponent.vue';
import DatePicker from 'vue-datepicker-next';
import ToastComponent from '@/components/claim-forms/components/ToastComponent.vue';
import 'vue-datepicker-next/index.css';
import BigNumber from 'bignumber.js';

export default {
  name: 'OutstandingPended',
  components: {
    vSelect,
    OutstandingDetailed,
    SpinnerLoader,
    TableComponent,
    ToastComponent,
    DatePicker
  },
  data() {
    return {
      openApi: new OpenAPIJSON(),
      isLoading: false,
      defaultYear: 2022,
      username: null,
      isMedic: null,
      medics: null,
      selectedConsultant: null,
      invoiceList: null,
      calendarMonths: [
        'January',
        'February',
        'March',
        'April',
        'May',
        'June',
        'July',
        'August',
        'September',
        'October',
        'November',
        'December'
      ],
      detailed: false,
      reportData: null,
      calculatedData: [],
      invoicedAmount: 0,
      paidAmount: 0,
      withholdingTax: 0,
      isSortOrderDESC: true,
      sortedProperty: null,
      language: '',
      selectedDateFrom: null,
      selectedDateTo: null,
      tableNames: [
        {
          TableTitle: 'Submitted Year',
          firstIndex: 'year',
          secondIndex: '',
          width: '155',
          money: false,
          selectedBox: 'year'
        },
        { TableTitle: 'Submitted Month', firstIndex: 'month', secondIndex: '', width: '155', money: false, date: true },
        {
          TableTitle: 'Invoice Count',
          firstIndex: 'invoiceCount',
          secondIndex: '',
          width: '155',
          money: false,
          sum: true
        },
        { TableTitle: 'Invoice', firstIndex: 'invoicedAmount', secondIndex: '', width: '155', money: true, sum: true },
        { TableTitle: 'Paid', firstIndex: 'paidAmount', secondIndex: '', width: '155', money: true, sum: true },
        {
          TableTitle: 'Outstanding',
          firstIndex: 'outstandingAmount',
          secondIndex: '',
          width: '155',
          money: true,
          sum: true
        }
      ],
      dateRange: null
    };
  },
  watch: {
    dateRange(newVal) {
      this.selectedDateFrom = newVal[0];
      this.selectedDateTo = newVal[1];
      this.fetch_json_report_data();
    },
    selectedConsultant(newVal) {
      if (!this.selectedConsultant) return (this.selectedConsultant = { consultant_id: null, username: '' });
      if (this.selectedConsultant.consultant_id == null) return;
      localStorage.setItem('selectedConsultantReport', JSON.stringify(newVal));
      this.fetch_json_report_data();
    }
  },
  async mounted() {
    await this.getConsultant();
    try {
      const selectedConsultantReport = JSON.parse(localStorage.getItem('selectedConsultantReport'));
      this.selectedConsultant = selectedConsultantReport;
      if (this.selectedConsultant?.person_id) this.fetch_json_report_data();
    } catch {
      this.fetch_json_report_data();
    }
  },
  methods: {
    getMonth(index) {
      if (index >= 12) index = index % 12;
      return this.calendarMonths[index];
    },
    _build_request() {
      return {
        consultant_id: this.selectedConsultant?.consultant_id,
        rollup: 'MONTH',
        submission_date: new OpenAPIDateRange(
          this.selectedDateFrom ? StringUtility.naiveDateYYYYMMDD(this.selectedDateFrom) : null,
          this.selectedDateTo ? StringUtility.naiveDateYYYYMMDD(this.selectedDateTo) : null
        )
      };
    },
    async fetch_json_report_data() {
      this.isLoading = true;
      if (this.selectedConsultant?.consultant_id == null) {
        this.isLoading = false;
        return this.$refs.toast.showToast('No consultant selected', 'fa-solid fa-triangle-exclamation', 'error');
      }

      try {
        const request_data = this._build_request();
        const { items } = await this.openApi.reportConsulantClaimOutstandingPended(
          request_data.consultant_id,
          null,
          null,
          null,
          null,
          request_data.rollup,
          request_data.submission_date
        );
        this.reportData = items;
        this.calculateReports();
        this.isLoading = false;
      } catch (error) {
        this.$toast.error('Something went wrong getting the report data. Please try again later.');
        this.isLoading = false;
      }
    },
    calculateReports() {
      this.language = navigator.language || navigator.userLanguage;

      if (this.reportData == null || !this.reportData?.length) return;

      let compare = function (a, b) {
        if (a.year < b.year) {
          return -1;
        }
        if (a.year > b.year) {
          return 1;
        }
        if (a.month < b.month) {
          return -1;
        }
        if (a.month > b.month) {
          return 1;
        }
        return 0;
      };
      let data = new SortedArrayMap(null, null, compare);
      for (let j = 0; j < this.reportData.length; j++) {
        const sd = new Date(this.reportData[j].submission_date);
        const key = { year: sd.getFullYear(), month: sd.getMonth() };
        let value = {};
        if (data.has(key)) {
          value = data.get(key);
        } else {
          value = {
            invoiceCount: 0,
            invoicedAmount: new BigNumber(0),
            paidAmount: new BigNumber(0),
            outstandingAmount: new BigNumber(0),
            year: key.year,
            month: key.month
          };
        }
        value.invoiceCount++;
        value.invoicedAmount = value.invoicedAmount.plus(this.reportData[j].payment['invoiced']);
        value.paidAmount = value.paidAmount
          .plus(this.reportData[j].payment['paid'])
          .plus(this.reportData[j].payment['part_paid']);
        value.outstandingAmount = value.outstandingAmount.plus(this.reportData[j].payment['outstanding']);
        data.set(key, value);
      }
      // The sorting on the table is done in-place, so we need to change the Map into an array.
      this.calculatedData = Array.from(data.values());
    },
    async sortBy(property) {
      this.sortedProperty == property ? (this.isSortOrderDESC = !this.isSortOrderDESC) : (this.isSortOrderDESC = true);
      this.sortedProperty = property;
      this.calculatedData.sort((a, b) => {
        let first;
        let second;
        try {
          first = typeof a[property] === 'number' ? a[property] : a[property].toUpperCase();
        } catch {
          first = '-1';
        }
        try {
          second = typeof a[property] === 'number' ? b[property] : b[property].toUpperCase();
        } catch {
          second = '-1';
        }

        if (this.isSortOrderDESC) return first < second ? -1 : first > second ? 1 : 0;
        else return first > second ? -1 : first < second ? 1 : 0;
      });
    },
    async getConsultant() {
      this.isMedic = userStore.getters.user?.is_medic;
      this.username = userStore.getters.user?.username;

      if (this.isMedic) {
        this.medics = [
          {
            person_id: userStore.getters.user?.person_id,
            username: userStore.getters.user?.username
          }
        ];
        this.selectedConsultant = {
          person_id: userStore.getters.user?.person_id
        };
      } else {
        await api_store.cache
          .dispatch('LIST_MY_CONSULTANTS', {})
          .then(res => {
            this.medics = res;
            this.medics.forEach(item => (item.consultant_id = item.medic.id));
            this.medics.forEach(item => (item.username = item.medic.ro.full_name));
            this.selectedConsultant = { consultant_id: null, username: 'Select Consultant' };
            this.setDoctorFromUrl();
          })
          .catch(error => {
            this.$refs.toast.showToast(error, 'fa-solid fa-triangle-exclamation', 'error');
          });
      }
    },
    setDoctorFromUrl() {
      if (this.$route.query?.personId) {
        const doctor = this.medics.find(medic => medic.person_id == parseInt(this.$route.query?.personId));
        if (doctor) {
          this.selectedConsultant = {
            consultant_id: doctor.consultant_id,
            username: doctor.firstname + ' ' + doctor.lastname
          };
        }
      }
    },
    goBack() {
      this.$router.push({ path: '/navigator/finance/reporting' });
    },
    openDetailed() {
      this.detailed = true;
    },
    closeDetailed() {
      this.detailed = false;
    }
  }
};
</script>

<style lang="scss" scoped>
.year-select {
  width: 400px;
}

.bg-blue {
  background-color: #00a7e1;
}

.price {
  display: flex;
  justify-content: flex-end;
}

.datePicker {
  display: flex;
  flex-direction: row;
  align-items: center;
}
</style>
