<script setup>
import { defineComponent, computed } from 'vue';
import { useField } from 'vee-validate';
import InputErrorMessage from './InputErrorMessage.vue';

defineComponent({ InputErrorMessage });

const props = defineProps({
  id: {
    type: String,
    required: true
  },
  name: {
    type: String,
    required: true
  },
  label: {
    type: String,
    default: ''
  },
  placeholder: {
    type: String,
    default: ''
  },
  required: {
    type: Boolean,
    default: false
  },
  editMode: {
    type: Boolean,
    default: true
  },
  isWarning: {
    type: Boolean,
    default: false
  },
  enableValidation: {
    type: Boolean,
    default: true
  }
});

const { value, errorMessage } = useField(() => props.name);

const showError = computed(() => {
  return props.enableValidation && errorMessage.value;
});
</script>

<template>
  <div :id="id + '-container'">
    <div class="flex">
      <label
        :for="name"
        class="text-sm sm:text-base font-bold"
        :class="{
          'text-medosync-dark-orange': isWarning && editMode,
          'text-medosync-red': showError
        }"
      >
        {{ label }}
        <i v-if="isWarning && editMode" class="fal fa-circle-exclamation mx-1 text-medosync-dark-orange"></i>
      </label>
    </div>
    <div v-if="editMode" class="mt-1">
      <textarea
        :id="id"
        v-model="value"
        :name="name"
        class="focus:ring-medosync-blue focus:border-medosync-blue block w-full rounded-md max-input-width"
        :class="{
          'border-medosync-dark-orange': isWarning && editMode,
          'border-medosync-red focus:ring-medosync-red focus:border-medosync-red': showError
        }"
        :rows="3"
      ></textarea>
    </div>
    <div v-else class="mt-1">
      <p :id="id + '-readonly'" class="text-lg text-gray-500 ml-4 mr-6">
        {{ value === '' || value === undefined || value === null ? 'N/A' : value }}
      </p>
    </div>
    <InputErrorMessage v-if="showError">Please enter your {{ label.toLowerCase().slice(0, -1) }}</InputErrorMessage>
  </div>
</template>
