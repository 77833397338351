export const ConsultantClaimStateEnum = Object.freeze({
  CREATED: "created",
  DRAFT: "draft",
  READY_FOR_CONSULTANT_APPROVAL: "ready_for_consultant_approval",
  CONSULTANT_APPROVED: "consultant_approved",
  READY_FOR_SEND_TO_INSURER: "ready_for_send_to_insurer",
  SENT_TO_INSURER: "sent_to_insurer",
  PAID_IN_FULL: "paid_in_full",
  PART_PAID: "part_paid",
  PENDED_WITH_HOSPITAL: "pended_with_hospital",
  PENDED_WITH_INSURER: "pended_with_insurer",
  RETURNED: "returned",
  REJECTED: "rejected",
  MANUAL_PROCESS: "manual_process",
  COMPLETE: "complete",
  MANUAL_COMPLETE: "manual_complete",
  MANUAL_REJECTED: "manual_rejected",
  REJECTED_BY_CONSULTANT_FOR_AMENDMENT: "rejected_by_consultant_for_amendment",
  FOR_APPEAL: "for_appeal",
  WRITE_OFF_APPEAL_DENIED: "write_off_appeal_denied",
  WRITE_OFF_REJECTED: "write_off_rejected",
  WRITE_OFF_BILLED_INCORRECTLY: "write_off_billed_incorrectly",
  OVER_PAID: "over_paid",
});
