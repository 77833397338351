<script setup>
/**
 * This component handles phone and email input with validation and country code selection.
 * Features:
 * - Automatic country detection from phone numbers
 * - Special handling for Irish numbers (must start with 08 and be 10 digits)
 * - European country selection with flags
 * - Phone number validation using libphonenumber-js
 * - Error handling and validation feedback
 * - Preserves all digits during editing
 */
import { ref, watch } from 'vue';
import { validateEmail } from '@/util/helper';
import { parsePhoneNumber, isValidPhoneNumber } from 'libphonenumber-js';

// Component props definition
const props = defineProps({
  option: {
    type: Object,
    required: true
  },
  isSingleOption: {
    type: Boolean,
    default: false
  },
  isEditingText: {
    type: Boolean,
    default: false
  }
});

// Event emitters for parent component communication
const emit = defineEmits(['update:modelValue', 'set-value', 'set-editing', 'cancel-editing', 'number-change']);

// European countries data with flags, codes, and names - Ireland and UK prioritized
const europeanCountries = [
  { code: '+353', country: 'Ireland', flag: '🇮🇪' },
  { code: '+44', country: 'United Kingdom', flag: '🇬🇧' },
  { code: '+43', country: 'Austria', flag: '🇦🇹' },
  { code: '+32', country: 'Belgium', flag: '🇧🇪' },
  { code: '+359', country: 'Bulgaria', flag: '🇧🇬' },
  { code: '+357', country: 'Cyprus', flag: '🇨🇾' },
  { code: '+420', country: 'Czech Republic', flag: '🇨🇿' },
  { code: '+45', country: 'Denmark', flag: '🇩🇰' },
  { code: '+372', country: 'Estonia', flag: '🇪🇪' },
  { code: '+358', country: 'Finland', flag: '🇫🇮' },
  { code: '+33', country: 'France', flag: '🇫🇷' },
  { code: '+49', country: 'Germany', flag: '🇩🇪' },
  { code: '+30', country: 'Greece', flag: '🇬🇷' },
  { code: '+36', country: 'Hungary', flag: '🇭🇺' },
  { code: '+354', country: 'Iceland', flag: '🇮🇸' },
  { code: '+39', country: 'Italy', flag: '🇮🇹' },
  { code: '+371', country: 'Latvia', flag: '🇱🇻' },
  { code: '+370', country: 'Lithuania', flag: '🇱🇹' },
  { code: '+352', country: 'Luxembourg', flag: '🇱🇺' },
  { code: '+356', country: 'Malta', flag: '🇲🇹' },
  { code: '+31', country: 'Netherlands', flag: '🇳🇱' },
  { code: '+47', country: 'Norway', flag: '🇳🇴' },
  { code: '+48', country: 'Poland', flag: '🇵🇱' },
  { code: '+351', country: 'Portugal', flag: '🇵🇹' },
  { code: '+40', country: 'Romania', flag: '🇷🇴' },
  { code: '+7', country: 'Russia', flag: '🇷🇺' },
  { code: '+381', country: 'Serbia', flag: '🇷🇸' },
  { code: '+421', country: 'Slovakia', flag: '🇸🇰' },
  { code: '+386', country: 'Slovenia', flag: '🇸🇮' },
  { code: '+34', country: 'Spain', flag: '🇪🇸' },
  { code: '+46', country: 'Sweden', flag: '🇸🇪' },
  { code: '+41', country: 'Switzerland', flag: '🇨🇭' },
  { code: '+380', country: 'Ukraine', flag: '🇺🇦' }
];

// Reactive state variables
const title = ref('');
const emptySubtitle = ref('');
const inputValue = ref('');
const previousInputValue = ref('');
const editMode = ref(false);
const showErrorMessage = ref(false);
const errorMessage = ref(undefined);
const selectedCountry = ref(null);

/**
 * Detects country from phone number using libphonenumber-js
 * @param {string} phoneNumber - The full phone number including country code
 * @returns {Object|null} The matching country object or null if not found
 */
const detectCountryFromNumber = phoneNumber => {
  if (!phoneNumber) return null;

  try {
    const parsedNumber = parsePhoneNumber(phoneNumber);
    if (parsedNumber) {
      const countryCode = '+' + parsedNumber.countryCallingCode;
      return europeanCountries.find(country => country.code === countryCode);
    }
  } catch (error) {
    console.error('Error parsing phone number:', error);
  }

  return null;
};

/**
 * Formats phone number using libphonenumber-js or fallback cleaning
 * @param {string} phone - The phone number to format
 * @returns {string} Formatted phone number
 */
const formatPhoneNumber = phone => {
  if (!phone) return '';
  try {
    const parsedNumber = parsePhoneNumber(phone);
    if (parsedNumber) {
      return parsedNumber.nationalNumber;
    }
  } catch (error) {
    return phone.replace(/[^\d]/g, '');
  }
  return phone;
};

/**
 * Adds leading zero for Irish numbers if not present
 * @param {string} number - The phone number without country code
 * @returns {string} Formatted Irish number with leading zero
 */
const formatIrishNumber = number => {
  const cleanNumber = number.replace(/^0+/, '');
  return '0' + cleanNumber;
};

/**
 * Validates phone number using libphonenumber-js with special handling for Irish numbers
 * @param {string} phone - The full phone number to validate
 * @returns {boolean} True if valid phone number
 */
const validatePhoneNumber = phone => {
  try {
    if (!phone) return false;

    // Extract country code from the phone number
    const parsedNumber = parsePhoneNumber(phone);
    if (!parsedNumber) return false;

    // Special validation for Irish numbers
    if (parsedNumber.countryCallingCode === '353') {
      // Get the full national number
      let nationalNumber = parsedNumber.nationalNumber;

      // If it doesn't start with 0, add it
      if (!nationalNumber.startsWith('0')) {
        nationalNumber = '0' + nationalNumber;
      }

      // Validate Irish mobile format (starts with 08 and has 10 digits)
      return nationalNumber.startsWith('08') && nationalNumber.length === 10;
    }

    // For non-Irish numbers, use standard validation
    return isValidPhoneNumber(phone);
  } catch (error) {
    console.error('Validation error:', error);
    return false;
  }
};

/**
 * Validates initial value and sets initial country selection
 * Handles both email and phone number validation
 */
const validateInitialValue = () => {
  if (props.option.value === '' || props.option.value === null) {
    selectedCountry.value = europeanCountries.find(country => country.country === 'Ireland');
    return;
  }

  if (props.option.type === 'email') {
    if (!validateEmail(props.option.value)) {
      errorMessage.value = 'Invalid email address provided initially';
      showErrorMessage.value = true;
    }
  } else if (props.option.type === 'phone') {
    const detectedCountry = detectCountryFromNumber(props.option.value);
    selectedCountry.value = detectedCountry || europeanCountries.find(country => country.country === 'Ireland');

    if (!validatePhoneNumber(props.option.value)) {
      errorMessage.value = 'Please confirm country code is correct.';
      showErrorMessage.value = true;
    }
  }
};

// Watch for editing state changes
watch(
  () => props.isEditingText,
  newValue => {
    if (newValue && editMode.value) {
      cancelEditMode();
    }
  }
);

/**
 * Sets initial values and labels based on input type
 */
const setValues = () => {
  inputValue.value = props.option?.value;

  if (props.option.type === 'phone' && inputValue.value) {
    // Keep the full number intact, only validate
    if (!inputValue.value.startsWith('+')) {
      inputValue.value = formatPhoneNumber(inputValue.value);
    }
  }

  switch (props.option.type) {
    case 'phone':
      title.value = 'Phone no.:';
      emptySubtitle.value = 'add mobile phone number';
      break;
    case 'email':
      title.value = 'E-mail:';
      emptySubtitle.value = 'add E-mail address';
      break;
  }
  validateInitialValue();
};

/**
 * Opens edit mode and preserves the entire number
 */
const openEditMode = () => {
  previousInputValue.value = inputValue.value;

  // If we have a full number with country code, extract the national part
  if (inputValue.value && inputValue.value.startsWith('+')) {
    try {
      const parsedNumber = parsePhoneNumber(inputValue.value);
      if (parsedNumber) {
        // Keep all digits after country code
        const nationalPart = inputValue.value.slice(parsedNumber.countryCallingCode.length + 1);
        inputValue.value = nationalPart.replace(/[^\d]/g, '');
      }
    } catch (error) {
      // If parsing fails, just remove the country code manually
      inputValue.value = inputValue.value.replace(/^\+\d{1,4}\s?/, '');
    }
  }

  emit('set-editing');
  editMode.value = true;
};

/**
 * Handles input changes while preserving all digits
 */
const changeInputValue = event => {
  let value = event.target.innerHTML;

  if (props.option.type === 'phone') {
    // Only remove non-numeric characters, preserve all digits
    value = value.replace(/[^\d]/g, '');
  }

  if (props.option.type === 'email' && errorMessage) {
    if (validateEmail(value)) {
      errorMessage.value = undefined;
    } else {
      errorMessage.value = 'Invalid email address';
    }
  } else if (props.option.type === 'phone') {
    const fullNumber = `${selectedCountry.value.code}${value}`;
    if (validatePhoneNumber(fullNumber)) {
      errorMessage.value = undefined;
    } else {
      // Show error but don't modify the number
      if (selectedCountry.value.country === 'Ireland') {
        errorMessage.value = 'Irish mobile numbers must start with 08 and be 10 digits long';
      } else {
        errorMessage.value = 'Please enter a valid phone number for ' + selectedCountry.value.country;
      }
    }
  }
  inputValue.value = value;
};

/**
 * Validates and saves the current value
 * Handles special formatting for Irish numbers
 */
const confirmEditMode = () => {
  showErrorMessage.value = true;

  if (props.option.type === 'email') {
    if (!validateEmail(inputValue.value) && inputValue.value !== '' && inputValue.value !== null) {
      errorMessage.value = 'Invalid email address';
      return;
    }
  } else if (props.option.type === 'phone') {
    // Clean the number and remove any existing country code
    let phoneNumber = inputValue.value.replace(/^\+\d{1,4}/, '').replace(/[^\d]/g, '');

    // Add leading zero for Irish numbers if not present
    if (selectedCountry.value.country === 'Ireland' && !phoneNumber.startsWith('0')) {
      phoneNumber = '0' + phoneNumber;
    }

    // Construct full number with country code
    const fullNumber = `${selectedCountry.value.code}${phoneNumber}`;

    // Validate the complete number
    try {
      if (!validatePhoneNumber(fullNumber)) {
        if (selectedCountry.value.country === 'Ireland') {
          errorMessage.value = 'Irish mobile numbers must start with 08 and be 10 digits long';
        } else {
          errorMessage.value = 'Please enter a valid phone number for ' + selectedCountry.value.country;
        }
        return;
      }
    } catch (error) {
      errorMessage.value = 'Invalid phone number format';
      return;
    }

    // Update value and emit change event
    inputValue.value = fullNumber;
    emit('number-change', fullNumber);
  }

  emit('set-value', { type: props.option.type, value: inputValue.value });
  emit('cancel-editing');
  showErrorMessage.value = false;
  editMode.value = false;
};

/**
 * Cancels edit mode and restores the previous value
 */
const cancelEditMode = () => {
  showErrorMessage.value = false;
  inputValue.value = previousInputValue.value;
  if (errorMessage.value) {
    errorMessage.value = undefined;
  }
  emit('cancel-editing');
  editMode.value = false;
};

/**
 * Updates country while preserving all digits
 */
const updateCountry = country => {
  selectedCountry.value = country;
  if (inputValue.value) {
    // Only remove the plus and country code if present, keep all other digits
    let phoneNumber = inputValue.value.replace(/^\+\d{1,4}\s?/, '');

    if (country.country === 'Ireland' && !phoneNumber.startsWith('0')) {
      phoneNumber = '0' + phoneNumber;
    }

    inputValue.value = phoneNumber;
  }
};

// Initialize component
setValues();
</script>

<!-- 
  Template section contains the component's HTML structure
  Key features:
  - Radio button for option selection (if not single option)
  - Country selector dropdown for phone numbers
  - Editable input field
  - Error message display
  - Edit/Cancel/Confirm buttons
-->
<template>
  <!-- Component container -->
  <div class="flex items-center mb-2.5">
    <!-- Radio button for option selection -->
    <input
      v-if="!isSingleOption"
      :data-test-id="'patient-form-input-option-' + option.type"
      :id="option.type + '-insurer-form'"
      :value="option.type"
      name="option-type-insurer-form"
      type="radio"
      class="h-12 w-12 rounded-full cursor-pointer mr-7 custom-radio"
      @change="$emit('update:modelValue', $event.target.value)"
    />

    <!-- Main input container -->
    <div class="items-center" :class="{ 'edit-border p-5': editMode }">
      <label :for="option.type + '-insurer-form'" class="inline-block">
        <!-- Field title -->
        <span class="font-bold">{{ title }}</span>

        <!-- Phone input with country selector -->
        <div v-if="editMode && option.type === 'phone'" class="inline-flex items-center">
          <!-- Country selector dropdown -->
          <div class="relative inline-block">
            <button
              type="button"
              class="flex items-center px-3 py-1 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
              @click="$refs.countryDropdown.classList.toggle('hidden')"
            >
              <span class="mr-2">{{ selectedCountry.flag }}</span>
              <span>{{ selectedCountry.code }}</span>
              <span class="ml-2">▼</span>
            </button>

            <!-- Dropdown menu for country selection -->
            <div
              ref="countryDropdown"
              class="hidden absolute z-10 mt-1 w-48 bg-white border rounded-md shadow-lg max-h-60 overflow-y-auto"
            >
              <div
                v-for="country in europeanCountries"
                :key="country.code"
                class="flex items-center px-4 py-2 hover:bg-gray-100 cursor-pointer"
                @click="
                  updateCountry(country);
                  $refs.countryDropdown.classList.add('hidden');
                "
              >
                <span class="mr-2">{{ country.flag }}</span>
                <span>{{ country.country }}</span>
                <span class="ml-auto text-gray-500">{{ country.code }}</span>
              </div>
            </div>
          </div>

          <!-- Phone number input field -->
          <span
            :data-test-id="'patient-form-change-add-' + option.type"
            class="ml-1 border-b border-black whitespace-pre-wrap"
            :class="[inputValue ? 'pr-2' : 'pr-20']"
            role="textbox"
            contenteditable
            @click.prevent
            @input="changeInputValue"
          >
            {{ inputValue ? formatPhoneNumber(inputValue.replace(/^\+\d{1,4}/, '')) : '' }}
          </span>
        </div>

        <!-- Regular input field (for email or non-edit mode) -->
        <span
          v-else-if="editMode"
          :data-test-id="'patient-form-change-add-' + option.type"
          class="ml-1 border-b border-black whitespace-pre-wrap"
          :class="[inputValue ? 'pr-2' : 'pr-20']"
          role="textbox"
          contenteditable
          @click.prevent
          @input="changeInputValue"
        >
          {{ inputValue }}
        </span>

        <!-- Display value when not in edit mode -->
        <span v-else-if="inputValue && option.type === 'phone'" class="ml-1"> [{{ inputValue }}] </span>
        <span v-else-if="inputValue" class="ml-1"> [{{ inputValue }}] </span>
        <span v-else class="ml-1 underline cursor-pointer" @click.prevent="openEditMode">{{ emptySubtitle }}</span>
      </label>

      <!-- Edit mode controls -->
      <div v-if="editMode" class="inline-block">
        <!-- Confirm button -->
        <i
          :data-test-id="'patient-form-submit-' + option.type"
          class="fa-solid fa-check ml-2.5 cursor-pointer text-2xl"
          style="color: #006600"
          @click="confirmEditMode"
        />
        <!-- Cancel button -->
        <i
          :data-test-id="'patient-form-cancel-' + option.type"
          class="fa-solid fa-xmark ml-2.5 cursor-pointer text-2xl"
          style="color: #ad0000"
          @click="cancelEditMode"
        />
      </div>

      <!-- Edit button when not in edit mode -->
      <i
        v-else-if="!isEditingText"
        :data-test-id="'patient-form-edit-' + option.type"
        class="fa-solid fa-pen ml-2.5 cursor-pointer"
        @click="openEditMode"
      />

      <!-- Error message display -->
      <span v-if="errorMessage && showErrorMessage" class="block error-message">
        <i class="fa-solid fa-triangle-exclamation mx-1"></i>
        {{ errorMessage }}
      </span>
    </div>
  </div>
</template>

<!-- 
  Styles section defines component-specific CSS
  Features:
  - Custom radio button styling
  - Input field appearance
  - Error message formatting
  - Responsive design adjustments
-->
<style lang="scss" scoped>
/* Custom radio button styling */
input[type='radio'] {
  accent-color: #029fd6;
}

/* Remove outline from editable content */
span[role='textbox']:focus {
  outline: none !important;
}

/* Edit mode border styling */
.edit-border {
  border: 1px solid #d9d9d9;
}

/* Error message styling */
.error-message {
  font-size: 14px;
  color: #ac0606;
}

/* Custom radio button appearance */
.custom-radio {
  @apply appearance-none border-2 rounded-full h-10 w-10 sm:h-12 sm:w-12 cursor-pointer;
}

/* Selected radio button state */
.custom-radio:checked {
  background-color: white;
  box-shadow: 0 0 0 2px white, 0 0 0 4px #00a7e1;
}

/* Custom radio button inner circle */
.custom-radio:checked::before {
  content: '';
  display: block;
  width: 90%;
  height: 90%;
  border-radius: 50%;
  background-color: #00a7e1;
  position: relative;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
</style>
