<template>
  <div :id="inputId" class="drugs-section">
    <div class="col-12">
      <div class="flex flex-col">
        <div class="my-2 sm:-mx-6 lg:-mx-8">
          <div class="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
            <div class="shadow border-b border-gray-200 sm:rounded-lg">
              <table class="min-w-full divide-y divide-gray-200">
                <thead class="bg-gray-50">
                  <tr>
                    <th
                      v-if="enableDrugName"
                      scope="col"
                      class="px-2 py-3 text-left text-xs font-bold text-gray-500 uppercase tracking-wider"
                    >
                      Drug Name
                    </th>
                    <th
                      v-if="enableDrugDosage"
                      scope="col"
                      class="px-2 py-3 w-24 text-left text-xs font-bold text-gray-500 uppercase tracking-wider"
                    >
                      Dosage
                    </th>
                    <th
                      v-if="enableDrugCode"
                      scope="col"
                      class="px-4 py-3 w-24 text-left text-xs font-bold text-gray-500 uppercase tracking-wider"
                    >
                      Drug Code
                    </th>
                    <th
                      v-if="enableBranding"
                      scope="col"
                      class="px-4 py-3 w-72 text-left text-xs font-bold text-gray-500 uppercase tracking-wider"
                    >
                      Branding
                    </th>
                    <th
                      v-if="enableEndDate"
                      scope="col"
                      class="px-4 py-3 w-72 text-left text-xs font-bold text-gray-500 uppercase tracking-wider"
                    >
                      Start - End Date
                    </th>
                    <th
                      v-else-if="enableStartDate"
                      scope="col"
                      class="px-4 py-3 w-72 text-left text-xs font-bold text-gray-500 uppercase tracking-wider"
                    >
                      Start Date
                    </th>
                    <th
                      v-if="enableDuration"
                      scope="col"
                      class="py-3 w-24 text-left text-xs font-bold text-gray-500 uppercase tracking-wider"
                    >
                      Duration (Hrs)
                    </th>
                    <th
                      v-if="enableDuration"
                      scope="col"
                      class="py-3 w-24 text-left text-xs font-bold text-gray-500 uppercase tracking-wider"
                    >
                      Duration (Days)
                    </th>
                    <th
                      v-if="enablePatientWeight"
                      scope="col"
                      class="px-4 w-24 py-3 text-left text-xs font-bold text-gray-500 uppercase tracking-wider"
                    >
                      Patient Weight (KG)
                    </th>
                    <th
                      v-if="enableDescription"
                      scope="col"
                      class="px-4 py-3 text-left text-xs font-bold text-gray-500 uppercase tracking-wider"
                    >
                      Reason
                    </th>
                    <th
                      scope="col"
                      class="px-2 py-3 text-left text-xs font-bold text-gray-500 uppercase tracking-wider"
                    ></th>
                  </tr>
                </thead>

                <tbody>
                  <!-- Grey/white alternate rows -->
                  <tr
                    id="drug-row"
                    :class="{ 'bg-white': idx % 2 === 0, 'bg-gray-50': idx % 2 !== 0 }"
                    v-for="(drug, idx) in treatment.pharmacology"
                    :key="drug.ivTreatmentId"
                  >
                    <td v-if="enableDrugName" class="px-2 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                      <input
                        :id="inputId + '_drug_name' + idx"
                        type="text"
                        class="custom-input shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                        placeholder=""
                        v-model="drug.drug.drug_name"
                        :disabled="isTextBoxDisabled"
                      />
                    </td>
                    <td v-if="enableDrugDosage" class="px-2 py-4 whitespace-nowrap text-sm text-gray-500">
                      <input
                        :id="inputId + '_dosage' + idx"
                        type="text"
                        class="custom-input shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                        placeholder=""
                        v-model="drug.drug.dosage"
                        :disabled="isTextBoxDisabled"
                      />
                    </td>
                    <td v-if="enableDrugCode" class="px-4 py-4 whitespace-nowrap text-sm text-gray-500">
                      <input
                        :id="inputId + '_drug_code' + idx"
                        type="text"
                        class="custom-input shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                        placeholder="if applicable"
                        v-model="drug.drug.drug_code"
                        :disabled="isTextBoxDisabled"
                      />
                    </td>
                    <td v-if="enableBranding" class="px-4 py-4 whitespace-nowrap text-sm text-gray-500">
                      <BooleanQuestion
                        :id="inputId + '_branding' + idx"
                        v-model="drug.drug.is_branded"
                        :onleft="true"
                        :options="[
                          { label: 'Branded', value: true },
                          { label: 'Generic', value: false },
                          { label: 'Unknown', value: null }
                        ]"
                      />
                    </td>

                    <td v-if="enableEndDate" class="py-4 whitespace-nowrap text-sm text-gray-500">
                      <date-picker
                        class="datepicker-width"
                        :id="inputId + '_start_time' + idx"
                        v-model:value="drug.date_of_service"
                        value-type="YYYY-MM-DD"
                        format="DD-MM-YYYY"
                        :disabled="isTextBoxDisabled"
                        :append-to-body="false"
                      ></date-picker>
                      <date-picker
                        class="datepicker-width"
                        :id="inputId + '_end_time' + idx"
                        v-model:value="drug.end_of_service"
                        value-type="YYYY-MM-DD"
                        format="DD-MM-YYYY"
                        :disabled="isTextBoxDisabled"
                        :append-to-body="false"
                      ></date-picker>

                      <!-- 
                    <CustomDatePicker class="mb-4" :id="inputId + '_start_time' + idx" v-model="drug.dateOfService"/>
                    <CustomDatePicker class="mb-4" :id="inputId + '_end_time' + idx" v-model="drug.endOfService"/> -->
                    </td>
                    <td v-else-if="enableStartDate" class="py-4 whitespace-nowrap text-sm text-gray-500">
                      <date-picker
                        :id="inputId + '_start_time' + idx"
                        v-model:value="drug.date_of_service"
                        value-type="YYYY-MM-DD"
                        format="DD-MM-YYYY"
                        :append-to-body="false"
                      ></date-picker>
                      <!-- <CustomDatePicker class="mb-4" :id="inputId + '_start_time' + idx" v-model="drug.dateOfService"/> -->
                    </td>

                    <td v-if="enableDuration" class="px-4 py-4 whitespace-nowrap text-sm text-gray-500">
                      <input
                        :id="inputId + '_duration_hours' + idx"
                        type="number"
                        class="custom-input shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                        placeholder="Hrs"
                        v-model="drug.duration_hours"
                        :disabled="isTextBoxDisabled"
                      />
                    </td>
                    <td v-if="enableDuration" class="px-4 py-4 whitespace-nowrap text-sm text-gray-500">
                      <input
                        :id="inputId + '_duration_days' + idx"
                        type="number"
                        class="custom-input shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                        placeholder="Days"
                        v-model="drug.duration_days"
                        :disabled="isTextBoxDisabled"
                      />
                    </td>

                    <td v-if="enablePatientWeight" class="px-4 py-4 whitespace-nowrap text-sm text-gray-500">
                      <input
                        :id="inputId + '_weight' + idx"
                        class="custom-input shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                        placeholder="KG"
                        v-model="drug.patient_weight_kg"
                        :disabled="isTextBoxDisabled"
                      />
                    </td>
                    <td v-if="enableDescription" class="px-2 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                      <textarea
                        :id="inputId + '_description' + idx"
                        class="custom-input shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                        placeholder="Description of treatment related to Principal and Secondary Diagnoses (if any)"
                        v-model="drug.description"
                        rows="4"
                        style="line-height: 1.5"
                        :disabled="isTextBoxDisabled"
                      ></textarea>
                    </td>

                    <td
                      v-if="!isTextBoxDisabled"
                      :id="inputId + '_remove_drug' + idx"
                      class="px-2 py-4 whitespace-nowrap text-sm text-gray-500 font-semibold cursor-pointer"
                      @click="removeDrug(idx, treatment.pharmacology)"
                    >
                      <i class="fas fa-minus-circle text-red-600"></i> Remove
                    </td>
                    <td
                      v-else
                      :id="inputId + '_remove_drug' + idx"
                      class="px-2 py-4 whitespace-nowrap text-sm text-gray-500 font-semibold"
                    >
                      <i class="fas fa-minus-circle text-grey-600"></i> Remove
                    </td>
                  </tr>
                  <tr>
                    <div class="col-6 mt-4 pl-5">
                      <div
                        v-if="!isTextBoxDisabled"
                        :id="inputId + '_add_drug'"
                        class="my-3 custom-click cursor-pointer font-semibold"
                        @click="addDrug(treatment)"
                      >
                        <i class="fas fa-plus-circle text-green-600"></i> {{ addAnotherText }}
                      </div>
                      <div class="my-3 text-gray-200" v-else><i class="fas fa-plus-circle text-gray-200"></i> {{ addAnotherText }}</div>
                    </div>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// components
//import CustomDatePicker from '@/components/claim-forms/components/Datepicker'
import BooleanQuestion from '@/components/claim-forms/components/BooleanQuestion.vue';
import DatePicker from 'vue-datepicker-next';
import 'vue-datepicker-next/index.css';
import { mapState } from 'vuex';

/* Params
  modelValue // objects with array of bed fields:
  title // title of section String
*/

export default {
  name: 'DrugList',
  props: {
    modelValue: {},
    title: { default: '' },
    enableStartDate: { type: Boolean, default: false },
    enableEndDate: { type: Boolean, default: false },
    enableDuration: { type: Boolean, default: false },
    enablePatientWeight: { type: Boolean, default: false },
    enableDescription: { type: Boolean, default: false },
    enableDrugCode: { type: Boolean, default: false },
    enableDrugName: { type: Boolean, default: false },
    enableDrugDosage: { type: Boolean, default: false },
    enableBranding: { type: Boolean, default: false },
    id: {}
  },
  components: {
    BooleanQuestion,
    //CustomDatePicker,
    DatePicker
  },
  data() {
    return {
      ivTreatmentIdx: -1,
      forceRender: 0,
      inputId: this.id || `ivdrugs_${(Math.random() * 1000).toFixed()}`
    };
  },
  computed: {
    ...mapState(['textBoxDisabled']),
    isTextBoxDisabled() {
      return this.textBoxDisabled;
    },
    addAnotherText() {
      return 'Add another entry';
    },
    treatment: {
      get() {
        return this.modelValue;
      },
      set(newValue) {
        this.$emit('update:modelValue', newValue);
      }
    }
  },
  methods: {
    addDrug(obj) {
      // Fake, negative diagnosis ids created here to allow new entries to be removed.
      // Submission to server ignores this id and creates its own.
      let start_date =
        obj.pharmacology.length > 0 ? obj.pharmacology[obj.pharmacology.length - 1].date_of_service : null;
      let end_date = obj.pharmacology.length > 0 ? obj.pharmacology[obj.pharmacology.length - 1].end_of_service : null;
      obj.pharmacology.push({
        ivTreatmentId: this.ivTreatmentIdx,
        drug: {
          dosage: null,
          drug_code: null,
          drug_id: null,
          drug_insurer_code_id: null,
          drug_name: null,
          is_branded: null,
          product_code: null,
          product_name: null
        },
        date_of_service: start_date,
        end_of_service: end_date,
        patient_weight_kg: null,
        description: null,
        duration_hours: null,
        duration_days: null
      });
      this.ivTreatmentIdx -= 1;
      this.forceRender++;
    },
    removeDrug(index, pharmacology) {
      const pharmacologyIndex = pharmacology[index];
      if (pharmacologyIndex) {
        pharmacology.splice(index, 1);
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.custom-input {
  border-radius: 5px;
}

.custom-input:disabled {
  background-color: #f3f3f3;
  color: #ccc;
  border-color: #ccc;
  cursor: not-allowed;
}

.required {
  color: red;
}

.custom-sub-title {
  font-size: 18px;
  font-weight: 600;
  font-style: italic;
  text-decoration: underline;
  color: #8b8b8b;
}
.datepicker-width {
  width: 150px;
}
</style>
