// A simplified set of state-transitions that a consultant claim can move through
// on its way to submission to a insurance company.
export const ConsultantClaimTargetStateEnum = Object.freeze({
  // Revert a claim to a draft-state (its initial state) for editing.  Remove any
  // consultant signings and void any non-draft invoices.
  REVERT_TO_DRAFT: 'revert_to_draft',

  // Mark a claim as rejected by the consultant, putting it into a queue for further
  // work by the claims admin.
  CONSULTANT_REJECTED: 'consultant_rejected',

  // Mark a claim as rejected by the claims-admin, which transitions it into a finalised
  // rejected state.
  ADMIN_REJECTED: 'admin_rejected',

  // Mark a claim as ready for consultant review.  This is the desired state to allow the
  // consultant to sign the claim.
  CONSULTANT_REVIEW: 'consultant_review',

  // A claim that has been signed by the consultant and is ready for final review by the
  // claims admin.
  CONSULTANT_APPROVED: 'consultant_approved',

  // Finalises the claim, making it immutable, converting the draft invoice to an immutable
  // invoice.  The claim is placed into a queue for automatic submission to a third-party
  // provider e.g. an insurance company.
  FINALISE: 'finalise',

  // Claim has been submitted to the third-party (insurance company).
  SUBMITTED_TO_THIRD_PARTY: 'submitted_to_third_party'
});
