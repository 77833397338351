<template>
  <Modal v-if="showManageModalClaims" @close-modal="setModalOff()">
    <template v-slot:header>
      <div class="flex row">
        <div class="text-center mr-6 mt-1">
          <i class="fa-solid fa-circle-exclamation text-5xl"></i>
        </div>
        <div class="col-span-3">
          <h3 class="text-lg text-left leading-6 font-medium medo-grey-600 my-2 m-2">
            {{ titleText }}
          </h3>
          <p class="modal-paragraph mt-4">
            {{ titleText2 }}
            <br />
            <br />
            {{ titleText3 }}
          </p>
        </div>
      </div>
      <div class="modalDelete mt-4">
        <div v-if="!optionReverse" class="ml-auto">
          <button class="modal-button py-2 px-18 whitespace-nowrap" @click="saveChanges()">
            {{ saveReplace }}
          </button>
          <button class="modal-button-canceled py-2 px-4" @click="setModalOff()">{{ cancelReplace }}</button>
        </div>

        <div v-else class="ml-auto">
          <button class="modal-button-canceled-reverse py-2 px-4 bg-transparent" @click="setModalOff()">
            {{ cancelReplace }}
          </button>
          <button class="modal-button py-2 px-18 whitespace-nowrap bg-blue" @click="saveChanges()">
            {{ saveReplace }}
          </button>
        </div>
      </div>
    </template>
  </Modal>
</template>

<script>
import Modal from '@/components/modal/Modal';

export default {
  name: 'EditClaimsModal',
  props: {
    showManageModal: {
      type: Boolean,
      default: false
    },
    titleProps: {
      type: String,
      default: ''
    },
    titlePropsSub: {
      type: String,
      default: ''
    },
    titlePropsFooter: {
      type: String,
      default: ''
    },
    tittleSaveReplace: {
      type: String,
      default: ''
    },
    tittleCancelReplace: {
      type: String,
      default: ''
    },
    typeModal: {
      type: Boolean,
      default: true
    },
    optionReverse: {
      type: Boolean,
      default: false
    }
  },

  emits: ['setModalOff', 'saveChanges'],

  components: {
    Modal
  },

  computed: {
    titleText() {
      return this.titleProps || 'Save all changes made in Claim form?';
    },
    titleText2() {
      return (
        this.titlePropsSub ||
        'Are you sure you want to save all changes made If you are unsure whether you want to save the changes made, please choose the button “Cancel.'
      );
    },
    titleText3() {
      return this.titlePropsFooter || 'You can then review the claim form again before making a decision.';
    },
    saveReplace() {
      return this.tittleSaveReplace || 'Save Changes';
    },
    cancelReplace() {
      return this.tittleCancelReplace || 'Cancel';
    }
  },

  watch: {
    showManageModal(val) {
      this.showManageModalClaims = val;
    }
  },

  data() {
    return {
      showManageModalClaims: false
    };
  },

  mounted() {
    this.showManageModalClaims = this.showManageModal;
  },

  methods: {
    setModalOff() {
      this.showManageModalClaims = false;
      this.$emit('setModalOff', false);
    },
    saveChanges() {
      this.$emit('saveChanges', true);
    }
  }
};
</script>

<style scoped>
.modal-button {
  border: 2px solid #00a7e1;
  border-radius: 50px;
  height: 45px;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 100%;
  align-items: center;
  white-space: nowrap;
  display: inline-block;
  padding: 0 20px;
}
.modal-button-canceled {
  background: #00a7e1;
  border-radius: 50px;
  width: 120px;
  height: 45px;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 100%;
  align-items: center;
  margin: 10px;
}
.modal-button-canceled-reverse {
  border: 2px solid #00a7e1;
  border-radius: 50px;
  width: 120px;
  height: 45px;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 100%;
  align-items: center;
  margin: 10px;
}
.modal-paragraph {
  font-size: 14px;
}
</style>
