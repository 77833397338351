<template>
    <div>
      <second-menu-user-management />

      <div class="main-content">

        <div class="claim-title medo-secondary">
          Patients Management Dashboard
        </div>


      </div>

    </div>
</template>

<script>
import SecondMenuUserManagement from '@/components/navigation/SecondMenuUserManagement.vue'

export default {
  name: 'Patients-dashboard',
  components: {
    SecondMenuUserManagement
  },
  data () {
    return {

    }
  },
  methods: {

  },
  mounted () {

  }
}
</script>

<style scoped>

</style>