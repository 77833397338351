<template>
  <div :id="inputId" class="col-12 procedures-list-section">
    <div v-for="(procedure, index) in procedures" :key="procedure.id" class="col-12 mt-1 li">
      <div v-if="index > 0" style="margin-top: 15px; border: 2px solid rgb(238, 238, 238)"></div>
      <div class="custom-procedure-section">
        <section>
          <div class="col-12 mt-4 float-left">
            <div class="custom-code-input">
              <div class="flex justify-between">
                <label for="email" class="mb-1 block text-sm font-medium text-gray-700">Procedure Code:</label>
              </div>
              <ProcedureCodeTypeahead
                :id="inputId + '_proc_code' + index"
                v-model="procedure.procedure.procedure_code"
                placeholder="Search procedure code"
                @selected-procedure="selected_procedure(index, $event)"
                @cleared-procedure="cleared_procedure(index)"
                :dataValue="dataValue"
                :not_categories="not_in_category"
              />
            </div>
            <div class="custom-text-input">
              <div class="flex justify-between">
                <label for="email" class="mb-1 block text-sm font-medium text-gray-700">Procedure Description:</label>
              </div>
              <textarea
                :id="inputId + '_proc_desc' + index"
                v-model="procedure.procedure.procedure_code_description"
                readonly
                rows="3"
                class="p-2 rounded"
                :disabled="isTextBoxDisabled"
              />
            </div>
          </div>
        </section>

        <!-- break -->

        <section v-if="noClinicalIndicators !== true">
          <div
            v-if="
              (clinicalIndicatorCodes[index] && clinicalIndicatorCodes[index].length > 0) ||
              procedure.procedure.clinical_indicator_code
            "
            class="col-12 mt-4"
          >
            <div class="custom-code-input">
              <div class="flex justify-between">
                <label for="email" class="block text-sm font-medium text-gray-700">Clinical Indicator:</label>
              </div>
              <Typeahead
                :id="inputId + '_clinical_code' + index"
                v-model="procedure.procedure.clinical_indicator_code"
                :items="clinicalIndicatorCodes[index]"
                placeholder="Search clinical code"
                :min-input-length="1"
                :item-projection="showCIItem"
                :item-value="getCIItemValue"
                @selectItem="selectCIItem"
                @update:modelValue="check_if_matching($event, index)"
                :field="procedure"
              />
            </div>
            <div class="custom-text-input">
              <div class="flex justify-between">
                <label for="email" class="mb-1 block text-sm font-medium text-gray-700">Clinical Description:</label>
              </div>
              <textarea
                :id="inputId + '_clinical_desc' + index"
                v-model="procedure.procedure.clinical_indicator_code_description"
                readonly
                rows="1"
                class="p-2 rounded"
                :disabled="isTextBoxDisabled"
              />
            </div>
          </div>
        </section>

        <!-- break -->

        <div v-if="noDate !== true" class="col-12 mt-4">
          <div class="mt-6">
            <label class="block text-sm font-medium text-gray-700">Date of Service</label>
            <date-picker
              :id="inputId + '_date_of_service' + index"
              v-model:value="procedure.date_of_service"
              value-type="YYYY-MM-DD"
              format="DD-MM-YYYY"
              :disabled="isTextBoxDisabled"
              :append-to-body="false"
            ></date-picker>
          </div>
          <!-- <DateQuestion :id="inputId + '_date_of_service' + index" v-model="procedure.dateOfService" question="Date of Service"/> -->
        </div>

        <div class="col-6 float-left" v-if="noAnaesthesia !== true">
          <EnumQuestion
            :id="inputId + '_anaesthesia' + index"
            v-model="procedure.anaesthesia.application"
            :options="anaesthesiaOptions"
            question="Anaesthesia"
          />
        </div>

        <TextQuestion
          v-show="enableFacilityName === true"
          v-model="procedure.otherFacilityName"
          :id="inputId + '_facility_name' + index"
          question="Facility name, if performed elsewhere"
        />
      </div>

      <div class="custom-remove-section float-right">
        <div
          v-if="!textBoxDisabled"
          :id="inputId + '_remove_treatment' + index"
          class="mt-2 custom-click cursor-pointer text-center"
          @click="removeTreatment(index, procedures)"
        >
          <i class="fas fa-minus-circle text-red-600 hover:text-red-700"></i>
          <div class="font-semibold">Remove Entry</div>
        </div>
      </div>
    </div>

    <div class="col-6 mt-1 mb-5">
      <div
        v-if="!textBoxDisabled"
        :id="inputId + '_add_treatment'"
        class="mt-3 custom-click cursor-pointer font-semibold"
        @click="addTreatment(procedures)"
      >
        <i class="fas fa-plus-circle text-green-600"></i> Add another treatment
      </div>
      <div v-else class="text-gray-200"><i class="fas fa-plus-circle text-gray-200"></i> Add another treatment</div>
    </div>
  </div>
</template>

<script>
import Typeahead from '@/components/claim-forms/components/Typeahead.vue';
import { mapState } from 'vuex';
// css
import '@/assets/css/typeahead.css';
import 'vue3-timepicker/dist/VueTimepicker.css';
import EnumQuestion from '@/components/claim-forms/components/EnumQuestion.vue';
import { ProcedureCodeCategory } from 'enums';
import TextQuestion from '@/components/form-fields/TextQuestion.vue';
import ProcedureCodeTypeahead from '@/components/form-fields/ProcedureCodeTypeahead.vue';

import DatePicker from 'vue-datepicker-next';
import 'vue-datepicker-next/index.css';

/**
 * Component for representing a list of procedures (treatments) applied to a patient.
 *
 * Properties:
 *   :treatments="sectionData.treatment"
 *   :noAnaesthesia="true" - if defined then the Anaesthesia options are suppressed.
 *   :anaesthesia-options - supply a list of dict of the anaesthesia application options specific for the
 *     form.  The 'name' appears as the description in the form, the 'value' is the enum value.
 *     e.g.
 *      "[{name: 'General', value: 'GENERAL'}, {name: 'Regional', value: 'REGIONAL'}, {name: 'Monitored', value: 'MONITORED'}, {name: 'N/A', value: null}]"
 */
export default {
  name: 'Procedure-list',
  components: {
    ProcedureCodeTypeahead,
    TextQuestion,
    EnumQuestion,
    Typeahead,
    DatePicker
  },
  props: {
    modelValue: {},
    dataValue: {},
    not_in_category: [
      ProcedureCodeCategory.RADIOLOGY_NORMAL,
      ProcedureCodeCategory.RADIOLOGY_MRI,
      ProcedureCodeCategory.RADIOLOGY_CT,
      ProcedureCodeCategory.RADIOLOGY_NUCLEAR,
      ProcedureCodeCategory.RADIOLOGY_INTERV,
      ProcedureCodeCategory.RADIOLOGY_INVASIVE_VASCULAR,
      ProcedureCodeCategory.RADIOLOGY_INVIVO
    ],
    noDate: {
      required: false,
      default: false,
      type: Boolean
    },
    noAnaesthesia: {
      required: false,
      default: false,
      type: Boolean
    },
    noClinicalIndicators: {
      required: false,
      default: false,
      type: Boolean
    },
    defaultDate: {
      required: false,
      default: new Date()
    },
    anaesthesiaOptions: {
      required: false,
      type: Array
    },
    enableFacilityName: {
      required: false,
      type: Boolean
    },
    id: {
      type: String
    },
    nonSurgical: { type: Boolean, required: false, default: false }
  },

  data() {
    return {
      formActive: false,
      picked: new Date(),
      sectionData: this.claim,
      sectionCompleted: false,

      treatmentIdx: -1,
      clinicalIndicatorCodes: [],
      inputId: this.id || `procedure_list${(Math.random() * 1000).toFixed()}`
    };
  },

  computed: {
    ...mapState(['textBoxDisabled']),
    isTextBoxDisabled() {
      return this.textBoxDisabled;
    },
    procedures: {
      get() {
        return this.modelValue;
      },
      set(newValue) {
        this.$emit('update:modelValue', newValue);
      }
    }
  },

  methods: {
    selected_procedure(index, item) {
      this.cleared_procedure(index);
      this.procedures[index].procedure.procedure_code = item.procedure_code;
      this.procedures[index].procedure.procedure_code_description = item.procedure_code_description;
      this.clinicalIndicatorCodes[index] = [];
      for (let cidx = 0; cidx < item.clinical_indicator_codes.length; cidx++) {
        this.clinicalIndicatorCodes[index].push({
          clinical_indicator_code: item.clinical_indicator_codes[cidx].ci_code,
          clinical_indicator_code_description: item.clinical_indicator_codes[cidx].ci_description
        });
      }
    },
    cleared_procedure(index) {
      this.procedures[index].procedure.procedure_code = '';
      this.procedures[index].procedure.procedure_code_description = '';
      this.clinicalIndicatorCodes[index] = [];
      this.procedures[index].procedure.clinical_indicator_code = '';
      this.procedures[index].procedure.clinical_indicator_code_description = '';
    },

    showCIItem(item) {
      if (item !== null) {
        return item.clinical_indicator_code + ' ' + item.clinical_indicator_code_description;
      } else {
        return '';
      }
    },

    getCIItemValue(item) {
      if (item !== null) {
        return item.clinical_indicator_code;
      } else {
        return '';
      }
    },

    selectCIItem(item, field) {
      field.procedure.clinical_indicator_code = item.clinical_indicator_code;
      field.procedure.clinical_indicator_code_description = item.clinical_indicator_code_description;
    },

    check_if_matching(item, index) {
      for (let cidx = 0; cidx < this.clinicalIndicatorCodes[index]?.length; cidx++) {
        if (this.clinicalIndicatorCodes[index].clinical_indicator_code === item) {
          this.procedures[index].procedure.clinical_indicator_code_description =
            this.clinicalIndicatorCodes[index].procedure.clinical_indicator_code_description;
          return;
        }
      }

      this.procedures[index].procedure.clinical_indicator_code_description = '';
    },

    addTreatment(treatments) {
      // Fake, negative diagnosis ids created here to allow new entries to be removed.
      // Submission to server ignores this id and creates its own.
      let dos = treatments.length > 0 ? treatments[treatments.length - 1].date_of_service : this.defaultDate;
      let data = new Date(dos);
      let year = data.getUTCFullYear();
      let month = String(data.getUTCMonth() + 1).padStart(2, '0');
      let day = String(data.getUTCDate()).padStart(2, '0');
      dos = `${year}-${month}-${day}`;
      treatments.push({
        id: Date.now().toString(36) + Math.random().toString(36).substr(2),
        non_surgical: this.nonSurgical,
        date_of_service: dos,
        anaesthesia: {
          anaesthesia: {
            dosage: null,
            drug_code: null,
            drug_id: null,
            drug_insurer_code_id: null,
            drug_name: null,
            is_branded: null,
            product_code: null,
            product_name: null
          },
          anaesthetist: null,
          application: null,
          reason: null
        },
        procedure: {
          procedure_code: null,
          procedure_code_description: null,
          clinical_indicator_code: null,
          clinical_indicator_code_description: null
        }
      });
      this.treatmentIdx -= 1;
    },
    removeTreatment(index, treatments) {
      const treatmentIndex = treatments[index];
      if (treatmentIndex) {
        treatments.splice(index, 1);
      }
    }
  },
  async mounted() {
    for (let idx = 0; idx < this.procedures.length; idx++) {
      this.procedures[idx].id = Date.now().toString(36) + Math.random().toString(36).substr(2);
    }
  }
};
</script>

<style scoped>
.custom-procedure-section {
  float: left;
  width: calc(100% - 130px);
  margin-top: 20px;
  margin-bottom: 20px;
  border-right: 1px solid #eee;
}

.custom-remove-section {
  float: right;
  width: 130px;
  padding-top: 150px;
}

.custom-code-input {
  float: left;
  width: 200px;
}

.custom-text-input {
  float: left;
  width: calc(100% - 220px);
  padding-left: 70px;
}

textarea {
  width: 100%;
  border: 1px solid #dedede;
}

textarea:disabled {
  background-color: #f3f3f3;
  color: #ccc;
  border-color: #ccc;
  cursor: not-allowed;
}
</style>
