<script setup>
import { ref, computed } from 'vue';

const props = defineProps({
  id: {
    type: String,
    required: false
  },
  modelValue: {
    type: Boolean,
    default: false
  },
  title: {
    type: String,
    required: false
  }
});
const emit = defineEmits(['update:modelValue']);

const inputId = ref(props.id || `checkboxQuestionReversed${(Math.random() * 1000).toFixed()}`);
const localData = computed({
  get() {
    return props.modelValue;
  },
  set(newValue) {
    emit('update:modelValue', newValue);
  }
});
</script>

<template>
  <div class="flex items-center justify-between">
    <label v-if="title" :for="inputId" class="cursor-pointer">{{ title }}</label>
    <input
      :id="inputId"
      v-model="localData"
      type="checkbox"
      class="h-12 w-12 text-medosync-blue rounded-lg cursor-pointer border-2"
    />
  </div>
</template>

<style scoped>
[type='checkbox']:checked {
  padding: 0.2rem 0.2rem;
  background-origin: content-box;
  background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMzciIGhlaWdodD0iMjYiIHZpZXdCb3g9IjAgMCAzNyAyNiIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPGcgaWQ9ImNoZWNrIj4KPGxpbmUgaWQ9IkxpbmUgMzMiIHgxPSIxLjQxNDIxIiB5MT0iMTMuMTA0NSIgeDI9IjEyLjAwNSIgeTI9IjIzLjY5NTMiIHN0cm9rZT0iYmxhY2siIHN0cm9rZS13aWR0aD0iMiIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIi8+CjxsaW5lIGlkPSJMaW5lIDM0IiB4MT0iMTIuMTQwNiIgeTE9IjIzLjU5NTYiIHgyPSIzNC43MzY0IiB5Mj0iMC45OTk3NjUiIHN0cm9rZT0iYmxhY2siIHN0cm9rZS13aWR0aD0iMiIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIi8+CjwvZz4KPC9zdmc+Cg==);
}

[type='checkbox']:focus {
  outline: none;
  box-shadow: none;
}
</style>
