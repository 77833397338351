import axios from 'axios';
import * as queries from './queries.js';
import * as mutations from './mutations.js';
import StringUtility from 'utils/stringUtility';

// token
const customToken = localStorage.getItem('_token');

// URL of backend server
const baseUrl = import.meta.env.VITE_BASE_URL;
const cfPdfUrl = import.meta.env.VITE_CLAIMFORM_URL;

// axios options (TO DO move to config)
let axiosOptions;
let axiosPdfOptions;
if (customToken) {
  axiosOptions = {
    baseURL: baseUrl,
    withCredentials: true,
    headers: {
      Authorization: customToken,
    },
  };
  axiosPdfOptions = {
    baseURL: cfPdfUrl,
    withCredentials: true,
    headers: {
      Authorization: customToken,
    },
  };
} else {
  axiosOptions = {
    baseURL: baseUrl,
    withCredentials: true,
  };
  axiosPdfOptions = {
    baseURL: cfPdfUrl,
    withCredentials: true,
  };
}
const instance = axios.create(axiosOptions);
const pdfInstance = axios.create(axiosPdfOptions);

export default {
  // get hospitals
  listHospitals(group_id) {
    return instance
      .post('', {
        query: queries.listHospitals.query,
        variables: {
          groupId: group_id,
        },
      })
      .then((res) => {
        if (res.data.errors && res.data.errors.length > 0)
          throw res.data.errors[0].message;
        else return res;
      });
  },

  listInsurersContracts() {
    return instance
      .post('', {
        query: queries.listInsurerContracts.query,
      })
      .then((res) => {
        if (res.data.errors && res.data.errors.length > 0)
          throw res.data.errors[0].message;
        else return res;
      });
  },

  //list specialisations
  listMedicSpecialisationTypes() {
    return instance
      .post('', {
        query: queries.listMedicSpecialisationTypes.query,
      })
      .then((res) => {
        if (res.data.errors && res.data.errors.length > 0)
          throw res.data.errors[0].message;
        else return res;
      });
  },

  //list groups
  listAclGroups() {
    return instance
      .post('', {
        query: queries.listAclGroups.query,
      })
      .then((res) => {
        if (res.data.errors && res.data.errors.length > 0)
          throw res.data.errors[0].message;
        else return res;
      });
  },

  //create group
  createAclGroup(name, desc) {
    return instance
      .post('', {
        query: mutations.createAclGroup.query,
        variables: {
          name: name,
          desc: desc,
        },
      })
      .then((res) => {
        if (res.data.errors && res.data.errors.length > 0)
          throw res.data.errors[0].message;
        else return res;
      });
  },

  //list logins
  listLogins() {
    return instance
      .post('', {
        query: queries.listLogins.query,
      })
      .then((res) => {
        if (res.data.errors && res.data.errors.length > 0)
          throw res.data.errors[0].message;
        else return res;
      });
  },

  updateLogin(updateLoginInput) {
    return instance
      .post('', {
        query: mutations.updateLogin.query,
        variables: { updateLoginInput: updateLoginInput },
      })
      .then((res) => {
        if (res.data.errors && res.data.errors.length > 0)
          throw res.data.errors[0].message;
        else return res;
      });
  },

  setUserPassword(newPasswordLogin) {
    return instance
      .post('', {
        query: mutations.setUserPassword.query,
        variables: { newPasswordLogin: newPasswordLogin },
      })
      .then((res) => {
        if (res.data.errors && res.data.errors.length > 0)
          throw res.data.errors[0].message;
        else return res;
      });
  },

  setUserPasswordWithOldPassword(newPasswordLogin) {
    return instance
      .post('', {
        query: mutations.setUserPasswordWithOldPassword.query,
        variables: { newPasswordLogin: newPasswordLogin },
      })
      .then((res) => {
        if (res.data.errors && res.data.errors.length > 0)
          throw res.data.errors[0].message;
        else return res;
      });
  },

  //create user
  createUser(user) {
    return instance
      .post('', {
        query: queries.createUser.query,
        variables: {
          user: user,
        },
      })
      .then((res) => {
        if (res.data.errors && res.data.errors.length > 0)
          throw res.data.errors[0].message;
        else return res;
      });
  },

  //deactivate user
  deactivateUser(hospitalId, hospitalGroupId, email, loginId) {
    return instance
      .post('', {
        query: mutations.deactivateUser.query,
        variables: {
          hospitalId: hospitalId,
          hospitalGroupId: hospitalGroupId,
          email: email,
          loginId: loginId,
        },
      })
      .then((res) => {
        if (res.data.errors && res.data.errors.length > 0)
          throw res.data.errors[0].message;
        else return res;
      });
  },

  //activate user
  activateUser(hospitalId, hospitalGroupId, email, loginId) {
    return instance
      .post('', {
        query: mutations.activateUser.query,
        variables: {
          hospitalId: hospitalId,
          hospitalGroupId: hospitalGroupId,
          email: email,
          loginId: loginId,
        },
      })
      .then((res) => {
        if (res.data.errors && res.data.errors.length > 0)
          throw res.data.errors[0].message;
        else return res;
      });
  },

  //create person
  createPerson(person) {
    return instance
      .post('', {
        query: queries.createPerson.query,
        variables: {
          person: person,
        },
      })
      .then((res) => {
        if (res.data.errors && res.data.errors.length > 0)
          throw res.data.errors[0].message;
        else return res;
      });
  },

  //assign login to person with email and personId
  assignLoginToPersonWithEmail(email, personId) {
    return instance
      .post('', {
        query: mutations.assignLoginToPersonWithEmail.query,
        variables: {
          email: email,
          personId: personId,
        },
      })
      .then((res) => {
        if (res.data.errors && res.data.errors.length > 0)
          throw res.data.errors[0].message;
        else return res;
      });
  },

  //List all or group acls
  listAcls() {
    return instance
      .post('', {
        query: queries.listAllAcls.query,
      })
      .then((res) => {
        if (res.data.errors && res.data.errors.length > 0)
          throw res.data.errors[0].message;
        else return res;
      });
  },

  listGroupAcls(aclGroupName) {
    return instance
      .post('', {
        query: queries.listGroupAcls.query,
        variables: {
          aclGroupName: aclGroupName,
        },
      })
      .then((res) => {
        if (res.data.errors && res.data.errors.length > 0)
          throw res.data.errors[0].message;
        else return res;
      });
  },

  //add acl to group
  addAclToGroup(aclGroupId, aclId, canRead, canWrite) {
    return instance
      .post('', {
        query: mutations.addAclToGroup.query,
        variables: {
          aclGroupId: aclGroupId,
          aclId: aclId,
          canRead: canRead,
          canWrite: canWrite,
        },
      })
      .then((res) => {
        if (res.data.errors && res.data.errors.length > 0)
          throw res.data.errors[0].message;
        else return res;
      });
  },

  //remove acl from group
  removeAclsFromGroup(aclGroupId, aclIds) {
    return instance
      .post('', {
        query: mutations.removeAclFromGroup.query,
        variables: {
          aclGroupId: aclGroupId,
          aclIds: aclIds,
        },
      })
      .then((res) => {
        if (res.data.errors && res.data.errors.length > 0)
          throw res.data.errors[0].message;
        else return res;
      });
  },

  //set user's acl group
  setUserAclGroup(hospitalId, loginId, aclGroupId) {
    return instance
      .post('', {
        query: mutations.setUserAclGroup.query,
        variables: {
          hospitalId: hospitalId,
          loginId: loginId,
          aclGroupId: aclGroupId,
        },
      })
      .then((res) => {
        if (res.data.errors && res.data.errors.length > 0)
          throw res.data.errors[0].message;
        else return res;
      });
  },

  // clone existing contract
  cloneInsurerContract(contractId, newContractName, forInsurer, startDate) {
    return instance
      .post('', {
        query: mutations.cloneInsurerContract.query,
        variables: {
          contractId: parseInt(contractId),
          newContractName: newContractName,
          forInsurer: parseInt(forInsurer),
          startDate: startDate,
        },
      })
      .then((res) => {
        if (res.data.errors && res.data.errors.length > 0)
          throw res.data.errors[0].message;
        else return res;
      });
  },

  renderPDF(bodyFormData) {
    return pdfInstance.post(cfPdfUrl, bodyFormData).then((res) => {
      if (res.data.errors && res.data.errors.length > 0)
        throw res.data.errors[0].message;
      else return res;
    });
  },

  //list consultant files
  listConsultantFiles(personId, fileName) {
    return instance
      .post('', {
        query: queries.listConsultantFilesWeb.query,
        variables: {
          personId: personId,
          fileName: fileName,
        },
      })
      .then((res) => {
        if (res.data.errors && res.data.errors.length > 0)
          throw res.data.errors[0].message;
        else return res;
      });
  },

  modifyInsurerRemittance(personId, item) {
    return instance
      .post('', {
        query: mutations.modifyRemittance.query,
        variables: {
          personId: personId,
          insurerRemittances: [item],
          manualRemittances: [],
        },
      })
      .then((res) => {
        if (res.data.errors && res.data.errors.length > 0)
          throw res.data.errors[0].message;
        else return res;
      });
  },

  //report for finance reporting dashboard
  reportClaimsBilledState(startDate) {
    return instance
      .post('', {
        query: queries.reportClaimsBilledState.query,
        variables: {
          startDate: startDate,
        },
      })
      .then((res) => {
        if (res.data.errors && res.data.errors.length > 0)
          throw res.data.errors[0].message;
        return res.data.data ? res.data.data.reportClaimsBilledState.items : [];
      });
  },

  // set doctor signature
  setMedicSignature(person, signature) {
    return instance.post('', {
      query: queries.setMedicSignature.query,
      variables: {
        person: person,
        signature: signature,
      },
    });
  },
};

