export const AssocType = Object.freeze({
  Claim: 'claim',
  ClaimNested: 'claimnested',
  CompositeClaim: 'compositeclaim',
  ConsultantClaim: 'consultantclaim',
  HospitalClaim: 'hospitalclaim',
  PatientClaim: 'patientclaim',
  ClaimForm: 'claimform',
  Consultant: 'consultant',
  Patient: 'patient',
  Appointment: 'appointment',
  Message: 'message',
  Hospital: 'hospital'
});
