import { createStore } from 'vuex';
import { OpenAPIJSON } from 'api';

export const hospitalConfigStore = createStore({
  /**
   *
   * @return {{config: {single_consultant_hospital: boolean, products: Array<string>}}}
   */
  state() {
    return {
      config: {
        products: [],
        single_consultant_hospital: false
      }
    };
  },
  getters: {
    config: state => state.config,
    /**
     *
     * @param state
     * @return {boolean}
     */
    is_single_consultant_hospital(state) {
      //console.log("configStore.is_single_consultant_hospital here");
      return state.config.single_consultant_hospital;
    },

    /**
     *
     * @param state
     * @return {boolean}
     */
    has_product_access(state) {
      return state.config.products.includes("access");
    },

    /**
     *
     * @param state
     * @return {boolean}
     */
    has_product_navigator(state) {
      return state.config.products.includes("navigator");
    }
  },
  mutations: {
    getConfig(state, configInfo) {
      state.config = configInfo;
    }
  },
  actions: {
    async getConfig({ commit }) {
      try {
        const openApi = new OpenAPIJSON();
        const configInfo = await openApi.getConfig();
        commit('getConfig', configInfo);
      } catch (error) {
        console.error("configStore: cannot fetch hospital config: ", error);
        new Error(error);
      }
    }
  }
});


