<script setup>
import { userStore } from '@/store/user';

const props = defineProps({
  messageParams: {
    type: Object,
    required: true
  },
  medics: {
    type: Array,
    default: () => []
  }
});
const emit = defineEmits(['filter-messages']);

const removeUnreadMessages = () => {
  if (props.messageParams.unread === null) {
    emit('filter-messages', { unread: false });
  } else {
    emit('filter-messages', { unread: null });
  }
};
const removeReadMessages = () => {
  if (props.messageParams.unread === null) {
    emit('filter-messages', { unread: true });
  } else {
    emit('filter-messages', { unread: null });
  }
};

const removeAssignMessages = () => {
  if (props.messageParams.assoc === null) {
    emit('filter-messages', {
      assoc: [
        {
          assoc_type: 'consultantclaim',
          type: 'none'
        }
      ]
    });
  } else {
    emit('filter-messages', { assoc: null });
  }
};
const removeUnsignMessages = () => {
  if (props.messageParams.assoc === null) {
    emit('filter-messages', {
      assoc: [
        {
          assoc_type: 'consultantclaim',
          type: 'any'
        }
      ]
    });
  } else {
    emit('filter-messages', { assoc: null });
  }
};
const removeConsultant = () => {
  emit('filter-messages', { recipients: null });
};

const isMedic = userStore.getters.user?.is_medic;
const getConsultantUsername = id => {
  return props.medics.find(medic => medic.consultant_id === id)?.username;
};
</script>

<template>
  <div class="flex gap-2.5 w-100 border p-2.5 mt-2.5">
    <div
      v-if="!isMedic && messageParams.recipients?.[0].id"
      class="flex items-center gap-2.5 justify-between border border-black px-2.5 w-fit"
    >
      <span>{{ getConsultantUsername(messageParams.recipients[0].id) }}</span>
      <i class="fa-solid fa-xmark cursor-pointer" @click="removeConsultant" />
    </div>
    <div
      v-if="messageParams.unread === true || messageParams.unread === null"
      class="flex items-center gap-2.5 justify-between border border-black px-2.5 w-fit"
    >
      <span>Unread</span>
      <i class="fa-solid fa-xmark cursor-pointer" @click="removeUnreadMessages" />
    </div>
    <div
      v-if="messageParams.unread === false || messageParams.unread === null"
      class="flex items-center gap-2.5 justify-between border border-black px-2.5 w-fit"
    >
      <span>Read</span>
      <i class="fa-solid fa-xmark cursor-pointer" @click="removeReadMessages" />
    </div>
    <div
      v-if="
        messageParams?.assoc?.[0]?.type === 'any' || messageParams.assoc === null || messageParams.assoc == undefined
      "
      class="flex items-center gap-2.5 justify-between border border-black px-2.5 w-fit"
    >
      <span>Assigned</span>
      <i class="fa-solid fa-xmark cursor-pointer" @click="removeAssignMessages" />
    </div>
    <div
      v-if="
        messageParams?.assoc?.[0]?.type === 'none' || messageParams.assoc === null || messageParams.assoc == undefined
      "
      class="flex items-center gap-2.5 justify-between border border-black px-2.5 w-fit"
    >
      <span>Not assigned</span>
      <i class="fa-solid fa-xmark cursor-pointer" @click="removeUnsignMessages" />
    </div>
    <div
      v-if="messageParams?.claimId != null"
      class="flex items-center gap-2.5 justify-between border border-black px-2.5 w-fit"
    >
      <span>Claim {{ messageParams?.claimId }}</span>
      <i class="fa-solid fa-xmark cursor-pointer" />
    </div>
  </div>
</template>
