<template>
  <Modal v-if="showManageModalClaims">
    <template v-slot:header>
      <h3 class="text-lg text-left leading-6 font-medium medo-grey-600">Save all changes made in Claim Form?</h3>
      <br />
      Are you sure you want to save all changes made? If you are unsure whether you want to save the changes made,
      please choose the button “Cancel”.
      <br />
      <br />
      You can then review the claim form again before making a decision.
      <br />

      <div class="modalDelete">
        <div class="ml-auto">
          <button class="file-upload-button-canceled py-2 px-4">Save</button>
          <button class="file-upload-button py-2 px-4" @click="showManageModalClaims = false">Cancel</button>
        </div>
      </div>
    </template>
  </Modal>
  <div class="p-4 bg-gray">
    <div class="bg-gray flex relative" v-if="consultantId && appointmentId && claimId">
      <ClaimFormMenu
        v-if="!formLoading && claimSections && containerId"
        :sections="claimSections"
        :container-id="containerId"
        @set-form-overlay="setFormOverlay"
      />
      <div :class="{ 'form-overlay': formOverlay }" />
      <ConsultantESB
        v-if="claim.claim_form_type.toLowerCase() == 'esb' || claim.claim_form_type.toLowerCase() == 'e_s_b'"
        :class="claimHeight"
        :claim="claim"
        :claimDataPreviousState="claimDataPreviousState"
        :genders="genders"
        @form-loaded="formLoaded"
        @set-previous-form-state="handleChildEvent"
        @update-header-info="updateHeaderInfo"
      />
      <ConsultantGarda
        v-else-if="claim.claim_form_type.toLowerCase() == 'garda'"
        :class="claimHeight"
        :claim="claim"
        :claimDataPreviousState="claimDataPreviousState"
        :genders="genders"
        :diagnosisTypes="diagnosisTypes"
        @form-loaded="formLoaded"
        @set-previous-form-state="handleChildEvent"
        @update-header-info="updateHeaderInfo"
      />
      <ConsultantIrishLife
        v-else-if="claim.claim_form_type.toLowerCase() == 'irish_life_hospital'"
        :class="claimHeight"
        :claim="claim"
        :claimDataPreviousState="claimDataPreviousState"
        :genders="genders"
        :diagnosisTypes="diagnosisTypes"
        @form-loaded="formLoaded"
        @set-previous-form-state="handleChildEvent"
        @update-header-info="updateHeaderInfo"
      />
      <ConsultantIrishLifeHospital2022
        v-else-if="claim.claim_form_type.toLowerCase() == 'irish_life_hospital2022'"
        :class="claimHeight"
        :claim="claim"
        :claimDataPreviousState="claimDataPreviousState"
        :genders="genders"
        @form-loaded="formLoaded"
        @set-previous-form-state="handleChildEvent"
        @update-header-info="updateHeaderInfo"
      />
      <ConsultantLaya
        v-else-if="claim.claim_form_type.toLowerCase() == 'laya_inpatient'"
        :class="claimHeight"
        :claim="claim"
        :claimDataPreviousState="claimDataPreviousState"
        :genders="genders"
        :diagnosisTypes="diagnosisTypes"
        @form-loaded="formLoaded"
        @set-previous-form-state="handleChildEvent"
        @update-header-info="updateHeaderInfo"
      />
      <ConsultantPrisonOfficers
        v-else-if="claim.claim_form_type.toLowerCase() == 'prison_officers'"
        :class="claimHeight"
        :claim="claim"
        :claimDataPreviousState="claimDataPreviousState"
        :genders="genders"
        @form-loaded="formLoaded"
        @set-previous-form-state="handleChildEvent"
        @update-header-info="updateHeaderInfo"
      />
      <ConsultantVhi
        v-else-if="claim.claim_form_type.toLowerCase() == 'vhi_hospital'"
        :class="claimHeight"
        :claim="claim"
        :claimDataPreviousState="claimDataPreviousState"
        :genders="genders"
        :diagnosisTypes="diagnosisTypes"
        @form-loaded="formLoaded"
        @set-previous-form-state="handleChildEvent"
        @update-header-info="updateHeaderInfo"
      />
      <ConsultantVhiCt
        v-else-if="claim.claim_form_type.toLowerCase() == 'vhi_c_t'"
        :class="claimHeight"
        :claim="claim"
        :claimDataPreviousState="claimDataPreviousState"
        :genders="genders"
        @form-loaded="formLoaded"
        @set-previous-form-state="handleChildEvent"
        @update-header-info="updateHeaderInfo"
      />
      <ConsultantVhiMri
        v-else-if="claim.claim_form_type.toLowerCase() == 'vhi_mri'"
        :class="claimHeight"
        :claim="claim"
        :claimDataPreviousState="claimDataPreviousState"
        :claim-id="claimId"
        :genders="genders"
        @form-loaded="formLoaded"
        @set-previous-form-state="handleChildEvent"
        @update-header-info="updateHeaderInfo"
      />
      <ConsultantVhiOncology
        v-else-if="claim.claim_form_type.toLowerCase() == 'vhi_oncology'"
        :class="claimHeight"
        :claim="claim"
        :claimDataPreviousState="claimDataPreviousState"
        :genders="genders"
        :diagnosisTypes="diagnosisTypes"
        @form-loaded="formLoaded"
        @set-previous-form-state="handleChildEvent"
        @update-header-info="updateHeaderInfo"
      />
      <ConsultantVhiSurgicalProcedure
        v-else-if="claim.claim_form_type.toLowerCase() == 'vhi_surgical'"
        :class="claimHeight"
        :claim="claim"
        :claimDataPreviousState="claimDataPreviousState"
        :genders="genders"
        :diagnosisTypes="diagnosisTypes"
        @form-loaded="formLoaded"
        @set-previous-form-state="handleChildEvent"
        @update-header-info="updateHeaderInfo"
      />
    </div>
  </div>
</template>

<script>
import { defineAsyncComponent } from 'vue';
import { mapState, mapMutations } from 'vuex';
import { OpenAPIJSON } from 'api/openapi';
import { getClaimSectionIds, getClaimContainerId } from '../../../util/claims/generateClaimIds';
import ClaimFormMenu from '../details/ClaimFormMenu.vue';
import Modal from '@/components/modal/Modal.vue';

export default {
  name: 'DetailsClaimForm',
  props: {
    claim: { required: true },
    claimDataPreviousState: { required: false }
  },
  emits: ['form-reload', 'update-header-info', 'set-previous-form-state'],
  components: {
    ClaimFormMenu,
    ConsultantESB: defineAsyncComponent(() => import('../ConsultantESB.vue')),
    ConsultantGarda: defineAsyncComponent(() => import('../ConsultantGarda.vue')),
    ConsultantIrishLife: defineAsyncComponent(() => import('../ConsultantIrishLife.vue')),
    ConsultantIrishLifeHospital2022: defineAsyncComponent(() => import('../ConsultantIrishLifeHospital2022.vue')),
    ConsultantLaya: defineAsyncComponent(() => import('../ConsultantLaya.vue')),
    ConsultantPrisonOfficers: defineAsyncComponent(() => import('../ConsultantPrisonOfficers.vue')),
    ConsultantVhi: defineAsyncComponent(() => import('../ConsultantVhi.vue')),
    ConsultantVhiCt: defineAsyncComponent(() => import('../ConsultantVhiCt.vue')),
    ConsultantVhiMri: defineAsyncComponent(() => import('../ConsultantVhiMri.vue')),
    ConsultantVhiOncology: defineAsyncComponent(() => import('../ConsultantVhiOncology.vue')),
    ConsultantVhiSurgicalProcedure: defineAsyncComponent(() => import('../ConsultantVhiSurgicalProcedure.vue')),
    Modal
  },
  data() {
    return {
      openApi: new OpenAPIJSON(),
      consultantId: null,
      appointmentId: null,
      claimId: null,
      claimSections: null,
      containerId: null,
      formLoading: true,
      showManageModalClaims: false,
      formOverlay: false,
      genders: [],
      diagnosisTypes: []
    };
  },
  computed: {
    ...mapState(['textBoxDisabled']),
    isTextBoxDisabled() {
      return this.textBoxDisabled;
    },
    claimHeight() {
      return this.isTextBoxDisabled ? 'h-[920px]' : 'h-[1020px]';
    }
  },
  created() {
    this.listGenders();
    this.listDiagnosisTypes();
  },
  mounted() {
    this.init();
  },
  watch: {
    claim() {
      this.init();
    }
  },
  methods: {
    ...mapMutations(['setTextBoxDisabled']),
    init() {
      this.formLoading = true;
      this.consultantId = this.claim.claim_assoc.id;
      this.appointmentId = this.claim.appointment_id;
      this.claimId = this.claim.claim_id;
      this.getClaimIds();
      this.enableTextBox();
    },

    handleChildEvent(value) {
      this.$emit('set-previous-form-state', value);
    },
    async listGenders() {
      try {
        this.genders = await this.openApi.listPersonGenders();
      } catch (e) {
        this.$refs?.toast?.showToast(e, 'fa-solid fa-triangle-exclamation', 'error');
      }
    },
    async listDiagnosisTypes() {
      try {
        this.diagnosisTypes = await this.openApi.listDiagnosisTypes();
      } catch (e) {
        this.$refs?.toast?.showToast(e, 'fa-solid fa-triangle-exclamation', 'error');
      }
    },
    disableTextBox() {
      this.setTextBoxDisabled(false);
    },
    enableTextBox() {
      this.setTextBoxDisabled(true);
    },
    formLoaded() {
      this.formLoading = false;
    },
    getClaimIds() {
      this.claimSections = getClaimSectionIds(this.claim.claim_form_type);
      this.containerId = getClaimContainerId(this.claim.claim_form_type);
    },
    setFormOverlay(bool) {
      this.formOverlay = bool;
    },
    updateHeaderInfo(data) {
      this.$emit('update-header-info', data);
    }
  }
};
</script>

<style>
.bg-gray {
  background-color: #d9d9d9;
}

.cancel-button,
.save-button {
  background: #00a7e1;
  border-radius: 50px;
  width: 95px;
  height: 45px;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 100%;
  align-items: center;
  margin: 10px;
  margin-top: 34px;
  margin-bottom: 34px;
}

.cancel-button {
  background-color: #cbd5e0;
  color: #fff;
  margin-left: 100px;
}

.file-upload-button {
  background: #00a7e1;
  border-radius: 50px;
  width: 95px;
  height: 45px;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 100%;
  align-items: center;
  margin: 10px;
}

.file-upload-button-canceled {
  border: 2px solid #00a7e1;
  border-radius: 50px;
  height: 45px;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 100%;
  align-items: center;
}

.form-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(25, 25, 25, 0.6);
  z-index: 10;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
