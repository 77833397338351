<template>
    <div>

      <header class="bg-white border-b border-t border-gray-200">

        <nav class="max-w-7xl mx-auto px-0" aria-label="Top">
          <div class="w-full py-4 flex items-left justify-between border-b border-blue-500 lg:border-none">

            <li class="flow-root float-left pl-4">
              <div class="relative flex items-center space-x-4 rounded-xl hover:bg-gray-50 focus-within:ring-2 focus-within:ring-indigo-500">
                <div class="flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full zima-blue-bg">
                  <i class="fas fa-comments text-white text-lg"></i>
                </div>
                <div>
                  <p class="mt-1 text-sm text-gray-500 font-semibold">Messages</p>
                </div>
              </div>
            </li>

            <div class="flex items-center pr-4">
              <div class="hidden ml-6 space-x-4 lg:block">
                <a href="#" class="text-base font-medium text-white text-blue-500" :class="{'active': !messagesActive}" @click="setActive('message')"> <i class="fas fa-comment text-blue-500"></i> Messages </a>
                <a href="#" class="text-base font-medium text-white text-blue-500" :class="{'active': !notesActive}" @click="setActive('note')"> <i class="fas fa-clipboard text-blue-500"></i> Notes </a>
              </div>
            </div>
          </div>
        </nav>    

      </header>

      <section v-if='notesActive'>
        <div class="notes">

          <div class="mt-6 pl-3 pr-3">
            <div v-for='(note, index) in notes' :key='index' class="note-li">
              {{note}}
              <div class="note-li-info">
                Added 8/25/2022, by William Manager
              </div>
            </div>
          </div>

        </div>
      </section>

      <!-- -->
      <section v-if='messagesActive'>

        <div class="messages">

          <div class="message-li" v-for='(message,index) in messages' :key='index'>
            <span v-if="message.who == 'person'" class="person">
              {{message.text}}
            </span>
            <span v-if="message.who == 'ai'" class="ai">
              {{message.text}}
            </span>
          </div>
          
        </div>

        <div style="clear:both;"></div>

        <div class="mt-3 text-center">
          <div class="p-2">
            <textarea class="p-2" v-model='text'></textarea>
          </div>
          <button @click='addMessage()' type="button" class="inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-blue-500 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500">
            <!-- Heroicon name: solid/mail -->
            <svg class="-ml-1 mr-3 h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
              <path d="M2.003 5.884L10 9.882l7.997-3.998A2 2 0 0016 4H4a2 2 0 00-1.997 1.884z" />
              <path d="M18 8.118l-8 4-8-4V14a2 2 0 002 2h12a2 2 0 002-2V8.118z" />
            </svg>
            Send Message
          </button>
        </div>

      </section>

    </div>
</template>

<script>

export default {
  name: 'Message-system',
  components: {

  },
  data () {
    return {
      messagesActive: true,
      notesActive: false,
      notes: [
        'Please add MRN to claim!',
        'Need signature from doctor.'
      ],
      text: '',
      messages: [
        {who: 'person', text: 'Hey, can you please send invoce?'},
        {who: 'ai', text: 'Oh hello, yes of course'},
        {who: 'person', text: 'Please also send last claim'},
        {who: 'person', text: 'Thank you!'},
        {who: 'ai', text: 'Not a problem'},
      ]
    }
  },
  methods: {
    setActive (item) {
      if (item == 'message') {
        this.messagesActive = true
        this.notesActive = false
      } else {
        this.messagesActive = false
        this.notesActive = true
      }
    },
    addMessage () {
      if (this.text == '') {
        return
      }
      let message = {
        who: 'person',
        text: this.text
      }
      this.messages.push(message)
      this.text = ''
    }
  },
  mounted () {

  }
}
</script>

<style lang="scss" scoped>

  .not-num {
    position: absolute;
    top: -8px;
    left: -8px;
    width: 16px;
    height: 16px;
    font-size: 10px;
    font-weight: 600;
    line-height: 16px;
    text-align: center;
    border-radius: 50%;
    color: #fff;
    background: #ef4444;
    cursor: pointer;
  }

  .notes {
    width: 100%;
    background: #fff;
  }

  .note-li {
    margin-bottom: 15px;
    padding: 30px;
    padding-top: 15px;
    padding-bottom: 15px;
    border-radius: 20px;
    font-size: 15px;
    font-weight: 500;
    background: #0ea5e9;
    color: #fff;
  }

  .note-li-info {
    font-size: 11px;
    font-style: italic;
    text-align: right;
    color: #f9f9f9;
  }

  .active {
    color: #0ea5e9;
  }

  .message-li {
    padding-left: 15px;
    padding-right: 15px;
  }

  .person {
    float: right;
    margin-top: 15px;
    padding-right: 15px;
    padding: 5px;
    font-size: 14px;
    border-radius: 5px;
    text-align: right;
    background: #3b82f6;
    color: #fff;
    overflow: auto;
    clear: both;
  }

  .ai {
    float: left;
    margin-top: 15px;
    padding-right: 15px;
    padding: 5px;
    font-size: 14px;
    border-radius: 5px;
    text-align: right;
    background: #22c55e;
    color: #fff;
    overflow: auto;
    clear: both;
  }

  .zima-blue-bg {
    background: #0ea5e9;
  }

  .zima-blue {
    color: #0ea5e9;
  }

  textarea {
    width: 100%;
    border: 1px solid #dedede;
    outline: none;
  }

</style>