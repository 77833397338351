<template>
  <div class="add-claim-section" v-if="modelValue" id="add-claim-modal">
    <div class="relative z-10">
      <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"></div>

      <div class="fixed z-10 inset-0 overflow-y-auto">
        <div class="flex items-end sm:items-center justify-center min-h-full p-4 text-center sm:p-0">
          <div
            class="custom-claim-modal relative bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-10 sm:max-w-2xl sm:w-full sm:p-6"
          >
            <SpinnerLoader v-if="isLoading" />
            <div>
              <div class="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-indigo-600">
                <!-- Heroicon name: outline/check -->
                <i class="fas fa-file-signature" style="padding-left: 6px; color: #fff"></i>
              </div>
              <div class="mt-3 text-center sm:mt-3">
                <h3 class="text-lg leading-6 font-medium text-gray-900">Add New Claim</h3>

                <div class="mt-2">
                  <!--
                    <p class="text-sm text-gray-500">Lorem ipsum, dolor sit amet consectetur adipisicing elit. Eius aliquam laudantium explicabo pariatur iste dolorem animi vitae error totam. At sapiente aliquam accusamus facere veritatis.</p>
                    -->

                  <!-- form fields -->

                  <!-- section -->

                  <div
                    class="col-12 mt-5"
                    title="Enter an MRN for the patient.  Use this field if you cannot find the patient using the patient search."
                  >
                    <div class="col-12 float-left">
                      <div>
                        <div class="text-left text-sm mb-1">
                          <label for="value" class="font-medium text-gray-700 mr-2"
                            >MRN: <span class="required">*</span></label
                          >
                        </div>
                        <div class="col-12">
                          <input
                            id="add-claim-mrn"
                            type="text"
                            data-test-id="new-claim-mrn"
                            class="custom-input shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                            v-model="claim.patient.mrn"
                            ref="patientTypeaheadMrn"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <!-- section end -->

                  <div style="clear: both"></div>

                  <div
                    class="sub-title mt-3"
                    title="Search existing records to find the patient by MRN or name, which will auto-populate several fields on the form.  Use the MRN field directly if you cannot find the patient here."
                  >
                    Patient
                  </div>

                  <div style="clear: both"></div>

                  <div class="float-left col-12 mt-2">
                    <PatientTypeahead
                      id="add-claim-patientname-th"
                      v-model="patientString"
                      :test-id="'mrn-search'"
                      placeholder="Search for patient by MRN or name"
                      @select-patient="patientSelected"
                    />
                  </div>

                  <!-- section -->
                  <div class="col-12 mt-1">
                    <div class="col-6 float-left pr-2">
                      <div class="mt-2">
                        <div>
                          <div class="text-left text-sm mb-1">
                            <label for="value" class="font-medium text-gray-700 mr-2"
                              >First Name: <span class="required">*</span></label
                            >
                          </div>
                          <div class="col-12">
                            <input
                              ref="patientTypeahead"
                              id="add-claim-patient-firstname"
                              type="text"
                              data-test-id="new-claim-name"
                              class="custom-input shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                              v-model="claim.patient.first_name"
                            />
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="col-6 float-left">
                      <div class="mt-2">
                        <div>
                          <div class="text-left text-sm mb-1">
                            <label for="value" class="font-medium text-gray-700 mr-2"
                              >Last Name: <span class="required">*</span></label
                            >
                          </div>
                          <div class="col-12">
                            <input
                              id="add-claim-patient-lastname"
                              type="text"
                              data-test-id="new-claim-surname"
                              class="custom-input shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                              v-model="claim.patient.last_name"
                              ref="patientTypeaheadLastName"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!-- section end -->

                  <!-- section -->
                  <div class="col-12 mt-1">
                    <div class="col-12 float-left pr-2">
                      <div class="mt-2 float-left">
                        <div>
                          <div class="text-left text-sm mb-1">
                            <label for="value" class="font-medium text-gray-700 mr-2"
                              >Date of Birth: <span class="required">*</span></label
                            >
                          </div>
                          <div class="col-12" data-test-id="new-claim-dob">
                            <date-picker
                              id="add-claim-patient-dob"
                              v-model:value="claim.patient.dob"
                              value-type="YYYY-MM-DD"
                              format="DD-MM-YYYY"
                              ref="patientTypeaheadDob"
                            />
                            <!-- <customDatePicker id="add-claim-patient-dob" v-model='claim.patient.dateOfBirth' :key='dobKey' /> -->
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!-- section end -->

                  <div style="clear: both"></div>

                  <!-- section -->
                  <div class="col-12 mt-5">
                    <div class="col-6 float-left pr-2">
                      <FormGender
                        :modelValue="claim.patient.gender"
                        :genders="genders"
                        id="add-claim-gender"
                        v-model="claim.patient.gender"
                        data-test-id="new-claim-gen"
                        ref="patientTypeaheadGender"
                      />
                    </div>

                    <div class="col-6 float-left">
                      <div>
                        <FormTitle id="add-claim-title" v-model="claim.patient.title" data-test-id="new-claim-title" />
                      </div>
                    </div>
                  </div>
                  <!-- section end -->

                  <div style="clear: both"></div>

                  <div style="clear: both"></div>
                  <div class="col-12 float-left">
                    <div>
                      <div class="text-left text-sm mt-2">
                        <label for="value" class="font-medium text-gray-700 mr-2">Address:</label>
                      </div>
                      <div class="col-12">
                        <CommonAddress
                          id="add-claim-address"
                          v-model="claim.patient.contact"
                          :fullWidth="false"
                          title="Address"
                          test-id="new-claim-"
                        />
                      </div>
                    </div>
                  </div>
                  <div style="clear: both"></div>

                  <!-- Appointment -->

                  <div
                    class="sub-title mt-5 mb-2"
                    title="A list of appointments already recorded in the system for the patient.  Entries may appear here after a successful patient search and selecting one will associate the claim to the selected appointment, auto-fill most of the fields on the form."
                  >
                    Appointments list
                  </div>

                  <div style="clear: both"></div>

                  <div v-if="appointmentSeach" class="appointment-list-section">
                    <div class="appointment-li">
                      <div class="app-table-head mb-1">
                        <div class="app-hospital-id float-left col-4 text-left">Hospital Appointment ID</div>
                        <div class="app-id float-left col-3 text-left">Admitted</div>
                        <div class="app-hospital-mrn float-left col-3 text-left">Discharged</div>
                        <div class="float-left col-2 text-left"></div>
                      </div>

                      <div class="app-table-li" v-for="(app, index) in appointments" :key="index">
                        <div id="app-table-li-appid" class="app-hospital-id float-left col-4 text-left">
                          {{ app.hospital_appointment_id }}
                        </div>
                        <div id="app-table-li-admitted" class="app-id float-left col-3 text-left">
                          {{ app.admitted_on ? formatDate(app.admitted_on) : 'N/A' }}
                        </div>
                        <div id="app-table-li-discharged" class="app-hospital-mrn float-left col-3 text-left">
                          {{ app.discharged_on ? formatDate(app.discharged_on) : 'N/A' }}
                        </div>
                        <div class="float-left col-2 text-right pr-3">
                          <button
                            id="app-table-li-btn"
                            type="button"
                            class="inline-flex items-center px-2.5 py-1.5 border border-transparent text-xs font-medium rounded shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                            @click="selectAppointmentId(app)"
                          >
                            Select
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>

                  <!--
                    <div v-if='appointmentSeach' class="float-left col-12 mt-2">
                      <Typeahead
                          v-model="appointmentString"
                          :items="appointments"
                          placeholder="Search for appointments"
                          :min-input-length="1"
                          :item-projection="showProcAppointment"
                          :item-value="getProcAppointmentItemValue"
                          :fullW='true'
                      />
                    </div>
                    -->

                  <div style="clear: both"></div>

                  <!-- section -->
                  <div
                    class="col-12 mt-1"
                    title="If the patient appointment is not known in the system, you can manually specify it here."
                  >
                    <div class="col-12 float-left">
                      <div class="mt-2">
                        <div>
                          <div class="text-left text-sm mb-1">
                            <label for="value" class="font-medium text-gray-700 mr-2"
                              >Appointment ID: <span class="required">*</span></label
                            >
                          </div>
                          <div class="col-12">
                            <input
                              id="add-claim-appid"
                              type="text"
                              data-test-id="new-claim-appointid"
                              class="custom-input shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                              v-model="claim.hospital_appointment_id"
                              ref="appointmentId"
                              :disabled="disbableAppointment"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!-- section end -->

                  <div style="clear: both"></div>

                  <div class="sub-title mt-5">Insurer</div>

                  <div style="clear: both"></div>

                  <div class="float-left col-12 mt-2">
                    <label v-if="disbableAppointment">{{ claim.insurance[0].insurer.ro.insurer_name }}</label>
                    <InsuranceCompany
                      v-if="!disbableAppointment"
                      id="add-claim-insurer"
                      v-model="claim.insurance[0]"
                      test-id="new-claim-insurer"
                      @update:modelValue="insuranceChanged"
                      :placeholder="insurerPlaceholder"
                      ref="insurerComponent"
                    />
                  </div>

                  <div style="clear: both"></div>

                  <!-- section -->
                  <div class="col-12 mt-5">
                    <div class="col-12 float-left" v-show="claim.insurance[0].insurer?.id">
                      <div class="float-left col-12 mt-2">
                        <InsurancePolicy
                          id="add-claim-insurer-policy"
                          :insurer_id="claim.insurance[0].insurer?.id"
                          v-model="claim.insurance[0].policy_plan"
                          :policy_name="policy_name"
                        />
                      </div>
                    </div>
                  </div>

                  <div style="clear: both"></div>

                  <div class="col-12 mt-5">
                    <div class="col-12 float-left" v-show="claim.insurance[0].insurer?.id">
                      <div class="float-left col-12 mt-2">
                        <InsuranceMembership
                          id="add-claim-insurer-memb-no1"
                          v-model="claim.insurance[0].policy_number"
                        />
                      </div>
                    </div>
                  </div>

                  <!-- section end -->

                  <div style="clear: both"></div>

                  <!-- section -->
                  <div
                    class="col-12 mt-3"
                    title="The claim date is separate from the admission/discharge date and instead represents the day that the claim was created, which generally going to be todays date"
                  >
                    <div class="col-12 float-left pr-2">
                      <div class="mt-2 float-left">
                        <div>
                          <div class="text-left text-sm mb-1">
                            <label for="value" class="font-medium text-gray-700 mr-2">Claim Date: </label>
                          </div>
                          <div class="col-12" data-test-id="new-claim-date">
                            <date-picker
                              id="add-claim-claimdate"
                              v-model:value="claimDateVar"
                              value-type="YYYY-MM-DD"
                              format="DD-MM-YYYY"
                            />
                            <!-- <customDatePicker id="add-claim-claimdate" v-model='claim.claimDate' :key='claimdateKey' /> -->
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!-- section end -->

                  <!-- section -->
                  <section>
                    <div class="col-12 mt-3" title="Date & time that patient was admitted (required).">
                      <div class="col-12 float-left pr-2">
                        <div class="mt-2">
                          <div>
                            <div class="text-left text-sm mb-1">
                              <label for="value" class="font-medium text-gray-700 mr-2">Admitted: </label>
                              <div class="inline-flex" data-test-id="new-claim-admitdate">
                                <date-picker
                                  v-model:value="datePickerAdmi"
                                  value-type="YYYY-MM-DD"
                                  format="DD-MM-YYYY"
                                  ref="admittingConsultantDate"
                                ></date-picker>
                              </div>
                              <div class="inline-flex" data-test-id="new-claim-time">
                                <date-picker
                                  style="margin-top: 10px"
                                  v-model:value="timerPickerAdmi"
                                  :minute-step="1"
                                  format="HH:mm"
                                  showSecond="00"
                                  value-type="HH:mm:ss"
                                  type="time"
                                  placeholder="HH:mm"
                                ></date-picker>
                              </div>
                            </div>

                            <div class="col-12">
                              <!-- <CustomDateTimePicker id="add-claim-admitted" v-model='claim.admissionDatetime'
                                :key='admitdateKey' /> -->
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </section>
                  <!-- section end -->

                  <!-- section -->
                  <section>
                    <div
                      class="col-12 mt-3"
                      title="Date & time that patient was discharged.  If the discharge information already exists for the appointment (from the hospital feed or defined by other claim) then setting the discharge information here will have no effect."
                    >
                      <div class="col-12 float-left pr-2">
                        <div class="mt-2">
                          <div>
                            <div class="text-left text-sm mb-1">
                              <label for="value" class="font-medium text-gray-700 mr-2">Discharged:</label>
                              <div class="inline-flex" data-test-id="new-claim-dischargedate">
                                <date-picker
                                  v-model:value="datePickerDischar"
                                  value-type="YYYY-MM-DD"
                                  format="DD-MM-YYYY"
                                  ref="dischargingConsultantDate"
                                ></date-picker>
                              </div>
                              <div class="inline-flex" data-test-id="new-claim-dischargetime">
                                <date-picker
                                  style="margin-top: 10px"
                                  v-model:value="timerPickerDischar"
                                  :minute-step="1"
                                  format="HH:mm"
                                  showSecond="00"
                                  value-type="HH:mm:ss"
                                  type="time"
                                  placeholder="HH:mm:ss"
                                ></date-picker>
                              </div>
                            </div>
                            <div class="col-12">
                              <!-- <CustomDateTimePicker id="add-claim-discharged" v-model='claim.dischargeDatetime'
                                :key='dischargedateKey' /> -->
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </section>
                  <!-- section end -->

                  <div style="clear: both"></div>

                  <!-- consultant -->
                  <section>
                    <div
                      class="sub-title mt-5"
                      title="The name of the consultant who has a claim against this appointment."
                    >
                      Claim Consultant
                    </div>

                    <div class="float-left col-12 mt-2">
                      <ListMedics
                        id="add-claim-consultant"
                        v-model="consultantValue"
                        modelValue=""
                        :enable_consultant="true"
                        placeholder="Search for  Consultant"
                        test-id="claim-consutant"
                        @update:modelValue="handleModelValueUpdateConsutant"
                        ref="consultantComponent"
                      />
                    </div>
                  </section>
                  <!-- consultant -->

                  <div style="clear: both"></div>

                  <div
                    class="sub-title mt-5"
                    title="The name of the consultant who admitted the patient (required for side 2). "
                  >
                    Admitting Consultant
                  </div>

                  <div style="clear: both"></div>

                  <div class="float-left col-12 mt-2">
                    <ListMedics
                      id="add-claim-admitting-consultant"
                      v-model="admittingMedicString"
                      modelValue=""
                      :enable_consultant="true"
                      placeholder="Search for Admitting Consultant"
                      test-id="new-claim-admitconsult"
                      @update:modelValue="handleModelValueUpdateAdmit"
                      ref="admittingConsultant"
                    />
                  </div>

                  <div style="clear: both"></div>

                  <div class="sub-title mt-5" title="The name of the consultant who discharged the patient (optional)">
                    Discharging Consultant
                  </div>

                  <div style="clear: both"></div>

                  <div class="float-left col-12 mt-2 mb-5">
                    <ListMedics
                      id="add-claim-discharging-consultant"
                      v-model="dischargingMedicString"
                      :enable_consultant="true"
                      modelValue=""
                      placeholder="Search for Discharging Consultant"
                      test-id="new-claim-dischargeconsult"
                      @update:modelValue="handleModelValueUpdateDischar"
                      ref="dischargingConsultant"
                    />
                  </div>

                  <div style="clear: both"></div>
                </div>
              </div>
            </div>
            <div class="mt-12 sm:mt-12 sm:grid sm:grid-cols-2 sm:gap-3 sm:grid-flow-row-dense">
              <button
                id="btn-add-new-claim"
                type="button"
                data-test-id="new-claim-add"
                class="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-indigo-600 text-base font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:col-start-2 sm:text-sm"
                @click="executeAsyncWithLoading(defineClaim)"
                :disabled="isLoadingButton"
              >
                Add Claim
              </button>
              <button
                id="btn-cancel-add-new-claim"
                @click="btn_cancel"
                type="button"
                data-test-id="new-claim-cancel"
                class="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:col-start-1 sm:text-sm"
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div v-if="successClaim" class="alert-modal-section" id="add-claim-success-modal">
      <div class="relative z-10" aria-labelledby="modal-title" role="dialog" aria-modal="true">
        <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"></div>

        <div class="fixed z-10 inset-0 overflow-y-auto">
          <div class="flex items-end sm:items-center justify-center min-h-full p-4 text-center sm:p-0">
            <div
              class="relative bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:max-w-lg sm:w-full sm:p-6"
            >
              <div>
                <div class="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-green-100">
                  <!-- Heroicon name: outline/check -->
                  <svg
                    class="h-6 w-6 text-green-600"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke-width="2"
                    stroke="currentColor"
                    aria-hidden="true"
                  >
                    <path stroke-linecap="round" stroke-linejoin="round" d="M5 13l4 4L19 7" />
                  </svg>
                </div>
                <div class="mt-3 text-center sm:mt-5">
                  <h3 class="text-lg leading-6 font-medium text-gray-900" id="modal-title">Claim successfully added</h3>
                  <div class="mt-2">
                    <p class="text-sm text-gray-500"></p>
                  </div>
                </div>
              </div>
              <div class="mt-5 sm:mt-6 sm:grid sm:grid-cols-2 sm:gap-3 sm:grid-flow-row-dense">
                <button
                  @click="
                    resetClaim();
                    successClaim = false;
                  "
                  id="btn-add-another-claim"
                  type="button"
                  class="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-indigo-600 text-base font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:col-start-2 sm:text-sm"
                >
                  Add another claim
                </button>
                <button
                  @click="
                    successClaim = false;
                    resetClaim();
                    updateValue(false);
                  "
                  id="btn-close-add-another-claim"
                  type="button"
                  class="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:col-start-1 sm:text-sm"
                >
                  Close
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <ToastComponent ref="toast"></ToastComponent>
</template>

<script>
import SpinnerLoader from '@/components/misc/SpinnerLoader.vue';
import CommonAddress from '@/components/form-fields/CommonAddress.vue';
import '@/assets/css/typeahead.css';
import loadingMixin from '@/components/mixins/loadingMixin';
import { OpenAPIJSON } from 'api/openapi';
import Dict from 'collections/dict';
import _, { clone } from 'lodash';
import PatientTypeahead from '@/components/form-fields/PatientTypeahead.vue';
import DatePicker from 'vue-datepicker-next';
import 'vue-datepicker-next/index.css';
import ToastComponent from '@/components/claim-forms/components/ToastComponent.vue';
import FormTitle from '@/components/form-fields/Title.vue';
import FormGender from '@/components/form-fields/Gender.vue';
import ListMedics from '@/components/form-fields/List-medics.vue';
import InsuranceCompany from '@/components/form-fields/InsuranceCompany.vue';
import InsurancePolicy from '@/components/form-fields/InsurancePolicy.vue';
import InsuranceMembership from '@/components/form-fields/InsuranceMembership.vue';

export default {
  name: 'NewClaim',
  props: {
    modelValue: {}
  },
  mixins: [loadingMixin],
  emits: ['update:modelValue'],
  components: {
    InsuranceMembership,
    InsurancePolicy,
    InsuranceCompany,
    ListMedics,
    FormGender,
    FormTitle,
    PatientTypeahead,
    CommonAddress,
    DatePicker,
    ToastComponent,
    SpinnerLoader
  },
  data() {
    return {
      claim: {
        patient: {
          mrn: '',
          title: '',
          first_name: '',
          last_name: '',
          dob: '',
          gender: '',
          contact: {
            postal_address: [''],
            telephone_landline: null,
            telephone_mobile: null,
            telephone_fax: null,
            email: null
          }
        },
        hospital_appointment_id: '',
        insurance: [
          {
            insurer: {
              type: 'name',
              name: ''
            },
            policy_plan: {
              type: 'name',
              name: ''
            },
            policy_number: ''
          }
        ],
        admitting_consultant: {
          consultant: {
            type: 'id',
            id: ''
          },
          datetime: ''
        },
        discharging_consultant: {
          consultant: {
            type: 'id',
            id: ''
          },
          datetime: ''
        }
      },
      policy_name: '',
      genders: [],
      consultantId: '',
      consultantValue: '',
      patients: null,
      patientMrn: '',
      patientString: '',
      appointment: null,
      appointments: null,
      appointmentSeach: false,
      appointmentString: '',
      insurer: null,
      medic: null,
      medics: null,
      admittingMedicString: '',
      dischargingMedicString: '',
      insurerPolicies: null,
      dobKey: 0,
      admitdateKey: 0,
      dischargedateKey: 0,
      claimdateKey: 0,
      successClaim: false,
      possibleClaimMedics: [],
      datePickerAdmi: null,
      timerPickerAdmi: '00:00:00',
      datePickerDischar: null,
      timerPickerDischar: '00:00:00',
      insurances: [],
      selectedInsurance: null,
      getProcInsurerItemValue: null,
      claimDateVar: null,
      insurerPlaceholder: "Search for insurer's name",
      isLoading: false,
      disbableAppointment: false
    };
  },
  computed: {
    patientDOB() {
      return this.claim.patient.dob;
    },
    admitDate() {
      return this.claim.admissionDatetime;
    },
    dischargeDate() {
      return this.claim.dischargeDatetime;
    },
    claimDate() {
      return this.claimDateVar;
    },
    formattedGender() {
      if (!this.claim.patient.gender) return '';
      return this.claim.patient.gender.charAt(0).toUpperCase() + this.claim.patient.gender.slice(1).toLowerCase();
    }
  },
  watch: {
    patientDOB() {
      this.dobKey++;
    },
    admitDate() {
      this.admitDate_();
      this.admitdateKey++;
    },
    dischargeDate() {
      this.dischargeDate_();
      this.dischargedateKey++;
    },
    claimDate() {
      this.claimdateKey++;
    },
    datePickerAdmi(newval) {
      if (newval === null || newval === undefined || newval === '') {
        this.claim.admitting_consultant.datetime = '';
        return;
      }
      this.claim.admitting_consultant.datetime = newval + 'T' + this.timerPickerAdmi;
    },
    timerPickerAdmi(newval) {
      if (newval === null) {
        this.timerPickerAdmi = '00:00:00';
      }
      this.claim.admitting_consultant.datetime = this.datePickerAdmi + 'T' + newval;
    },

    datePickerDischar(newval) {
      if (newval === null || newval === undefined || newval === '') {
        this.claim.discharging_consultant.datetime = '';
        return;
      }
      this.claim.discharging_consultant.datetime = newval + 'T' + this.timerPickerDischar;
    },

    timerPickerDischar(newval) {
      if (newval === null) {
        this.timerPickerDischar = '00:00:00';
      }

      this.claim.discharging_consultant.datetime = this.datePickerDischar + 'T' + newval;
    }
  },
  methods: {
    handleModelValueUpdateConsutant(newMedicValue) {
      this.consultantId = newMedicValue.id;
    },
    handleModelValueUpdateAdmit(newMedicValue) {
      this.claim.admitting_consultant.consultant.id = newMedicValue.ro.consultant_id;
    },

    handleModelValueUpdateDischar(newMedicValue) {
      this.claim.discharging_consultant.consultant.id = newMedicValue.ro.consultant_id;
    },
    insuranceChanged() {
      // Insurance company is changing, so the policy plan needs to be reset.
      this.claim.insurance[0].policy_plan = {};
      this.policy_name = '';
    },
    admitDate_() {
      if (this.claim.admissionDatetime != null) {
        var dateOnly = this.claim.admissionDatetime.split('T')[0];
        var timeOnly = this.claim.admissionDatetime.split('T')[1];
        if (timeOnly === 'undefined' || timeOnly == null) {
          timeOnly = '00:00:00';
        }
        this.datePickerAdmi = dateOnly;
        this.timerPickerAdmi = timeOnly;
        this.claim.admissionDatetime = dateOnly + 'T' + timeOnly;
      }
    },
    dischargeDate_() {
      if (this.claim.dischargeDatetime != null) {
        var dateOnly = this.claim.dischargeDatetime.split('T')[0];
        var timeOnly = this.claim.dischargeDatetime.split('T')[1];
        if (timeOnly === 'undefined' || timeOnly == null) {
          timeOnly = '00:00:00';
        }
        this.datePickerDischar = dateOnly;
        this.timerPickerDischar = timeOnly;
        this.claim.dischargeDatetime = dateOnly + 'T' + timeOnly;
      }
    },

    formatDate(date) {
      if (date === null || date === undefined) return '';
      return date.split('-').reverse().join('-');
    },

    updateValue(e) {
      this.$emit('update:modelValue', e);
    },

    async getAppointments(patientId) {
      const oapi = new OpenAPIJSON();
      let response = await oapi.searchClaims(null, patientId);
      if (response && response.status && response.status >= 300) {
        this.$refs.toast.showToast(response.data.status.reason, 'fas fa-xmark', 'error');
      } else {
        // Call will return a list of claims, meaning there can be more than
        // one item in the appointments list with the same appointment id.
        let data = new Dict();
        for (let idx = 0; idx < response.items.length; idx++) {
          let item = response.items[idx];
          const consultant_id = item.claim_consultant.consultant_id;
          const key = item.app_id.toString();
          // Record the consultants that have already got claims for this appointment
          // so that they can be excluded from the Claim Consultant menu because a
          // consultant can only claim once for an appointment.
          if (data.has(key)) {
            let existing_item = data.get(key);
            existing_item.claim_consultants.push(consultant_id);
            data.set(key, existing_item);
          } else {
            item.claim_consultants = [consultant_id];
            data.set(key, item);
          }
        }
        this.appointments = data.toArray();
        // only on patient select, search input will appear for appointment
        this.appointmentSeach = true;
      }
    },

    async getMedics() {
      const oapi = new OpenAPIJSON();
      const res = await oapi.searchDoctors(null, null, null, true);

      this.medics = res;

      // Needs to be initially populated otherwise the consultant list will be empty
      this.possibleClaimMedics = [];
      for (let idx = 0; idx < res?.length; idx++) {
        let m = clone(res[idx]);
        m.tooltip = '';
        this.possibleClaimMedics.push(m);
      }
    },

    async listGenders() {
      try {
        const oapi = new OpenAPIJSON();
        this.genders = await oapi.listPersonGenders();
      } catch (e) {
        this.$refs?.toast?.showToast(e, 'fa-solid fa-triangle-exclamation', 'error');
      }
    },

    async defineClaim() {
      //Validation
      if (this.claim.patient.first_name === '') {
        this.$refs.toast.showToast('Please add Patient Name.', 'fas fa-xmark', 'error');
        this.$nextTick(() => {
          if (this.$refs.patientTypeahead) {
            this.$refs.patientTypeahead.focus();
            this.$refs.patientTypeahead.scrollIntoView({ behavior: 'smooth', block: 'center' });
          }
        });
        return;
      }

      if (this.claim.patient.last_name === '') {
        this.$refs.toast.showToast('Please add Patient Last Name.', 'fas fa-xmark', 'error');
        this.$nextTick(() => {
          if (this.$refs.patientTypeaheadLastName) {
            this.$refs.patientTypeaheadLastName.focus();
            this.$refs.patientTypeaheadLastName.scrollIntoView({ behavior: 'smooth', block: 'center' });
          }
        });
        return;
      }

      if (this.claim.patient.dob === null || this.claim.patient.dob === '') {
        this.$refs.toast.showToast('Please add Patient DOB.', 'fas fa-xmark', 'error');
        this.$nextTick(() => {
          if (this.$refs.patientTypeaheadDob) {
            this.$refs.patientTypeaheadDob.focus();
            this.$refs.patientTypeaheadDob.scrollIntoView({ behavior: 'smooth', block: 'center' });
          }
        });
        return;
      }

      if (this.claim.patient.gender === null || this.claim.patient.gender === '') {
        this.$refs.toast.showToast('Please add Patient Gender.', 'fas fa-xmark', 'error');
        this.$nextTick(() => {
          if (this.$refs.patientTypeaheadGender) {
            this.$refs.patientTypeaheadGender.$el.focus();
            this.$refs.patientTypeaheadGender.$el.scrollIntoView({ behavior: 'smooth', block: 'center' });
          }
        });
        return;
      }

      if (this.claim.patient.mrn === '') {
        this.$refs.toast.showToast('Please add MRN..', 'fas fa-xmark', 'error');
        this.$nextTick(() => {
          if (this.$refs.patientTypeaheadMrn) {
            this.$refs.patientTypeaheadMrn.focus();
            this.$refs.patientTypeaheadMrn.scrollIntoView({ behavior: 'smooth', block: 'center' });
          }
        });
        return;
      }
      if (this.claim.hospital_appointment_id === '') {
        this.$refs.toast.showToast('Please add Appointment id.', 'fas fa-xmark', 'error');
        this.$nextTick(() => {
          if (this.$refs.appointmentId) {
            this.$refs.appointmentId.focus();
            this.$refs.appointmentId.scrollIntoView({ behavior: 'smooth', block: 'center' });
          }
        });
        return;
      }

      if (this.claim.admitting_consultant.consultant.id !== '') {
        if (
          !this.claim ||
          !this.claim.admitting_consultant ||
          !this.claim.admitting_consultant.datetime ||
          this.claim.admitting_consultant.datetime === '' ||
          this.claim.admitting_consultant.datetime === null ||
          this.claim.admitting_consultant.datetime === undefined
        ) {
          this.$refs.toast.showToast('Please add Admitting Consultant Date.', 'fas fa-xmark', 'error');
          this.$nextTick(() => {
            if (this.$refs.admittingConsultant) {
              this.$refs.admittingConsultantDate.focus();
              this.$refs.admittingConsultantDate.scrollIntoView({ behavior: 'smooth', block: 'center' });
            }
          });

          return;
        }
      }
      if (
        this.claim.admitting_consultant.consultant.id === '' ||
        this.claim.admitting_consultant.consultant.id === null ||
        this.claim.admitting_consultant.consultant.id === undefined
      ) {
        if (this.claim.admitting_consultant.datetime !== '') {
          this.$refs.toast.showToast('Please add Admitting Consultant.', 'fas fa-xmark', 'error');
          this.$nextTick(() => {
            if (this.$refs.admittingConsultant) {
              this.$refs.admittingConsultant.$el.focus();
              this.$refs.admittingConsultant.$el.scrollIntoView({ behavior: 'smooth', block: 'center' });
            }
          });
          return;
        }
      }

      if (this.claim.discharging_consultant.consultant.id !== '') {
        if (
          !this.claim ||
          !this.claim.discharging_consultant ||
          !this.claim.discharging_consultant.datetime ||
          this.claim.discharging_consultant.datetime === '' ||
          this.claim.discharging_consultant.datetime === null ||
          this.claim.discharging_consultant.datetime === undefined
        ) {
          this.$refs.toast.showToast('Please add Discharging Consultant Date.', 'fas fa-xmark', 'error');
          this.$nextTick(() => {
            if (this.$refs.dischargingConsultantDate) {
              this.$refs.dischargingConsultantDate.focus();
              this.$refs.dischargingConsultantDate.scrollIntoView({ behavior: 'smooth', block: 'center' });
            }
          });
          return;
        }
      }

      if (
        this.claim.discharging_consultant.consultant.id === '' ||
        this.claim.discharging_consultant.consultant.id === null ||
        this.claim.discharging_consultant.consultant.id === undefined
      ) {
        if (this.claim.discharging_consultant.datetime !== '') {
          this.$refs.toast.showToast('Please add Discharging Consultant.', 'fas fa-xmark', 'error');
          this.$nextTick(() => {
            if (this.$refs.discharging_consultant) {
              this.$refs.discharging_consultant.$el.focus();
              this.$refs.discharging_consultant.$el.scrollIntoView({ behavior: 'smooth', block: 'center' });
            }
          });
          return;
        }
      }

      // VALIDATION IF ADMITTING CONSULTANT / DISCHARGING CONSULTANT IS MANDATORY
      // if (
      //   this.claim.admitting_consultant.consultant.id === '' ||
      //   this.claim.admitting_consultant.consultant.id === null ||
      //   this.claim.admitting_consultant.consultant.id === undefined
      // ) {
      //   this.$refs.toast.showToast('Please add Admitting Consultant.', 'fas fa-xmark', 'error');
      //   this.$nextTick(() => {
      //     if (this.$refs.admittingConsultant) {
      //       this.$refs.admittingConsultant.$el.focus();
      //       this.$refs.admittingConsultant.$el.scrollIntoView({ behavior: 'smooth', block: 'center' });
      //     }
      //   });

      //   return;
      // } else {
      //   if (
      //     !this.claim ||
      //     !this.claim.admitting_consultant ||
      //     !this.claim.admitting_consultant.datetime ||
      //     this.claim.admitting_consultant.datetime === '' ||
      //     this.claim.admitting_consultant.datetime === null ||
      //     this.claim.admitting_consultant.datetime === undefined
      //   ) {
      //     this.$refs.toast.showToast('Please add Admitting Consultant Date.', 'fas fa-xmark', 'error');
      //     this.$nextTick(() => {
      //       if (this.$refs.admittingConsultant) {
      //         this.$refs.admittingConsultantDate.focus();
      //         this.$refs.admittingConsultantDate.scrollIntoView({ behavior: 'smooth', block: 'center' });
      //       }
      //     });

      //     return;
      //   }
      // }

      // if (this.claim.discharging_consultant.consultant.id === '') {
      //   this.$refs.toast.showToast('Please add Discharging Consultant.', 'fas fa-xmark', 'error');
      //   this.$nextTick(() => {
      //     if (this.$refs.dischargingConsultant) {
      //       this.$refs.dischargingConsultant.$el.focus();
      //       this.$refs.dischargingConsultant.$el.scrollIntoView({ behavior: 'smooth', block: 'center' });
      //     }
      //   });

      //   return;
      // } else {
      //   if (
      //     !this.claim ||
      //     !this.claim.discharging_consultant ||
      //     !this.claim.discharging_consultant.datetime ||
      //     this.claim.discharging_consultant.datetime === '' ||
      //     this.claim.discharging_consultant.datetime === null ||
      //     this.claim.discharging_consultant.datetime === undefined
      //   ) {
      //     this.$refs.toast.showToast('Please add Discharging Consultant Date.', 'fas fa-xmark', 'error');
      //     this.$nextTick(() => {
      //       if (this.$refs.dischargingConsultantDate) {
      //         this.$refs.dischargingConsultantDate.focus();
      //         this.$refs.dischargingConsultantDate.scrollIntoView({ behavior: 'smooth', block: 'center' });
      //       }
      //     });
      //     return;
      //   }
      // }

      if (this.consultantId === '' || this.consultantId === null || this.consultantId === undefined) {
        this.$refs.toast.showToast('Please add Claim Consultant.', 'fas fa-xmark', 'error');
        this.$nextTick(() => {
          if (this.$refs.consultantComponent) {
            this.$refs.consultantComponent.$el.focus();
            this.$refs.consultantComponent.$el.scrollIntoView({ behavior: 'smooth', block: 'center' });
          }
        });
        return;
      }

      if (
        !this.claim ||
        !this.claim.insurance ||
        !this.claim.insurance[0] ||
        !this.claim.insurance[0].insurer ||
        !this.claim.insurance[0].insurer.id ||
        this.claim.insurance[0].insurer.id === ''
      ) {
        this.$refs.toast.showToast('Please add Insurer.', 'fas fa-xmark', 'error');
        this.$nextTick(() => {
          if (this.$refs.insurerComponent) {
            this.$refs.insurerComponent.$el.focus();
            this.$refs.insurerComponent.$el.scrollIntoView({ behavior: 'smooth', block: 'center' });
          }
        });
        return;
      }
      if (
        this.claim.insurance[0].policy_plan.name === undefined ||
        this.claim.insurance[0].policy_plan.name === null
      ) {
        this.claim.insurance[0].policy_plan = {
          name: '',
          type: 'name'
        };
      }
      if (
        this.claim.insurance[0].policy_number === '' ||
        this.claim.insurance[0].policy_number === null ||
        this.claim.insurance[0].policy_number === undefined
      ) {
        this.claim.insurance[0].policy_number = ' ';
      }

      if (
        this.claim.discharging_consultant.datetime === '' ||
        this.claim.discharging_consultant.datetime === null ||
        this.claim.discharging_consultant.datetime === undefined
      ) {
        delete this.claim.discharging_consultant;
      }

      if (
        this.claim.admitting_consultant.datetime === '' ||
        this.claim.admitting_consultant.datetime === null ||
        this.claim.admitting_consultant.datetime === undefined
      ) {
        delete this.claim.admitting_consultant;
      }

      this.appointmentCreate();
    },

    async appointmentCreate() {
      const oapi = new OpenAPIJSON();
      try {
        const res = await oapi.appointment_create(this.claim);
        await this.createClaim(res.claims[0].claim_id);
      } catch (error) {
        if (error.response.data.reason.includes('Cannot associate appointment')) {
          this.$refs.toast.showToast(
            'This appointment is already being used by another MRN. Please choose another one.',
            'fas fa-xmark',
            'error'
          );
          this.$refs.appointmentId.focus();
          this.$refs.appointmentId.scrollIntoView({ behavior: 'smooth', block: 'center' });
        }
      }
    },

    async createClaim(appointment_claim_id) {
      const oapi = new OpenAPIJSON();
      try {
        await oapi.claim_create(appointment_claim_id, this.consultantId, this.claim.insurance[0].insurer.id);
        this.$refs.toast.showToast('Claim Created', 'fas fa-check', 'success');
        this.resetClaim();
        this.updateValue(false);
      } catch (error) {
        this.$refs.toast.showToast(error, 'fas fa-xmark', 'error');
      }
    },

    async patientSelected(item) {
      if (!item) {
        return;
      }

      this.isLoading = true;
      this.resetClaim();
      await this.getMedics();
      await this.listGenders();
      this.disbableAppointment = false;
      this.claim.patient = item;
      // get appointments for specific person
      this.getAppointments(item.patient_id);
      this.isLoading = false;
      return item.full_name;
    },

    todaysDate() {
      let date = new Date();
      return date.toLocaleDateString('en-CA');
    },

    resetClaim() {
      this.consultantValue = '';
      this.disbableAppointment = false;
      this.genders = [];
      this.consultantId = '';
      this.patient = null;
      this.patients = null;
      this.patientMrn = '';
      this.patientString = '';
      this.appointment = null;
      this.appointments = null;
      this.appointmentSeach = false;
      this.appointmentString = '';
      this.insurer = null;
      this.insurers = null;
      this.medic = null;
      this.medics = null;
      this.admittingMedicString = '';
      this.dischargingMedicString = '';
      this.insurerPolicies = null;
      this.dobKey = 0;
      this.admitdateKey = 0;
      this.dischargedateKey = 0;
      this.claimdateKey = 0;
      this.successClaim = false;
      this.possibleClaimMedics = [];
      this.datePickerAdmi = null;
      this.timerPickerAdmi = '00:00:00';
      this.datePickerDischar = null;
      this.timerPickerDischar = '00:00:00';
      this.insurances = [];
      this.selectedInsurance = null;
      this.getProcInsurerItemValue = null;
      this.claimDateVar = null;
      this.claim = {
        patient: {
          mrn: '',
          title: '',
          first_name: '',
          last_name: '',
          dob: '',
          gender: '',
          contact: {
            postal_address: [''],
            telephone_landline: null,
            telephone_mobile: null,
            telephone_fax: null,
            email: null
          }
        },
        hospital_appointment_id: '',
        insurance: [
          {
            insurer: {
              type: 'name',
              name: ''
            },
            policy_plan: {
              type: 'name',
              name: ''
            },
            policy_number: ''
          }
        ],
        admitting_consultant: {
          consultant: {
            type: 'id',
            id: ''
          },
          datetime: ''
        },
        discharging_consultant: {
          consultant: {
            type: 'id',
            id: ''
          },
          datetime: ''
        }
      };
    },

    async selectAppointmentId(app) {
      this.isLoading = true;
      this.claim.hospital_appointment_id = app.hospital_appointment_id.toString();
      //this.claimDate = app.claim_date;
      this.claim.admitting_consultant.datetime = app.admitted_on + 'T00:00:00';
      if (app.admitted_by) {
        this.claim.admitting_consultant.datetime = app.admitted_on + 'T00:00:00';
        this.claim.admitting_consultant.consultant.id = app.admitted_by.consultant_id;
        this.admittingMedicString = { ro: { full_name: app.admitted_by.full_name } };
        this.datePickerAdmi = app.admitted_on;
      } else {
        this.datePickerAdmi = '';
        this.admittingMedicString = '';
        this.claim.admitting_consultant.datetime = '';
        this.claim.admitting_consultant.consultant.id = '';
      }

      if (app.discharged_by) {
        this.claim.discharging_consultant.datetime = app.discharged_on + 'T00:00:00';
        this.claim.discharging_consultant.consultant.id = app.discharged_by.consultant_id;
        this.dischargingMedicString = { ro: { full_name: app.discharged_by.full_name } };
        this.datePickerDischar = app.discharged_on;
      } else {
        this.datePickerDischar = '';
        this.dischargingMedicString = '';
        this.claim.discharging_consultant.datetime = '';
        this.claim.discharging_consultant.consultant.id = '';
      }

      let osvc = new OpenAPIJSON();
      let info = await osvc.appointmentInsuranceInfo(null, app.app_id);
      this.claim.insurance[0].insurer = info.insurer;
      this.claim.insurance[0].policy_plan = info.policy_plan;
      this.claim.insurance[0].policy_number = info.membership_no1;
      this.policy_name = info.policy_plan.ro.name;

      // When a appointment is selected, the available claim consultants has to be reduced to the
      // list of those who haven't already made a claim.
      this.possibleClaimMedics = [];
      for (let idx = 0; idx < this.medics.length; idx++) {
        let m = clone(this.medics[idx]);
        if (app.claim_consultants.includes(m.consultantId)) {
          m.tooltip = 'Claim already exists';
        } else {
          m.tooltip = '';
        }
        this.possibleClaimMedics.push(m);
      }
      this.disbableAppointment = true;
      this.isLoading = false;
    },
    async btn_cancel() {
      this.claimDateVar = this.todaysDate();

      this.resetClaim();
      this.updateValue(false);
    }
  },

  mounted() {
    // assign todays date to claimDate
    this.claimDateVar = this.todaysDate();
    this.resetClaim();
    this.getMedics();
    this.listGenders();
  }
};
</script>

<style lang="scss" scoped>
.custom-claim-modal {
  padding-bottom: 75px;
}

.app-table-head {
  padding-bottom: 3px;
  font-size: 14px;
  font-weight: 600;
  font-style: italic;
  border-bottom: 1px solid #eee;
  overflow: auto;
}

.app-table-li {
  overflow: auto;
  padding-top: 5px;
  padding-bottom: 3px;
  border-bottom: 1px solid #eee;
  font-weight: 600;
}

.sub-title {
  text-align: left;
  font-size: 18px;
  font-weight: 400;
  font-style: italic;
  color: #0054ab;
}

.required {
  color: red;
}
</style>
