<template>
  <!-- MODAL ITENS -->
  <div v-if="showManageModal" class="modal">
    <div class="modal-content">
      <button class="right-button" @click="showManageModal = false">X</button>

      <div class="cs-table-section">
        <div class="cs-table">
          <div class="cs-li cs-tag" style="resize: none">
            <div class="cs-item cs-tag-border"></div>
          </div>
          <div
            v-for="(itemTableSecond, index1) in tableNamesSecond"
            :key="index1"
            :style="{ width: itemTableSecond.width + 'px' }"
            class="cs-li cs-patient"
          >
            <div class="cs-title">
              <div v-if="itemTableSecond.firstIndex" class="sort-title">{{ itemTableSecond.TableTitle }}</div>
            </div>
            <div
              class="cs-item"
              v-for="(modal, index1) in claimsSecond[itemTableSecond.Indexer]"
              :key="index1"
              :class="{ 'even-background': index1 % 2, highlighted: index1 === highlightedIndex1 }"
              @mouseover="highlightedIndex1 = index"
              @mouseleave="highlightedIndex1 = -1"
            >
              {{ modal[itemTableSecond.firstIndex] }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- MODAL ITENS END -->
  <div class="cs-table-section" style="margin-bottom: 20px">
    <SpinnerLoader v-if="isLoading" />
    <!-- INPUT SEARCH -->
    <input
      v-if="optionSearch"
      @click="secondIndex = tableNames[0].firstIndex"
      type="text"
      v-model="searchTerm"
      placeholder="   Search..."
      class="search-input-field"
      style="margin: 5px"
    />
    <!-- INPUT SEARCH END -->

    <div v-if="claims === null || claims === '' || claims === undefined" class="no-claims-text">
      <div v-if="optionNoData" class="addAttachments">
        <div class="m-8">
          No payment has been received from the insurer.
          <div>
            <router-link :to="{ name: 'finance' }" style="margin-top: 18px; text-decoration: underline"
              >Upload and scan an insurer Payment File in "Payments and Reconciliation"</router-link
            >
          </div>
        </div>
      </div>
      <div v-else>There are no Data on table</div>
    </div>
    <div v-else class="cs-table flex">
      <div v-if="optionResultPerPage" class="cs-li cs-select-wrapper cs-hide-resize">
        <div class="cs-title select-wrapper">
          <div class="select-wrapper">
            <i class="fa fa-refresh" style="margin-left: 10px; margin-right: 15px" @click="resetSort()"></i>
          </div>
          <!-- <select class="select-wrapper" id="combo" v-model="itemsPerPage">
            <option v-for="value in values" :key="value" :value="value">{{ value }}</option>
          </select> -->
        </div>

        <div
          class="cs-item cs-tag-border_"
          v-for="(item, index) in claimsIndex"
          :key="index"
          :class="{ 'even-background': index % 2, highlighted: index === highlightedIndex }"
        ></div>

        <!-- <div class="cs-item cs-tag-border">
                </div> -->
      </div>

      <div
        v-for="(itemTable, index) in tableNames"
        :key="index"
        :style="{ 'min-width': itemTable.width + 'px' }"
        class="cs-li cs-patient"
      >
        <div class="cs-title">
          <select
            @click="getFiltered(itemTable.selectedBox)"
            v-if="itemTable.selectedBox && itemTable.secondIndex === ''"
            v-model="selectedItens"
            class="selecteditem"
          >
            <option value="">{{ itemTable.TableTitle }}</option>
            <option v-for="dataFilter in dataFilters" :value="dataFilter" :key="dataFilter">{{ dataFilter }}</option>
            <i class="fa fa-chevron-down select-arrow"></i>
          </select>

          <select
            @click="getFiltered(itemTable.selectedBox, itemTable.firstIndex)"
            v-if="itemTable.selectedBox && itemTable.secondIndex"
            v-model="selectedItens"
            class="selecteditem"
          >
            <option value="">{{ itemTable.TableTitle }}</option>
            <option v-for="dataFilter in dataFiltersSecondIndex" :value="dataFilter" :key="dataFilter">
              {{ dataFilter }}
            </option>
            <i class="fa fa-chevron-down select-arrow"></i>
          </select>

          <div v-if="!itemTable.selectedBox" class="sort-title">{{ itemTable.TableTitle }}</div>
          <div
            v-if="itemTable.firstIndex && itemTable.secondIndex === '' && !itemTable.selectedBox"
            class="sort-icons"
            style="right: 24px"
          >
            <i
              class="fas fa-sort-up sort-up ml-2"
              :style="{ color: itemTable.firstIndex === currentSortIndex && currentSortDirection ? 'blue' : 'inherit' }"
              @click="sortData(itemTable.firstIndex, true, null, itemTable.money)"
            ></i>
            <i
              class="fas fa-sort-down sort-down ml-2"
              :style="{
                color: itemTable.firstIndex === currentSortIndex && !currentSortDirection ? 'blue' : 'inherit'
              }"
              @click="sortData(itemTable.firstIndex, false, null, itemTable.money)"
            ></i>
          </div>
          <div v-else-if="!itemTable.selectedBox" class="sort-icons" style="right: 24px">
            <i
              class="fas fa-sort-up sort-up ml-2"
              :style="{
                color: itemTable.secondIndex === currentSortIndex && currentSortDirection ? 'blue' : 'inherit'
              }"
              @click="sortData(itemTable.secondIndex, true, itemTable.firstIndex, itemTable.money, itemTable.dateF)"
            ></i>
            <i
              class="fas fa-sort-down sort-down ml-2"
              :style="{
                color: itemTable.secondIndex === currentSortIndex && !currentSortDirection ? 'blue' : 'inherit'
              }"
              @click="sortData(itemTable.secondIndex, false, itemTable.firstIndex, itemTable.money, itemTable.dateF)"
            ></i>
          </div>
        </div>
        <div
          class="cs-item"
          v-for="(item, index) in claimsIndex"
          :key="index"
          @click="showItens(item)"
          :class="{ 'even-background': index % 2, highlighted: index === highlightedIndex }"
          @mouseover="highlightedIndex = index"
          @mouseleave="highlightedIndex = -1"
        >
          <!-- First Index -->

          <div
            v-if="
              itemTable.secondIndex === '' ||
              itemTable.secondIndex == null ||
              itemTable.checkBoxValue != null ||
              itemTable.activeIcon != null
            "
          >
            <!-- CHECK BOX STATE -->
            <div class="group relative">
              <i v-if="itemTable.activeIcon === true" :class="getFileIcon(item[itemTable.activeIconValue])"></i>
              <span
                class="group-hover:opacity-100 transition-opacity bg-gray-400 px-1 text-xs text-gray-100 absolute left-1/2 -translate-x-1/2 translate-y-full opacity-0 mx-auto"
                >{{ getFileIconToolTip(item[itemTable.activeIconValue]) }}</span
              >
            </div>

            <label v-if="itemTable.checkbox === true" class="flex items-center space-x-2 cursor-pointer mt-2 ml-8">
              <input type="checkbox" :checked="isCheck(item)" @change="toggleCheck(item)" class="hidden" />
              <div
                class="w-5 h-5 rounded-full border-2 border-grey-600 flex items-center justify-center transition duration-300"
                :class="item[itemTable.checkBoxValue] ? 'bg-white border-green-600' : 'bg-white border-gray-400'"
              >
                <i v-if="item[itemTable.checkBoxValue]" class="text-green-600 fas fa-check"></i>
              </div>
            </label>

            <span
              v-if="
                itemTable.secondIndex === '' &&
                itemTable.money != null &&
                itemTable.date == null &&
                itemTable.regex == null &&
                itemTable.dateF == null
              "
            >
              {{
                itemTable.money
                  ? parseFloat(item[itemTable.firstIndex]).toLocaleString(language, {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2
                    })
                  : item[itemTable.firstIndex]
              }}
            </span>
            <span v-if="itemTable.regex">
              {{ String(item[itemTable.firstIndex]).replace(/\"[\[\]']+/g, '') }}
            </span>
            <span v-if="itemTable.dateF">
              {{ humanDate(item[itemTable.firstIndex]) }}
            </span>
          </div>

          <!-- Second Index -->

          <div v-else>
            <button
              @click="sentButtonValue(item[itemTable.firstIndex][itemTable.secondIndex], itemTable.buttons[0].command)"
              v-if="itemTable.buttons && itemTable.stateButton == null"
              type="button"
              :class="itemTable.buttons[0].class"
            >
              {{ itemTable.buttons[0].name }}
            </button>

            <button
              @click="sentButtonValue(item['file_id'], itemTable.buttons[0].command)"
              v-if="itemTable.buttons && itemTable.stateButton == true"
              type="button"
              :class="[itemTable.buttons[0].class, item[itemTable.firstIndex] === 'Failed' ? 'bg-red-500' : '']"
            >
              {{ tableState(item[itemTable.firstIndex]) }}
            </button>

            <span v-if="itemTable.dateF">
              {{ humanDate(item[itemTable.firstIndex][itemTable.secondIndex]) }}
            </span>

            <span v-if="itemTable.secondIndex != '' && itemTable.money != null && itemTable.dateF == null">
              {{
                itemTable.money
                  ? parseFloat(item[itemTable.firstIndex][itemTable.secondIndex]).toLocaleString(language, {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2
                    })
                  : item[itemTable.firstIndex][itemTable.secondIndex]
              }}
            </span>
          </div>

          <span v-if="itemTable.date != null">
            {{ getMonth(item[itemTable.firstIndex]) }}
          </span>

          <div v-if="itemTable.secondIndex == null || itemTable.secondIndex == null">
            <button
              @click="sentButtonValue(item, itemTable.buttons[0].command)"
              v-if="itemTable.buttons"
              type="button"
              :class="itemTable.buttons[0].class"
            >
              {{ itemTable.buttons[0].name }}
            </button>
          </div>
        </div>

        <!-- TOTALS -->
        <div v-if="optionTotal" class="cs-title">
          <div v-if="itemTable.sum && itemTable.money" class="sort-title">
            €
            {{
              calculateSum(itemTable.firstIndex).toLocaleString(language, {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2
              })
            }}
          </div>
          <div v-if="itemTable.sum && !itemTable.money" class="sort-title">
            {{ calculateSum(itemTable.firstIndex) }}
          </div>
          <div class="sort-icons" style="right: 24px"></div>
        </div>
      </div>
    </div>
  </div>

  <div v-if="claims && claims.length === 0">
    <div v-if="optionNoData" class="addAttachments">
      <div v-if="optionNoData.claim_state != 'paid_in_full'" class="m-8">
        No payment has been received from the insurer.
        <div>
          <router-link :to="{ name: 'finance' }" style="margin-top: 18px; text-decoration: underline"
            >Upload and scan an insurer Payment File in "Payments and Reconciliation"</router-link
          >
        </div>
      </div>
      <div v-else class="m-8">
        This claim was paid in full by the insurer.
        <div>
          <router-link :to="{ name: 'finance' }" style="margin-top: 18px; text-decoration: underline"
            >Find more details in “Payment and Reconciliation"</router-link
          >
        </div>
      </div>
    </div>
    <div v-else>There is no data in the table</div>
  </div>

  <div v-if="claims && claims.length > 0" class="pagination" style="margin-bottom: 20px">
    <div
      class="pagination-btn"
      :class="{ 'pag-btn-disabled': currentPage <= 1 }"
      style="border-top-left-radius: 8px; border-bottom-left-radius: 8px"
      @click="previousPage"
    >
      <i class="fas fa-chevron-left" style="padding-right: 3px"></i> Prev
    </div>
    <div class="pagination-btn-el" style="border-left: 0; border-right: 0">
      {{ currentPage }}
    </div>
    <div class="pagination-btn-el" style="border-left: 0; border-right: 0; padding: 0">/</div>
    <div class="pagination-btn-el" style="border-left: 0; border-right: 0">
      {{ totalPages }}
    </div>
    <div
      class="pagination-btn"
      :class="{ 'pag-btn-disabled': currentPage === totalPages }"
      style="border-top-right-radius: 8px; border-bottom-right-radius: 8px"
      @click="nextPage"
    >
      Next <i class="fas fa-chevron-right" style="padding-left: 3px"></i>
    </div>
  </div>
  <ToastComponent ref="toast"></ToastComponent>
</template>

<script>
import '@/assets/scss/claim-list/index.scss';
import StringUtility from 'utils/stringUtility';
import api from 'api/api';
import { OpenAPIJSON } from 'api';
import { format } from 'date-fns';
import SpinnerLoader from '@/components/misc/SpinnerLoader.vue';
import ToastComponent from '@/components/claim-forms/components/ToastComponent.vue';
import JSZip from 'jszip';
import { saveAs } from 'file-saver';

export default {
  name: 'TableComponent',
  emits: ['sentValue', 'unset-download-state'],
  components: {
    SpinnerLoader,
    ToastComponent
  },
  props: {
    claimsProp: { default: '' },
    tableNames: { default: '' },
    consultant: { default: '' },
    tableNamesSecond: { default: '' },
    optionSearch: { type: Boolean, default: true },
    optionTotal: { type: Boolean, default: false },
    optionNoData: { default: null },
    optionResultPerPage: { type: Boolean, default: true },
    isLoading: { type: Boolean, default: false }
  },
  data() {
    return {
      openApi: new OpenAPIJSON(),
      highlightedIndex: -1,
      calendarMonths: [
        'January',
        'February',
        'March',
        'April',
        'May',
        'June',
        'July',
        'August',
        'September',
        'October',
        'November',
        'December'
      ],
      dataTop: null,
      totalWithholdingTax: null,
      buttonValue: null,
      sortedData: null,
      currentSortIndex: '',
      currentSortDirection: true,
      teste: null,
      claims: null,
      allClaims: [],
      selectedItens: '',
      valueSelected: '',
      valueSelected2: '',
      filteredItens: [],
      searchTerm: '',
      secondIndex: '',
      showManageModal: false,
      claimsSecond: '',
      currentPage: 1,
      itemsPerPage: 20,
      values: [10, 20, 50, 100, 500],
      isChecked: false,
      itemUpdate: {},
      initialDataSet: false
    };
  },

  watch: {
    claims(newVal) {
      this.dataTop = newVal;
    },
    sortedData(newVal) {
      this.dataTop = newVal;
    },
    claimsProp(newVal) {
      if (this.initialDataSet === false || this.allClaims.length < newVal.length) {
        this.allClaims = [...newVal];
        this.initialDataSet = true;
      }
      this.claims = [...newVal];
    },
    selectedItens() {
      if (this.valueSelected === 'insurer') {
        this.$emit('set-insurer', this.selectedItens);
      } else {
        this.updateFiltered();
      }
    },
    searchTerm() {
      this.updateFiltered();
    }
  },
  computed: {
    claimsIndex() {
      const startIndex = (this.currentPage - 1) * this.itemsPerPage;
      const endIndex = startIndex + this.itemsPerPage;
      if (this.claims?.length) {
        return this.claims.slice(startIndex, endIndex);
      }
      return [];
    },
    totalPages() {
      if (this.claims == null) return 0;
      return Math.ceil(this.claims.length / this.itemsPerPage);
    },
    dataFilters() {
      const claimData = !this.allClaims.length ? this.claimsProp : this.allClaims;
      const types = claimData.map(selected => selected[this.valueSelected]);
      return [''].concat([...new Set(types)]);
    },
    dataFiltersSecondIndex() {
      const claimData = !this.allClaims.length ? this.claimsProp : this.allClaims;
      const types = claimData.map(selected => selected?.[this.valueSelected2]?.[this.valueSelected]);
      return [''].concat([...new Set(types)]);
    }
  },

  mounted() {
    this.language = navigator.language || navigator.userLanguage;
    this.claims = this.claimsProp;
  },

  methods: {
    async downloadCurrentTable() {
      const currentTableFiles = this.currentTableFiles();
      if (currentTableFiles.length) {
        const zip = new JSZip();
        zip.folder('InsurerFiles');
        const assoc = this.getAssocType();

        if (assoc) {
          try {
            let fileNames = [];
            for (let i = 0; i < currentTableFiles.length; i++) {
              fileNames.push(currentTableFiles[i].file_name);
            }
            const { files } = await this.openApi.file_get(fileNames, assoc);
            if (files.length) {
              for (let i = 0; i < files.length; i++) {
                zip.file('InsurerFiles/' + files[i].file_name, files[i].data, { base64: true });
              }

              zip.generateAsync({ type: 'blob' }).then(function (blob) {
                saveAs(blob, 'archive.zip');
              });
            }
            this.$emit('unset-download-state');
          } catch (error) {
            this.$toast.error('Something went wrong downloading files. Please try again.');
            this.$emit('unset-download-state');
          }
        }
      } else {
        this.$emit('unset-download-state');
      }
    },
    async downloadCsvTable() {
      const currentTableFiles = this.currentTableFiles();
      if (currentTableFiles.length) {
        let cvsData = [];
        currentTableFiles.forEach(item => {
          let creationDate;
          let insurerFileName;
          if (item.creation_date) {
            creationDate = this.formatDate(item.creation_date);
          } else {
            creationDate = '';
          }
          if (item.file_name) {
            insurerFileName = item.file_name;
          } else {
            insurerFileName = '/';
          }

          let parseItem = {
            Insurer: item.insurer.insurer_name,
            'Payment Filename': insurerFileName,
            'Uploaded Date': creationDate,
            'Payment Date': item.payment_date ? this.humanDate(item.payment_date) : '',
            Processed: item.processed_date ? this.humanDate(item.insurerFile.processed_date) : '',
            'Total Invoiced': item.total_amount_invoiced,
            Paid: item.total_amount_paid,
            Pended: item.total_amount_pended,
            Rejected: item.total_amount_rejected,
            Reconciled: item.reconciled?.amount_payable,
            'External Invoices Paid': item.external.amount_payable,
            Unmatched: item.unreconciled.amount_payable
          };
          cvsData.push(parseItem);
        });

        const unparsedResults = this.$papa.unparse(cvsData, {
          delimiter: ','
        });
        this.$papa.download(unparsedResults, 'CSV-PaymentAndTax-' + Math.floor(Date.now() / 1000));
      }
    },
    currentTableFiles() {
      if (this.totalPages > 1) {
        return this.claims.slice((this.currentPage - 1) * this.itemsPerPage, this.currentPage * this.itemsPerPage);
      } else {
        return this.claims;
      }
    },
    getFileIcon(file) {
      if (file === 'PAID') {
        return 'fa-solid text-xl text-green-500 fa-triangle-exclamation';
      }
      if (file === 'PART_PAID') {
        return 'fa-solid text-xl text-yellow-500 fa-triangle-exclamation';
      }
      if (file === 'REJECTED') {
        return 'fa-solid text-xl text-red-500 fa-triangle-exclamation';
      }
      if (file === 'PENDED') {
        return 'fa-solid text-xl text-yellow-500 fa-triangle-exclamation';
      }
    },

    getFileIconToolTip(file) {
      if (file === 'PAID') {
        return 'Paid';
      }
      if (file === 'PART_PAID') {
        return 'Part Paid';
      }
      if (file === 'REJECTED') {
        return 'Rejected';
      }
      if (file === 'PENDED') {
        return 'Pended';
      }
    },
    async updateInsurerPayment() {
      await api
        .modifyInsurerRemittance(this.consultant.consultant_id, this.itemUpdate)
        .then(res => {
          console.log(res);
        })
        .catch(error => {
          this.$refs.toast.showToast(error, 'fas fa-check', 'error');
        });
    },
    toggleCheck(item) {
      //const dayDate = new Date();
      if (item.processedOn != null) {
        item.processedOn = null;
        this.itemUpdate = item;
        let deleteIcon = this.itemUpdate.statusPayment;
        let deleteIcon1 = this.itemUpdate.procedureCode;
        let deleteIcon2 = this.itemUpdate.description;
        delete this.itemUpdate.statusPayment;
        delete this.itemUpdate.procedureCode;
        delete this.itemUpdate.description;
        this.updateInsurerPayment();
        this.itemUpdate.statusPayment = deleteIcon;
        this.itemUpdate.procedureCode = deleteIcon1;
        this.itemUpdate.description = deleteIcon2;
        return;
      }
      const dateObj = new Date();
      const formattedDate = format(dateObj, "yyyy-MM-dd'T'HH:mm:ss");

      item.processedOn = formattedDate;
      this.itemUpdate = item;
      let deleteIcon = this.itemUpdate.statusPayment;
      let deleteIcon1 = this.itemUpdate.procedureCode;
      let deleteIcon2 = this.itemUpdate.description;
      delete this.itemUpdate.statusPayment;
      delete this.itemUpdate.procedureCode;
      delete this.itemUpdate.description;

      this.updateInsurerPayment();
      this.itemUpdate.statusPayment = deleteIcon;
      this.itemUpdate.procedureCode = deleteIcon1;
      this.itemUpdate.description = deleteIcon2;
    },
    isCheck(item) {
      return item.processedOn !== null;
    },
    changePage(page) {
      if (page >= 1 && page <= this.totalPages) {
        this.currentPage = page;
      }
    },
    previousPage() {
      if (this.currentPage > 1) {
        this.currentPage--;
      }
    },
    nextPage() {
      if (this.currentPage < this.totalPages) {
        this.currentPage++;
      }
    },
    showItens(item) {
      if (this.tableNamesSecond === '' || this.tableNamesSecond === null || this.tableNamesSecond === undefined) {
        return;
      }
      this.showManageModal = true;
      this.claimsSecond = item;
    },
    getFiltered(newVal, newVal2) {
      this.currentPage = 1;

      this.claims = [...this.claimsProp];
      let filteredItens = [...this.claimsProp];
      this.filteredItens = [...this.claimsProp];

      if (newVal) {
        this.valueSelected = newVal;
      }

      if (newVal2) {
        this.valueSelected2 = newVal2;
      }

      if (this.searchTerm) {
        const searchTermLower = this.searchTerm.toLowerCase();
        filteredItens = filteredItens.filter(selected =>
          Object.values(selected).some(value => value?.toString().toLowerCase().includes(searchTermLower))
        );
      }

      if (this.valueSelected2 === '') {
        if (this.selectedItens !== '') {
          filteredItens = filteredItens.filter(selected => selected[this.valueSelected] === this.selectedItens);
        }
      } else {
        if (this.selectedItens !== '') {
          filteredItens = filteredItens.filter(
            selected => selected[this.valueSelected2][this.valueSelected] === this.selectedItens
          );
        }
      }

      return filteredItens;
    },
    updateFiltered() {
      return (this.claims = this.getFiltered());
    },

    sentButtonValue(neValue, newValueSecond) {
      this.$emit('sentValue', neValue, newValueSecond);
    },

    calculateSum(key) {
      this.sum = this.claims.reduce((accumulator, item) => accumulator + item[key], 0);
      return this.sum;
    },

    sortData(key, ascending = true, secondValue, typeValue, typeValue1) {
      this.currentSortIndex = key;
      this.currentSortDirection = ascending;

      if (secondValue != undefined) {
        this.sortedData = this.dataTop.sort((a, b) => {
          if (typeValue) {
            const numA = parseFloat(a[secondValue][key]);
            const numB = parseFloat(b[secondValue][key]);

            if (!isNaN(numA) && !isNaN(numB)) {
              if (numA < numB) {
                return ascending ? -1 : 1;
              }
              if (numA > numB) {
                return ascending ? 1 : -1;
              }
            }
          }

          if (typeValue1) {
            const dateA = new Date(a[secondValue][key]);
            const dateB = new Date(b[secondValue][key]);

            if (!isNaN(dateA.getTime()) && !isNaN(dateB.getTime())) {
              if (dateA < dateB) {
                return ascending ? -1 : 1;
              }
              if (dateA > dateB) {
                return ascending ? 1 : -1;
              }
            }
          }

          if (typeValue != true && typeValue1 != true) {
            if (a[secondValue][key] < b[secondValue][key]) {
              return ascending ? -1 : 1;
            }
            if (a[secondValue][key] > b[secondValue][key]) {
              return ascending ? 1 : -1;
            }
          }

          return 0;
        });
      } else {
        this.sortedData = this.dataTop.sort((a, b) => {
          if (typeValue) {
            const numA = parseFloat(a[key]);
            const numB = parseFloat(b[key]);

            if (!isNaN(numA) && !isNaN(numB)) {
              if (numA < numB) {
                return ascending ? -1 : 1;
              }
              if (numA > numB) {
                return ascending ? 1 : -1;
              }
            }
          }

          if (typeValue1) {
            const dateA = new Date(a[key]);
            const dateB = new Date(b[key]);
            if (!isNaN(dateA.getTime()) && !isNaN(dateB.getTime())) {
              if (dateA < dateB) {
                return ascending ? -1 : 1;
              }
              if (dateA > dateB) {
                return ascending ? 1 : -1;
              }
            }
          }

          if (typeValue != true && typeValue1 != true) {
            if (a[key] < b[key]) {
              return ascending ? -1 : 1;
            }
            if (a[key] > b[key]) {
              return ascending ? 1 : -1;
            }
          }
          return 0;
        });
      }
    },

    resetSort() {
      this.claims = [...this.claimsProp];
      this.currentSortIndex = '';
      this.currentSortDirection = true;
      this.searchTerm = '';
      this.selectedItens = '';
    },

    tableState(newValue) {
      if (newValue === 'Success') return 'RE-SCAN';
      if (newValue === 'Failed') return 'FAILED / RE-SCAN';
      if (newValue === 'Unknown') return 'SCAN';
      if (newValue === 'InProgress') return 'SCANNING';
    },

    humanDate(date) {
      return StringUtility.formatDate(date);
    },

    getMonth(index) {
      if (index >= 12) index = index % 12;

      return this.calendarMonths[index];
    },
    formatDate(data) {
      let date = new Date(data);
      return date.toLocaleDateString('es-CL');
    },
    getAssocType() {
      if (this.consultant?.consultant_id) {
        return {
          id: this.consultant?.consultant_id,
          type: 'id',
          assoc_type: 'consultant'
        };
      } else if (this.consultant?.person_id) {
        return {
          id: this.consultant?.person_id,
          type: 'personid',
          assoc_type: 'consultant'
        };
      } else {
        return null;
      }
    }
  }
};
</script>

<style scoped>
.even-background {
  background-color: #f2f2f2;
}

.highlighted {
  background-color: #00a9e170;
}

.bg-blue {
  background-color: #00a7e1;
}

.selecteditem {
  -webkit-appearance: none;
  -moz-appearance: none;
  background-color: rgba(0, 0, 255, 0);
  color: rgb(121, 121, 121);
  padding-right: 30px;
  border: 0px;
  text-align: center;
  font-size: 12px;
}

.select-arrow {
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
  pointer-events: none;
}

.search-input-field {
  height: 30px;
  border: 1px solid #e6e6e6;
  border-radius: 8px;
  background: #f9f9f9;
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999;
}

.modal-content {
  max-height: 90%;
  overflow-y: auto;
  background-color: #fff;
  padding: 20px;
  border-radius: 4px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
}

.right-button {
  float: right;
}

.select-wrapper {
  display: flex;
  align-items: center;
  width: 75px;
  border: 0px;
  font-size: 12px;
}

.addAttachments {
  background-color: #d9d9d9;
  border-width: 0.1px;
}

.noFiles {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100px;
  margin: 20px;
}
</style>
