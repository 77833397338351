export const ProcedureCodeCategory = Object.freeze({
  BREAST: 'breast',
  CARDIOLOGY: 'cardiology',
  CONSULTATIONS: 'consultations',
  DENTAL_ORAL_SURGERY: 'dental_oral_surgery',
  EAR_NOSE_THROAT: 'ear_nose_throat',
  GENERAL_PRACTITIONER: 'general_practitioner',
  GENDER_REASSIGN: 'gender_reassign',
  GENERAL_SURGERY: 'general_surgery',
  GYNAECOLOGY: 'gynaecology',
  CLINICAL_INPATIENT: 'clinical_inpatient',
  MAJOR_MEDICAL: 'major_medical',
  INPATIENT_CONSULTATION: 'inpatient_consultation',
  MAXILLOFAC_SURGERY: 'maxillofac_surgery',
  NEUROSURGERY: 'neurosurgery',
  OPTHALMIC: 'opthalmic',
  ORTHOPEDIC: 'orthopedic',
  OTHER_SERV: 'other_serv',
  OTHER_RESPIRATORY: 'other_respiratory',
  PALLIATIVE: 'palliative',
  PATHOLOGY_CAT1: 'pathology_cat1',
  PATHOLOGY_CAT3: 'pathology_cat3',
  PATHOLOGY_CAT4: 'pathology_cat4',
  PATHOLOGY_CAT5A: 'pathology_cat5_a',
  PATHOLOGY_CAT6: 'pathology_cat6',
  PATHOLOGY_CAT7: 'pathology_cat7',
  PATHOLOGY_CAT8: 'pathology_cat8',
  PATHOLOGY_CAT9: 'pathology_cat9',
  PLASTIC_SURGERY: 'plastic_surgery',
  RADIOLOGY_NORMAL: 'radiology_normal',
  RADIOLOGY_INTERV: 'radiology_interv',
  RADIOLOGY_MRI: 'radiology_m_r_i',
  RADIOLOGY_INVASIVE_VASCULAR: 'radiology_invasive_vascular',
  RADIOLOGY_NUCLEAR: 'radiology_nuclear',
  RADIOLOGY_INVIVO: 'radiology_invivo',
  RADIOTHERAPY: 'radiotherapy',
  SPINAL_OPS: 'spinal_ops',
  THORASIC: 'thorasic',
  UROLOGY: 'urology',
  MEDICAL_ONCOLOGY: 'medical_oncology',
  PATHOLOGY_CAT5_B: 'pathology_cat5_b',
  DAYS_STAYED: 'days_stayed',
  HAEMATOLOGY: 'haematology',
  IMMUNOLOGY: 'immunology',
  BIOCHEMISTRY: 'biochemistry',
  MICROBIOLOGY: 'microbiology',
  HISTOPATHOLOGY: 'histopathology',
  RADIOLOGY_CT: 'radiology_c_t',
  NOT_CATEGORISED: 'not_categorised',
});
