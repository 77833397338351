<template>
  <div class="example-one">
    <vue-awesome-paginate
      :total-items="totalItems"
      :items-per-page="itemsPerPage"
      :max-pages-shown="3"
      v-model="internalCurrentPage"
    />
  </div>
</template>

<script>
import { ref, watch } from 'vue';

export default {
  props: {
    totalItems: {
      type: Number,
      required: true
    },
    itemsPerPage: {
      type: Number,
      default: 10
    },
    currentPage: {
      type: Number,
      default: 1
    }
  },
  setup(props, { emit }) {
    const internalCurrentPage = ref(props.currentPage);

    watch(
      () => props.currentPage,
      newValue => {
        internalCurrentPage.value = newValue;
      }
    );

    watch(internalCurrentPage, newValue => {
      emit('update:page', newValue);
    });

    return {
      internalCurrentPage
    };
  }
};
</script>

<style>
.example-one {
  margin-top: 10px;
  margin-left: auto;
  margin-right: auto;
  max-width: 1300px;
  width: 100%;
  padding-left: 15px;
  padding-right: 15px;
  box-sizing: border-box;
  text-align: center;
}
.example-one .pagination-container {
  column-gap: 10px;
}
.example-one .paginate-buttons {
  height: 40px;
  width: 40px;
  border-radius: 20px;
  cursor: pointer;
  color: black;
}
.example-one .paginate-buttons:hover {
  background-color: #d8d8d8;
}
.example-one .active-page {
  background-color: #00a7e1;
  border: 1px solid #00a7e1;
  color: white;
}
.example-one .active-page:hover {
  background-color: #2988c8;
}
.example-one .back-button:active,
.example-one .next-button:active {
  background-color: #c4c4c4;
}
</style>
