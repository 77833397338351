<template>
  <div class="invoice-dropdown">
    <Typeahead
      v-model="selectedInvoice"
      :items="invoices"
      :placeholder="this.placeholder"
      :min-input-length="this.min_input"
      :item-projection="showItem"
      :item-value="getItemValue"
      @select-item="selectItem"
      :fullW="true"
    />
  </div>
</template>

<script>
import { OpenAPIJSON } from 'api/openapi';
import { ClaimPhase } from 'enums';
import Typeahead from '@/components/claim-forms/components/Typeahead.vue';

export default {
  name: 'InvoiceTypeahead',
  components: {
    Typeahead
  },
  props: {
    //get the value to appear in PlaceHolder
    placeholder: { require: false, default: 'Search' },
    min_input: { require: false, default: 1 },
    consultant_id: { require: false, default: null },
    consultant_person_id: { require: false, default: null },
    insurer_id: { require: false, default: null }
  },
  emits: ['selectedInvoice'],
  data() {
    return {
      invoices: [],
      selectedInvoice: ''
    };
  },
  methods: {
    async fetch_invoices() {
      try {
        const openApi = new OpenAPIJSON();
        let { items } = await openApi.searchInvoice(null, ClaimPhase.POST_SUBMIT, null, null, null, this.consultant_id);
        this.invoices = items;
      } catch (error) {
        this.$refs.toast.showToast(
          'Something went wrong getting invoices. Please try again later.',
          'fa-solid fa-triangle-exclamation',
          'error'
        );
      }
    },
    showItem(item) {
      return item.invoice_ref + ', ' + item.patient_name + ' MRN ' + item.patient_mrn;
    },
    getItemValue(item) {
      this.selectedInvoice = item.invoice_ref;
      return item.invoice_ref;
    },

    selectItem(item) {
      this.$emit('selectedInvoice', item);
      this.selectedInvoice = item.invoice_ref;
    }
  },
  mounted() {
    this.fetch_invoices();
  }
};
</script>

<style scoped>
.invoice-dropdown {
  display: inline-block;
}
</style>
