<template>
  <!-- start -->
  <div class="col-12 mt-5">

    <div class='col-6 float-left mb-2'>
      <div v-if="question" class="flex justify-between">
        <label class="block text-sm font-medium text-gray-700">{{ question }}</label>
      </div>
      <span v-if="textBoxDisabled" class="block w-full text-sm text-gray-500">{{ getOptionText }}</span>

      <div v-else :id="inputId" class="mt-1" :title="title">
        <div v-for="(option, idx) in options" :key="option.label">
          <div v-if="!noNull || (noNull && option.value !== null)">
          <div class="float-left mr-5 flex items-center">
            <div v-if="!onleft" class="float-left mr-1 text-sm">
              <label class="font-medium text-gray-700">{{ option.label }}</label>
            </div>
            <div class="float-left">
              <input
                  :id="inputId + idx"
                  type="radio"
                  :value="option.value"
                  :checked="isChecked(option, modelValue)"
                  @change="updateValue(option.value)"
                  :disabled="isTextBoxDisabled"
                  class="h-9 w-9 text-medosync-blue border-medosync-black focus:ring-medosync-blue checked:border-medosync-blue border-2 rounded-full cursor-pointer">
            </div>
              <div v-if="onleft" class="float-left mr-1 text-sm">
                  <label class="font-medium text-gray-700">{{ option.label }}</label>
              </div>
          </div>
          </div>
       </div>
      </div>
    </div>

  </div>
  <!-- end -->
</template>

<script>
/**
 * Component to create a grouping of radio boxes around a question
 *   Yes, No and N/A (null)
 *
 * Args:
 *   - question: a sentence to describe why there is a Yes/No
 *   - noNull: if present then there will not be an N/A option.
 *   - title: if defined then this will be hover-over text
 *   - onleft: if set true, radio appears left of text, otherwise radio appears right of text
 */
import { mapState } from 'vuex';
export default {
  name: "BooleanQuestion",
  props: {
    modelValue: { default: "" },
    question: { type: String, required: false },
    noNull: { type: Boolean, default: false },
    title: { type: String, required: false },
    onleft: { type: Boolean, default: false },
    options: { type: Array, required: false, default: () => [
        {
          label: "Yes",
          value: true,
        },
        {
          label: "No",
          value: false,
        },
        {
          label: "N/A",
          value: null,
        },
      ]
    },
    id: {}
  },
  emits: ['update:modelValue'],
  data() {
    return {
      inputId: this.id || `boolean_question${(Math.random() * 1000).toFixed()}`,
    }
  },
  computed: {
    ...mapState(['textBoxDisabled']),
    isTextBoxDisabled() {
      return this.textBoxDisabled;
    },
    getOptionText() {
      let name = "N/A"
      this.options.forEach((opt) => {
        if (opt.value === this.modelValue) {
          name = opt.label
        }
      });
      return name
    },
  },
  methods: {
    isChecked(option, value) {
      return option.value === value
    },
    updateValue(v) {
      this.$emit('update:modelValue', v)
    }
  },
}
</script>

<style scoped>

textarea {
  width: 100%;
  border: 1px solid #dedede;
}

.custom-checkbox {
  width: 20px;
  height: 20px;
  cursor: pointer;
}

</style>