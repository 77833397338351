<template>
    <div class="test-layout-section">
      
      <!-- left section -->
      <section class="left-section">
        <div>

          <!-- 1 section -->
          <section>
            <div class="bg-white shadow overflow-hidden sm:rounded-lg">
              <div class="bg-white px-4 py-5 border-b border-gray-200 sm:px-6">
                <div class="-ml-4 -mt-4 flex justify-between items-center flex-wrap sm:flex-nowrap">
                  <div class="ml-4 mt-4">
                    <div class="flex items-center">
                      <div class="flex-shrink-0">
                        <span class="inline-flex items-center justify-center h-12 w-12 rounded-full zima-blue-bg">
                          <span class="font-medium leading-none text-white">LA</span>
                        </span>
                      </div>
                      <div class="ml-4">
                        <h3 class="text-lg leading-6 font-medium text-gray-900">John Doe</h3>
                        <p class="text-sm text-gray-500">
                          <a href="#"> @john </a>
                        </p>
                      </div>
                    </div>
                  </div>
                  <div class="ml-4 mt-4 flex-shrink-0 flex">
                    <button type="button" class="ml-3 relative inline-flex items-center px-4 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                      <svg class="-ml-1 mr-2 h-5 w-5 text-gray-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                        <path d="M2.003 5.884L10 9.882l7.997-3.998A2 2 0 0016 4H4a2 2 0 00-1.997 1.884z" />
                        <path d="M18 8.118l-8 4-8-4V14a2 2 0 002 2h12a2 2 0 002-2V8.118z" />
                      </svg>
                      <span> Email </span>
                    </button>
                  </div>
                </div>
              </div>
              <div class="border-t border-gray-200">
                <dl>
                  <div class="bg-white px-4 py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                    <dt class="text-sm font-medium text-gray-500">MRN</dt>
                    <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">Test-1011</dd>
                  </div>
                  <div class="bg-gray-50 px-4 py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                    <dt class="text-sm font-medium text-gray-500">Email address</dt>
                    <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">john@email.com</dd>
                  </div>
                  <div class="bg-white px-4 py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                    <dt class="text-sm font-medium text-gray-500">Date of Birth</dt>
                    <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">June 16, 1996</dd>
                  </div>
                  <div class="bg-gray-50 px-4 py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                    <dt class="text-sm font-medium text-gray-500">Phone</dt>
                    <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">088-123-4567</dd>
                  </div>
                  <div class="bg-white px-4 py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                    <dt class="text-sm font-medium text-gray-500">Patient name</dt>
                    <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">John Doe</dd>
                  </div>
                </dl>
              </div>
            </div>
          </section>
          <!-- 1 section end -->

          <!-- 2 section -->
          <section class="mt-4">
            <div class="bg-white shadow overflow-hidden sm:rounded-lg">
              <div class="bg-white px-4 py-3 border-b border-gray-200">
                <li class="flow-root">
                  <div class="relative flex items-center space-x-4 rounded-xl hover:bg-gray-50 focus-within:ring-2 focus-within:ring-indigo-500">
                    <div class="flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full zima-blue-bg">
                      <i class="fas fa-user-md text-white text-lg"></i>
                    </div>
                    <div>
                      <p class="mt-1 text-sm text-gray-500 font-bold">Sarah Maguire <span class="font-semibold zima-blue">(Admitting Consultant)</span></p>
                    </div>
                  </div>
                </li>
              </div>
              <div class="border-t border-gray-200">
                <dl>
                  <div class="bg-gray-50 px-4 py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                    <dt class="text-sm font-semibold text-gray-500">Email</dt>
                    <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2 font-semibold">sarah@maguire.com</dd>
                  </div>
                  <div class="bg-white px-4 py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                    <dt class="text-sm font-semibold text-gray-500">Hospital</dt>
                    <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2 font-semibold">County General</dd>
                  </div>
                  <div class="bg-gray-50 px-4 py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                    <dt class="text-sm font-semibold text-gray-500">Title</dt>
                    <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2 font-semibold">Doctor</dd>
                  </div>
                </dl>
              </div>
            </div>
          </section>
          <!-- 2 section end -->

          <!-- 3 section -->
          <section class="mt-4">
            <div class="bg-white shadow overflow-hidden sm:rounded-lg">
              <div class="bg-white px-4 py-3 border-b border-gray-200">
                <li class="flow-root">
                  <div class="relative flex items-center space-x-4 rounded-xl hover:bg-gray-50 focus-within:ring-2 focus-within:ring-indigo-500">
                    <div class="flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full zima-blue-bg">
                      <i class="fas fa-heartbeat text-white text-lg"></i>
                    </div>
                    <div>
                      <p class="mt-1 text-sm text-gray-500 font-semibold">Insurer Section</p>
                    </div>
                  </div>
                </li>
              </div>
              <div class="border-t border-gray-200">
                <dl>

                  <div class="w-full">
                    <div class="mt-0 flex flex-col">
                      <div class="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
                        <div class="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
                          <div class="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
                            <table class="min-w-full divide-y divide-gray-300">
                              <thead class="bg-gray-50">
                                <tr>
                                  <th scope="col" class="whitespace-nowrap py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">Insurer</th>
                                  <th scope="col" class="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-gray-900">Policy Name</th>
                                  <th scope="col" class="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-gray-900">Member Numer</th>
                                  <th scope="col" class="relative whitespace-nowrap py-3.5 pl-3 pr-4 sm:pr-6">
                                    <span class="sr-only">Edit</span>
                                  </th>
                                </tr>
                              </thead>
                              <tbody class="divide-y divide-gray-200 bg-white">
                                <tr>
                                  <td class="whitespace-nowrap py-2 pl-4 pr-3 text-sm text-gray-500 sm:pl-6">Laya</td>
                                  <td class="whitespace-nowrap px-2 py-2 text-sm text-gray-500">Laya Plan 1</td>
                                  <td class="whitespace-nowrap px-2 py-2 text-sm text-gray-500">L-123T</td>
                                  <td class="relative whitespace-nowrap py-2 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
                                    <a href="#" class="text-indigo-600 hover:text-indigo-900">View<span class="sr-only">, AAPS0L</span></a>
                                  </td>
                                </tr>
                                <!-- More transactions... -->
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                </dl>
              </div>
            </div>
          </section>
          <!-- 3 section end -->

          <!-- 4 section -->
          <section class="mt-4">
            <div class="bg-white shadow overflow-hidden sm:rounded-lg">
              <div class="bg-white px-4 py-3">
                <li class="flow-root">
                  <div class="relative flex items-center space-x-4 rounded-xl hover:bg-gray-50 focus-within:ring-2 focus-within:ring-indigo-500">
                    <div class="flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-green-500">
                      <i class="far fa-check-circle text-white text-3xl"></i>
                    </div>
                    <div>
                      <p class="mt-1 text-sm text-gray-500 font-bold"><span class="mr-3 text-green-500">This patients policy is active</span>
                        <br/>
                      (Insurance Check)</p>
                    </div>
                  </div>
                </li>

                <li class="flow-root mt-3">
                  <div class="relative flex items-center space-x-4 rounded-xl hover:bg-gray-50 focus-within:ring-2 focus-within:ring-indigo-500">
                    <div class="flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-red-500">
                      <i class="fas fa-exclamation-circle text-white text-3xl"></i>
                    </div>
                    <div>
                      <p class="mt-1 text-sm text-red-500 font-bold">
                        Please advise patient excess will apply for private room
                      </p>
                    </div>
                  </div>
                </li>
              </div>

            </div>
          </section>
          <!-- 4 section end -->

        </div>
      </section>
      <!-- left section end -->



      <!-- mid section -->
      <section class="mid-section">
        <div class="mid-upper-section">
          
          <!-- 1 mid section -->
          
            <section class="top-mid-section">
              <div style="text-align: center;">
                <ul class="breadcrumbs">
                  <li class="first"><a href="#" class="icon-home"><i class="fas fa-info-circle"></i></a></li>
                  <li class="active"><a href="#"> <i class="fas fa-check-circle"></i> Preadmission</a></li>
                  <li><a href="#">Admission</a></li>
                  <li><a href="#">Post admission</a></li>
                  <li class="last"><a href="#">Submited</a></li>
                </ul>
              </div>
            </section>
          
          <!-- 1 mid section end -->

          <!-- 2 mid section -->
          <section class="mid-2-section">
            <div>
              <div>
                <div class="hidden sm:block">
                  <div class="border-b border-gray-200">
                    <nav class="-mb-px flex space-x-8" aria-label="Tabs">
                      <a href="#" class="border-transparent zima-blue hover:text-blue-700 hover:border-blue-300 group inline-flex items-center py-4 px-1 border-b-2 font-medium text-sm">
                        <i class="fas fa-file-import zima-blue group-hover:text-gray-500 -ml-0.5 mr-1.5 group-hover:text-blue-500"></i>
                        <span>Preadmission</span>
                      </a>

                      <a href="#" class="border-transparent text-gray-500 hover:text-blue-600 hover:border-blue-300 group inline-flex items-center py-4 px-1 border-b-2 font-medium text-sm">
                        <i class="fas fa-file-signature text-gray-400 group-hover:text-gray-500 -ml-0.5 mr-1.5 group-hover:text-blue-500"></i>
                        <span>Admission</span>
                      </a>

                      <a href="#" class="border-transparent text-gray-500 hover:text-blue-600 hover:border-blue-300 group inline-flex items-center py-4 px-1 border-b-2 font-medium text-sm" aria-current="page">
                        <i class="fas fa-file-archive text-gray-400 group-hover:text-gray-500 -ml-0.5 mr-1.5 group-hover:text-blue-500"></i>
                        <span>Post admission</span>
                      </a>
                    </nav>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <!-- 2 mid section end -->

          <!-- 3 mid section -->
          <section>
            <!-- start -->
            <div class="col-12 mt-5 pl-5 pr-5 overflow-auto">
                <div class='col-12'>
                    <div class="flex justify-between">
                        <label class="block text-sm font-medium text-gray-700">Insert Phone Numer Below:</label>
                        <!--<span class="text-sm text-gray-500" id="email-optional">Optional</span>-->
                    </div>
                    <div class="col-12 mt-1">
                        <input type="text" class="custom-input shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md" placeholder="">
                    </div>
                    <div class="col-12 mt-3">
                      <button type="button" class="mr-3 text-left float-left mt-1 mb-5 inline-flex items-center px-3.5 py-2.5 border border-transparent text-xs font-medium rounded shadow-sm text-white bg-blue-500 hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                        Send SMS registration
                      </button>
                      <button type="button" class="text-left float-left mt-1 mb-5 inline-flex items-center px-3.5 py-2.5 border border-transparent text-xs font-medium rounded shadow-sm text-white bg-blue-500 hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                        Send Payment Link
                      </button>
                    </div>
                </div>
            </div>
            <!-- end -->
          </section>
          <!-- 3 mid section end -->

          <div style="clear: both;"></div>

        </div>


        <div class="mid-lower-section">
          <section>
            <div class="forms-section">
              <Forms />
            </div>
          </section>
        </div>

      </section>
      <!-- mid section end -->




      <!-- right section -->
      <section class="right-section">
        <div>

          <!-- 1 section -->
          <section>
            <div class="bg-white shadow overflow-hidden">
              <div class="bg-white px-4 py-5 border-b border-gray-200 sm:px-6">
                <div class="-ml-4 -mt-4 flex justify-between items-center flex-wrap sm:flex-nowrap">
                  <div class="ml-4 mt-4">
                    <div class="flex items-center">
                      <div class="flex-shrink-0">
                        <span class="inline-flex items-center justify-center h-12 w-12 rounded-full zima-blue-bg">
                          <span class="font-medium leading-none text-white"><i class="fas fa-tasks"></i></span>
                        </span>
                      </div>
                      <div class="ml-4">
                        <h3 class="text-lg leading-6 font-medium text-gray-900">Status of Claim</h3>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="border-t border-gray-200">
                <div>
                  <div class="px-4 py-5">
                    <!-- This example requires Tailwind CSS v2.0+ -->
                    <div class="flow-root">
                      <ul role="list">
                        <li>
                          <div class="relative pb-8">
                            <span class="absolute top-4 left-4 -ml-px h-full w-0.5 bg-gray-200" aria-hidden="true"></span>
                            <div class="relative flex space-x-3">
                              <div>
                                <span class="h-8 w-8 rounded-full bg-gray-400 flex items-center justify-center ring-8 ring-white">
                                  <i class="fas fa-file text-white"></i>
                                </span>
                              </div>
                              <div class="min-w-0 flex-1 pt-1.5 flex justify-between space-x-4">
                                <div>
                                  <p class="text-sm text-gray-500">Status set to <a href="#" class="font-medium text-gray-900">Draft</a></p>
                                </div>
                                <div class="text-right text-sm whitespace-nowrap text-gray-500">
                                  <time datetime="2020-09-20">Sep 20</time>
                                </div>
                              </div>
                            </div>
                          </div>
                        </li>

                        <li>
                          <div class="relative pb-8">
                            <span class="absolute top-4 left-4 -ml-px h-full w-0.5 bg-gray-200" aria-hidden="true"></span>
                            <div class="relative flex space-x-3">
                              <div>
                                <span class="h-8 w-8 rounded-full bg-blue-500 flex items-center justify-center ring-8 ring-white">
                                  <i class="fas fa-file-signature text-white pl-1"></i>
                                </span>
                              </div>
                              <div class="min-w-0 flex-1 pt-1.5 flex justify-between space-x-4">
                                <div>
                                  <p class="text-sm text-gray-500">Status set to<a href="#" class="font-medium text-gray-900"> Registration link sent</a></p>
                                </div>
                                <div class="text-right text-sm whitespace-nowrap text-gray-500">
                                  <time datetime="2020-09-22">Sep 22</time>
                                </div>
                              </div>
                            </div>
                          </div>
                        </li>

                        <li>
                          <div class="relative pb-8">
                            <span class="absolute top-4 left-4 -ml-px h-full w-0.5 bg-gray-200" aria-hidden="true"></span>
                            <div class="relative flex space-x-3">
                              <div>
                                <span class="h-8 w-8 rounded-full bg-blue-500 flex items-center justify-center ring-8 ring-white">
                                  <i class="fas fa-file-invoice-dollar text-white"></i>
                                </span>
                              </div>
                              <div class="min-w-0 flex-1 pt-1.5 flex justify-between space-x-4">
                                <div>
                                  <p class="text-sm text-gray-500">Status set to<a href="#" class="font-medium text-gray-900"> Payment link sent</a></p>
                                </div>
                                <div class="text-right text-sm whitespace-nowrap text-gray-500">
                                  <time datetime="2020-09-28">Sep 28</time>
                                </div>
                              </div>
                            </div>
                          </div>
                        </li>

                        <li>
                          <div class="relative pb-8">
                            <span class="absolute top-4 left-4 -ml-px h-full w-0.5 bg-gray-200" aria-hidden="true"></span>
                            <div class="relative flex space-x-3">
                              <div>
                                <span class="h-8 w-8 rounded-full bg-green-500 flex items-center justify-center ring-8 ring-white">
                                  <i class="fas fa-file-upload text-white"></i>
                                </span>
                              </div>
                              <div class="min-w-0 flex-1 pt-1.5 flex justify-between space-x-4">
                                <div>
                                  <p class="text-sm text-gray-500">Status set to<a href="#" class="font-medium text-gray-900"> Registration form received</a></p>
                                </div>
                                <div class="text-right text-sm whitespace-nowrap text-gray-500">
                                  <time datetime="2020-09-28">Sep 28</time>
                                </div>
                              </div>
                            </div>
                          </div>
                        </li>
                      </ul>
                    </div>

                  </div>
                </div>
              </div>
            </div>
          </section>
          <!-- 1 section end -->

          <!-- 2 section -->
          <section class="mt-0">
            <div class="w-full">
              <MessageSystem />
            </div>
          </section>
          <!-- 2 section end -->

          <!-- 3 section -->
          <section class="mt-0">
            <div class="w-full">
              <Emails />
            </div>
          </section>
          <!-- 3 section end -->

        </div>
      </section>
      <!-- right section end -->

      <div class="medosync-logo">
        <img src="@/assets/img/medosync-logo.png">
      </div>


    </div>
</template>

<script>
import MessageSystem from '@/views/tests/MessageSystem.vue'
import Emails from '@/views/tests/Emails.vue'
import Forms from '@/views/tests/Forms.vue'

export default {
  name: 'Test-layout',
  components: {
    MessageSystem,
    Emails,
    Forms
  },
  data () {
    return {
        
    }
  },
  methods: {

  },
  mounted () {

  }
}
</script>

<style lang="scss" scoped>

  .test-layout-section {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    background: #fff;
    z-index: 999999999;
  }

  .left-section {
    float: left;
    width: 400px;
    height: 100vh;
    border-right: 1px solid #eee;
    background: #f9f9f9;
    overflow: auto;
  }

  .left-mid-section {
    padding: 15px;
    background: #fff;
  }

  .mid-section {
    float: left;
    width: calc(100% - 800px);
    background: #f9f9f9;
  }

  .top-mid-section {
    float: left;
    width: 100%;
    margin: auto;
    padding-bottom: 23px;
    border-bottom: 2px solid #eee;
    background: #fff;
  }

  .mid-2-section {
    float: left;
    padding-left: 15px;
  }

  .right-section {
    float: right;
    width: 400px;
    height: 100vh;
    border-left: 1px solid #eee;
    background: #fff;
    overflow: auto;
  }



  /* Customize website's scrollbar like Mac OS
  Not supports in Firefox and IE */

  /* total width */
  ::-webkit-scrollbar {
      background-color: #fff;
      width: 14px;
  }

  /* background of the scrollbar except button or resizer */
  ::-webkit-scrollbar-track {
      background-color: #fff;
  }

  /* scrollbar itself */
  ::-webkit-scrollbar-thumb {
      background-color: #d9d9d9;
      border-radius: 16px;
      border: 4px solid #fff;
  }

  /* set button(top and bottom of the scrollbar) */
  ::-webkit-scrollbar-button {
      display:none;
  }



  /* breadcrums css */
  $green: #0ea5e9;

  @mixin inline {
    display: inline-block;
    *display: inline;
    zoom: 1;
    vertical-align: top;
  }

  body {
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
    background: #fff;
    color: #757575;
  }

  .description {
    padding-left: 15px;
    border-left: 2px solid #000;
    
    h3 {
      font-weight:300;
      font-size: 20px;
      line-height: 20px;
      margin: 0px;
      color: #000;
      text-transform: uppercase;
    }
    
    p {
      margin-top: 10px;
      font-weight:300;
    }
  }

  .wrapper {
    margin: 50px;
  }

  ul.breadcrumbs {
    margin: 25px 0px 0px;
    padding: 0px;
    font-size: 0px;
    line-height: 0px;
    @include inline;
    height: 40px;

    li {
      position: relative;
      margin: 0px 0px;
      padding: 0px;
      list-style: none;
      list-style-image: none;
      @include inline;
      border-left: 1px solid #ccc;
      transition: 0.3s ease;

      &:hover {
        &:before {
          border-left: 10px solid $green;
        }

        a {
          font-weight: 600;
          color: #fff;
          background: $green;
        }

      }
      
      &:before {
        content:"";
        position: absolute;
        right: -9px;
        top: -1px;
        z-index: 20;
        border-left: 10px solid #fff;
        border-top: 22px solid transparent;
        border-bottom: 22px solid transparent;
        transition: 0.3s ease;
      }

      &:after {
        content:"";
        position: absolute;
        right: -10px;
        top: -1px;
        z-index: 10;
        border-left: 10px solid #ccc;
        border-top: 22px solid transparent;
        border-bottom: 22px solid transparent;
      }


      &.active {

        a {
          font-weight: 600;
          color: #fff;
          background: $green;
        }

        &:before {
          border-left: 10px solid $green;
        }
      }

      &.first {
        border-left: none;

        a {
          font-size: 18px;
          padding-left: 20px;
          border-radius: 5px 0px 0px 5px;
        }
      }

      &.last {

        &:before {
          display: none;
        }
        &:after {
          display: none;
        }

        a {
          padding-right: 20px;
          border-radius: 0px 40px 40px 0px;
        }
      }

      a {
        display: block;
        font-size: 12px;
        font-weight: 600;
        line-height: 40px;
        color: #757575;
        padding: 0px 15px 0px 25px;
        text-decoration: none;
        background: #fff;
        border: 1px solid #ddd;
        white-space:nowrap;
        overflow: hidden;
        transition: 0.3s ease;
      }
    }
  }

  .medosync-logo {
    position: fixed;
    right: 15px;
    bottom: 5px;
    width: 60px;
    height: 60px;
    background: #fff;
    z-index: 99999;
  }

  .mid-upper-section {
    height: 310px;
    overflow: auto;
    background: #fff;
  }

  .mid-lower-section {
    height: calc(100vh - 320px);
    overflow: auto;
    background: #fff;
  }

  .forms-section {
    padding: 15px;
    padding-left: 30px;
    padding-right: 30px;
    background: #fff;
  }

  .zima-blue-bg {
    background: #0ea5e9;
  }

  .zima-blue {
    color: #0ea5e9;
  }

</style>