<template>
  <div class="proc-dropdown">
    <Typeahead
      :id="id"
      v-model="selectedProcedureCode"
      :items="procedure_codes"
      :placeholder="this.placeholder"
      :min-input-length="this.min_input"
      :item-projection="show_suggestions"
      :item-value="getItemValue"
      @select-item="selectItem"
      @update:modelValue="check_if_matching"
      :fullW="true"
    />
  </div>
</template>

<script>
import { OpenAPIJSON } from 'api/openapi';
import Typeahead from '@/components/claim-forms/components/Typeahead.vue';

// {"claim_state":["sent_to_insurer","part_paid"],"consultant":{"type":"person_id","id":4}}

export default {
  name: 'ProcedureCodeTypeahead',
  components: {
    Typeahead
  },
  props: {
    //get the value to appear in PlaceHolder
    modelValue: {},
    dataValue: {},
    placeholder: { require: false, default: 'Search' },
    min_input: { require: false, default: 1 },
    contract_id: { require: false, default: null },
    insurer: { require: false, default: null },
    appointment_id: { require: false, default: null },
    claim_id: { require: false, default: null },
    categories: { require: false, default: [] },
    not_categories: { require: false, default: [] },
    id: { type: String }
  },
  emits: ['selectedProcedure', 'clearedProcedure'],
  data() {
    return {
      procedure_codes: [],
      selectedProcedureCode: '',
      api: new OpenAPIJSON(),
    };
  },
  methods: {
    show_suggestions(item) {
      return item.procedure_code + ' ' + item.procedure_code_description;
    },
    getItemValue(item) {
      //console.log("ProcedureCodeTypeahead: getItemValue item=", item);
      this.$emit('selectedProcedure', item);
      this.selectedProcedureCode = item.procedure_code;
      return item.procedure_code;
    },

    selectItem(item) {
      //console.log("ProcedureCodeTypeahead: selectItem item=", item);
      this.$emit('selectedProcedure', item);
      this.selectedProcedureCode = item.procedure_code;
    },
    check_if_matching(item) {
      //console.log("ProcedureCodeTypeahead: check_if_matching item=", item);
      if (item.length === 0) {
        this.$emit('clearedProcedure');
      }
    },
    async lookupProcedureCode(new_filter) {
      let search_term = new_filter.trim();
      let results = { items: [] };
      if (search_term.length > 0) {
        if (this.dataValue) {
          results = await this.api.searchProcedureCodes(
              null,
              null,
              this.dataValue.appointment_id !== null ? this.dataValue.appointment_id : null,
              this.dataValue.insurance.insurer.id !== null ? this.dataValue.insurance.insurer.id : null,
              null,
              this.contract_id !== null ? this.contract_id : null,
              null,
              null,
              null,
              search_term,
              null,
              this.categories.length > 0 ? this.categories : null,
              this.not_categories.left > 0 ? this.not_categories : null,
              null
          );
        } else {
          results = await this.api.searchProcedureCodes(
              null,
              null,
              this.claim_id !== null ? this.appointment_id : null,
              this.insurer !== null ? this.insurer : null,
              null,
              this.contract_id !== null ? this.contract_id : null,
              null,
              null,
              null,
              search_term,
              null,
              this.categories.length > 0 ? this.categories : null,
              this.not_categories.left > 0 ? this.not_categories : null,
              null
          );
        }
      }

      if (results.items.length > 0) {
        this.procedure_codes = results.items;
      } else {
        this.procedure_codes = [];
      }
    }
  },
  mounted() {
    if (this.modelValue) {
      //console.log("ProcedureCodeTypeahead: mounted set procedure code to ", this.modelValue);
      this.selectedProcedureCode = this.modelValue;
    }
    // Instantiate the watch on selectedProcedureCode after the initial assignment to prevent a double-api-lookup
    // of the procedure code when the claim form is being loaded.
    this.$watch('selectedProcedureCode', this.lookupProcedureCode);
  }
};
</script>

<style scoped>
.proc-dropdown {
  display: inline-block;
}
</style>
