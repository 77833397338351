<template>
  <div class="add-claim-section">
    <div class="relative z-10">
      <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"></div>

      <div class="fixed z-10 inset-0 overflow-y-auto">
        <div class="flex items-end sm:items-center justify-center min-h-full p-4 text-center sm:p-0">
          <div
            class="custom-claim-modal relative bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-10 sm:max-w-2xl sm:w-full sm:p-6"
          >
            <div>
              <div class="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-indigo-600">
                <!-- Heroicon name: outline/check -->
                <i class="fas fa-file-signature" style="padding-left: 6px; color: #fff"></i>
              </div>
              <div class="mt-3 text-center sm:mt-3">
                <h3 class="text-lg leading-6 font-medium text-gray-900">Add New Payment</h3>
                <h1 class="text-lg leading-6 font-medium text-gray-900">{{ insurerFileName }}</h1>
                <div class="mt-2">
                  <!--
                    <p class="text-sm text-gray-500">Lorem ipsum, dolor sit amet consectetur adipisicing elit. Eius aliquam laudantium explicabo pariatur iste dolorem animi vitae error totam. At sapiente aliquam accusamus facere veritatis.</p>
                    -->

                  <!-- form fields -->

                  <div style="clear: both"></div>

                  <div
                    class="sub-title mt-3"
                    title="Search existing records to find the patient by MRN or name, which will auto-populate several fields on the form.  Use the MRN field directly if you cannot find the patient here."
                  >
                    Invoice
                  </div>

                  <div style="clear: both"></div>

                  <div class="float-left col-12 mt-2">
                    <InvoiceTypeahead
                      :consultant_person_id="selectedConsultant.person_id"
                      :consultant_id="selectedConsultant.consultant_id"
                      :insurer_id="insurerId"
                      v-model="selectedInvoiceId"
                      @selected-invoice="populateData"
                    ></InvoiceTypeahead>
                  </div>

                  <div style="clear: both"></div>

                  <div
                    class="sub-title mt-5"
                    title="Search existing records to find the patient by MRN or name, which will auto-populate several fields on the form.  Use the MRN field directly if you cannot find the patient here."
                  >
                    Patient Details
                  </div>

                  <div style="clear: both"></div>

                  <!-- section -->
                  <div
                    class="col-12 mt-3"
                    title="Enter an MRN for the patient.  Use this field if you cannot find the patient using the patient search."
                  >
                    <div class="col-12 float-left">
                      <div>
                        <div class="text-left text-sm mb-1">
                          <label for="value" class="font-medium text-gray-700 mr-2">MRN:</label>
                        </div>
                        <div class="col-12">
                          <input
                            disabled
                            type="text"
                            class="custom-input shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                            v-model="mrn"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <!-- section end -->

                  <!-- section -->
                  <div class="col-12 mt-1">
                    <div class="col-6 float-left pr-2">
                      <div class="mt-2">
                        <div>
                          <div class="text-left text-sm mb-1">
                            <label for="value" class="font-medium text-gray-700 mr-2">First Name:</label>
                          </div>
                          <div class="col-12">
                            <input
                              disabled
                              type="text"
                              v-model="firstName"
                              class="custom-input shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                            />
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="col-6 float-left">
                      <div class="mt-2">
                        <div>
                          <div class="text-left text-sm mb-1">
                            <label for="value" class="font-medium text-gray-700 mr-2">Last Name:</label>
                          </div>
                          <div class="col-12">
                            <input
                              disabled
                              type="text"
                              v-model="lastName"
                              class="custom-input shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!-- section end -->

                  <!-- section -->
                  <div class="col-12 mt-1">
                    <div class="col-12 float-left pr-2">
                      <div class="mt-2">
                        <div>
                          <div class="text-left text-sm mb-1">
                            <label for="value" class="font-medium text-gray-700 mr-2">Date of Birth:</label>
                          </div>
                          <div class="col-12">
                            <input
                              disabled
                              type="text"
                              v-model="dobFormatted"
                              :key="dobKey"
                              class="custom-input shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!-- section end -->

                  <div style="clear: both"></div>

                  <!-- Appointment -->

                  <div style="clear: both"></div>

                  <!-- section -->
                  <div
                    class="col-12 mt-2"
                    title="If the patient appointment is not known in the system, you can manually specify it here."
                  >
                    <div class="col-12 float-left">
                      <div class="mt-2">
                        <div>
                          <div class="text-left text-sm mb-1">
                            <label for="value" class="font-medium text-gray-700 mr-2">Appointment ID:</label>
                          </div>
                          <div class="col-12">
                            <input
                              disabled
                              v-model="appointmentId"
                              type="text"
                              class="custom-input shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!-- section end -->

                  <div style="clear: both"></div>

                  <div>
                    <div class="text-left text-sm mt-3 mb-1">
                      <label for="value" class="font-medium text-gray-700 mr-2">Insurer Name:</label>
                    </div>
                    <div class="col-12">
                      <input
                        disabled
                        v-model="selectedInsurer"
                        type="text"
                        class="custom-input shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                      />
                    </div>
                  </div>

                  <div style="clear: both"></div>

                  <!-- start section -->
                  <div style="clear: both"></div>

                  <div
                    class="sub-title mt-3"
                    title="Search existing records to find the patient by MRN or name, which will auto-populate several fields on the form.  Use the MRN field directly if you cannot find the patient here."
                  >
                    Invoice Payment Summary
                  </div>

                  <div style="clear: both"></div>

                  <table class="min-w-full divide-y divide-gray-200">
                    <thead class="bg-gray-50">
                      <tr>
                        <th>Paid</th>
                        <th>Outstanding</th>
                        <th>W/Tax</th>
                        <th>Adjustment</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>{{ selectedInvoiceDetail.amount_paid }}</td>
                        <td
                          :class="[selectedInvoiceDetail.amount_outstanding > 0 ? 'clickable' : '']"
                          @click="payAmount = selectedInvoiceDetail.amount_outstanding"
                        >
                          {{ selectedInvoiceDetail.amount_outstanding }}
                        </td>
                        <td>{{ selectedInvoiceDetail.withholding_tax }}</td>
                        <td>{{ selectedInvoiceDetail.adjustment_amount }}</td>
                      </tr>
                    </tbody>
                  </table>

                  <div
                    class="sub-title mt-3"
                    title="Search existing records to find the patient by MRN or name, which will auto-populate several fields on the form.  Use the MRN field directly if you cannot find the patient here."
                  >
                    New Payment details
                  </div>

                  <div style="clear: both"></div>
                  <div class="flex justify-center my-3">
                    <div class="col-4 mx-3">
                      Pay Amount (Gross)
                      <input
                        v-model="payAmount"
                        class="custom-input shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                      />
                    </div>

                    <div class="col-4 mx-3">
                      Withholding Tax
                      <input
                        v-model="withholdingTax"
                        class="custom-input shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                      />
                    </div>

                    <div class="col-4 mx-3">
                      Adjustment Amount
                      <input
                        v-model="adjustmentAmount"
                        class="custom-input shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                      />
                    </div>
                  </div>

                  <!-- end section -->
                </div>
              </div>
            </div>
            <div class="mt-12 sm:mt-12 sm:grid sm:grid-cols-2 sm:gap-3 sm:grid-flow-row-dense">
              <button
                @click="definePayment"
                :disabled="!isValid"
                type="button"
                class="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-indigo-600 text-base font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:col-start-2 sm:text-sm"
              >
                Add Payment
              </button>
              <button
                @click="$emit('close-modal')"
                type="button"
                class="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:col-start-1 sm:text-sm"
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <ToastComponent ref="toast"></ToastComponent>
  </div>
</template>

<script>
import { OpenAPIJSON } from 'api';
import '@/assets/css/typeahead.css';
import StringUtility from 'utils/stringUtility';
import InvoiceTypeahead from '@/components/form-fields/InvoiceTypeahead.vue';
import ToastComponent from '@/components/claim-forms/components/ToastComponent.vue';

export default {
  name: 'AddPayment',
  props: {
    selectedConsultant: {
      required: true,
      default: null
    },
    insurerFileId: { required: true, type: Number },
    insurerFileName: { required: true, type: String },
    insurerId: { required: true, type: Number },
    preselectedInvoiceId: {
      default: null
    }
  },
  emits: ['paymentAdded'],
  components: {
    InvoiceTypeahead,
    ToastComponent
  },
  data() {
    return {
      openApi: new OpenAPIJSON(),
      selectedInvoice: null,
      selectedInvoiceId: '',
      selectedInvoiceDetail: {},
      selectedInsurer: '',
      mrn: null,
      firstName: null,
      lastName: null,
      dob: null,
      dobKey: 0,
      appointmentId: null,
      amountInvoiced: null,
      amountOutstanding: null,
      payAmount: null,
      withholdingTax: null,
      adjustmentAmount: null,
      successClaim: false
    };
  },
  computed: {
    dobFormatted() {
      return StringUtility.formatDate(this.dob);
    },
    isValid() {
      return (
        this.payAmount > 0 &&
        this.payAmount <= this.selectedInvoiceDetail.amount_invoiced - this.selectedInvoiceDetail.amount_paid &&
        this.withholdingTax >= 0 &&
        this.selectedInvoice
      );
    }
  },
  watch: {
    dob() {
      this.dobKey++;
    },
    payAmount(new_val) {
      if (new_val === '') {
        this.withholdingTax = '';
      } else {
        this.withholdingTax = (parseFloat(new_val) * 0.2).toFixed(2);
      }
    },
    withholdingTax(new_val) {
      if (new_val === '') {
        this.payAmount = '';
      }
    }
  },
  mounted() {
    if (this.preselectedInvoiceId) this.selectedInvoiceId = this.preselectedInvoiceId;

    document.addEventListener('keyup', this.keyListener);
  },
  beforeUnmount() {
    document.removeEventListener('keyup', this.keyListener);
  },
  methods: {
    keyListener(event) {
      if (event.defaultPrevented) {
        return;
      }

      if (event.key === 'Enter') {
        this.definePayment();
      }
    },
    async populateData(selectedInvoice) {
      this.selectedInvoice = selectedInvoice;

      try {
        const { items } = await this.openApi.invoices_remittances_search({
          consultant: this.getConsultant(),
          invoice_ref: selectedInvoice.invoice_ref
        });
        if (!items.length) {
          this.$refs.toast.showToast('No known data for invoice.', 'fa-solid fa-triangle-exclamation', 'error');
        } else {
          const data = items[0];
          this.selectedInvoiceDetail = data;
          this.mrn = data.patient?.mrn;
          this.appointmentId = data.hospital_appointment_id;
          this.selectedInsurer = data.insurer?.insurer_name;
          if (data.patient) {
            this.firstName = data.patient?.first_name;
            this.lastName = data.patient?.last_name;
            this.dob = data.patient?.dob;
          }
        }
      } catch (error) {
        this.$refs.toast.showToast(
          'Something went wrong getting invoice data. Please try again later.',
          'fa-solid fa-triangle-exclamation',
          'error'
        );
      }
    },
    resetPayment() {
      window.scrollTo(0, 0);

      this.selectedInvoiceId = '';
      this.selectedInsurer = '';

      this.mrn = null;
      this.firstName = null;
      this.lastName = null;
      this.dob = null;
      this.appointmentId = null;

      this.payAmount = null;
      this.withholdingTax = null;
      this.adjustmentAmount = null;

      this.successClaim = false;
    },
    async definePayment() {
      // validation
      if (!this.selectedInvoiceDetail) {
        this.$refs.toast.showToast('Please select invoice first.', 'fa-solid fa-triangle-exclamation', 'error');
        return;
      }

      if (this.payAmount == null) {
        this.$refs.toast.showToast('Please add pay amount.', 'fa-solid fa-triangle-exclamation', 'error');
        return;
      }

      if (this.withholdingTax == null) {
        this.$refs.toast.showToast('Please add withholding tax amount.', 'fa-solid fa-triangle-exclamation', 'error');
        return;
      }

      if (this.adjustmentAmount == null) {
        this.$refs.toast.showToast('Please add adjustment amount.', 'fa-solid fa-triangle-exclamation', 'error');
        return;
      }

      const newPaymentObj = {
        insurer: {
          type: 'id',
          id: this.selectedInvoiceDetail.insurer?.insurer_id
        },
        insurer_name: this.selectedInvoiceDetail.insurer?.insurer_name,
        consultant_file: {
          file_name: this.insurerFileName,
          assoc: this.getAssocType()
        },
        pricing: {
          amount_invoiced: this.selectedInvoiceDetail.amount_invoiced,
          amount_payable: this.payAmount,
          withholding_tax: this.withholdingTax,
          adjustment_amount: this.adjustmentAmount
        },
        invoice_ref: this.selectedInvoiceDetail.invoice_ref,
        hospital_appointment_id: this.selectedInvoiceDetail.hospital_appointment_id,
        invoice_id: this.selectedInvoiceDetail.invoice_id,
        patient_name: this.firstName + ' ' + this.lastName,
        patient_dob: this.dob
      };

      try {
        await this.openApi.claim_statement_add(newPaymentObj);
        this.$refs.toast.showToast('Payment Added!', 'fas fa-check', 'success');
        this.populateData(this.selectedInvoice);
        this.payAmount = null;
        this.withholdingTax = null;
        this.adjustmentAmount = null;
        this.$emit('paymentAdded');
      } catch (error) {
        this.$refs.toast.showToast(
          'Something went wrong adding payment. Please try again later.',
          'fa-solid fa-triangle-exclamation',
          'error'
        );
      }
    },
    showProcInsurer(item) {
      return item.name;
    },
    getProcInsurerItemValue(item) {
      this.selectedInsurer = item.insurerName;
      return item.name;
    },
    getConsultant() {
      if (this.selectedConsultant?.consultant_id) {
        return {
          id: this.selectedConsultant?.consultant_id,
          type: 'id'
        };
      } else if (this.selectedConsultant?.person_id) {
        return {
          id: this.selectedConsultant?.person_id,
          type: 'personid'
        };
      } else {
        return null;
      }
    },
    getAssocType() {
      if (this.selectedConsultant?.consultant_id) {
        return {
          id: this.selectedConsultant?.consultant_id,
          type: 'id',
          assoc_type: 'consultant'
        };
      } else if (this.selectedConsultant?.person_id) {
        return {
          id: this.selectedConsultant?.person_id,
          type: 'personid',
          assoc_type: 'consultant'
        };
      } else {
        return null;
      }
    }
  }
};
</script>

<style lang="scss" scoped>
button:disabled {
  opacity: 0.4;
  cursor: unset;
}
.clickable {
  text-decoration: underline;
  color: #23a6f0;
  cursor: pointer;
}
.custom-claim-modal {
  padding-bottom: 75px;
}

.app-table-head {
  padding-bottom: 3px;
  font-size: 14px;
  font-weight: 600;
  font-style: italic;
  border-bottom: 1px solid #eee;
  overflow: auto;
}

.app-table-li {
  overflow: auto;
  padding-top: 5px;
  padding-bottom: 3px;
  border-bottom: 1px solid #eee;
  font-weight: 600;
}

.sub-title {
  text-align: left;
  font-size: 18px;
  font-weight: 400;
  font-style: italic;
  color: #0054ab;
}

.required {
  color: red;
}
</style>
