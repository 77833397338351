<script setup>
import { ref, computed } from 'vue';

const emit = defineEmits(['sort-by-date']);

const ACTIVE_COLOR = '#00a3f4';
const INACTIVE_COLOR = '#000000';
const descSort = ref(true);

const primaryColor = computed(() => (descSort.value ? INACTIVE_COLOR : ACTIVE_COLOR));
const secondaryColor = computed(() => (descSort.value ? ACTIVE_COLOR : INACTIVE_COLOR));

const sortByDate = () => {
  descSort.value = !descSort.value;
  emit('sort-by-date', { sort_by: 'created', desc: descSort.value });
};
</script>

<template>
  <div class="cursor-pointer" @click="sortByDate" data-test-id="nc-sort-by-date">
    <span class="underline">Sort by date</span>
    <i
      class="fa-duotone fa-sort fa-xl pl-2"
      :style="`--fa-primary-color: ${primaryColor}; --fa-secondary-color: ${secondaryColor}; --fa-secondary-opacity: 1;`"
    />
  </div>
</template>
