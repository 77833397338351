<template>
  <div class="input-wrapper">
    <input
      :style="error ? 'border: 1px solid #f60404;' : 'border: 1px solid #ccc;'"
      type="text"
      v-model="query"
      @input="onInput"
      @keydown.enter="onEnter"
      placeholder=""
      data-test-id="mrn-patient-search-input"
    />
    <span v-if="query" class="clear-icon" @click="clearSearch" data-test-id="clear-search-appointment"
      ><i class="fa-solid fa-x"></i
    ></span>
    <span class="search-icon"><i class="fa-solid fa-magnifying-glass"></i></span>
    <div v-if="!clear">
      <ul v-if="filteredPatients.length" class="results">
        <li
          data-test-id="selected-patient-appointment"
          v-for="patient in filteredPatients"
          :key="patient.mrn"
          @click="selectPatient(patient)"
        >
          {{ patient.mrn }} ( {{ patient.full_name }} )
        </li>
      </ul>
    </div>
  </div>
  <SpinnerLoader v-if="isLoading" />
</template>

<script>
import { OpenAPIJSON } from 'api/openapi';
import SpinnerLoader from '@/components/misc/SpinnerLoader.vue';
export default {
  props: {
    errorValue: { default: false }
  },
  components: {
    SpinnerLoader
  },
  data() {
    return {
      query: '',
      queryShow: '',
      clear: false,
      error: false,
      patients: [],
      isLoading: false
    };
  },

  watch: {
    errorValue(newVal) {
      if (!newVal) {
        this.$emit('errorReturn', false);
        this.clearSearch();
      }
    }
  },

  mounted() {
    this.fetch_patients();
  },
  computed: {
    filteredPatients() {
      if (!this.query) return [];
      let lowerCaseQuery = this.query.toLowerCase();
      return this.patients.filter(
        patient =>
          patient.mrn.toLowerCase().includes(lowerCaseQuery) || patient.full_name.toLowerCase().includes(lowerCaseQuery)
      );
    }
  },
  methods: {
    onInput() {
      this.clear = false;

      if (this.filteredPatients.length > 0) {
        this.error = false;
        this.$emit('errorReturn', false);
      } else {
        this.error = true;
        this.$emit('errorReturn', true);
        this.$emit('valueReturn', null);
      }

      if (this.query.length === 0) {
        this.query = '';
        this.clear = false;
        this.error = false;
        this.$emit('valueReturn', null);
        this.$emit('errorReturn', false);
      }
    },
    onEnter() {
      if (this.filteredPatients.length > 0) {
        this.selectPatient(this.filteredPatients[0]);
      }
    },
    clearSearch() {
      this.query = '';
      this.clear = false;
      this.filteredPatients = [];
      this.error = false;
      this.$emit('valueReturn', null);
      this.$emit('errorReturn', false);
    },
    selectPatient(patient) {
      this.error = false;
      this.$emit('errorReturn', false);
      this.query = `${patient.mrn} ( ${patient.full_name} )`;
      this.$emit('valueReturn', patient);
      this.clear = true;
    },
    async fetch_patients() {
      this.isLoading = true;
      let oapi = new OpenAPIJSON();

      try {
        let resp = await oapi.searchPatients();
        this.patients = resp.items;
      } catch (error) {
        console.error('Erro ao buscar pacientes:', error);
      } finally {
        this.isLoading = false;
      }
    }
  }
};
</script>

<style scoped>
.input-wrapper {
  position: relative;
  display: inline-block;
  width: 100%;
  height: 36px;
}

input {
  width: 100%;
  border-radius: 5px;
  padding: 8px 30px 8px 8px;
  border: 1px solid #ccc;
  box-sizing: border-box;
  position: relative;
}

.search-icon {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
  z-index: 3;
}
.clear-icon {
  position: absolute;
  right: 30px;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
  z-index: 3;
}

.results {
  max-height: 110px;
  overflow-y: auto;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  position: absolute;
  top: 100%;
  width: 100%;
  z-index: 1;
  background-color: white;
}

.results li {
  padding: 8px;
  border-bottom: 1px solid #eee;
  background-color: white;
  font-size: 12px;
}

.results li:last-child {
  border-bottom: none;
}

.results li:hover {
  background-color: #00a7e1;
  color: black;
  cursor: pointer;
}
</style>
