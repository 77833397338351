<template>
  <div>
    <div class="spinner" v-if="isLoading">
      <div class="spinner-icon">
        <img src="@/assets/img/medosync-logo.png" />
      </div>
    </div>
    <slot v-else />
  </div>
</template>

<script>
export default {
  name: 'SpinnerLoader',
  data() {
    return {
      isLoading: true
    };
  }
};
</script>

<style scoped>
.spinner {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 9999;
}

.spinner-icon {
  width: 75px;
  height: 75px;
  border-radius: 50%;
  border: 5px solid #fff;
  border-top-color: #3498db;
  border-bottom-color: #3498db;
  border-color: #3498db;
  animation: spinner 1s ease-in-out infinite;
}

@keyframes spinner {
  to {
    transform: rotate(360deg);
  }
}
</style>
