<script setup lang="ts">
import { ref, onMounted, onUnmounted } from 'vue';
const props = defineProps({
  modelValue: {
    type: [File, Array],
    default: null
  },
  multiple: {
    type: Boolean,
    default: false
  },
  showButtons: {
    type: Boolean,
    default: false
  },
  noClose: {
    type: Boolean,
    default: false
  }
});
const emit = defineEmits(['update:modelValue', 'cancel']);
const isActive = ref(false);
function onDrop(e: DragEvent) {
  setInactive();
  if (!e.dataTransfer) return;
  emit('update:modelValue', props.multiple ? [...e.dataTransfer.files] : e.dataTransfer.files[0]);
}
const onUpload = (event: Event) => {
  const target = event.target as HTMLInputElement;
  if (!target.files) return;
  emit('update:modelValue', props.multiple ? target.files : target.files[0]);
};
function preventDefaults(e: Event) {
  e.preventDefault();
}
const events = ['dragenter', 'dragover', 'dragleave', 'drop'];
function setActive() {
  isActive.value = true;
}
function setInactive() {
  isActive.value = false;
}
onMounted(() => {
  events.forEach(eventName => {
    document.body.addEventListener(eventName, preventDefaults);
  });
});
onUnmounted(() => {
  events.forEach(eventName => {
    document.body.removeEventListener(eventName, preventDefaults);
  });
});
</script>

<template>
  <label
    for="file-upload"
    class="flex flex-col justify-center items-center cursor-pointer bg-[#F9F9F9] p-8 border-2 border-dashed"
    :class="isActive ? 'border-medosync-blue' : 'border-medosync-grey border-opacity-60'"
    :data-active="isActive"
    @drop.prevent="onDrop"
    @dragenter.prevent="setActive"
    @dragover.prevent="setActive"
    @dragleave.prevent="setInactive"
  >
    <h3 class="mb-4">Drag and drop a file here or click</h3>
    <i class="fa-solid fa-cloud-arrow-up text-medosync-blue text-5xl"></i>
  </label>
  <input type="file" id="file-upload" class="hidden" :multiple="multiple" @input="onUpload" />
  <div v-if="showButtons" class="flex justify-end gap-4 mt-4">
    <button
      v-if="!noClose"
      type="button"
      data-test-id="cancel-upload-button"
      class="flex items-center border-2 border-medosync-blue px-6 py-2 text-sm cursor-pointer rounded-full"
      @click="emit('cancel')"
    >
      Cancel
    </button>
    <label
      for="file-upload"
      class="flex items-center bg-medosync-blue px-6 py-2 text-sm cursor-pointer rounded-full"
      data-test-id="upload-file-button"
    >
      Select File
    </label>
  </div>
</template>
