<template>
  <Transition>
    <Toast v-if="toastMessage" :message="toastMessage" :error="isError" @close-toast="closeToast" />
  </Transition>
  <li v-if="!isDeleted" :class="{ 'note-item-hover': hover }" class="note-item pl-6 pr-2 py-5">
    <i class="fas fa-circle bullet"></i>
    <div class="note-content pl-3">
      <p class="text-sm" v-if="note.content.subject !== null">
        <b>{{ note.content.subject }}</b>
      </p>
      <p class="text-sm">{{ note.content.content }}</p>
      <div class="flex flex-row items-center justify-between">
        <span class="font-medium"> ({{ formattedDate }} {{ note.author }}) </span>
        <i
          v-if="username == note.author"
          id="delete-note-btn"
          @mouseover="hover = true"
          @mouseleave="hover = false"
          class="far fa-trash-can text-xl cursor-pointer"
          @click="showDeleteModal(note.message.id)"
        ></i>
      </div>
    </div>
  </li>
  <EditClaimsModal
    v-if="deleteModal"
    titleProps="Delete note?"
    titlePropsSub="Are you sure you want to delete note?"
    titlePropsFooter='Please choose the "Cancel" button to return to note.'
    tittleSaveReplace="Delete Note"
    :showManageModal="true"
    @setModalOff="closeDeleteModal"
    @saveChanges="deleteSelectedNote"
  />
</template>

<script>
import { OpenAPIJSON } from 'api';
import { userStore } from '@/store/user';
import Toast from './Toast.vue';
import EditClaimsModal from '@/components/claim-forms/components/EditClaimsModal.vue';

export default {
  name: 'NoteClaims',
  components: { Toast, EditClaimsModal },
  emits: ['add-delete-count'],
  props: {
    claim: { required: false },
    note: null
  },
  data() {
    return {
      toastMessage: null,
      isDeleted: false,
      isError: false,
      hover: false,
      oapi: new OpenAPIJSON(),
      deleteModal: false,
      deleteId: null,
      username: null
    };
  },
  watch: {
    note() {
      this.toastMessage = null;
      this.isDeleted = false;
      this.isError = false;
      this.hover = false;
    }
  },
  computed: {
    formattedDate() {
      return new Date(this.note.created).toLocaleDateString('es-CL');
    }
  },
  mounted() {
    this.username = userStore.getters.user?.username ?? null;
  },
  methods: {
    closeToast() {
      this.toastMessage = null;
    },
    showDeleteModal(id) {
      this.deleteId = id;
      this.deleteModal = true;
    },
    closeDeleteModal() {
      this.deleteId = null;
      this.deleteModal = false;
    },
    async deleteSelectedNote() {
      await this.oapi
        .message_delete(this.deleteId)
        .then(() => {
          this.isDeleted = true;
          this.closeDeleteModal();
          this.toastMessage = 'Message successfully deleted!';
          this.$emit('add-delete-count');
          setTimeout(() => {
            this.toastMessage = null;
          }, 3500);
        })
        .catch(error => {
          console.log(error);
          this.isError = true;
          this.toastMessage = 'An error occured. Please try again.';
          setTimeout(() => {
            this.toastMessage = null;
          }, 3500);
        });
    }
  }
};
</script>

<style lang="scss" scoped>
.note-item {
  display: flex;
  flex-direction: row;
}
.note-item-hover {
  background-color: #bee9f6;
}
.note-content {
  flex-grow: 1;
}
.bullet {
  padding-top: 9px;
  font-size: 0.4em;
}
.v-enter-active,
.v-leave-active {
  transition: opacity 0.5s ease;
}

.v-enter-from,
.v-leave-to {
  opacity: 0;
}
</style>
