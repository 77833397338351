<template>
  <ListGeneralPractitioners
    id="referring_doctor"
    v-model="localData.referral_doctor"
    question="Name of the person who referred the patient to you"
    hint="Search by full-name for GP or Consultant"
    :enable_gp="true"
    :enable_consultant="true"
  />
  <TextQuestion
    id="referring_doctor_details"
    v-model="localData.referral_other_details"
    placeholder="Optional further information to augment the referring doctor"
    style="margin-bottom: 10px"
  />
</template>

<script>
import ListGeneralPractitioners from '@/components/form-fields/ListGeneralPractitioners.vue';
import TextQuestion from '@/components/form-fields/TextQuestion.vue';

/// A component to represent the common pairing of a referring doctor that is a lookup in a database
/// of GPs or Consultants, paired with an overridable field of descriptive information.
export default {
  name: 'AdmissionReferringDoctor',
  components: {
    ListGeneralPractitioners,
    TextQuestion
  },
  props: ['modelValue'],
  computed: {
    localData: {
      get() {
        return this.modelValue;
      },
      set(newValue) {
        this.$emit('update:modelValue', newValue);
      }
    }
  }
};
</script>
