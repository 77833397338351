<script setup>
import { useForm } from 'vee-validate';
import { object, string, date, boolean, array } from 'yup';

import PolicyDetailsSurgical from './sections/vhi/PolicyDetailsSurgical.vue';
import IllnessHistorySurgical from './sections/vhi/IllnessHistorySurgical.vue';
import InjurySection from './sections/vhi/InjurySection.vue';

const props = defineProps({
  editMode: {
    type: Boolean,
    default: false
  },
  warnings: {
    type: Array,
    default: () => []
  },
  initialData: {
    type: Object,
    default: () => ({})
  }
});

const { values, setValues, defineInputBinds } = useForm({
  validationSchema: object({
    policyNo: string(),
    patientForename: string().required(),
    patientSurname: string().required(),
    patientAddress: array().of(string()).required(),
    dateOfTreatment: date(),
    policyHolderName: string(),
    birthDate: date().required().min(new Date('1900-01-01')).max(new Date()),
    telephone: string().phone().required(),
    email: string().email(),
    treatmentSetting: string(),

    doctorName: string(),
    firstConsultation: date(),
    doctorAddress: string(),
    firstTreatment: date(),
    similarIllness: boolean(),
    similarIllnessDate: date(),
    similarIllnessDetails: string(),
    otherSource: boolean(),
    otherSourceDetails: string(),
    treatmentSettingOther: boolean(),
    treatmentSettingConsultantRooms: boolean(),
    treatmentSettingGP: boolean(),

    injuryDate: date(),
    injuryPlace: string(),
    injuryDescription: string(),
    pursueLegalClaim: boolean(),
    solicitorName: string(),
    solicitorAddress: string()
  }),
  initialValues: {
    policyNo: props.initialData?.policyNo ?? undefined,
    patientForename: props.initialData?.patientForename ?? undefined,
    patientSurname: props.initialData?.patientSurname ?? undefined,
    patientAddress: props.initialData?.patientAddress ?? undefined,
    dateOfTreatment: props.initialData?.dateOfTreatment ?? undefined,
    policyHolderName: props.initialData?.policyHolderName ?? undefined,
    birthDate: props.initialData?.birthDate ?? undefined,
    telephone: props.initialData?.telephone ?? undefined,
    email: props.initialData?.email ?? undefined,
    treatmentSetting: props.initialData?.treatmentSetting ?? undefined,

    treatmentSettingConsultantRooms: props.initialData?.treatmentSettingConsultantRooms ?? undefined,
    treatmentSettingGP: props.initialData?.treatmentSettingGP ?? undefined,
    treatmentSettingOther: props.initialData?.treatmentSettingOther ?? undefined,

    doctorName: props.initialData?.doctorName ?? undefined,
    firstConsultation: props.initialData?.firstConsultation ?? undefined,
    doctorAddress: props.initialData?.doctorAddress ?? undefined,
    firstTreatment: props.initialData?.firstTreatment ?? undefined,
    similarIllness: props.initialData?.similarIllness ?? undefined,
    similarIllnessDate: props.initialData?.similarIllnessDate ?? undefined,
    similarIllnessDetails: props.initialData?.similarIllnessDetails ?? undefined,
    otherSource: props.initialData?.otherSource ?? undefined,
    otherSourceDetails: props.initialData?.otherSourceDetails ?? undefined,

    injuryDate: props.initialData?.injuryDate ?? undefined,
    injuryPlace: props.initialData?.injuryPlace ?? undefined,
    injuryDescription: props.initialData?.injuryDescription ?? undefined,
    pursueLegalClaim: props.initialData?.pursueLegalClaim ?? undefined,
    solicitorName: props.initialData?.solicitorName ?? undefined,
    solicitorAddress: props.initialData?.solicitorAddress ?? undefined
  }
});

const similarIllness = defineInputBinds('similarIllness');
const otherSource = defineInputBinds('otherSource');
const pursueLegalClaim = defineInputBinds('pursueLegalClaim');
const treatmentSettingOther = defineInputBinds('treatmentSettingOther');
const treatmentSettingGP = defineInputBinds('treatmentSettingGP');
const treatmentSettingConsultantRooms = defineInputBinds('treatmentSettingConsultantRooms');

defineExpose({ values, setValues });
</script>

<template>
  <PolicyDetailsSurgical
    id="vhi-surgical-policy-details-surgical"
    :edit-mode="editMode"
    :warnings="warnings"
    :treatmentSettingOther="treatmentSettingOther.value"
    :treatmentSettingGP="treatmentSettingGP.value"
    :treatmentSettingConsultantRooms="treatmentSettingConsultantRooms.value"
  />
  <IllnessHistorySurgical
    id="vhi-surgical-illness-history-surgical"
    :edit-mode="editMode"
    :warnings="warnings"
    :similar-illness="similarIllness.value"
    :other-source="otherSource.value"
  />
  <InjurySection
    id="vhi-surgical-injury-section"
    :edit-mode="editMode"
    :warnings="warnings"
    :pursue-legal-claim="pursueLegalClaim.value"
  />
</template>
