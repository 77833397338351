<script setup>
// Adding new claim form is loaded with unnecessary BE calls 🙄
// In order to show the form, we need to create it first, then get it, then delete it
// Deletion of the form is needed because of the page reload/exit/redirects
// Otherwise, the form would be created in the BE and would be visible in the list of forms
// This is a workaround to show the form without creating it in the BE
import { onMounted, ref, computed, defineExpose } from 'vue';
import { useStore } from 'vuex';
import { OpenAPIJSON } from 'api/openapi';
import _ from 'lodash';

import SpinnerLoader from '@/components/misc/SpinnerLoader.vue';
import ClaimForm from '@/views/claims/details/ClaimForm.vue';
import DefaultModal from '@/components/claim-forms/components/DefaultModal.vue';
import ToastComponent from '@/components/claim-forms/components/ToastComponent.vue';
import SaveButtonsEditForm from '@/components/claim-forms/components/SaveButtonsEditForm.vue';

const props = defineProps({
  claimConsultantId: {
    type: Number,
    required: true
  },
  claimTypes: {
    type: Array,
    required: true
  },
  claimId: {
    type: Number,
    required: true
  },
  appointmentId: {
    type: Number,
    required: true
  }
});
const emit = defineEmits(['add-form', 'close-adding-form']);

const store = useStore();
const openApi = new OpenAPIJSON();
const toast = ref(null);
const dropdownOpen = ref(false);
const noFormSelectedModal = ref(false);
const removeNewClaimFormModal = ref(false);
const selectedForm = ref(null);
const loadingNewForm = ref(false);
const claimFormInitialValue = ref(null);
const claimForm = ref(null);

const title = computed(() => {
  if (selectedForm.value) {
    return `Claim Form - ${selectedForm.value.name}`;
  }
  return 'New Claim Form';
});

const toggleDropdown = () => {
  dropdownOpen.value = !dropdownOpen.value;
};
const clickOutsideDropdown = () => {
  if (dropdownOpen.value && !selectedForm.value) {
    dropdownOpen.value = false;
    noFormSelectedModal.value = true;
  } else {
    dropdownOpen.value = false;
  }
};
const selectNewClaimForm = async item => {
  dropdownOpen.value = false;
  selectedForm.value = item;
  await showNewForm(item);
  store.commit('setTextBoxDisabled', false);
};
const saveForm = async () => {
  if (!selectedForm.value) {
    noFormSelectedModal.value = true;
  } else {
    try {
      const { claim_form_id } = await openApi.claimform_consultant_create(
        props.claimConsultantId,
        selectedForm.value.insurer_id,
        selectedForm.value.claim_form_type
      );

      const claimData = await openApi.claimform_get(props.appointmentId, props.claimId, claim_form_id);

      claimForm.value.claim_form_id = claimData.claim_form_id;
      if (!_.isEqual(claimForm.value, claimFormInitialValue.value)) {
        await openApi.claimform_update(claimForm.value);
      }
      emit('add-form', { claimForm: claimForm.value, claimType: selectedForm.value });
    } catch (error) {
      toast.value?.showToast(
        'An error occurred. Please contact the support.',
        'fa-solid fa-triangle-exclamation',
        'error'
      );
    }
  }
};
const closeAddingModal = () => {
  if (claimForm.value) {
    removeNewClaimFormModal.value = true;
  } else {
    noFormSelectedModal.value = false;
    emit('close-adding-form');
  }
};
const closeRemoveNewClaimFormModal = async () => {
  try {
    if (!claimForm.value.claim_id && !claimForm.value.claim_form_id) return;
    await openApi.claimform_consultant_delete(props.claimConsultantId, claimForm.value.claim_form_id);
    claimForm.value = null;
    selectedForm.value = null;
    emit('close-adding-form');
  } catch (error) {
    toast.value?.showToast(
      'An error occurred. Please contact the support.',
      'fa-solid fa-triangle-exclamation',
      'error'
    );
  }
  removeNewClaimFormModal.value = false;
};
const showNewForm = async item => {
  try {
    loadingNewForm.value = true;
    const { claim_form_id } = await openApi.claimform_consultant_create(
      props.claimConsultantId,
      item.insurer_id,
      item.claim_form_type
    );
    claimForm.value = await openApi.claimform_get(props.appointmentId, props.claimId, claim_form_id);
    claimFormInitialValue.value = JSON.parse(JSON.stringify(claimForm.value));
    await openApi.claimform_consultant_delete(props.claimConsultantId, claim_form_id);
  } catch (err) {
    toast.value?.showToast(
      'An error occurred. Please contact the support.',
      'fa-solid fa-triangle-exclamation',
      'error'
    );
  }
  loadingNewForm.value = false;
};

onMounted(() => {
  store.commit('setTextBoxDisabled', false);
  toggleDropdown();
});

defineExpose({ closeAddingModal });
</script>

<template>
  <section>
    <div v-click-outside="clickOutsideDropdown">
      <h2 class="text-xl font-medium cursor-pointer text-blue-900 inline-flex" @click="toggleDropdown">
        {{ title }}
      </h2>
      <i class="inline-flex fa-solid fa-chevron-down cursor-pointer pl-4" @click="toggleDropdown"></i>
      <div :class="{ hidden: !dropdownOpen }" class="absolute z-[1000] bg-white flex flex-column justify-end">
        <div class="border border-black bg-white action-dropdown-size w-72 overflow-auto">
          <ul class="bg-white text-sm text-gray-700">
            <li
              v-for="item in claimTypes"
              :key="item.claim_form_type"
              @click="selectNewClaimForm(item)"
              class="block cursor-pointer px-2 py-1 hover:bg-[#00a3f4]/50 text-lg font-medium"
            >
              {{ item.name }}
            </li>
          </ul>
        </div>
      </div>
    </div>
    <SpinnerLoader v-if="loadingNewForm" />
    <ClaimForm v-else-if="claimForm" :claim="claimForm" />
    <div v-else class="bg-medosync-grey p-8 mt-4 h-screen">
      <span>You can select a new Claim Form by click on the headline above.</span>
      <span class="block pt-4">
        Or
        <span class="underline cursor-pointer" @click="closeAddingModal">delete the new Claim Form.</span>
      </span>
    </div>
  </section>
  <DefaultModal
    v-if="noFormSelectedModal"
    :showManageModal="true"
    title="No Claim Form selected"
    text="To save the new Claim Form you have to select one item from the list. Open the list of Claim Forms by clicking on the headline."
    subtext="If you want to delete the new Claim Form from this claim you can click on the button “Delete new Claim Form” below."
    secondaryButtonText="Delete <span class='block'>new Claim Form</span>"
    primaryButtonText="Select a Claim Form"
    @secondary-changes="closeAddingModal"
    @primary-changes="noFormSelectedModal = false"
    @close-modal="noFormSelectedModal = false"
  ></DefaultModal>
  <DefaultModal
    v-if="removeNewClaimFormModal"
    :showManageModal="true"
    :warningIcon="true"
    :title="`Revert all changes from Claim Form ${selectedForm.name}?`"
    text="Are you sure you want to leave this page and revert all changes you made in this claim form?"
    subtext="Please choose the button “Cancel” to review the claim form again."
    secondaryButtonText="Revert changes"
    primaryButtonText="Cancel"
    @secondary-changes="closeRemoveNewClaimFormModal"
    @primary-changes="removeNewClaimFormModal = false"
    @close-modal="removeNewClaimFormModal = false"
  ></DefaultModal>
  <ToastComponent ref="toast"></ToastComponent>
  <SaveButtonsEditForm @save="saveForm" @cancel="closeAddingModal" />
</template>
