<template>
  <div class="main-claim-view popup-scrollbar" id="claimform_vhi_mri" v-if="claim" v-bind="$attrs">
    <section class="break mt-4">
      <ClaimSection id="hospital_form_vhimri" title="Hospital Form" />
      <div class="transition px-5">
        <div class="flex flex-col grid-rows-1 bg-white pb-5">
          <div class="w-full md:w-1/2">
            <TextQuestion question="Hospital Code" id="hospital_code" :rows="2" v-model="claim.hospital_code" />
          </div>
          <div class="w-full md:w-1/2">
            <TextQuestion question="Hospital Name" id="hospital_name" :rows="2" v-model="claim.hospital_name" />
          </div>
          <div class="col-6 mt-6">
            <label class="block text-sm font-medium text-gray-700">Date &amp; Time of Admission</label>
            <span v-if="textBoxDisabled" class="block text-sm text-gray-500">{{
              datePickerAdmi ? formatDate(datePickerAdmi) : 'N/A'
            }}</span>
            <date-picker
              v-else
              v-model:value="datePickerAdmi"
              value-type="YYYY-MM-DD"
              format="DD-MM-YYYY"
              :disabled-date="disabledBeforeToday"
              :disabled="isTextBoxDisabled"
              :append-to-body="false"
            ></date-picker>
            <span v-if="textBoxDisabled" class="block text-sm text-gray-500" style="margin-top: 10px">{{
              timerPickerAdmi ? timerPickerAdmi : 'N/A'
            }}</span>
            <date-picker
              v-else
              style="margin-top: 10px"
              v-model:value="timerPickerAdmi"
              :minute-step="1"
              format="HH:mm"
              showSecond="00"
              value-type="HH:mm:ss"
              type="time"
              placeholder="HH:mm"
              :disabled="isTextBoxDisabled"
              :append-to-body="false"
            ></date-picker>
          </div>
          <div class="col-6 mt-6">
            <label class="block text-sm font-medium text-gray-700">Date &amp; Time of Discharge</label>
            <span v-if="textBoxDisabled" class="block text-sm text-gray-500">{{
              datePickerDischar ? formatDate(datePickerDischar) : 'N/A'
            }}</span>
            <date-picker
              v-else
              v-model:value="datePickerDischar"
              value-type="YYYY-MM-DD"
              format="DD-MM-YYYY"
              :disabled-date="disabledBeforeToday"
              :disabled="isTextBoxDisabled"
              :append-to-body="false"
            ></date-picker>
            <span v-if="textBoxDisabled" class="block text-sm text-gray-500" style="margin-top: 10px">{{
              timerPickerDischar ? timerPickerDischar : 'N/A'
            }}</span>
            <date-picker
              v-else
              style="margin-top: 10px"
              v-model:value="timerPickerDischar"
              :minute-step="1"
              format="HH:mm"
              showSecond="00"
              value-type="HH:mm:ss"
              type="time"
              placeholder="HH:mm"
              :disabled="isTextBoxDisabled"
              :append-to-body="false"
            ></date-picker>
          </div>

          <EnumQuestion
            id="reinbursement_method"
            v-model="claim.reimbursement_method"
            question="Reinbursement Method"
            :options="[
              { name: 'FPP', value: 'fpp' },
              { name: 'PP', value: 'pp' },
              { name: 'PER DIEM', value: 'per_diem' },
              { name: 'HRS', value: 'hrs' },
              { name: 'PUBLIC', value: 'public' },
              { name: 'GOVT .LEVY ONLY', value: 'government_levy' },
              { name: 'N/A', value: null }
            ]"
          />

          <div class="w-full md:w-1/2">
            <TextQuestion
              question="Hospital Invoice Value"
              id="labs_other_facility"
              :rows="2"
              v-model="claim.hospital_invoice_value"
            />
          </div>
          <label class="block text-sm font-medium text-gray-700">Treatment Setting</label>
          <label class="block text-sm font-medium text-gray-700"
            >(If the patient was not admitted to a ward in the hospital, please specify the treatment setting)</label
          >
          <div v-if="claim.questions">
            <CheckboxQuestion
              id="ts_theatre"
              question="Theatre"
              v-model="claim.questions.treatment_setting_hospital_theatre"
            />

            <CheckboxQuestion
              id="ts_sideroom"
              question="Sideroom"
              v-model="claim.questions.treatment_setting_sideroom"
            />
            <CheckboxQuestion
              id="ts_outpatient_dept"
              question="Out-patient Dept."
              v-model="claim.questions.treatment_setting_outpatient_dept"
            />
            <CheckboxQuestion
              id="ts_a_and_e"
              question="A&E Dept."
              v-model="claim.questions.treatment_setting_accident_and_emergency"
            />
            <CheckboxQuestion
              id="ts_radiology"
              question="Radiology Centre"
              v-model="claim.questions.treatment_setting_radiology"
            />
            <CheckboxQuestion
              id="ts_gp_rooms"
              question="Consultant/GP Rooms"
              v-model="claim.questions.treatment_setting_consultant_rooms"
            />
            <CheckboxQuestion
              id="ts_minor_injury"
              question="Minor Injury Unit"
              v-model="claim.questions.treatment_setting_minor_injury"
            />
          </div>

          <BedType v-if="claim.admission" id="beds" :default-date="claim.admission.on" v-model="claim.beds" />
          <div v-if="claim.questions">
            <BooleanQuestion
              id="xfer_from_hospital"
              v-model="claim.questions.xfer_from_facility"
              question="Was the patient transferred directly from another facility for this procedure?"
            />

            <TextQuestion
              question="If yes , name other facility:"
              id="xfer_from_facility_detail_hospital"
              :rows="2"
              v-model="claim.questions.xfer_from_facility_detail"
            />
          </div>
        </div>
      </div>
    </section>
    <PatientPolicyDetails id="vhi_mri_patient_details" :genders="genders" v-model="claimData" />

    <section class="break mt-4">
      <ClaimSection id="section6_medical_history-hdr" v-model="section6" title="Section 6: Medical History" />
      <div class="col-12 bg-white p-5 mb-5" id="section6_medical_history">
        <AdmissionReferringDoctor v-model="claimData.admission" />
        <TextQuestion
          id="symptoms_nature"
          v-model="claimData.admission.nature_of_symptoms"
          question="Nature of symptoms/signs"
          :rows="4"
        />

        <DurationOfSymptoms
          id="duration_of_symptoms"
          v-model="claimData.admission"
          :enable-hours="true"
          :enable-days="true"
          :enable-weeks="true"
          :enable-months="true"
          :enable-years="true"
          :enable-other="true"
          question="Duration of symptoms/signs on admission"
        />

        <BooleanQuestion
          id="adm_clinical_research"
          v-model="claimData.admission.related_to_clinical_research_study"
          question="Is this CT Scan related to a Clinical Research Study?"
        />
      </div>
    </section>

    <section class="break mt-4">
      <ClaimSection id="section7_labs-hdr" v-model="section7" title="Section 7: MRI Details" />
      <div class="col-12 bg-white p-5" id="section7_labs">
        <MRIProcedureList
          id="mri_scans"
          :dataValue="claimData"
          v-model="claimData.investigations.radiology_mri"
          :default-date="claimData.discharge.on"
          :enable-date="true"
          :enable-clinical-indicators="true"
          :enable-anatomical-site="true"
          :enable-clinical-interpretation="true"
          :enable-facility-name="false"
          :enable-consultant="false"
          :enable-anaesthesia="true"
          :anaesthesia-options="[
            { name: 'General', value: 'general' },
            { name: 'Regional', value: 'regional' },
            { name: 'Monitored', value: 'monitored' },
            { name: 'N/A', value: 'no_anaesthesia' }
          ]"
          :mri="true"
          :insurerId="claimData.insurance.insurer_id"
          :appointment-id="claimData.appointment_id"
        />
      </div>
    </section>

    <section class="break"></section>
  </div>
  <EditClaimsModal
    v-if="showManageModalClaims"
    :showManageModal="true"
    @setModalOff="showManageModalClaims = false"
    @saveChanges="updateClaim()"
  ></EditClaimsModal>

  <EditClaimsModal
    v-if="revertModal"
    :titleProps="titleText"
    :titlePropsSub="titleText2"
    :titlePropsFooter="titleText3"
    :tittleSaveReplace="saveReplace"
    :showManageModal="true"
    @setModalOff="revertModal = false"
    @saveChanges="enableTextBox()"
  ></EditClaimsModal>

  <SaveButtonsEditForm
    :activeFinalSaveButton="true"
    v-if="!isTextBoxDisabled"
    @save="updateClaimNoModal()"
    @final="updateClaim()"
    @cancel="updateSaveModal()"
  />
  <ToastComponent ref="toast"></ToastComponent>
</template>

<script>
import claimMixin from '@/components/mixins/claimMixin';
//css
import '@/assets/scss/forms.scss';
import ClaimSection from '@/components/claim-forms/components/ClaimSection.vue';
//import DateQuestion from "@/components/form-fields/DateQuestion.vue";
import DurationOfSymptoms from '@/components/claim-forms/components/DurationOfSymptoms.vue';
import PatientPolicyDetails from '@/components/claim-forms/components/PatientPolicyDetails.vue';
import AdmissionReferringDoctor from '@/components/form-fields/AdmissionReferringDoctor.vue';
import 'vue-datepicker-next/index.css';
import { mapState, mapMutations } from 'vuex';
import EditClaimsModal from '../../components/claim-forms/components/EditClaimsModal.vue';
import SaveButtonsEditForm from '../../components/claim-forms/components/SaveButtonsEditForm.vue';
import ToastComponent from '@/components/claim-forms/components/ToastComponent.vue';
import MRIProcedureList from '@/components/form-fields/MRIProcedureList.vue';
import { OpenAPIJSON } from 'api/openapi';

import BedType from '@/components/form-fields/Bed-type.vue';
import EnumQuestion from '@/components/claim-forms/components/EnumQuestion.vue';
import CheckboxQuestion from '@/components/form-fields/CheckboxQuestion.vue';
import DatePicker from 'vue-datepicker-next';
import TextQuestion from '@/components/form-fields/TextQuestion.vue';
import BooleanQuestion from '@/components/claim-forms/components/BooleanQuestion.vue';

export default {
  name: 'CompositeVhirMri',
  mixins: [claimMixin],

  components: {
    MRIProcedureList,
    AdmissionReferringDoctor,
    PatientPolicyDetails,
    DurationOfSymptoms,
    // DateQuestion,
    ClaimSection,
    EditClaimsModal,
    SaveButtonsEditForm,
    ToastComponent,
    BedType,
    EnumQuestion,
    CheckboxQuestion,
    DatePicker,
    TextQuestion,
    BooleanQuestion
  },
  props: {
    claim: {
      default: null
    },
    genders: null,
    diagnosisTypes: null,
    claimDataPreviousState: null
  },
  emits: ['form-reload', 'form-loaded', 'update-header-info'],
  data() {
    return {
      percentage: 1,
      fillColor: '#5FA4F9',
      claimData: null,
      inputId: 'vhi_mri',
      section1: false,
      section2: false,
      section3: false,
      section4: false,
      section5: false,
      section6: false,
      section7: false,
      section8: false,
      revertModal: false,
      showManageModalClaims: false,
      openApi: new OpenAPIJSON(),
      percentage: 1,
      fillColor: '#5FA4F9',
      claimData: null,
      inputId: 'vhi',
      section9_procedures: false,
      section9_other: false,
      section9_medical_attendance: false,
      section9_general: false,
      section10: false,
      showManageModalClaims: false,
      revertModal: false,
      labFacilities: [],
      datePickerAdmi: null,
      timerPickerAdmi: '0:00',
      datePickerDischar: null,
      timerPickerDischar: '00:00'
    };
  },
  computed: {
    ...mapState(['textBoxDisabled']),
    isTextBoxDisabled() {
      return this.textBoxDisabled;
    },
    admitDate() {
      return this.claim.admission ? this.claim.admission.on : null;
    },
    dischargeDate() {
      return this.claim.discharge ? this.claim.discharge.on : null;
    }
  },
  methods: {
    ...mapMutations(['setTextBoxDisabled']),
    async listLabFacilities() {
      try {
        const { items } = await this.openApi.list_facilities({
          facility_type: ['laboratory']
        });
        this.labFacilities = items;
      } catch (e) {
        this.$refs?.toast?.showToast(e, 'fa-solid fa-triangle-exclamation', 'error');
      }
    },
    formatDate(date) {
      if (!date) return 'N/A';
      const dateObj = new Date(date);
      if (isNaN(dateObj)) return 'N/A';
      const day = String(dateObj.getDate()).padStart(2, '0');
      const month = String(dateObj.getMonth() + 1).padStart(2, '0');
      const year = String(dateObj.getFullYear()).slice(-2);
      return `${day}-${month}-${year}`;
    },
    admitDate_() {
      if (this.claim.admission.on != null) {
        var dateOnly = this.claim.admission.on.split('T')[0];
        var timeOnly = this.claim.admission.on.split('T')[1];
        this.datePickerAdmi = dateOnly;
        this.timerPickerAdmi = timeOnly;
        this.claim.admission.on = dateOnly + 'T' + timeOnly;
      }
    },
    dischargeDate_() {
      if (this.claim.discharge.on != null) {
        var dateOnly = this.claim.discharge.on.split('T')[0];
        var timeOnly = this.claim.discharge.on.split('T')[1];
        this.datePickerDischar = dateOnly;
        this.timerPickerDischar = timeOnly;
        this.claim.discharge.on = dateOnly + 'T' + timeOnly;
      }
    }
  },
  created() {
    this.claimData = this.claim;
    this.listLabFacilities();
    this.admitDate_();
    this.dischargeDate_();
  },
  updated() {
    this.$emit('form-loaded');
  },

  watch: {
    admitDate() {
      this.admitDate_();
    },
    dischargeDate() {
      this.dischargeDate_();
    },
    datePickerAdmi(newval) {
      this.claim.admission.on = newval + 'T' + this.timerPickerAdmi;
    },
    timerPickerAdmi(newval) {
      this.claim.admission.on = this.datePickerAdmi + 'T' + newval;
    },

    datePickerDischar(newval) {
      this.claim.discharge.on = newval + 'T' + this.timerPickerDischar;
    },
    timerPickerDischar(newval) {
      this.claim.discharge.on = this.datePickerDischar + 'T' + newval;
    },
    'claimData.investigations.radiology_mri': {
      handler() {
        this.claimData.investigations?.radiology_mri?.forEach(item => {
          if (item?.anaesthesia?.application === 'no_anaesthesia' || item?.anaesthesia?.application === null)
            item.anaesthesia.reason = null;
        });
      },
      deep: true
    }
  }
};
</script>

<style lang="scss" scoped>
.claim-title {
  width: 100%;
  height: 75px;
  margin-bottom: 45px;
  padding-left: 15px;
  font-size: 40px;
  line-height: 75px;
  font-weight: 600;
  border-bottom: 2px solid #002e7e;
  background: #fff;
}

.notes {
  float: left;
  width: 250px;
}

.title-info {
  font-size: 30px;
  color: #72777a;
}
</style>
