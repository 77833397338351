<template>
  <div class="me-[65px]">
    <!-- Testing message box -->
    <div
      v-if="showTestingMessage"
      class="flex justify-center items-top bg-medosync-dark-red text-white w-3/4 mx-auto p-5 gap-4"
    >
      <div><i class="fas fa-warning text-3xl"></i></div>
      <div>
        <h5 class="font-bold">Important Notice: You're Trying to Access a Test Environment</h5>
        <p>
          It looks like you're trying to connect to our UAT (User Acceptance Testing) environment, which is used for
          testing and not for regular use. To continue with the live system, please use the correct link: Live
          Environment URL: {{ productionSiteUrl }}
        </p>
      </div>
      <div><i class="fas fa-close text-xl"></i></div>
    </div>
    <div class="min-h-full flex flex-col justify-center py-12 sm:px-6 lg:px-8 relative">
      <div class="sm:mx-auto sm:w-full sm:max-w-md">
        <h2 class="mt-5 text-center text-3xl font-extrabold text-gray-900">Sign in to your account</h2>
      </div>

      <div class="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
        <div class="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
          <div class="space-y-6">
            <div>
              <label for="email" class="block text-sm font-medium text-gray-700"> Email address </label>
              <div class="mt-1">
                <input
                  v-model="user.email"
                  type="email"
                  autocomplete="email"
                  data-test-id="username-input"
                  required
                  class="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                />
              </div>
            </div>

            <div>
              <label for="password" class="block text-sm font-medium text-gray-700"> Password </label>
              <div class="mt-1 relative">
                <input
                  v-model="user.password"
                  :type="showPassword ? 'text' : 'password'"
                  autocomplete="current-password"
                  data-test-id="password-input"
                  required
                  class="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                />
                <div
                  class="absolute flex justify-center items-center right-0 top-0 cursor-pointer w-5 mt-2.5 mr-2"
                  @mousedown="showPassword = true"
                  @mouseup="showPassword = false"
                  @mouseleave="showPassword = false"
                >
                  <i class="fas" :class="showPassword ? 'fa-eye' : 'fa-eye-slash'"></i>
                </div>
              </div>
            </div>

            <div class="flex items-center justify-between">
              <div class="flex items-center">
                <input
                  id="remember-me"
                  name="remember-me"
                  type="checkbox"
                  class="h-4 w-4 text-indigo-600 focus:ring-indigo-500 border-gray-300 rounded"
                />
                <label for="remember-me" class="ml-2 block text-sm text-gray-900"> Remember me </label>
              </div>

              <!-- <div class="text-sm">
                <router-link to="/reset-password" href="#" class="font-medium text-indigo-600 hover:text-indigo-500">
                  Forgot your password?
                </router-link>
              </div> -->
            </div>

            <div>
              <button
                @click="login()"
                type="submit"
                data-test-id="loginButton"
                class="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
              >
                Sign in
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <ToastComponent ref="toast"></ToastComponent>
  </div>
</template>

<script>
import axios from 'axios';
import { OpenAPIJSON } from 'api/openapi';
import { userStore } from '@/store/user';
import { dashboardRedirectPage } from '@/util/helper';
import ToastComponent from '@/components/claim-forms/components/ToastComponent.vue';

export default {
  name: 'AuthLogin',
  components: {
    ToastComponent
  },
  data() {
    return {
      openApi: new OpenAPIJSON(),
      user: {
        email: '',
        password: ''
      },
      pingInterval: null,
      showTestingMessage: false,
      showPassword: false
    };
  },
  computed: {
    productionSiteUrl() {
      if (window.location.hostname == 'localhost') {
        return 'localhost';
      }
      const urlSubs = window.location.hostname.split('.');
      return urlSubs.slice(1).join('.');
    }
  },
  mounted() {
    window.addEventListener('keyup', this.handleKeyup);
    this.pingInterval = setInterval(() => {
      this.ping();
    }, 5000);

    // Show the testing message if the hostname matches
    const testingHosts = [
      'localhost',
      'uat.beacon.medosync.com',
      'dev.hospital.medosync.com',
      'uat.bshs.medosync.com',
      'dev.bshs.medosync.intern'
    ];
    if (testingHosts.includes(window.location.hostname)) {
      this.showTestingMessage = true;
    }
  },
  beforeUnmount() {
    window.removeEventListener('keyup', this.handleKeyup);
    if (this.pingInterval) {
      clearInterval(this.pingInterval);
    }
  },
  methods: {
    async ping() {
      try {
        await this.openApi.ping();
      } catch (error) {
        if (axios.isAxiosError(error)) {
          this.$refs.toast.showToast(
            'Service connection failed cannot reach backend service',
            'fa-solid fa-triangle-exclamation',
            'error'
          );

          console.error('SERVICE CONNECTION ERROR: Service connection failed cannot reach backend service');
        } else {
          this.$refs.toast.showToast('Service connection failed', 'fa-solid fa-triangle-exclamation', 'error');
          console.error('SERVICE CONNECTION ERROR: Service connection failed');
        }
        console.error(
          `SERVICE CONNECTION ERROR: OpenAPI service is not responding for user: ${
            this.user?.email
          }, with the following error: ${JSON.stringify(error)}`
        );
      }
    },
    handleKeyup(e) {
      if (e.key === 'Enter') {
        this.login();
      }
    },
    async login() {
      // if email or password is empty return
      this.user.email = this.user.email.trim();
      this.user.password = this.user.password.trim();
      if (this.user.email.length < 4) {
        localStorage.removeItem('_token');
        localStorage.removeItem('tokenExpiration');
        this.$refs.toast.showToast('User name is too short', 'fa-solid fa-triangle-exclamation', 'error');
        console.error(`LOGIN ERROR: Email is too short for user: ${this.user?.email}`);
        return;
      }
      if (this.user.password.length < 4) {
        localStorage.removeItem('_token');
        localStorage.removeItem('tokenExpiration');
        this.$refs.toast.showToast('Password is too short', 'fa-solid fa-triangle-exclamation', 'error');
        console.error(`LOGIN ERROR: Password is too short for user: ${this.user?.email}`);
        return;
      }

      try {
        console.info(`Logging in user: ${this.user?.email}`);
        let response = await this.openApi.login(this.user.email, this.user.password);

        // Must force update of localStorage cache of /me data after login
        // otherwise swapping logins can use data from previous login.
        localStorage._token = response.access_token;

        await userStore.dispatch('getUserInfo');

        // Expire logins at the end of the day.
        let expiration = new Date();
        expiration.setHours(24, 0, 0, 0);
        localStorage.setItem('tokenExpiration', expiration.toISOString());
        localStorage.setItem('selectedConsultantReport', '');
        await this.setPreferences(JSON.parse(userStore.getters.user?.preferences));
        const redirectPage = dashboardRedirectPage();
        window.location.href = redirectPage;
      } catch (error) {
        if (Object.hasOwn(error, 'response') && Object.hasOwn(error.response, 'data')) {
          if (error.response?.data?.reason) {
            if (error.response.data.reason.startsWith('LOGIN-002')) {
              this.$refs.toast.showToast('Username is not known', 'fa-solid fa-triangle-exclamation', 'error');

              console.error('LOGIN ERROR: Username is not known for user: ', this.user?.email);
              localStorage.removeItem('_token');
              localStorage.removeItem('tokenExpiration');
              return;
            } else if (error.response.data.reason.startsWith('LOGIN-001')) {
              this.$refs.toast.showToast(
                'Username or password is not known',
                'fa-solid fa-triangle-exclamation',
                'error'
              );

              console.error('LOGIN ERROR: Username or password is not known for user: ', this.user?.email);
              localStorage.removeItem('_token');
              localStorage.removeItem('tokenExpiration');
              return;
            }
          }
        } else {
          this.$refs.toast.showToast('Service connection error', 'fa-solid fa-triangle-exclamation', 'error');
        }
        sessionStorage.clear();
        localStorage.removeItem('_token');
        localStorage.removeItem('tokenExpiration');
        console.error(
          `LOGIN ERROR: Login failed for user: ${this.user?.email}, with the following error: ${JSON.stringify(error)}`
        );
        throw new Error(error);
      }
    },
    async setPreferences(data) {
      //define preference object here
      //use whole object for saveUserPreferences()
      let preferences = {
        tableLayout: null,
        tagsLayout: null,
        menuLayout: null
      };

      // load data if it is already defined
      if (Object.keys(data).length > 0) {
        preferences = data;
      }

      // set table layout to default columns and columns width
      if (!data.tableLayout) {
        preferences.tableLayout = [
          {
            name: 'Index',
            active: true,
            width: 35
          },
          {
            name: 'Patient',
            active: true,
            width: 130
          },
          {
            name: 'MRN',
            active: true,
            width: 100
          },
          {
            name: 'AppID',
            active: true,
            width: 120
          },
          {
            name: 'LOS',
            active: true,
            width: 60
          },
          {
            name: 'Admitted',
            active: true,
            width: 100
          },
          {
            name: 'Discharged',
            active: true,
            width: 100
          },
          {
            name: 'Insurer',
            active: true,
            width: 130
          },
          {
            name: 'Invoice Consultant',
            active: true,
            width: 140
          },
          {
            name: 'Status',
            active: true,
            width: 130
          },
          {
            name: 'Note',
            active: true,
            width: 90
          },
          {
            name: 'Tag',
            active: true,
            width: 100
          },
          {
            name: 'Menage',
            active: true,
            width: 120
          },
          {
            name: 'Action',
            active: true,
            width: 100
          }
        ];
      }

      // if there are no tags defines get default tags for logged user
      if (!data.tagsLayout) {
        preferences.tagsLayout = await this.getTagList();
      }

      //save it to localStorage
      localStorage.preferences = JSON.stringify(preferences);
    },
    async getTagList() {
      try {
        let response = await this.openApi.tagList();

        response.hospital.forEach(item => {
          item.active = true;
          item.color = '#00A7E1';
        });

        return response.hospital;
      } catch (error) {
        console.log('Error getting tags: ', JSON.stringify(error));
      }
    }
  }
};
</script>
