import { createStore } from 'vuex';
import { OpenAPIJSON } from 'api';
import { TemplateMessageType } from 'enums';

export const templateMessageStore = createStore({
  state() {
    return {
      [TemplateMessageType.PATIENT_REGISTRATION_SMS]: null,
      [TemplateMessageType.PATIENT_REGISTRATION_EMAIL]: null
    };
  },
  getters: {
    patientRegistrationSms: state => {
      return state[TemplateMessageType.PATIENT_REGISTRATION_SMS];
    },
    patientRegistrationEmail: state => {
      return state[TemplateMessageType.PATIENT_REGISTRATION_EMAIL];
    }
  },
  mutations: {
    async setMessageTemplate(state, template) {
      state[template.type] = template.message;
    }
  },
  actions: {
    async getMessageTemplate({ commit, state }, templateType) {
      if (
        (templateType === TemplateMessageType.PATIENT_REGISTRATION_SMS && state.patientRegistrationSms) ||
        (templateType === TemplateMessageType.PATIENT_REGISTRATION_EMAIL && state.patientRegistrationEmail)
      ) {
        return;
      }

      try {
        const openApi = new OpenAPIJSON();
        const messageTemplate = await openApi.getMessageTemplate(templateType);
        commit('setMessageTemplate', { type: templateType, message: messageTemplate });
      } catch (error) {
        new Error(error);
      }
    }
  }
});
