// login expected (email, password)
const loginWeb = {
  query: `query ($email: String!, $password: String!, $hospitalId: Int!) {
          login (
            input: {
              hospital: { hospitalId: $hospitalId }
              email: $email
              password: $password
            }
          ) {
            accessToken
          }
        }`,
};

// login expected (email, password)
const loginMobile = {
  query: `query ($email: String!, $password: String!) {
          login (
            input: {
              email: $email
              password: $password
            }
          ) {
            accessToken
          }
        }`,
};

const listHospitals = {
  query: `query ($groupId: Int!) {
        listHospitals(group: { groupId: $groupId}) {
          hospital {
            hospitalId
            name
            hospitalType
            hospitalGroupId
            hospitalGroupName
          }
        }
      }`,
};

const listMedics = {
  query: `query {
        listMedics {
          medics {
              personId
              consultantId
              medicId
              title
              firstname
              lastname
              gender
              dateOfBirth
              medicalNumber
              insurerCode
              allInsurerCodes {
                code
                insurer
                insurerId
              }
              qualifications
              speciality
              clinicContact {
                  postalAddress
                  telephoneLandline
                  telephoneMobile
                  telephoneFax
                  email
              }
              departmentContact {
                  postalAddress
                  telephoneLandline
                  telephoneMobile
                  telephoneFax
                  email
              }
              specialisations
          }
        }
      }`,
};

// Specialisation of listMedics to only return those consultants associated to the
// logged-in user.
const listMyMedics = {
  query: `query {
        listMedics(input: { my: true }) {
          medics {
              personId
              consultantId
              medicId
              title
              firstname
              lastname
              gender
              dateOfBirth
              medicalNumber
              insurerCode
              allInsurerCodes {
                code
                insurer
                insurerId
              }
          }
        }
      }`,
};

const listInsurers = {
  query: `query {
      listInsurers {
        insurers {
          insurerId
          name
          contact {
            postalAddress
            telephoneLandline
            telephoneMobile
            telephoneFax
            email
          }
        }
      }
    }`,
};

const listInsurerContracts = {
  query: `query {
      listInsurerContracts {
        contracts {
          insurerId
          name
          contracts {
            contractId,
            contractName,
            contractDate,
            insurerContractRef,
            hospitalContractRef,
            createdOn,
            startDate,
          }
        }
      }
    }`,
};

const listPatients = {
  query: `query {
      listPatients {
        patients {
          patientId
          mrn
          person {
            personId
            title
            firstName
            lastName
            gender
            dateOfBirth
            contact {
                postalAddress
                telephoneLandline
                telephoneMobile
                telephoneFax
                email
            }
          }
        }
      }
    }`,
};

const getBedTypes = {
  query: `query { getBedTypes }`,
};

const getDiagnosisTypes = {
  query: `query { getDiagnosisTypes }`,
};

const getRadiologyTypes = {
  query: `query { getRadiologyTypes }`,
};

const listProcedureCodes = {
  query: `query ($appointmentId: Int!, $consultantId: Int!) {
      listProcedureCodes(input: { claim: { appointmentId: $appointmentId consultantPersonId: $consultantId } }) {
        codes {
          procedureCodeId
          procedureCode
          procedureCodeDescription
          participatingConsultantCost
          # standardConsultantCost
          # participatingAnaesthetistCost
          # participatingGpCost
          # standardGpCost
        }
      }
    }`,
};

const listAllProcedureCodes = {
  query: `query ($insurerId: Int!) {
    listAllProcedureCodes(input: { insurer: { insurerId: $insurerId } }) {
      codes {
        procedureCodeId
        procedureCode
        procedureCodeDescription
        participatingConsultantCost
        participatingAnaesthetistCost
      }
    }
  }`,
};

const listMriProcedureCodes = {
  query: `query ($appointmentId: Int!, $consultantId: Int!) {
      listProcedureCodes(input: {
          claim: { appointmentId: $appointmentId consultantPersonId: $consultantId }
          cats: [RADIOLOGY, CT, MRI]
          }
        ) {
        codes {
          procedureCodeId
          procedureCode
          procedureCodeDescription
          participatingConsultantCost
          # standardConsultantCost
          # participatingAnaesthetistCost
          # participatingGpCost
          # standardGpCost
        }
      }
    }`,
};

const listClinicalIndicatorCodes = {
  query: `query ($appointmentId: Int!, $consultantId: Int!, $procedureCode: String!) {
      listClinicalIndicatorCodes (
        input: { 
          claim: { appointmentId: $appointmentId consultantPersonId: $consultantId }
          procedureCode: $procedureCode
        }
      ) {
        codes {
          indicatorCode
          procedureCode
          indicatorCode
          indicatorDescription
        }
      }
    }`,
};

const listTags = {
  query: `query {
      listTags {
        tags
      }
    }`,
};

const listTitles = {
  query: `query {
    listTitles {
      title
      titleId
      name
    }
  }`,
};

const listGenders = {
  query: `query {
    listGenders {
      genderId
      gender
      name
    }
  }`,
};

const listMedicSpecialisationTypes = {
  query: `query {
      listMedicSpecialisationTypes {
        specs {
          specialisationType,
          name
        }
      }
    }`,
};

const listAllAcls = {
  query: `query {
      listAcls {
        aclId,
        name,
        description
      }
    }`,
};

const listGroupAcls = {
  query: `query ($aclGroupName: String!) {
      listAcls (aclGroup: { aclGroupName: $aclGroupName }) {
        aclId
        name
        description
        canRead
        canWrite
  
      }
    }`,
};

const listAclGroups = {
  query: `query {
      listAclGroup {
        groups {
          aclGroupId,
          name,
          description
        }
      }
    }`,
};

const listLogins = {
  query: `query {
      listLogins {
        logins {
          loginAccountId,
          hospitalId,
          userName,
          emailAddress,
          disabled,
          aclGroupId
          personId
          confirmed,
          associates
        }
      }
    }`,
};

const createUser = {
  query: `mutation ($user: CreateUserInput!) {
      createUser (
        input: $user
      ) {
        success
      }
    }`,
};

const createPerson = {
  query: `mutation ($person: PersonDetail!) {
      createPerson (
        input: $person
      ) {
        personId
      }
    }`,
};

const listRemittancesWithPersonId = {
  query: `query ($consultantId: Int!, $invoiceRef: String!, $fileName: String!, $stateId: String!) {
      listRemittances (
        input: {
          consultant: {personId: $consultantId}
          invoiceRef: $invoiceRef
          forFile: $fileName
          stateId: $stateId
        }
      ) {
        insurerRemittances {
          remittanceLineId,
          insurerId,
          insurerName,
          amountInvoiced,
          amountPayable,
          withholdingTax,
          adjustmentAmount,
          comments,
          paidOn,
          payReference,
          invoiceRef,
          insurerClaimId,
          hospitalAppointmentId
          invoiceId,
          stateId,
          stateName
          patientName
          patientDob
          admitted
          membershipNo
          fileId
          pageNumber
          pageLine
          pendedDate
          pendedReason
          rejectedDate
          rejectedReason
          confidenceAmountInvoiced
          confidenceAmountPayable
          confidenceWithholdingTax
          confidenceAdjustmentAmount
          confidenceInvoiceRef
          confidenceInsurerClaimId
          delete
        },
        manualRemittances {
          remittanceLineId,
          insurerId,
          insurerName,
          amountInvoiced,
          amountPayable,
          withholdingTax,
          adjustmentAmount,
          comments,
          paidOn,
          payReference,
          invoiceRef,
          insurerClaimId,
          hospitalAppointmentId
          invoiceId,
          stateId,
          stateName,
          patientName
          patientDob
          admitted
          membershipNo
          fileId
          pageNumber
          pageLine
          pendedDate
          pendedReason
          rejectedDate
          rejectedReason
          confidenceAmountInvoiced
          confidenceAmountPayable
          confidenceWithholdingTax
          confidenceAdjustmentAmount
          confidenceInvoiceRef
          confidenceInsurerClaimId
          delete
        }
      }
    }`,
};

const listInvoicesAndRemittances = {
  query: `query ($consultantId: Int!, $startDate: NaiveDate!, $endDate: NaiveDate!, $invoiceRef: String, $claimState: String) {
      listInvoicesAndRemittances (input: {
        consultant: {personId: $consultantId}
        startDate: $startDate
        endDate: $endDate
        invoiceRef: $invoiceRef
        claimState: $claimState
      }) {
        invoiceId
        claimId
        claimRef
        appointmentId
        hospitalPatientId
        hospitalAppointmentId
        consultantId
        invoiceRef
        invoiceDate
        insurerId
        insurerName
        patientMrn
        amountInvoiced
        amountPaid
        amountOutstanding
        withholdingTax
        adjustmentAmount
        consultant {
          personId
          title
          firstname
          lastname
          medicId
          medicalNumber
          dateOfBirth
        }
        patient {
          title
          firstName
          middleNames
          lastName
          gender
          dateOfBirth
        }
        payments {
          remittanceLineId
          insurerId
          insurerName
          amountInvoiced
          amountPayable
          withholdingTax
          adjustmentAmount
          comments
          paidOn
          payReference
          invoiceRef
          insurerClaimId
          hospitalAppointmentId
          invoiceId
          stateName
          stateId
          patientName
          patientDob
          admitted
          membershipNo
          fileId
          pendedDate
          pendedReason
          rejectedDate
          rejectedReason
          confidenceAmountInvoiced
          confidenceAmountPayable
          confidenceWithholdingTax
          confidenceAdjustmentAmount
          confidenceInvoiceRef
          confidenceInsurerClaimId
          processedOn
          processedBy
        }
        paymentStatus
      }
    }`,
};

const listPaymentsAndTaxFiles = {
  query: `query ($personId: Int!) {
      listPaymentsAndTaxFiles(input: {
        consultant: { personId: $personId }
      }) {
        files {
          insurerFile {
            fileId
            fileName
            blobName
            fileSize
            mimeType
            fileCategory
            creationDate
            totalAmountInvoiced
            totalAmountPaid
            totalAmountPended
            totalAmountRejected
            reconciledAmountInvoiced
            reconciledAmountPayable
            reconciledWithholdingTax
            reconciledAdjustmentAmount
            unreconciledAmountInvoiced
            unreconciledAmountPayable
            unreconciledWithholdingTax
            unreconciledAdjustmentAmount
            externalAmountInvoiced
            externalAmountPayable
            externalWithholdingTax
            externalAdjustmentAmount
            scanTaskId
            scanState
            insurerId
            insurerName
            paymentDate,
            processedDate
          }
          taxCert {
            fileId
            fileName
            blobName
            fileSize
            mimeType
            fileCategory
            creationDate        
          }
        }
      }
    }`,
};

const listAllPaymentsAndTaxFiles = {
  query: `query {
      listPaymentsAndTaxFiles (input: {}) {
        files {
          insurerFile {
            fileId
            fileName
            blobName
            fileSize
            mimeType
            fileCategory
            creationDate
            amountInvoiced
            amountPayable
            withholdingTax
            adjustmentAmount
            scanTaskId
            scanState
            insurerName
          }
          taxCert {
            fileId
            fileName
            blobName
            fileSize
            mimeType
            fileCategory
            creationDate        
          }
        }
      }
    }`,
};

const listClaimformTypes = {
  query: `query {
      listClaimformTypes {
        types {
            claimformType
            name
        }
      }
    }`,
};

const listInsurerPolicies = {
  query: `query ($insurerName: String!) {
      listInsurerPolicies (input: {
        insurer: {
          insurerName: $insurerName
        }
      }
      ) {
        policies {
          insurerId
          policyPlanId
          policyPlanName
        }
      }
    }`,
};

const listConsultantFilesWeb = {
  query: `query ($personId: Int!, $fileName: String!) {
      listConsultantFiles (input: {
        fileName: $fileName,
        consultantId: $personId
      }) {
        files {
          fileId,
          fileName,
          blobName,
          fileSize,
          mimeType,
          fileCategory,
          creationDate
        }
      }
    }`,
};

const listConsultantFilesMobile = {
  query: `query ($consultantId: Int!) {
        listConsultantFiles(
            input: { consultantId: $consultantId }
        ) {
          files {
            fileName
            blobName
            fileName
            mimeType
            fileCategory
            creationDate
          }
        }
      }`,
};

const reportClaimsBilledState = {
  query: `query ($startDate: NaiveDate) {
      reportClaimsBilledState (input: {
        startDate: $startDate    }) {
        items {
          claimId,
          claimDate,
          consultantId,
          consultantName,
          amountInvoiced,
          amountRemittance,
          billedState
        }
      }
    }`,
};

// consultant notes
const getConsultantNotes = {
  query: `query ($consultantId: [Int!]) {
      getConsultantNotes (
      input: {
        consultantId: $consultantId
      }) {
        consultantId
        notes {
          noteId,
          content,
          important,
          noteDate,
          author,
          author,
          files {
            fileId,
            fileName,
            blobName,
            fileSize,
            mimeType,
            creationDate
          }
        }
      }
    }`,
};

// capture signature
const setMedicSignature = {
  query: `mutation ($person: FindPerson!, $signature: String!) {
      setMedicSignature (
        input: {
          person: $person
          signature: $signature
        }
      ) {
        success
      }
    }`,
};

export {
  loginWeb,
  loginMobile,
  listHospitals,
  listMedics,
  listMyMedics,
  listInsurers,
  listInsurerContracts,
  getBedTypes,
  getDiagnosisTypes,
  getRadiologyTypes,
  listProcedureCodes,
  listAllProcedureCodes,
  listMriProcedureCodes,
  listClinicalIndicatorCodes,
  listTags,
  listTitles,
  listGenders,
  listMedicSpecialisationTypes,
  listAclGroups,
  listLogins,
  createUser,
  createPerson,
  listAllAcls,
  listGroupAcls,
  listRemittancesWithPersonId,
  listInvoicesAndRemittances,
  listPaymentsAndTaxFiles,
  listPatients,
  listClaimformTypes,
  listInsurerPolicies,
  listConsultantFilesWeb,
  listConsultantFilesMobile,
  listAllPaymentsAndTaxFiles,
  reportClaimsBilledState,
  getConsultantNotes,
  setMedicSignature,
};
