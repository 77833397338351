<template>
  <!-- start -->
  <div class="col-12 mt-5">
    <div v-if="question">
      <label class="block text-sm font-medium text-gray-700">{{ question }} </label>
    </div>
    <div v-if="isTextBoxDisabled">
      <span class="text-sm text-gray-500">{{ modelValue ? modelValue : question ? 'N/A' : '' }}</span>
    </div>
    <div v-else class="mt-1" :title="title">
      <textarea v-if="rows > 2" :id="inputId" :rows="rows" v-model="localData" class="p-2 shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md" :placeholder="placeholder" :disabled="isTextBoxDisabled"></textarea>
      <input v-else :id="inputId" v-model="localData" class="custom-input shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md" :placeholder="placeholder" :disabled="isTextBoxDisabled">
    </div>
  </div>
  <!-- end -->
</template>


<script>
 import { mapState } from 'vuex';
/**
 * Component for a simple Question with a text-box answer.
 *
 * Args:
 *   - question: a sentence to describe why there is a Yes/No
 *   - title: if defined then this will be hover-over text
 */
export default {
  name: "TextQuestion",
  props: {
    modelValue: { default: "" },
    question: { type: String, required: false },
    title: { type: String, required: false },
    placeholder: { type: String, required: false, default: "" },
    rows: { type: Number, required: false, default: 1 },
    readonly: { type: Boolean, required: false, default: false },
    id: {}
  },
  emits: ['update:modelValue'],
  data() {
    return {
      inputId: this.id || `text_question${(Math.random() * 1000).toFixed()}`,
      readonlyAttr: this.readonly ? "readonly" : "",
    }
  },


 
  computed: {
    ...mapState(['textBoxDisabled']),
      isTextBoxDisabled() {
        return this.textBoxDisabled;
      },
    localData: {
      get() {
        return this.modelValue
      },
      set (newValue) {
        this.$emit('update:modelValue', newValue)
      }
    }
  },
}
</script>

<style scoped>

textarea {
  width: 100%;
  border: 1px solid #dedede;
}

</style>