<template>
  <SpinnerLoader v-if="isLoading" />
  <div>
    <div class="p-8">
      <div class="col flex flex-row px-2 mb-2">
        <div class="col s-report-title">
          <span class="cursor-pointer" @click="$emit('close-detailed')">
            <i class="fas fa-chevron-left mr-3"></i>
          </span>
          {{ title }}
        </div>
        <div class="col-4 flex flex-col px-2">
          <h3 class="text-lg leading-6 font-medium text-gray-900 mr-5">Filter by Date:</h3>

          <div class="datePicker">
            <date-picker v-model:value="dateRange" type="date" range placeholder="Select date range"></date-picker>
          </div>
        </div>
        <button
          @click="fetch_pdf_report_data"
          type="button"
          class="inline-flex items-center mx-2 px-2.5 py-1.5 border border-transparent text-xs font-medium rounded shadow-sm text-white bg-blue focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
        >
          DOWNLOAD PDF
        </button>
        <button
          @click="fetch_csv_report_data"
          type="button"
          class="inline-flex items-center mx-2 px-2.5 py-1.5 border border-transparent text-xs font-medium rounded shadow-sm text-white bg-blue focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
        >
          DOWNLOAD CSV
        </button>
      </div>

      <div class="col-12">
        <TableComponent
          :claimsProp="reportData"
          :tableNames="tableNames"
          :isLoading="isLoading"
          @set-insurer="setInsurer"
        />
      </div>
    </div>
  </div>
  <ToastComponent ref="toast"></ToastComponent>
</template>

<script>
// import Datepicker from 'vue3-datepicker'
// import vSelect from 'vue-select'
import { api_store } from '@/store';
import { userStore } from '@/store/user';
import { OpenAPICSV, OpenAPIDateRange, OpenAPIJSON, OpenAPIPDF } from 'api/openapi';
import StringUtility from 'utils/stringUtility';
import TableComponent from '@/components/claim-forms/components/TableComponent.vue';
import DatePicker from 'vue-datepicker-next';
import 'vue-datepicker-next/index.css';
import SpinnerLoader from '@/components/misc/SpinnerLoader.vue';
import ToastComponent from '@/components/claim-forms/components/ToastComponent.vue';

export default {
  name: 'OutstandingDetailed',
  components: {
    TableComponent,
    DatePicker,
    SpinnerLoader,
    ToastComponent
  },

  props: {
    preselectedConsultant: null,
    type: { type: String, default: '' },
    dataSelectFrom: null,
    dataSelectTo: null
  },

  data() {
    return {
      me: null,
      reportData: null,
      medics: null,
      isMedic: null,
      insurers: [],
      selectedInsurer: null,
      selectedConsultant: null,
      language: '',
      selectedDateFrom: null,
      selectedDateTo: null,
      tableNames: [
        {
          TableTitle: 'Consultand',
          firstIndex: 'consultant',
          secondIndex: '',
          width: '155',
          money: false
        },
        {
          TableTitle: 'Insurer',
          firstIndex: 'insurer',
          secondIndex: '',
          width: '155',
          money: false,
          selectedBox: 'insurer'
        },
        {
          TableTitle: 'Claim Id',
          firstIndex: 'hospital_claim_ref',
          secondIndex: '',
          width: '155',
          money: false
        },
        {
          TableTitle: 'Claim Status',
          firstIndex: 'claim_state_str',
          secondIndex: '',
          width: '155',
          money: false
        },
        {
          TableTitle: 'MRN',
          firstIndex: 'patient_mrn',
          secondIndex: '',
          width: '155',
          money: false
        },
        {
          TableTitle: 'Name',
          firstIndex: 'patient_name',
          secondIndex: '',
          width: '155',
          money: false
        },
        {
          TableTitle: 'Admitted',
          firstIndex: 'admission_date',
          secondIndex: '',
          width: '155',
          money: false,
          dateF: true
        },
        {
          TableTitle: 'Discharged',
          firstIndex: 'discharge_date',
          secondIndex: '',
          width: '155',
          money: false,
          dateF: true
        },
        {
          TableTitle: 'Procedure',
          firstIndex: 'procedure',
          secondIndex: '',
          width: '155',
          money: false,
          regex: true
        },

        {
          TableTitle: 'Submitted',
          firstIndex: 'submission_date',
          secondIndex: '',
          width: '170',
          money: false,
          dateF: true
        },
        {
          TableTitle: 'Invoiced Amount',
          firstIndex: 'payment',
          secondIndex: 'invoiced',
          width: '170',
          money: true
        },
        {
          TableTitle: 'Part Paid Amount',
          firstIndex: 'payment',
          secondIndex: 'part_paid',
          width: '170',
          money: true
        },

        {
          TableTitle: 'Payment Date',
          firstIndex: 'payment',
          secondIndex: 'payment_date',
          width: '155',
          money: false,
          dateF: true
        },
        {
          TableTitle: 'Outstanding Amount',
          firstIndex: 'payment',
          secondIndex: 'outstanding',
          width: '190',
          money: true
        },
        {
          TableTitle: 'Payment Status',
          firstIndex: 'claim_payment_status',
          secondIndex: '',
          width: '170',
          money: false
        },
        {
          TableTitle: 'Pended Date',
          firstIndex: 'pended',
          secondIndex: 'date',
          width: '155',
          money: false,
          dateF: true
        },
        {
          TableTitle: 'Pended Reason',
          firstIndex: 'pended',
          secondIndex: 'reason',
          width: '170',
          money: false
        },

        {
          TableTitle: 'Rejected Date',
          firstIndex: 'rejected',
          secondIndex: 'date',
          width: '155',
          money: false,
          dateF: true
        },
        {
          TableTitle: 'Rejected Reason',
          firstIndex: 'rejected',
          secondIndex: 'reason',
          width: '455',
          money: false
        },
        {
          TableTitle: 'Claim Note',
          firstIndex: 'claim_note',
          secondIndex: '',
          width: '455',
          money: false
        }
      ],

      dateRange: null,
      isLoading: true
    };
  },

  watch: {
    dateRange(newVal) {
      this.selectedDateFrom = newVal[0];
      this.selectedDateTo = newVal[1];
      this.fetch_json_report_data();
    }
  },

  computed: {
    title() {
      switch (this.type) {
        case 'submitted':
          return 'Detailed Submitted Report';
        case 'rejected':
          return 'Detailed Rejected Outstanding Report';
        case 'pended':
          return 'Detailed Pended Outstanding Report';
        case 'insurer':
          return 'Detailed Outstanding Report by insurer';
        case 'procedure':
          return 'Detailed Outstanding Report by procedure';
        default:
          return 'Detailed Outstanding Report';
      }
    }
  },

  methods: {
    to_nicedate(date_str) {
      return StringUtility.formatDate(date_str);
    },

    async init() {
      await this.listMedics();
      await this.listInsurerContracts();
      if (this.preselectedConsultant) this.selectedConsultant = this.preselectedConsultant;
      if (this.dataSelectFrom && this.dataSelectTo) {
        this.dateRange = [this.dataSelectFrom, this.dataSelectTo];
        this.selectedDateFrom = this.dataSelectFrom;
        this.selectedDateTo = this.dataSelectTo;
      }
      this.fetch_json_report_data();
    },

    _build_request() {
      let req = {
        consultant_id: this.selectedConsultant?.consultant_id,
        submission_date: new OpenAPIDateRange(
          this.selectedDateFrom ? StringUtility.naiveDateYYYYMMDD(this.selectedDateFrom) : null,
          this.selectedDateTo ? StringUtility.naiveDateYYYYMMDD(this.selectedDateTo) : null
        )
      };
      if (this.selectedInsurer != null) {
        req.insurer_name = this.selectedInsurer;
      }
      return req;
    },

    async _fetch(oapi, request_data) {
      switch (this.type) {
        case 'submitted':
          return await oapi.reportConsulantClaimSubmitted(
            request_data.consultant_id,
            null,
            null,
            null,
            request_data.insurer_name,
            null,
            request_data.submission_date
          );
        case 'rejected':
          return await oapi.reportConsulantClaimOutstandingRejected(
            request_data.consultant_id,
            null,
            null,
            null,
            request_data.insurer_name,
            null,
            request_data.submission_date
          );
        case 'pended':
          return await oapi.reportConsulantClaimOutstandingPended(
            request_data.consultant_id,
            null,
            null,
            null,
            request_data.insurer_name,
            null,
            request_data.submission_date
          );
        case 'insurer':
          return await oapi.reportConsulantClaimOutstandingByInsurer(
            request_data.consultant_id,
            null,
            null,
            null,
            request_data.insurer_name,
            null,
            request_data.submission_date
          );
        case 'procedure':
          return await oapi.reportConsulantClaimOutstandingByProcedure(
            request_data.consultant_id,
            null,
            null,
            null,
            request_data.insurer_name,
            null,
            request_data.submission_date
          );
        default:
          return await oapi.reportConsulantClaimOutstandingByMonth(
            request_data.consultant_id,
            null,
            null,
            null,
            request_data.insurer_name,
            null,
            request_data.submission_date
          );
      }
    },

    async fetch_json_report_data() {
      this.isLoading = true;
      const request_data = this._build_request();
      const oapi = new OpenAPIJSON();
      const response = await this._fetch(oapi, request_data);
      this.isLoading = false;
      this.reportData = response.items;

      if (this.reportData == null) return;
      let startDate = '';
      let endDate = '';
      if (this.selectedDateFrom === null || this.selectedDateFrom === 'null') {
        startDate = '';
      } else {
        startDate = new Date(this.selectedDateFrom); // the start of the desired date range
      }

      if (this.selectedDateTo === null || this.selectedDateTo === 'null') {
        endDate = new Date();
      } else {
        endDate = new Date(this.selectedDateTo); // the start of the desired date range
      }

      if (startDate === '' && endDate !== '') {
        this.filteredClaims = this.reportData.filter(claim => {
          const submissionDate = new Date(claim.submission_date);
          return submissionDate < endDate;
        });
      } else {
        this.filteredClaims = this.reportData.filter(claim => {
          const submissionDate = new Date(claim.submission_date);
          return submissionDate >= startDate && submissionDate <= endDate;
        });
      }

      this.reportData = this.filteredClaims;
      this.isLoading = false;
    },

    async fetch_pdf_report_data() {
      const request_data = this._build_request();
      const oapi = new OpenAPIPDF();
      return await this._fetch(oapi, request_data);
    },

    async fetch_csv_report_data() {
      const request_data = this._build_request();
      const oapi = new OpenAPICSV();
      return await this._fetch(oapi, request_data);
    },

    async listInsurerContracts() {
      /* await api_store.cache
                                .dispatch("INSURERS", {})
                                .then((res) => {
                                        this.insurers = res;
                                })
                                .catch((error) => {
                                        this.$refs.toast.showToast(error, 'fa-solid fa-triangle-exclamation', 'error');
                                }); */
      const oapi = new OpenAPIJSON();
      await oapi
        .list_insurance()
        .then(res => {
          this.insurers = res.items;
        })
        .catch(error => {
          this.$refs.toast.showToast(error, 'fa-solid fa-triangle-exclamation', 'error');
        });
    },
    async listMedics() {
      this.isMedic = userStore.getters.user?.is_medic;

      if (this.isMedic) {
        this.medics = [
          {
            person_id: userStore.getters.user?.person_id,
            username: userStore.getters.user?.username
          }
        ];
        this.selectedConsultant = {
          person_id: userStore.getters.user?.person_id,
          username: userStore.getters.user?.username
        };
      } else {
        await api_store.cache
          .dispatch('LIST_MY_CONSULTANTS', {})
          .then(res => {
            this.medics = res;
            this.medics.forEach(item => (item.consultant_id = item.medic.id));
            this.medics.forEach(item => (item.username = item.medic.ro.full_name));
          })
          .catch(error => {
            this.$refs.toast.showToast(error, 'fa-solid fa-triangle-exclamation', 'error');
          });
      }
    },
    setInsurer(insurerName) {
      this.selectedInsurer = insurerName;
      this.fetch_json_report_data();
    },
    goBack() {
      this.$router.push({
        path: '/navigator/finance/reporting',
        query: {
          personId: this.selectedConsultant?.personId
        }
      });
    }
  },
  mounted() {
    this.language = navigator.language || navigator.userLanguage;
    this.init();
  }
};
</script>

<style lang="scss" scoped>
.bg-blue {
  background-color: #00a7e1;
}

.price {
  justify-content: flex-end;
}

.datePicker {
  display: flex;
  flex-direction: row;
  align-items: center;
}
</style>
