import { createStore } from 'vuex'

// Create a new store instance.
export const notes_store = createStore({
  state () {
    return {
      notesOpened: false
    }
  },
  mutations: {
    toggleNotes (state) {
      state.notesOpened = !state.notesOpened;
    },
    closeNotes (state) {
      state.notesOpened = false;
    }
  },
  actions: {
    toggleNotes({ commit }) {
      commit("toggleNotes");
    },
    closeNotes({ commit }) {
      commit("closeNotes");
    },
  }
})