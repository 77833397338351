<script setup lang="ts">
import { BaseDatePicker, BaseInput, BaseRadioButton, BaseTextarea } from 'ui';
import ClaimSectionTitle from '@/components/admission/ClaimSectionTitle.vue';

const props = defineProps({
  editMode: {
    type: Boolean,
    default: false
  },
  warnings: {
    type: Array,
    default: () => []
  },
  similarIllness: {
    type: Boolean,
    default: null
  },
  otherSource: {
    type: Boolean,
    default: null
  }
});

const sectionHasWarnings = sectionId => {
  if (!props.warnings) return false;
  return props.warnings.some(warning => warning.id === sectionId);
};
</script>

<template>
  <div>
    <ClaimSectionTitle
      id="vhi-illness-history-surgical-title"
      title="History of Illness - for completion by the Patient or Parent/Legal Guardian (if patient is under 18 years of age)"
    />
    <BaseInput
      id="doctor-name"
      name="doctorName"
      class="mt-6"
      label="Name of doctor first attended"
      :edit-mode="editMode"
      :is-warning="sectionHasWarnings('doctor-name')"
    />
    <BaseDatePicker
      id="first-consultation"
      name="firstConsultation"
      class="mt-6 sm:mt-12"
      label="Date of first consultation"
      placeholder="dd.mm.yyyy"
      :edit-mode="editMode"
    />
    <BaseTextarea
      id="doctor-address"
      name="doctorAddress"
      class="mt-6 sm:mt-12"
      label="Doctor's Address"
      :edit-mode="editMode"
      :is-warning="sectionHasWarnings('doctor-address')"
    />
    <BaseDatePicker
      id="first-treatment"
      name="firstTreatment"
      class="mt-6 sm:mt-12"
      label="When was it first made known to you that this particular investigation/treatment (which is the subject of this claim) was required?"
      placeholder="dd.mm.yyyy"
      :edit-mode="editMode"
    />
    <BaseRadioButton
      name="similarIllness"
      class="mt-6 sm:mt-12"
      :options="{
        Yes: true,
        No: false,
        'N/A': null
      }"
      label="Has this patient had this or a similar illness before?"
      :edit-mode="editMode"
    />
    <BaseDatePicker
      v-if="similarIllness"
      id="similar-illness-date"
      name="similarIllnessDate"
      class="mt-6 sm:mt-12"
      :class="[editMode ? 'ml-10 sm:ml-20' : '']"
      label="Date"
      placeholder="dd.mm.yyyy"
      :edit-mode="editMode"
    />
    <BaseTextarea
      v-if="similarIllness"
      id="similar-illness-details"
      name="similarIllnessDetails"
      class="mt-6 sm:mt-12 max-input-width-conditional"
      :class="[editMode ? 'ml-10 sm:ml-20' : '']"
      label="Details"
      :edit-mode="editMode"
      :is-warning="sectionHasWarnings('similar-illness-details')"
    />
    <BaseRadioButton
      name="otherSource"
      class="mt-6 sm:mt-12"
      :options="{
        Yes: true,
        No: false,
        'N/A': null
      }"
      label="Are any of these expenses fully or partially recoverable from any other source?"
      :edit-mode="editMode"
    />
    <BaseTextarea
      v-if="otherSource"
      id="other-source-details"
      name="otherSourceDetails"
      class="mt-6 sm:mt-12 max-input-width-conditional"
      :class="[editMode ? 'ml-10 sm:ml-20' : '']"
      label="Details"
      :edit-mode="editMode"
      :is-warning="sectionHasWarnings('other-source-details')"
    />
  </div>
</template>
