<script setup>
import { ref, computed, watch } from 'vue';
import { OpenAPIJSON } from 'api/openapi';
import { templateMessageStore } from '@/store/templateMessage';
import { convertHtmlToSms, convertSmsToHtml } from '@/util/helper';
import { insurerName } from 'utils';

import Modal from '@/components/modal/Modal';
import BaseEditableCheckbox from './BaseEditableCheckbox.vue';
import BaseWYSIWYG from './BaseWYSIWYG.vue';

const props = defineProps({
  appointmentId: {
    type: Number,
    required: true
  },
  appointmentDate: {
    type: String,
    required: false
  },
  patientClaimId: {
    type: Number,
    required: false
  },
  claimFormType: {
    type: String,
    required: false
  },
  patientInfo: {
    type: Object,
    required: true
  },
  hospitalName: {
    type: String,
    required: false
  },
  fetchClaimFormData: {
    type: Object,
    required: false
  }
});
const emit = defineEmits(['send-form', 'close', 'refresh-data', 'update-patient-claimform']);

const openApi = new OpenAPIJSON();
const options = ref([]);
const tempOptions = ref(null);
const sendClaimType = ref(undefined);
const isEditingText = ref(false);
const isEditingInfo = ref(0);
const isSending = ref(false);
const selectedPatientClaimId = ref(null);
const selectedInsurerName = ref(null);
const selectedHospitalName = ref('');
const optionSelectScreen = ref(true);

watch(selectedHospitalName, hospitalName => {
  if (hospitalName) {
    setOptions(hospitalName);
  }
});

const updatePatientClaimform = async data => {
  if (!data) {
    return;
  }
  const updatedClaimFormData = {
    ...props.fetchClaimFormData,
    patient: {
      ...props.fetchClaimFormData.patient,
      contact: {
        ...props.fetchClaimFormData.patient.contact,
        telephone_mobile: data
      }
    }
  };
  try {
    await openApi.claimform_update(updatedClaimFormData);
    emit('update-patient-claimform', updatedClaimFormData);
  } catch (error) {
    console.error(error);
  }
};

const submitButtonText = computed(() => {
  return optionSelectScreen.value ? 'Review message' : 'Send form to patient';
});
const submitButtonTitleText = computed(() => {
  if (showConfirmButton.value) return null;
  if (isEditingText.value) {
    return 'You have to leave the edit mode to send the message';
  } else {
    return 'You have to select an option how to send the form(s)';
  }
});
const showConfirmButton = computed(() => {
  return (
    sendClaimType.value &&
    !isEditingInfo.value &&
    !isEditingText.value &&
    !isSending.value &&
    currentOption.value?.value
  );
});
const claimTypeText = computed(() => {
  switch (sendClaimType.value) {
    case 'phone':
      return 'SMS';
    case 'email':
      return 'E-mail';
    default:
      return undefined;
  }
});
const currentOption = computed(() => {
  if (sendClaimType.value) {
    return tempOptions.value.find(option => option.type === sendClaimType.value);
  } else {
    return undefined;
  }
});

const setOptions = hospitalName => {
  options.value = [];
  if (templateMessageStore.getters.patientRegistrationSms?.content) {
    options.value.push({
      type: 'phone',
      value: props.patientInfo?.contact?.telephone_mobile || null,
      text: getPhoneText(hospitalName),
      url: '##URL##'
    });
  }
  if (templateMessageStore.getters.patientRegistrationEmail?.content) {
    options.value.push({
      type: 'email',
      value: props.patientInfo?.contact?.email || null,
      text: getEmailText(hospitalName),
      url: '##URL##'
    });
  }
  setClaimType();
};
const setAppointmentDate = appDate => {
  if (!appDate) return 'the date of your admission.';
  const date = new Date(appDate);
  const irelandDateTime = date.toLocaleString('en-IE', {
    year: '2-digit',
    month: '2-digit',
    day: 'numeric',
    hour: '2-digit',
    minute: '2-digit',
    hour12: false
  });
  return irelandDateTime;
};
const getPhoneText = hospitalName => {
  const phoneTemplateText = templateMessageStore.getters.patientRegistrationSms?.content;
  if (!phoneTemplateText) return null;
  return phoneTemplateText
    .replace(/\[PATIENT_NAME\]/g, props.patientInfo.first_name ?? 'Patient')
    .replace(/\[HOSPITAL_NAME\]/g, hospitalName ?? props.hospitalName)
    .replace(/\[APPOINTMENT_DATE\]/g, setAppointmentDate(props.appointmentDate));
};
const getEmailText = hospitalName => {
  const emailTemplateText = templateMessageStore.getters.patientRegistrationEmail?.content;
  if (!emailTemplateText) return null;
  return emailTemplateText
    .replace(/\[PATIENT_NAME\]/g, props.patientInfo.first_name ?? 'Patient')
    .replace(/\[HOSPITAL_NAME\]/g, hospitalName ?? props.hospitalName)
    .replace(/\[APPOINTMENT_DATE\]/g, setAppointmentDate(props.appointmentDate));
};
const setPatientClaimId = async () => {
  if (!props?.patientClaimId && props.appointmentId) {
    const { claim_form_list } = await openApi.claimform_patient_list(props.appointmentId);
    selectedPatientClaimId.value = claim_form_list?.[0]?.claim_patient_id;
    selectedInsurerName.value = insurerName(claim_form_list?.[0]?.claim_form_type);
    const claim_data = await openApi.claimform_get(
      claim_form_list?.[0]?.appointment_id,
      claim_form_list?.[0]?.claim_id,
      claim_form_list?.[0]?.locator?.claim_form_id
    );
    selectedHospitalName.value = claim_data?.hospital_name;
  } else {
    selectedPatientClaimId.value = props.patientClaimId;
    selectedInsurerName.value = insurerName(props.claimFormType);
    selectedHospitalName.value = props.hospitalName;
  }
  setClaimType();
};
const setClaimType = () => {
  tempOptions.value = JSON.parse(JSON.stringify(options.value));
  if (options.value.length === 1) {
    sendClaimType.value = options.value[0].type;
  }
};
const updateUserValue = option => {
  const optionItem = tempOptions.value.find(item => item.type === option.type);
  if (optionItem) {
    optionItem.value = option.value;
  }
  options.value = JSON.parse(JSON.stringify(tempOptions.value));
};
const submitButton = () => {
  if (optionSelectScreen.value) {
    optionSelectScreen.value = false;
  } else {
    sendText();
  }
};
const backToSelectionScreen = () => {
  sendClaimType.value = undefined;
  claimTypeText.value = undefined;
  currentOption.value = undefined;
  optionSelectScreen.value = true;
};
const sendText = async () => {
  isSending.value = true;
  try {
    if (sendClaimType.value === 'phone') {
      await sendSMS();
    } else if (sendClaimType.value === 'email') {
      await sendEmail();
    }
    emit('send-form', { type: claimTypeText.value, success: true });
  } catch (error) {
    emit('send-form', { type: claimTypeText.value, success: false });
  }
  isSending.value = false;
};
const assocData = () => {
  return [
    {
      assoc_type: 'appointment',
      type: 'id',
      appointment_id: props.appointmentId
    },
    {
      assoc_type: 'patientclaim',
      type: 'id',
      id: selectedPatientClaimId.value
    }
  ];
};
const sendSMS = async () => {
  let convertText = convertHtmlToSms(currentOption.value.text);
  convertText += currentOption.value.url;

  await openApi.message_create({
    content: {
      subject: 'Admission Form',
      content: convertText
    },
    recipients: [
      {
        message_recipient: 'sms',
        phone_number: currentOption.value.value
      }
    ],
    assoc: assocData()
  });
};
const sendEmail = async () => {
  const tempElement = document.createElement('div');
  tempElement.innerHTML = currentOption.value.text;
  const anchorUrlElement = document.createElement('a');
  anchorUrlElement.href = currentOption.value.url;
  anchorUrlElement.innerText = currentOption.value.url;
  tempElement.querySelector('p:last-child').append(anchorUrlElement);
  const modifiedText = tempElement.innerHTML;

  await openApi.message_create({
    content: {
      subject: 'Admission Form',
      content: modifiedText
    },
    recipients: [
      {
        message_recipient: 'email',
        email: currentOption.value.value
      }
    ],
    assoc: assocData()
  });
};
const notificationContent = () => {
  if (sendClaimType.value === 'phone') {
    return convertSmsToHtml(currentOption.value.text);
  } else {
    return currentOption.value.text;
  }
};
const saveNewText = text => {
  const optionItem = tempOptions.value.find(item => item.type === sendClaimType.value);
  if (optionItem) {
    optionItem.text = text;
  }
  isEditingText.value = false;
};
const claimTypeChanged = () => {
  if (isEditingText.value) {
    isEditingText.value = false;
  }
};
const optionTitle = option => {
  switch (option) {
    case 'phone':
      return 'Phone no.:';
    case 'email':
      return 'E-mail:';
    default:
      return '';
  }
};

setOptions();
setPatientClaimId();
</script>

<template>
  <Modal class="send-insurer-form" :closeOnClickOutside="false" @close-modal="$emit('close')">
    <template v-slot:header>
      <div class="flex row header mb-7" test-data-id="patient-form-title">Send insurer form to patient</div>
    </template>
    <template v-slot:body>
      <div>
        <span class="font-bold">Patient:</span>
        <span class="ml-1" test-data-id="patient-form-patient-full-name">{{ patientInfo.full_name }}</span>
        <span class="font-bold block mt-7" test-data-id="patient-form-insurer-name">{{ selectedInsurerName }}</span>
      </div>
      <hr class="my-5 border-black" />
      <div v-if="optionSelectScreen" :class="{ 'grid grid-cols-2 gap-6': isEditingInfo }">
        <div :class="[options.length > 1 ? 'mt-3.5' : 'mt-7']">
          <BaseEditableCheckbox
            v-for="option in options"
            v-model="sendClaimType"
            :key="option.type"
            :option="option"
            :isSingleOption="options.length === 1"
            :isEditingText="isEditingText"
            @number-change="updatePatientClaimform"
            @change="claimTypeChanged"
            @set-value="updateUserValue"
            @set-editing="isEditingInfo++"
            @cancel-editing="isEditingInfo--"
          />
        </div>
        <div v-if="isEditingInfo" class="bg-medosync-grey p-4 flex gap-4 justify-self-end max-w-sm h-fit">
          <i class="fa-solid fa-circle-info" />
          <span data-test-id="patient-form-info-text-box" class="font-bold">
            The changed data will only be used to send the SMS or email and will not be transmitted to the hospital
            system.
          </span>
        </div>
      </div>
      <div class="mt-5" v-if="!optionSelectScreen && sendClaimType">
        <div>
          <span class="font-bold">{{ optionTitle(sendClaimType) }}</span>
          <span data-test-id="patient-form-selected-option-text" class="ml-1">{{ currentOption.value }}</span>
        </div>
        <div v-if="!isEditingText">
          <div class="flex justify-between mt-7">
            <span class="font-bold">{{ claimTypeText }} to be sent</span>
            <div data-test-id="patient-form-edit-text" @click="isEditingText = true" class="cursor-pointer">
              <span>(</span>
              <span class="underline">Edit text of this specific {{ claimTypeText }} </span>
              <span>)</span>
            </div>
          </div>
          <div id="wysiwyg-notification-content" class="mt-2 py-2 px-7 preview break-words">
            <div v-html="notificationContent()"></div>
          </div>
        </div>
      </div>
      <BaseWYSIWYG
        class="mt-7"
        v-if="isEditingText"
        :content="currentOption.text"
        :form-url="currentOption.url"
        :claimType="sendClaimType"
        :claimTypeText="claimTypeText"
        @save-content="saveNewText"
        @close="isEditingText = false"
      />
      <div v-if="!options.length" class="text-center text-medosync-red">
        <span data-test-id="patient-form-no-option-text" class="mt-7">No option available to send the form</span>
        <span class="block">Please contact the support.</span>
      </div>
      <div class="flex mt-8 justify-between">
        <div>
          <button
            v-if="!optionSelectScreen"
            test-data-id="patient-form-cancel-left"
            class="cancel px-8"
            :disabled="isSending"
            @click="$emit('close')"
          >
            Cancel
          </button>
        </div>
        <div class="flex gap-5">
          <button
            v-if="optionSelectScreen"
            test-data-id="patient-form-cancel-right"
            class="cancel px-8"
            :disabled="isSending"
            @click="$emit('refresh-data')"
          >
            Cancel
          </button>
          <button
            v-if="!optionSelectScreen && !isEditingText"
            test-data-id="patient-form-back-to-selection"
            class="confirm-reverse px-8"
            @click="backToSelectionScreen"
          >
            Back to selection
          </button>
          <button
            class="confirm px-8"
            test-data-id="patient-form-submit"
            :disabled="!showConfirmButton"
            @click="submitButton"
            :title="submitButtonTitleText"
          >
            {{ submitButtonText }}
          </button>
        </div>
      </div>
    </template>
  </Modal>
</template>

<style lang="scss" scoped>
.send-insurer-form {
  font-size: 16px;
  line-height: 150%;

  .header {
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 150%;
  }

  button {
    height: 48px;
    border-radius: 50px;

    &:hover:enabled {
      opacity: 0.85;
    }

    &.cancel {
      background: #cbd5e0;
    }

    &.confirm {
      background: #00a7e1;
    }

    &.confirm-reverse {
      border: 2px solid #00a7e1;
    }
  }

  .preview {
    background-color: #d9d9d9;
  }
}
</style>
