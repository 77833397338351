<template>
    <div>
      <second-menu-user-management @set-menu-active="setMenuActive" />

      <div class="menu-transition" :class="isActive ? 'main-content-full' : 'main-content-no-menu'">

        <div class="claim-title medo-secondary">
          Hospitals Management Dashboard
        </div>


        <div class="col-4">
          <div class="mt-1">
            <input v-model='searchString' class="custom-input shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md" placeholder="Search Hospital by name or ID">
          </div>
        </div>

        <!--
        <div style="float: right" class="col-7">
          <div class="mt-3">
            <router-link to="create-user">
              <button type="button" class="medo-bg-secondary inline-flex items-center px-3 py-2 border border-transparent text-sm leading-4 font-medium rounded-md shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                Add Hospital
              </button>
            </router-link>
          </div>
        </div>
        -->

        <div style="clear: both"></div>

        <div class="flex flex-col pl-6 pr-6 mt-5 ml-2">
          <div class="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div class="py-2 align-middle inline-block min-w-full">
              <div class="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">

                <table v-if='hospitals' class="min-w-full divide-y divide-gray-200">
                  <thead class="bg-gray-50">
                    <tr>
                      <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                        ID
                      </th>
                      <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                        Name
                      </th>
                      <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                        Type
                      </th>
                      <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                        Country
                      </th>
                      <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider text-center">
                        Office Phone
                      </th>
                      <th scope="col" class="relative px-6 py-3">
                        <span class="sr-only">Edit</span>
                      </th>
                      <th scope="col" class="relative px-6 py-3">
                        <span class="sr-only">Edit</span>
                      </th>
                    </tr>
                  </thead>

                  <tbody>
                    <!-- Odd row -->
                    <tr v-for='(hospital, index) in filteredHospitals' :key='index' class="bg-white">
                      <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                        {{hospital.hospitalId}}
                      </td>
                      <td class="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                        {{hospital.name}}
                      </td>
                      <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                        {{hospital.hospitalType}}
                      </td>
                      <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                        {{hospital.contact[0].country}}
                      </td>
                      <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500 text-center">
                        <span v-if='hospital.contact[0].officePhone'>{{hospital.contact[0].officePhone}}</span>
                        <span v-if='!hospital.contact[0].officePhone'>/</span>
                      </td>
                      <!--
                      <td class="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                        <button type="button" class="medo-bg-secondary inline-flex items-center px-2.5 py-1.5 border border-transparent text-xs font-medium rounded shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                          Deactivate
                        </button>
                      </td>
                      <td class="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                        <router-link :to="" class="text-indigo-600 hover:text-indigo-900 medo-secondary">Edit</router-link>
                      </td>
                      -->
                    </tr>

                  </tbody>
                </table>

              </div>
            </div>
          </div>
        </div>

      </div>
      <ToastComponent ref="toast"></ToastComponent>
    </div>
</template>

<script>
import api from 'api/api'
import SecondMenuUserManagement from '@/components/navigation/SecondMenuUserManagement.vue'
import ToastComponent from '@/components/claim-forms/components/ToastComponent.vue';

export default {
  name: 'Hospitals-dashboard',
  components: {
    SecondMenuUserManagement,
    ToastComponent
  },
  data () {
    return {
      hospitals: null,
      searchString: '',
      isActive: true,
    }
  },
  computed: {
    filteredHospitals () {
      if (this.searchString.toLowerCase() == "") {
        return this.hospitals
      } else {
        return this.hospitals.filter((item) => {
          if ((item.name).toLowerCase().includes(this.searchString.toLowerCase()) || (item.hospitalId).toString().toLowerCase().includes(this.searchString.toLowerCase())) {
            return item
          }
        })
      }
    }
  },
  methods: {
    async listHospitals () {
      await api.listHospitals()
        .then((res) => {
          this.hospitals = res.data.data.listHospitals.hospital
        })
        .catch((error) => {
          this.$refs.toast.showToast(error, 'fa-solid fa-triangle-exclamation', 'error');
        });
    },

    fetchDoctor (doctor) {
      this.doctor = doctor
    },

    setMenuActive (bool) {
      this.isActive = bool;
    },
  },
  created () {
    
  },
  mounted () {
    this.listHospitals()
  }
}
</script>

<style lang='scss' scoped>

  .claim-title  {
    margin-bottom: 30px;
    font-size: 26px;
    font-weight: 600;
  }

  .custom-input {
    height: 40px;
    padding-left: 8px;
    line-height: 40px;
    border: 1px solid #dedede;
  }
</style>