<script setup>
import { ref, computed, watch } from 'vue';
import { userStore } from '@/store/user';
import { AssocType } from 'enums';
import vSelect from 'vue-select';
import CheckboxQuestionReversed from '@/components/form-fields/CheckboxQuestionReversed.vue';

const props = defineProps({
  messageParams: {
    type: Object,
    required: true
  },
  medics: {
    type: Array,
    default: () => []
  }
});
const emit = defineEmits(['filter-messages']);

const isFilterShown = ref(false);
const isFilterEmpty = computed(() => {
  const messagesValueFalse = Object.values(messages.value).some(value => value);
  return !messagesValueFalse && !selectedConsultant.value;
});
const tooltipButtonTitle = computed(() => {
  return isFilterEmpty.value ? 'Select a filter option to active this button.' : null;
});
const closeFilterDropdown = () => {
  messages.value = { ...initialMessages.value };
  selectedConsultant.value = initialSelectedConsultant.value;
  isFilterShown.value = false;
};
const openCloseFilterDropdown = () => {
  isFilterShown.value = !isFilterShown.value;
  if (isFilterShown.value) {
    initialMessages.value = { ...messages.value };
    initialSelectedConsultant.value = selectedConsultant.value;
  }
};
const resetAllFilters = () => {
  selectedConsultant.value = null;
  resetMessageFilters();
  emit('filter-messages', {
    recipients: null,
    assoc: null,
    unread: null
  });
  isFilterShown.value = false;
};

const applyFilterSelection = () => {
  const recipients = consultantValue();
  const assoc = assocValue();
  const unread = unreadMessageValue();

  emit('filter-messages', {
    recipients,
    assoc,
    unread
  });

  isFilterShown.value = false;
};

const messages = ref({
  unread: true,
  read: true,
  assigned: true,
  notAssigned: true
});
const initialMessages = ref({});
// watch values changed in the filter preview
watch(
  () => props.messageParams.unread,
  () => {
    if (props.messageParams.unread === null) {
      messages.value.unread = true;
      messages.value.read = true;
    } else if (props.messageParams.unread) {
      messages.value.unread = true;
      messages.value.read = false;
    } else if (!props.messageParams.unread) {
      messages.value.unread = false;
      messages.value.read = true;
    }
  }
);
// watch values changed in the filter preview
watch(
  () => props.messageParams.assoc,
  () => {
    if (props.messageParams.assoc === null) {
      messages.value.assigned = true;
      messages.value.notAssigned = true;
    } else if (props.messageParams.assoc?.[0].assoc_type === AssocType.ConsultantClaim) {
      if (props.messageParams.assoc[0].type === 'none') {
        messages.value.assigned = false;
        messages.value.notAssigned = true;
      } else if (props.messageParams.assoc[0].type === 'any') {
        messages.value.assigned = true;
        messages.value.notAssigned = false;
      }
    }
  }
);
const resetMessageFilters = () => {
  Object.keys(messages.value).forEach(key => (messages.value[key] = true));
};
const unreadMessageValue = () => {
  if ((messages.value.unread && messages.value.read) || (!messages.value.unread && !messages.value.read)) {
    return null;
  } else if (messages.value.unread) {
    return true;
  } else if (messages.value.read) {
    return false;
  }
};
const assocValue = () => {
  if (messages.value.assigned && messages.value.notAssigned) {
    return null;
  } else if (messages.value.notAssigned) {
    return [
      {
        assoc_type: AssocType.ConsultantClaim,
        type: 'none'
      }
    ];
  } else if (messages.value.assigned) {
    return [
      {
        assoc_type: AssocType.ConsultantClaim,
        type: 'any'
      }
    ];
  }
};

const isMedic = userStore.getters.user?.is_medic;
const selectedConsultant = ref(null);
const initialSelectedConsultant = ref(null);
// watch values changed in the filter preview
watch(
  () => props.messageParams.recipients,
  () => {
    if (!props.messageParams.recipients?.length && selectedConsultant.value) {
      selectedConsultant.value = null;
    }
  }
);
const consultantValue = () => {
  if (selectedConsultant.value) {
    return [
      {
        message_recipient: 'consultantadmin',
        type: 'id',
        id: selectedConsultant.value.consultant_id
      }
    ];
  } else {
    return null;
  }
};
</script>

<template>
  <div class="relative">
    <div class="cursor-pointer" @click="openCloseFilterDropdown" data-test-id="nc-filter-by">
      <i class="fa-solid fa-filter fa-xl pr-2" />
      <span class="underline">Filter by</span>
    </div>
    <div
      v-if="isFilterShown"
      v-click-outside="closeFilterDropdown"
      class="absolute bg-white border border-black p-5 right-0"
      data-test-id="nc-filter-dropdown"
    >
      <div class="flex justify-between items-center pb-5 w-[366px]">
        <span class="font-semibold">Filter messages</span>
        <i
          class="fa-solid fa-times fa-lg pl-2 cursor-pointer"
          @click="closeFilterDropdown"
          data-test-id="nc-close-filter-dropdown"
        />
      </div>
      <div v-if="!isMedic && props.medics.length" class="w-[366px] pb-10">
        <span class="font-bold">Consultant</span>
        <v-select
          label="username"
          placeholder="Select a Consultant"
          :options="props.medics"
          v-model="selectedConsultant"
          data-test-id="message-consultant-select"
        ></v-select>
      </div>
      <div class="pb-7 font-bold">
        <span class="block pb-2.5">Messages</span>
        <div class="grid grid-cols-2 gap-[50px]">
          <CheckboxQuestionReversed
            id="messagesUnread"
            v-model="messages.unread"
            title="Unread"
            data-test-id="nc-filter-unread"
          />
          <CheckboxQuestionReversed
            id="messagesRead"
            v-model="messages.read"
            title="Read"
            data-test-id="nc-filter-read"
          />
        </div>
        <div class="grid grid-cols-2 gap-[50px] pt-6">
          <CheckboxQuestionReversed
            id="messagesAssigned"
            v-model="messages.assigned"
            title="Assigned"
            data-test-id="nc-filter-assigned"
          />
          <CheckboxQuestionReversed
            id="messagesUnassigned"
            v-model="messages.notAssigned"
            title="Not assigned"
            data-test-id="nc-filter-not-assigned"
          />
        </div>
      </div>
      <div class="flex justify-between items-center">
        <span
          class="underline cursor-pointer text-medosync-violet-highlight"
          @click="resetAllFilters"
          data-test-id="nc-reset-filters"
        >
          Reset all filters
        </span>
        <button
          class="rounded-full bg-medosync-blue px-4 py-2"
          :title="tooltipButtonTitle"
          :disabled="isFilterEmpty"
          @click="applyFilterSelection"
          data-test-id="nc-apply-filters"
        >
          Apply selection
        </button>
      </div>
    </div>
  </div>
</template>
