<template>
  <!-- start -->
  <div :id="inputId" class="col-12 mt-5">
    <div v-if="question" class="flex justify-between">
      <label class="block text-sm font-medium text-gray-700">{{ question }}</label>
    </div>
    <div class="mt-1" :title="title">
      <span v-if="textBoxDisabled" class="block w-full text-sm text-gray-500">{{ getOptionText }}</span>

      <div v-else :id="inputId + '_enum'" class="flex flex-wrap mr-5 mt-4 gap-y-2">
        <div v-for="(opt, idx) in options" :key="idx">
          <div class="flex ml-2 items-center">
            <div class="mr-2">
              <input
                :id="inputId + '_enum' + idx"
                v-model="localData"
                :name="inputId"
                :disabled="isTextBoxDisabled"
                :value="opt.value"
                aria-describedby="comments-description"
                type="radio"
                class="h-9 w-9 text-medosync-blue border-medosync-black focus:ring-medosync-blue checked:border-medosync-blue border-2 rounded-full cursor-pointer"
              />
            </div>

            <div class="mr-1 text-sm">
              <label for="comments" class="font-medium text-gray-700">{{ opt.name }}</label>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- end -->
</template>

<script>
/**
 * Component to create a grouping of radio boxes around a question
 *   Yes, No and N/A (null)
 *
 * Properties:
 *   :question: a sentence to describe why there is a Yes/No
 *   :options - supply a list of dict of the anaesthesia application options specific for the
 *     form.  The 'name' appears as the description in the form, the 'value' is the enum value.
 *     e.g.
 *      "[{name: 'General', value: 'GENERAL'}, {name: 'Regional', value: 'REGIONAL'}, {name: 'Monitored', value: 'MONITORED'}, {name: 'N/A', value: null}]"
 *   title: if defined then this will be hover-over text
 */
import { mapState } from 'vuex';
export default {
  name: 'EnumQuestion',
  props: {
    modelValue: { default: '' },
    question: { type: String, required: false },
    options: { required: true, type: Array },
    title: { type: String, required: false },
    addNull: { type: Boolean, required: false },
    id: {}
  },
  emits: ['update:modelValue'],
  data() {
    return {
      inputId: this.id || `enum_question${(Math.random() * 1000).toFixed()}`
    };
  },
  computed: {
    ...mapState(['textBoxDisabled']),
    isTextBoxDisabled() {
      return this.textBoxDisabled;
    },
    localData: {
      get() {
        return this.modelValue;
      },
      set(newValue) {
        this.$emit('update:modelValue', newValue);
      }
    },
    getOptionText() {
      let name = 'N/A';
      this.options.forEach(opt => {
        if (opt.value === this.localData) {
          name = opt.name;
        }
      });
      return name;
    }
  },
  methods: {
    isChecked(option, value) {
      return option.value === value;
    },
    updateValue(v) {
      this.$emit('update:modelValue', v);
    }
  }
};
</script>

<style scoped>
textarea {
  width: 100%;
  border: 1px solid #dedede;
}

.custom-checkbox {
  width: 20px;
  height: 20px;
  cursor: pointer;
}
</style>
