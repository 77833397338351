<template>
  <div :id="inputId" class="form-title accordion" :class="{ 'section-completed': sectionCompleted }">
    {{ title }}
  </div>
</template>

<script>
export default {
  name: 'ClaimSection',
  props: {
    title: { required: true, type: String },
    id: {}
  },
  emits: ['update:modelValue'],

  data() {
    return {
      inputId: this.id || `section_hdr${(Math.random() * 1000).toFixed()}`,
      formActive: false,
      sectionCompleted: false
    };
  }
};
</script>

<style scoped>
.form-title {
  width: 100%;
  height: 50px;
  padding-left: 15px;
  line-height: 50px;
  font-weight: 600;
  border-radius: 5px;
  color: #fff;
  background: #4338ca;
  cursor: pointer;
}
.accordion {
  background-color: #ffffff;
  color: #000000;
  border-radius: 0;
  border-bottom: 1px solid #c8c8c8;
}
</style>
