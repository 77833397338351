<template>
  <div class="add-claim-section">
    
    <div class="relative z-10">

      <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"></div>

      <div class="fixed z-10 inset-0 overflow-y-auto">

        <div class="flex items-end sm:items-center justify-center min-h-full p-4 text-center sm:p-0">
          <div class="custom-claim-modal relative bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-10 sm:max-w-2xl sm:w-full sm:p-6">
            <div>
              <div class="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-indigo-600">
                <!-- Heroicon name: outline/check -->
                <i class="fas fa-file-signature" style="padding-left: 6px;color: #fff"></i>
              </div>
              <div class="mt-3 text-center sm:mt-3">
                <h3 class="text-lg leading-6 font-medium text-gray-900">Add External Invoice Payment</h3>
                <h1 class="text-lg leading-6 font-medium text-gray-900">{{insurerFileName}}</h1>
                <div class="mt-2">
                  <!--
                  <p class="text-sm text-gray-500">Lorem ipsum, dolor sit amet consectetur adipisicing elit. Eius aliquam laudantium explicabo pariatur iste dolorem animi vitae error totam. At sapiente aliquam accusamus facere veritatis.</p>
                  -->

                  <!-- form fields -->

                  <div style="clear: both;"></div>

                  <div class="sub-title mt-3" title="Search existing records to find the patient by MRN or name, which will auto-populate several fields on the form.  Use the MRN field directly if you cannot find the patient here.">
                    Invoice
                  </div>

                  <div style="clear: both;"></div>

                  <div class="float-left col-12 mt-2">
                    <input
                        id="invoice_ref"
                        type="text"
                        v-model="payment.invoice_ref"
                        class="custom-input shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                    />
                  </div>

                  <div style="clear: both;"></div>

                  <div class="sub-title mt-5" title="Search existing records to find the patient by MRN or name, which will auto-populate several fields on the form.  Use the MRN field directly if you cannot find the patient here.">
                    Patient Details
                  </div>

                  <div style="clear: both;"></div>

                  <!-- section -->
                  <div class="col-12 mt-3" title="Enter an MRN for the patient.  Use this field if you cannot find the patient using the patient search.">
                    <div class='col-12 float-left'>
                      <div>
                        <div class="text-left text-sm mb-1">
                          <label for="value" class="font-medium text-gray-700 mr-2">MRN:</label>
                        </div>
                        <div class="col-12">
                          <PatientTypeahead
                            id="patient-mrn"
                            :v-model="patient_mrn"
                            @select-patient="patient_selected"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <!-- section end -->

                  <!-- section -->
                  <div class="col-12 mt-1">
                    <div class='col-6 float-left pr-2'>
                      <div class="mt-2">

                        <div>
                          <div class="text-left text-sm mb-1">
                            <label for="value" class="font-medium text-gray-700 mr-2">First Name:</label>
                          </div>
                          <div class="col-12">
                            <input disabled type="text" v-model="patient_firstname" class="custom-input shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md">
                          </div>
                        </div>

                      </div>
                    </div>

                    <div class='col-6 float-left'>
                      <div class="mt-2">

                        <div>
                          <div class="text-left text-sm mb-1">
                            <label for="value" class="font-medium text-gray-700 mr-2">Last Name:</label>
                          </div>
                          <div class="col-12">
                            <input disabled type="text" v-model="patient_lastname" class="custom-input shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md">
                          </div>
                        </div>

                      </div>
                    </div>
                  </div>
                  <!-- section end -->

                  <!-- section -->
                  <div class="col-12 mt-1">
                    <div class='col-12 float-left pr-2'>
                      <div class="mt-2">
                        <div>
                          <div class="text-left text-sm mb-1">
                            <label for="value" class="font-medium text-gray-700 mr-2">Date of Birth:</label>
                          </div>
                          <div class="col-12">
                            <input disabled type="text" v-model="patient_dob" :key="dobKey" class="custom-input shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md">
                          </div>
                        </div>

                      </div>
                    </div>

                  </div>
                  <!-- section end -->

                  <div style="clear: both;"></div>

                  <!-- start section -->
                  <div class="flex justify-center my-3">
                    <div class="col-4 mx-3">
                      Invoiced Amount
                      <input v-model="payment.pricing.amount_invoiced" class="custom-input shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md">
                    </div>
                    <div class="col-4 mx-3">
                      Pay Amount (Gross)
                      <input v-model="payment.pricing.amount_payable" class="custom-input shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md">
                    </div>

                    <div class="col-4 mx-3">
                      Withholding Tax
                      <input v-model="payment.pricing.withholding_tax" class="custom-input shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md">
                    </div>

                    <div class="col-4 mx-3">
                      Adjustment Amount
                      <input v-model="payment.pricing.adjustment_amount" class="custom-input shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md">
                    </div>
                  </div>
                  <div style="clear: both;"></div>

                  <div class="col-12 mt-2" title="If the patient appointment is not known in the system, you can manually specify it here.">
                    <div class='col-12 float-left'>
                      <div class="mt-2">

                        <div>
                          <div class="text-left text-sm mb-1">
                            <label for="value" class="font-medium text-gray-700 mr-2">Payment Date:</label>
                          </div>
                          <div class="col-6">
                            <CustomDatepicker v-model="payment.paid_on" />
                          </div>
                        </div>

                      </div>
                    </div>
                  </div>
                  <div style="clear: both;"></div>


                  <div class="col-12 mt-2">
                    <div class='col-12 float-left'>
                      <div class="mt-2">

                        <div>
                          <div class="text-left text-sm mb-1">
                            <label for="value" class="font-medium text-gray-700 mr-2">Pended Date:</label>
                          </div>
                          <div class="col-6">
                            <CustomDatepicker v-model="pended.date" />
                          </div>
                        </div>
                        <div>
                          <div class="text-left text-sm mb-1">
                            <label for="value" class="font-medium text-gray-700 mr-2">Pended Reason:</label>
                          </div>
                          <div class="col-6">
                            <input type="text" v-model="pended.reason" class="custom-input shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md" />
                          </div>
                        </div>

                      </div>
                    </div>
                  </div>
                  <div style="clear: both;"></div>

                  <div class="col-12 mt-2">
                    <div class='col-12 float-left'>
                      <div class="mt-2">

                        <div>
                          <div class="text-left text-sm mb-1">
                            <label for="value" class="font-medium text-gray-700 mr-2">Rejected Date:</label>
                          </div>
                          <div class="col-6">
                            <CustomDatepicker v-model="rejected.date" />
                          </div>
                        </div>
                        <div>
                          <div class="text-left text-sm mb-1">
                            <label for="value" class="font-medium text-gray-700 mr-2">Rejected Reason:</label>
                          </div>
                          <div class="col-6">
                            <input type="text" v-model="rejected.reason" class="custom-input shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md" />
                          </div>
                        </div>

                      </div>
                    </div>
                  </div>
                  <div style="clear: both;"></div>

                  <!-- end section -->

                </div>
              </div>
            </div>
            <div class="mt-12 sm:mt-12 sm:grid sm:grid-cols-2 sm:gap-3 sm:grid-flow-row-dense">
              <button @click="definePayment" :disabled="!isValid" type="button" class="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-indigo-600 text-base font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:col-start-2 sm:text-sm">Add Payment</button>
              <button @click="$emit('close-modal')" type="button" class="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:col-start-1 sm:text-sm">Cancel</button>
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
// components
import '@/assets/css/typeahead.css';
import StringUtility from "utils/stringUtility";
import PatientTypeahead from "@/components/form-fields/PatientTypeahead.vue";
import CustomDatepicker from "@/components/claim-forms/components/Datepicker.vue";
import { OpenAPIJSON } from "api/openapi";

export default {
name: 'AddExternalPayment',
components: {CustomDatepicker, PatientTypeahead},
props: {
  selectedConsultant: {
    required: true,
    default: null
  },
  insurerFileId: { required: true, type: Number },
  insurerFileName: { required: true, type: String },
  insurerId: { required: true, type: Number },
  preselectedInvoiceId: {
    default: null
  }
},
emits: ['paymentAdded', 'close-modal'],
data () {
  return {
    invoice_id: "",
    insurers: [],
    selectedInsurer: "",

    mrn: null,
    firstName: null,
    lastName: null,
    dob: null,
    dobKey: 0,
    appointmentId: null,

    successClaim: false,

    patient_mrn: null,
    patient_firstname: null,
    patient_lastname: null,
    patient_dob: null,
    pended: {
      date: null,
      reason: null,
    },
    rejected: {
      date: null,
      reason: null,
    },
    payment: {
      insurer: {
        type: "id",
        id: this.insurerId,
      },
      consultant_file: {
        file_name: this.insurerFileName,
        assoc: {
          assoc_type: "consultant",
          type: "id",
          id: this.selectedConsultant.consultant_id,
        }
      },
      pricing: {
        amount_invoiced: "0.00",
        amount_payable: "0.00",
        withholding_tax: "0.00",
        adjustment_amount: "0.00",
      },
      invoice_ref: "",
      paid_on: null,
      patient_dob: null,
      patient_name: null,
      pended: null,
      rejected: null,
      state: {
        state_id: "external_invoice"
      },
    },
    api: new OpenAPIJSON(),
  }
},
computed: {
  dobFormatted () {
    return StringUtility.formatDate(this.dob);
  },
  isValid() {
    return (this.payment.pricing.amount_invoiced > 0
    && this.payment.pricing.amount_payable > 0
    && this.payment.pricing.withholding_tax > 0
    && this.payment.invoice !== "")
  }
},
watch: {
  dob () {
    this.dobKey++
  },
  payAmount(new_val) {
    if (new_val === '') {
      this.payment.withholdingTax = '';
    } else {
      this.payment.withholdingTax = (parseFloat(new_val) * 0.20).toFixed(2);
    }
  },
  withholdingTax(new_val) {
    if (new_val === '') {
      this.payment.payAmount = '';
    }
  },
},
mounted () {
  document.addEventListener('keyup', this.keyListener);
},
beforeUnmount () {
  document.removeEventListener('keyup', this.keyListener);
},
methods: {
  patient_selected(item) {
    console.log("chosen patient: ", item);
    this.patient_firstname = item.first_name;
    this.patient_lastname = item.last_name;
    this.patient_mrn = item.mrn;
    this.patient_dob = item.dob;
    this.payment.patient_dob = item.dob;
    this.payment.patient_name = item.first_name + ' ' + item.last_name;
  },
  keyListener(event) {
    if (event.defaultPrevented) {
      return;
    }

    if (event.key === 'Enter') {
      this.definePayment();
    }
  },
  resetPayment () {
    window.scrollTo(0, 0);
    
    this.selectedInvoiceId = ""
    this.selectedInsurer = ""

    this.mrn = null
    this.firstName = null
    this.lastName = null
    this.dob = null
    this.appointmentId = null

    this.payAmount = null
    this.withholdingTax = null
    this.adjustmentAmount = null

    this.successClaim = false
  },

  async definePayment () {
    console.log("new payment: ", this.payment);
    let req = this.payment;
    if (this.patient_mrn !== null) {
      req.membership_no = this.patient_mrn;
    } else if (this.patient_firstname !== null && this.patient_lastname !== null && this.patient_dob !== null) {
      req.patient_name = this.patient_firstname + " " + this.patient_lastname;
      req.patient_dob = this.patient_dob;
    }
    if (this.rejected.date && this.rejected.reason) {
      req.rejected = this.rejected;
      req.pended = null;
    } else if (this.pended.date && this.pended.reason) {
      req.pended = this.pended;
      req.rejected = null;
    } else {
      req.rejected = null;
      req.pended = null;
    }
    try {
      await this.api.claim_statement_add(req);
      this.$toast.success("External invoice payment added");
    } catch (e) {
      this.$toast.error(e)
    }
  }
},
}
</script>

<style lang="scss" scoped>
button:disabled {
  opacity: 0.4;
  cursor: unset;
}
.clickable {
  text-decoration: underline;
  color: #23A6F0;
  cursor: pointer;
}
.custom-claim-modal {
  padding-bottom: 75px;
}

.app-table-head {
  padding-bottom: 3px;
  font-size: 14px;
  font-weight: 600;
  font-style: italic;
  border-bottom: 1px solid #eee;
  overflow: auto;
}

.app-table-li {
  overflow: auto;
  padding-top: 5px;
  padding-bottom: 3px;
  border-bottom: 1px solid #eee;
  font-weight: 600;
}

.sub-title {
  text-align: left;
  font-size: 18px;
  font-weight: 400;
  font-style: italic;
  color: #0054ab;
}

.required {
  color: red;
}

</style>