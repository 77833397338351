import { createStore } from 'vuex';
import { OpenAPIJSON } from 'api';

export const userStore = createStore({
  state() {
    return {
      user: {}
    };
  },
  getters: {
    user: state => state.user,
    isFeatureAllowed: state => feature => {
      const superUserId = parseInt(import.meta.env.VITE_TEST_SUPERUSER_ID ?? -1);
      return state.user?.acls?.includes(feature) || (superUserId && state.user?.person_id === superUserId);
    }
  },
  mutations: {
    async getUserInfo(state, user) {
      state.user = user;
      state.user.is_medic = user.medic;
    }
  },
  actions: {
    async getUserInfo({ commit }) {
      try {
        const openApi = new OpenAPIJSON();
        const user = await openApi.me();
        commit('getUserInfo', user);
      } catch (error) {
        new Error(error);
      }
    },
    isFeatureAllowed({ commit }, feature) {
      commit('isFeatureAllowed', feature);
    }
  }
});
