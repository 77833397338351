<script setup>
import { defineComponent, ref, computed, onMounted, nextTick } from 'vue';
import { useField } from 'vee-validate';
import InputErrorMessage from './InputErrorMessage.vue';

defineComponent({ InputErrorMessage });

const props = defineProps({
  id: {
    type: String,
    required: true
  },
  name: {
    type: String,
    required: true
  },
  label: {
    type: String,
    default: ''
  },
  placeholder: {
    type: String,
    default: ''
  },
  required: {
    type: Boolean,
    default: false
  },
  editMode: {
    type: Boolean,
    default: true
  },
  isWarning: {
    type: Boolean,
    default: false
  },
  inputListLength: {
    type: Number,
    default: 1
  },
  enableValidation: {
    type: Boolean,
    default: true
  }
});

const { value, setValue, errorMessage } = useField(() => props.name);
var inputPlaceholders = Array.from({ length: props.inputListLength }, (_, i) => `${props.placeholder} ${i + 1}`);

const updateValueOnInputChange = () => {
  const inputs = document.getElementById(props.id)?.querySelectorAll('input');
  if (!inputs) return;
  var inputArray = [];
  inputs.forEach(element => {
    inputArray.push(element.value ? element.value : '');
  });
  setValue(inputArray);
};

const addInputEventListeners = () => {
  const inputs = document.getElementById(props.id)?.querySelectorAll('input');
  if (!inputs?.length) return;

  inputs.forEach(input => {
    input.addEventListener('input', updateValueOnInputChange);
  });
};

const populateInputsFromValue = () => {
  const inputs = document.getElementById(props.id)?.querySelectorAll('input');
  if (!inputs) return;
  const initialValues = value?.value ?? [];
  inputs.forEach((input, index) => {
    input.value = initialValues[index] || '';
  });
};

const formattedInputs = computed(() => {
  const initialValues = value?.value ?? [];
  const inputFields = initialValues.filter(field => field !== '');
  if (inputFields) {
    var string = '';
    for (let i = 0; i < inputFields.length; i++) {
      string += inputFields[i];
      if (inputFields[i] === '') continue;
      string += i < inputFields.length - 1 ? ', ' : '';
    }
    return string;
  }
  return '';
});

onMounted(() => {
  populateInputsFromValue();
  addInputEventListeners();
});
</script>

<template>
  <div :id="id + '-container'">
    <div class="flex">
      <label
        :for="name"
        class="text-sm sm:text-base font-bold"
        :class="{
          'text-medosync-dark-orange': isWarning && editMode,
          'text-medosync-red': enableValidation && errorMessage
        }"
      >
        {{ label }}
        <i v-if="isWarning && editMode" class="fal fa-circle-exclamation mx-1 text-medosync-dark-orange"></i>
      </label>
    </div>
    <div :id="id" v-show="editMode" class="flex flex-col mt-1">
      <div v-for="index in inputListLength" class="flex items-center mb-2 last:mb-0">
        <input
          :id="`${id}-${index}`"
          class="focus:ring-medosync-blue focus:border-medosync-blue block w-full rounded-md h-12 max-input-width"
          :class="{
            'border-medosync-dark-orange': isWarning && editMode,
            'border-medosync-red focus:ring-medosync-red focus:border-medosync-red': enableValidation && errorMessage
          }"
          :placeholder="placeholder && placeholder !== '' ? inputPlaceholders[index - 1] : ''"
        />
      </div>
    </div>
    <div v-show="!editMode" class="mt-1">
      <p :id="id + '-readonly'" class="text-lg text-gray-500 ml-4 mr-6">
        {{ value === '' || value === undefined || value === null ? 'N/A' : formattedInputs }}
      </p>
    </div>
    <InputErrorMessage v-if="enableValidation && errorMessage"
      >Please enter your {{ label.toLowerCase().slice(0, -1) }}</InputErrorMessage
    >
  </div>
</template>
