// update invoice data

// create acl group
const createAclGroup = {
  query: `mutation ($name: String!, $desc: String!) {
      createAclGroup(input: {
          name: $name, 
          description: $desc
        }
      ) {
          aclGroupId
        }
      }`,
};

// set user's acl gorup
const setUserAclGroup = {
  query: `mutation ($hospitalId: Int!, $loginId: Int!, $aclGroupId: Int!) {
      setUserAclGroup(input: {
        hospital: {hospitalId: $hospitalId}
        account: {loginId: $loginId},
        aclGroup: {aclGroupId: $aclGroupId}
      })
      {success}
    }`,
};

//add acl to group
const addAclToGroup = {
  query: `mutation ($aclGroupId: Int!, $aclId: Int!, $canRead: Boolean!, $canWrite: Boolean!) {
      addAclToGroup(input: {
        aclGroup: {aclGroupId: $aclGroupId},
        aclId: $aclId,
        canRead: $canRead,
        canWrite: $canWrite
      })
      {success}
    }`,
};

//add acl to group
const removeAclFromGroup = {
  query: `mutation ($aclGroupId: Int!, $aclIds: [Int!]) {
      removeAclFromGroup(input: {
        aclGroup: {aclGroupId: $aclGroupId},
        aclIds: $aclIds
      })
      {success}
    }`,
};

//assign login to person with email and personId
const assignLoginToPersonWithEmail = {
  query: `mutation ($email: String!, $personId: Int!) {
      assignLoginToPerson(input: {
        account: {email: $email},
        person: {personId: $personId}
      })
      {success}
    }`,
};


//update login
const updateLogin = {
  query: `mutation ($updateLoginInput: UpdateLoginInput!) {
      updateLogin(input: $updateLoginInput)
      {success}
    }`,
};

//set new password for user
const setUserPassword = {
  query: `mutation ($newPasswordLogin: SetUserPasswordInput!) {
      setUserPassword(input: $newPasswordLogin)
      {success}
    }`,
};

const setUserPasswordWithOldPassword = {
  query: `mutation ($newPasswordLogin: SetUserPasswordInput!) {
      setUserPassword(input: $newPasswordLogin)
      {success}
    }`,
};

//deactivate user
const deactivateUser = {
  query: `mutation ($hospitalId: Int!, $hospitalGroupId: Int!, $email: String!, $loginId: Int!) {
      deactivateUser(input: {
        hospital: {hospitalId: $hospitalId, hospitalGroupId: $hospitalGroupId},
        account: {email: $email, loginId: $loginId}
      })
      {success}
    }`,
};

//activate user
const activateUser = {
  query: `mutation ($hospitalId: Int!, $hospitalGroupId: Int!, $email: String!, $loginId: Int!) {
      activateUser(input: {
        hospital: {hospitalId: $hospitalId, hospitalGroupId: $hospitalGroupId},
        account: {email: $email, loginId: $loginId}
      })
      {success}
    }`,
};

const modifyRemittance = {
  query: `mutation ($personId: Int, $insurerRemittances: [RemittanceItemInput!], $manualRemittances: [RemittanceItemInput!]) {
      modifyRemittance(input: {
        consultant: {personId: $personId}
        insurerRemittances: $insurerRemittances
        manualRemittances: $manualRemittances
      })
      {success}
    }`,
};

const cloneInsurerContract = {
  query: `mutation ($contractId: Int!, $newContractName: String!, $forInsurer: Int!, $startDate: NaiveDate!) {
      cloneInsurerContract(input: {
        contractId: $contractId
        newContractName: $newContractName
        forInsurer: {insurerId: $forInsurer}
        startDate: $startDate
      }) {
        contractId
      }
    }`,
};

const updateInvoiceState = {
  query: `mutation ($invoiceId: Int!, $stateId: Int!) {
      setInvoiceState(input: {
        invoiceId: $invoiceId
        stateId: $stateId
      }) {
        success
      }
    }`,
};

export {
  createAclGroup,
  setUserAclGroup,
  addAclToGroup,
  removeAclFromGroup,
  assignLoginToPersonWithEmail,
  updateLogin,
  setUserPassword,
  setUserPasswordWithOldPassword,
  deactivateUser,
  activateUser,
  modifyRemittance,
  cloneInsurerContract,
  updateInvoiceState,
};
