<script setup lang="ts">
import { BaseDatePicker, BaseTextarea, BaseRadioButton } from 'ui';
import ClaimSectionTitle from '@/components/admission/ClaimSectionTitle.vue';

const props = defineProps({
  editMode: {
    type: Boolean,
    default: false
  },
  warnings: {
    type: Array,
    default: () => []
  },
  accidentFault: {
    type: Boolean,
    default: null
  },
  solicitor: {
    type: Boolean,
    default: null
  }
});

const sectionHasWarnings = sectionId => {
  if (!props.warnings) return false;
  return props.warnings.some(warning => warning.id === sectionId);
};
</script>

<template>
  <div>
    <ClaimSectionTitle id="laya-accident-details-title" title="Accident Details" />
    <BaseDatePicker
      id="injury-date"
      name="injuryDate"
      class="mt-6"
      label="Date of Accident/Injury"
      placeholder="dd.mm.yyyy"
      :edit-mode="editMode"
    />
    <BaseTextarea
      id="injury-place"
      name="injuryPlace"
      class="mt-6 sm:mt-12"
      label="Place where accident/injury occurred?"
      :edit-mode="editMode"
      :is-warning="sectionHasWarnings('injury-place')"
    />
    <BaseTextarea
      id="injury-description"
      name="injuryDescription"
      class="mt-6 sm:mt-12"
      label="How accident/injury occurred"
      :edit-mode="editMode"
      :is-warning="sectionHasWarnings('injury-description')"
    />
    <BaseRadioButton
      name="causedByThirdParty"
      class="mt-6 sm:mt-12"
      :options="{
        Yes: true,
        No: false,
        'N/A': null
      }"
      label="Was this accident/injury due to the fault of another party?"
      :edit-mode="editMode"
    />
    <BaseTextarea
      v-if="accidentFault"
      id="third-party-details"
      name="thirdPartyDetails"
      class="mt-6 sm:mt-12 max-input-width-conditional"
      :class="[editMode ? 'ml-10 sm:ml-20' : '']"
      label="If yes please provide the name & address of the person, company or public body responsible"
      :edit-mode="editMode"
      :is-warning="sectionHasWarnings('third-party-details')"
    />
    <BaseTextarea
      v-if="accidentFault"
      id="third-party-insurer"
      name="thirdPartyInsurer"
      class="mt-6 sm:mt-12 max-input-width-conditional"
      :class="[editMode ? 'ml-10 sm:ml-20' : '']"
      label="Please provide the name of their insurance company?"
      :edit-mode="editMode"
      :is-warning="sectionHasWarnings('third-party-liability')"
    />
    <BaseRadioButton
      name="pursueLegalClaim"
      class="mt-6 sm:mt-12"
      :options="{
        Yes: true,
        No: false,
        'N/A': null
      }"
      label="Are you claiming these expenses through a Solicitor?"
      :edit-mode="editMode"
    />
    <BaseTextarea
      v-if="solicitor"
      id="solicitor-name"
      name="solicitorName"
      class="mt-6 sm:mt-12 max-input-width-conditional"
      :class="[editMode ? 'ml-10 sm:ml-20' : '']"
      label="Name of solicitor"
      :edit-mode="editMode"
      :is-warning="sectionHasWarnings('solicitor-name')"
    />
    <BaseTextarea
      v-if="solicitor"
      id="solicitor-address"
      name="solicitorAddress"
      class="mt-6 sm:mt-12 max-input-width-conditional"
      :class="[editMode ? 'ml-10 sm:ml-20' : '']"
      label="Address of solicitor"
      :edit-mode="editMode"
      :is-warning="sectionHasWarnings('solicitor-address')"
    />
    <BaseRadioButton
      name="pursuePiabClaim"
      class="mt-6 sm:mt-12"
      :options="{
        Yes: true,
        No: false,
        'N/A': null
      }"
      label="Or through a Personal Injuries Assesment Board?"
      :edit-mode="editMode"
    />
  </div>
</template>
