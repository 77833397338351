<template>
  <div>
    <div v-if="question" class='col-6 float-left'>
      <div class="flex justify-between">
        <label class="block text-sm font-medium text-gray-700">{{ question }}</label>
      </div>
    </div>

    <div :id="inputId" class="consultant-services-section">
      <div class="col-12">

        <div class="flex flex-col">
          <div class="my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div class="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
              <div class="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">

                <table class="min-w-full divide-y divide-gray-200">

                  <thead class="bg-gray-50">
                    <tr>
                      <th scope="col"
                        class="px-2 py-3 text-left text-xs font-bold text-gray-500 uppercase tracking-wider">
                        Consultant Name
                      </th>
                      <th v-if="enableDate" scope="col"
                        class="px-2 py-3 w-80 text-left text-xs font-bold text-gray-500 uppercase tracking-wider">
                        Date Attendance Requested
                      </th>
                      <th scope="col"
                        class="px-2 py-3 text-left text-xs font-bold text-gray-500 uppercase tracking-wider">

                      </th>
                    </tr>
                  </thead>

                  <tbody>
                    <!-- Grey/white alternate rows -->
                    <tr :id="inputId + '_bed_row'" :class="{ 'bg-white': idx % 2 === 0, 'bg-gray-50': idx % 2 !== 0 }"
                      v-for="(svc, idx) in localData.other_services" :key="svc.othSvcId">
                      <td class="px-2 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                        <ListMedics :id="inputId + '_cons_name' + idx" v-model='svc.consultant' :enable_consultant="true" hint="Search by full-name" />
                      </td>
                      <td v-if="enableDate" class="py-4 whitespace-nowrap text-sm text-gray-500">
                        <date-picker :id="inputId + '_start_time' + idx" v-model:value="svc.on"
                          value-type="YYYY-MM-DD" format="DD-MM-YYYY" :disabled="isTextBoxDisabled" />
                        <!-- <CustomDatepicker class="mb-4" :id="inputId + '_start_time' + idx" v-model="svc.dateRequested" /> -->
                      </td>

                      <td v-if="!isTextBoxDisabled" :id="inputId + '_remove_svc' + idx"
                        class="px-2 py-4 whitespace-nowrap text-sm text-gray-500 font-semibold"
                        @click='removeOtherConsultantService(idx, localData.other_services)'>
                        <i class="fas fa-minus-circle text-red-600"></i> Remove
                      </td>
                      <td v-else :id="inputId + '_remove_svc' + idx"
                        class="px-2 py-4 whitespace-nowrap text-sm text-gray-500 font-semibold"
                        >
                        <i class="fas fa-minus-circle text-grey-600"></i> Remove
                      </td>
                    </tr>

                  </tbody>

                </table>

                <div class='col-6 mt-4 pl-5'>
                  <div v-if="!isTextBoxDisabled" :id="inputId + '_add_svc'" class="my-3 custom-click cursor-pointer font-semibold"
                    @click='addOtherConsultantService(localData)'>
                    <i class="fas fa-plus-circle text-green-600"></i> Add another Consultant Service
                  </div>
                  <div v-else :id="inputId + '_add_svc'" class="my-3 custom-click cursor-pointer font-semibold text-gray-200"
                    >
                    <i class="fas fa-plus-circle text-gray-200"></i> Add another Consultant Service
                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>

      </div>

    </div>
  </div>
</template>

<script>
import ListMedics from "@/components/form-fields/List-medics.vue";
//import CustomDatepicker from "@/components/claim-forms/components/Datepicker.vue";
import DatePicker from 'vue-datepicker-next';
import 'vue-datepicker-next/index.css';
import { mapState, mapMutations } from 'vuex';

export default {
  name: "ConsultantServices",
  components: { ListMedics, DatePicker },
  props: {
    modelValue: { default: "" },
    question: { type: String, required: false },
    id: {},
    enableDate: { type: String, required: false },
  },
  data() {
    return {
      osvcIdx: -1,
      inputId: this.id || `cons_svc${(Math.random() * 1000).toFixed()}`,
    }
  },
  computed: {
    ...mapState(['textBoxDisabled']),
    isTextBoxDisabled() {
      return this.textBoxDisabled;
    },
    localData: {
      get() {
        return this.modelValue
      },
      set(newValue) {
        this.$emit('update:modelValue', newValue)
      }
    }
  },
  methods: {
    ...mapMutations(['setTextBoxDisabled']),
    addOtherConsultantService(treatments) {
      // Fake, negative diagnosis ids created here to allow new entries to be removed.
      // Submission to server ignores this id and creates its own.
      treatments.other_services.push({
        othsvcId: this.osvcIdx,
        consultant: {
          id: null,
          ro: {
            consultant_id: null,
            full_name: null
          },
          type: 'id',
        },
        on: null,
      });
      this.osvcIdx -= 1;
    },
    removeOtherConsultantService(index, treatments) {
      const treatmentIndex = treatments[index];
      if (treatmentIndex) {
        treatments.splice(index, 1);
      }
    },
  }
}
</script>

<style scoped></style>