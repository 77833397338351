<template>
  <div class="custom-menu-section uppercase flex-1 flex flex-col min-h-0 pt-8 menu-transition" :class="{'custom-menu-collapse': !isActive}">

    <div class="collapse-menu-off" @click="toggleMenu">
      <i v-show='!isActive' class="fas fa-chevron-right"></i>
      <i v-show="isActive" class="fas fa-chevron-left"></i>
    </div>

    <div v-show="isActive" class="flex-1 flex flex-col overflow-y-auto">

      <nav class="mt-5 flex-1 px-2 pt-0 space-y-1" aria-label="Sidebar">
        
          <router-link to='/user-management/manage-users' class="flex items-center p-2 pl-4 rounded-lg">
            <span class="flex flex-1 justify-between items-center">
              Manage Users<i class="fas fa-users"></i>
            </span>
          </router-link>

          <router-link to='/user-management/groups' class="flex items-center p-2 pl-4 rounded-lg">
            <span class="flex flex-1 justify-between items-center">
              Manage Groups<i class="fas fa-users-viewfinder"></i>
            </span>
          </router-link>

          <!-- <router-link to='/user-management/procedure-codes' class="flex items-center p-2 pl-4 rounded-lg text-indigo-200 hover:bg-indigo-700">
          <span class="flex-1">
            <i class="fas fa-barcode-alt mr-3 text-xl"></i> Manage Procedure Codes
          </span>
          </router-link>

          <router-link to='/settings/tags' class="flex items-center p-2 pl-4 rounded-lg text-indigo-200 hover:bg-indigo-700">
          <span class="flex-1">
            <i class="fas fa-tags mr-3 text-xl"></i> Manage Tags
          </span>
          </router-link> -->

          <!--
          <router-link to='/user-management/patients' class="flex items-center p-2 pl-4 rounded-lg text-indigo-200 hover:bg-indigo-700">
          <span class="flex-1">
            <i class="fas fa-user-injured mr-3 text-xl"></i> Patients
          </span>
          <span class="medo-bg-secondary ml-3 inline-block py-0.5 px-3 text-xs font-medium rounded-full">
            200
          </span>
          </router-link>
          -->
<!-- 
          <router-link to='/user-management/hospitals' class="flex items-center p-2 pl-4 rounded-lg text-indigo-200 hover:bg-indigo-700">
          <span class="flex-1">
            <i class="fas fa-hospital mr-3 text-xl"></i> Hospitals
          </span>
          </router-link>
-->
<!--  
          <router-link to='/user-management/create-user' class="flex items-center p-2 pl-4 rounded-lg text-indigo-200 hover:bg-indigo-700">
          <span class="flex-1">
            <i class="fas fa-user-plus mr-3 text-xl"></i> Create User
          </span>
          </router-link>
-->
          


          <!--
          <span class="medo-bg-secondary ml-3 inline-block py-0.5 px-3 text-xs font-medium rounded-full">
            200
          </span>
          -->
          

      </nav>
    </div>

  </div>
</template>

<script>

export default {
name: 'SecondMenuUserManagement',
components: {
  
},
data () {
  return {
    isActive: true
  }
},
methods: {
  toggleMenu () {
    this.isActive = !this.isActive;
    this.$emit('set-menu-active', this.isActive)
  }
},
mounted () {

}
}
</script>

<style lang='scss' scoped>

.custom-menu-section {
  float: left;
  width: 210px;
  height: 100vh;
  background-color: #EDEDED;
  color: #0C276C;
  font-family: 'Roboto';
  font-size: 14px;
  font-weight: 600;
}

.custom-menu-collapse {
  width: 30px;
}

.collapse-menu {
  padding-top: 20px;
  padding-left: 10px;
  text-align: left;
  cursor: pointer;

  &:hover {
    opacity: 0.65;
  }
}

.collapse-menu-off {
  padding-top: 20px;
  padding-left: 10px;
  text-align: left;
  cursor: pointer;

  &:hover {
    opacity: 0.65;
  }
}
</style>