<template>
    <div>
      <second-menu v-if='menuData' :menuData='menuData' />

      <div class="main-content">

        <div class="claim-title medo-secondary">
          User Management Dashboard
        </div>


      </div>

    </div>
</template>

<script>
import SecondMenu from '@/components/navigation/SecondMenu.vue'

export default {
  name: 'User-dashboard',
  components: {
    SecondMenu
  },
  data () {
    return {
      menuData: [
        {name: 'Doctors', link: '/user-management/doctors', count: null, icon: 'fas fa-user-md mr-3 text-xl'},
        {name: 'Patients', link: '/user-management/patients', count: null, icon: 'fas fa-user-injured mr-3 text-xl'}
      ]
    }
  },
  methods: {

  },
  mounted () {

  }
}
</script>

<style scoped>

</style>