<template>
  <div v-if="notes.length <= deleteCount" class="mb-3">
    <div class="flex justify-center items-center">No Internal Notes</div>
  </div>

  <ul v-if="notes.length > 0">
    <Note v-for="(note, index) in notes" :key="index" :note="note" :claim="claim" @add-delete-count="addDeleteCount" />
  </ul>
</template>

<script>
import Note from './Note.vue';
export default {
  components: { Note },
  props: {
    notes: { required: true },
    claim: { required: true }
  },
  watch: {
    notes() {
      this.deleteCount = 0;
    }
  },
  data() {
    return {
      deleteCount: 0
    };
  },
  methods: {
    addDeleteCount() {
      this.deleteCount++;
    }
  }
};
</script>

<style lang="scss" scoped></style>
