<script setup lang="ts">
import { BaseDatePicker, BaseInput, BaseTextarea, BaseRadioButton } from 'ui';
import ClaimSectionTitle from '@/components/admission/ClaimSectionTitle.vue';

const props = defineProps({
  editMode: {
    type: Boolean,
    default: false
  },
  warnings: {
    type: Array,
    default: () => []
  }
});

const sectionHasWarnings = sectionId => {
  if (!props.warnings) return false;
  return props.warnings.some(warning => warning.id === sectionId);
};
</script>

<template>
  <div>
    <ClaimSectionTitle id="irish-life-accident-details-title" title="Accident Details" />
    <BaseDatePicker
      id="injury-date"
      name="injuryDate"
      class="mt-6"
      label="Date of Accident/Injury"
      placeholder="dd.mm.yyyy"
      :edit-mode="editMode"
    />
    <BaseInput
      id="injury-place"
      name="injuryPlace"
      class="mt-6 sm:mt-12"
      label="Place of accident/injury"
      :edit-mode="editMode"
      :is-warning="sectionHasWarnings('injury-place')"
    />
    <BaseTextarea
      id="injury-description"
      name="injuryDescription"
      class="mt-6 sm:mt-12"
      label="Brief description of how accident/injury occurred"
      :edit-mode="editMode"
      :is-warning="sectionHasWarnings('injury-description')"
    />
    <BaseInput
      id="third-party-details"
      name="thirdPartyDetails"
      class="mt-6 sm:mt-12"
      label="Name of person, company or public body responsible"
      :edit-mode="editMode"
      :is-warning="sectionHasWarnings('third-party-details')"
    />
    <BaseRadioButton
      name="pursueLegalClaim"
      class="mt-6 sm:mt-12"
      :options="{
        Yes: true,
        No: false,
        'N/A': null
      }"
      label="Do you plan to pursue a claim against a third party?"
      :edit-mode="editMode"
    />
    <BaseTextarea
      id="solicitor-name"
      name="solicitorName"
      class="mt-6 sm:mt-12"
      label="Name of your solicitor (where applicable)"
      :edit-mode="editMode"
      :is-warning="sectionHasWarnings('solicitor-name')"
    />
    <BaseTextarea
      id="solicitor-address"
      name="solicitorAddress"
      class="mt-6 sm:mt-12"
      label="Address of your solicitor (where applicable)"
      :edit-mode="editMode"
      :is-warning="sectionHasWarnings('solicitor-address')"
    />
  </div>
</template>
