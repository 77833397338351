<template>
  <!-- start -->
  <div class="col-12 mt-5">
    <div class="col-6 float-left">
      <div class="flex justify-between">
        <label class="block text-sm font-medium text-gray-700">{{ question }}</label>
      </div>
      <div class="mt-1" :title="title">
        <span v-if="textBoxDisabled" class="text-sm text-gray-500">{{ localData ? localData : 'N/A' }}</span>

        <input
          v-else
          :id="inputId"
          v-model="localData"
          type="number"
          class="custom-input shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md text-center"
          :placeholder="placeholder"
          min="0"
          :disabled="isTextBoxDisabled"
        />
      </div>
    </div>
  </div>
  <!-- end -->
</template>

<script>
/**
 * Component for a simple Question with a numeric answer.
 *
 * Args:
 *   - question: a sentence to describe why there is a Yes/No
 *   - title: if defined then this will be hover-over text
 */
import { mapState } from 'vuex';
export default {
  name: 'NumberQuestion',
  props: {
    modelValue: { default: '' },
    question: { type: String, required: true },
    title: { type: String, required: false },
    placeholder: { type: String, required: false, default: '' },
    id: {}
  },
  emits: ['update:modelValue'],
  data() {
    return {
      inputId: this.id || `number_question${(Math.random() * 1000).toFixed()}`
    };
  },
  computed: {
    ...mapState(['textBoxDisabled']),
    isTextBoxDisabled() {
      return this.textBoxDisabled;
    },
    localData: {
      get() {
        return this.modelValue;
      },
      set(newValue) {
        this.$emit('update:modelValue', typeof newValue === 'string' ? null : newValue);
      }
    }
  }
};
</script>

<style scoped>
textarea {
  width: 100%;
  border: 1px solid #dedede;
}
</style>
