<template>
    <div class="custom-menu-section flex-1 flex flex-col min-h-0 bg-indigo-700 medo-bg-primary" :class="{'custom-menu-collapse': !isActive}">

      <div v-show='!isActive' class="collapse-menu-off" @click="isActive=!isActive">
        <i class="fas fa-chevron-right text-indigo-100"></i>
      </div>

      <div v-show="isActive" class="flex-1 flex flex-col pt-5 pb-4 overflow-y-auto">

        <div class="text-indigo-100 font-bold flex items-center flex-shrink-0 px-4">
          Menu List
        </div>

        <div class="collapse-menu" @click="isActive=!isActive">
          <i class="fas fa-chevron-left text-indigo-100"></i>
        </div>

        <nav v-if='menuData' class="mt-4 flex-1 px-2 pt-0 space-y-1" aria-label="Sidebar">
          
            <router-link v-for='(item, index) in menuData' :key='index' :to='item.link' class="flex items-center p-2 pl-4 rounded-lg text-indigo-200 hover:bg-indigo-700">
            <span class="flex-1">
              <i v-if='item.icon' :class="item.icon"></i>{{item.name}}
            </span>
            <span v-if='item.count' class="medo-bg-secondary ml-3 inline-block py-0.5 px-3 text-xs font-medium rounded-full">
              200
            </span>
            </router-link>

        </nav>
      </div>

    </div>
</template>

<script>

export default {
  name: 'SecondMenu',
  components: {
    
  },
  props: ['menuData'],
  data () {
    return {
      isActive: true
    }
  },
  methods: {

  },
  mounted () {

  }
}
</script>

<style lang='scss' scoped>

  .custom-menu-section {
    float: left;
    width: 270px;
    height: 100vh;
    /*transition: all 0.25s;*/
  }
  
  .custom-menu-collapse {
    width: 30px;
    transition: all 0.75s;
  }

  .collapse-menu {
    padding-top: 20px;
    padding-right: 15px;
    text-align: right;
    cursor: pointer;
    transition: all 0.25s;

    &:hover {
      opacity: 0.65;
    }
  }

  .collapse-menu-off {
    padding-top: 65px;
    padding-right: 10px;
    text-align: right;
    cursor: pointer;
    transition: all 0.25s;

    &:hover {
      opacity: 0.65;
    }
  }
</style>