<script setup lang="ts">
import { BaseInput, BaseAddressInput, BaseDropdown, BaseListInput, BaseRadioButton } from 'ui';
import { relationshipOptions } from 'utils';

import ClaimSectionTitle from '@/components/admission/ClaimSectionTitle.vue';

const props = defineProps({
  editMode: {
    type: Boolean,
    default: false
  },
  warnings: {
    type: Array,
    default: () => []
  },
  details: {
    type: String,
    default: null
  }
});

const sectionHasWarnings = sectionId => {
  if (!props.warnings) return false;
  return props.warnings.some(warning => warning.id === sectionId);
};
</script>

<template>
  <div>
    <ClaimSectionTitle id="pomas-membership-details-title" title="Membership Details" />

    <!-- <BaseDropdown
      id="policyholder-relationship"
      name="policyholderRelationship"
      class="mt-6 sm:mt-12"
      label="Beneficiary"
      :edit-mode="editMode"
      :options="relationshipOptions"
    /> -->

    <BaseRadioButton
      name="policyholderRelationship"
      class="mt-6 sm:mt-12"
      :options="{
        Child: 'child',
        'Spouse/Partner': 'spouse',
        'N/A': null
      }"
      label="Beneficiary"
      :edit-mode="editMode"
    />
    <BaseInput
      v-if="details === '' || details === null"
      id="patient-name"
      name="patientForename"
      class="mt-6 sm:mt-12"
      label="Patient's Name*"
      :edit-mode="editMode"
      :is-warning="sectionHasWarnings('patient-name')"
    />
    <BaseInput
      v-if="details === '' || details === null"
      id="patient-surname"
      name="patientSurname"
      class="mt-6 sm:mt-12"
      label="Patient's Surname*"
      :edit-mode="editMode"
      :is-warning="sectionHasWarnings('patient-surname')"
    />

    <BaseListInput
      v-if="details === 'child'"
      id="patientChildren-name"
      name="patientChildren"
      class="mt-6 sm:mt-12"
      label="Patient's Name*"
      :edit-mode="editMode"
      :enableValidation="false"
    />

    <BaseInput
      v-if="details === 'spouse'"
      id="partner-name"
      name="patientPartner"
      class="mt-6 sm:mt-12"
      label="Patient's Name*"
      :edit-mode="editMode"
    />

    <BaseInput
      v-if="details !== null"
      id="patient-name"
      name="patientForename"
      class="mt-6 sm:mt-12"
      label="Policy Holder's First Name*"
      :edit-mode="editMode"
      :is-warning="sectionHasWarnings('patient-name')"
    />
    <BaseInput
      v-if="details !== null"
      id="patient-surname"
      name="patientSurname"
      class="mt-6 sm:mt-12"
      label="Policy Holder's Surname*"
      :edit-mode="editMode"
      :is-warning="sectionHasWarnings('patient-surname')"
    />

    <BaseAddressInput
      id="patient-address"
      name="patientAddress"
      class="mt-6 sm:mt-12"
      label="Address*"
      :edit-mode="editMode"
      :is-warning="sectionHasWarnings('patient-address')"
    />
  </div>
</template>
