export const colors = [
  {name: 'Red', color: '#f44336'},
  {name: 'Pink', color: '#e91e63'},
  {name: 'Purple', color: '#9c27b0'},
  {name: 'Deep Purple', color: '#673ab7'},

  {name: 'Indigo', color: '#3f51b5'},
  {name: 'Blue', color: '#2196f3'},
  {name: 'Light Blue', color: '#03a9f4'},
  {name: 'Cyan', color: '#00bcd4'},

  {name: 'Teal', color: '#009688'},
  {name: 'Green', color: '#4caf50'},
  {name: 'Light Green', color: '#8bc34a'},
  {name: 'Lime', color: '#cddc39'},

  {name: 'Yellow', color: '#ffeb3b'},
  {name: 'Amber', color: '#ffc107'},
  {name: 'Orange', color: '#ff9800'},
  {name: 'Deep Orange', color: '#ff5722'},
]
export const icons = [
  {name: 'Tag', icon: 'fa-tag'},
  {name: 'User Tag', icon: 'fa-user-tag'},
  {name: 'Check', icon: 'fa-check'},
  {name: 'X', icon: 'fa-times'},

  {name: 'Copy', icon: 'fa-copy'},
  {name: 'Trash', icon: 'fa-trash'},
  {name: 'Tools', icon: 'fa-tools'},
  {name: 'Tint', icon: 'fa-tint'},

  {name: 'Paperclip', icon: 'fa-paperclip'},
  {name: 'Money', icon: 'fa-money-bill'},
  {name: 'Mobile', icon: 'fa-mobile'},
  {name: 'Key', icon: 'fa-key'},

  {name: 'Lock Open', icon: 'fa-lock-open'},
  {name: 'Lock', icon: 'fa-lock'},
  {name: 'Info', icon: 'fa-info'},
  {name: 'Time', icon: 'fa-hourglass-half'},
  ]

export function getColorName (selectedColor) {
  if(selectedColor == undefined)
    return "No Colour"
    
  return colors.find(color => color.color == selectedColor).name
}

export function getIconName (selectedIcon) {
  if(selectedIcon == undefined)
    return "No Icon"
    
  return icons.find(icon => icon.icon == selectedIcon).name
}