// A simplified set of state-transitions that a consultant claim can move through
// on its way to submission to a insurance company.
export const CompositeClaimTargetStateEnum = Object.freeze({
  /// Revert a claim to a draft-state (its initial state) for editing.  Remove any
  /// consultant signings and void any non-draft invoices.
  REVERT_TO_DRAFT: 'revert_to_draft',

  /// Mark a claim as rejected by the claims-admin, which transitions it into a finalised
  /// rejected state.
  CANCELLED: 'cancelled',

  /// Mark a claim as rejected by the admin/consultant, putting it into a queue for further
  /// work by the claims admin.
  NEEDS_MORE_WORK: 'needs_more_work',

  /// Mark a claim as ready for review.  This is the desired state to allow the
  /// consultant/admin to sign the claim.
  READY_FOR_REVIEW: 'ready_for_review',

  /// A claim that has been signed by the and is ready for final review by the
  /// claims admin.
  APPROVED: 'approved',

  /// Finalises the claim, making it immutable, converting the draft invoice to an immutable
  /// invoice.  The claim is placed into a queue for automatic submission to a third-party
  /// provider e.g. an insurance company.
  ENQUEUE_FOR_SUBMISSION: 'enqueue_for_submission',

  /// Claim has been submitted to the third-party/insurer/payment-processor
  SUBMITTED: 'submitted'
});
