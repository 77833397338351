<script setup>
import { ref, computed, onMounted } from 'vue';
import {
  IsSigWebInstalled,
  GetSigWebVersion,
  isSigWeb_1_7_0_0_Installed,
  isSigWeb_1_6_4_0_Installed,
  GetDaysUntilCertificateExpires,
  SetDisplayXSize,
  SetDisplayYSize,
  SetTabletState,
  SetJustifyMode,
  ClearTablet,
  NumberOfTabletPoints,
  SetSigCompressionMode,
  SetImageXSize,
  SetImageYSize,
  SetImagePenWidth,
  GetSigImageB64,
  GetSigString
} from '../../util/sigWeb';

const props = defineProps({
  editMode: {
    type: Boolean,
    default: false
  },
  signature: {
    type: String,
    required: false
  }
});
const emit = defineEmits(['signature-captured']);

const tmr = ref(null);
const resetIsSupported = ref(false);
const SigWeb_1_6_4_0_IsInstalled = ref(false);
const SigWeb_1_7_0_0_IsInstalled = ref(false);

const bioSigData = ref('');
const sigStringData = ref('');
const sigImageData = ref('');
const sigWebVersionNote = ref(null);
const daysUntilExp = ref(null);
const sigCaptured = ref(null);

const imageSrc = computed(() => {
  return sigCaptured.value?.src;
});

const listener = () => {
  if (IsSigWebInstalled()) {
    let sigWebVer = '';
    try {
      sigWebVer = GetSigWebVersion();
    } catch (err) {
      console.log('Unable to get SigWeb Version: ' + err.message);
    }

    if (sigWebVer != '') {
      try {
        SigWeb_1_7_0_0_IsInstalled.value = isSigWeb_1_7_0_0_Installed(sigWebVer);
      } catch (err) {
        console.log(err.message);
      }
      //if SigWeb 1.7.0.0 is installed, then enable corresponding functionality
      if (SigWeb_1_7_0_0_IsInstalled.value) {
        resetIsSupported.value = true;
        try {
          const daysUntilCertExpires = GetDaysUntilCertificateExpires();
          daysUntilExp.value = 'SigWeb Certificate expires in ' + daysUntilCertExpires + ' days.';
        } catch (err) {
          console.log(err.message);
        }
        sigWebVersionNote.value = 'SigWeb 1.7.0 installed';
      } else {
        try {
          SigWeb_1_6_4_0_IsInstalled.value = isSigWeb_1_6_4_0_Installed(sigWebVer);
          //if SigWeb 1.6.4.0 is installed, then enable corresponding functionality
        } catch (err) {
          console.log(err.message);
        }
        if (SigWeb_1_6_4_0_IsInstalled.value) {
          resetIsSupported.value = true;
          sigWebVersionNote.value =
            'SigWeb 1.6.4 is installed. Install the newer version of SigWeb from the following link: https://www.topazsystems.com/software/sigweb.exe';
        } else {
          sigWebVersionNote.value =
            'A newer version of SigWeb is available. Please uninstall the currently installed version of SigWeb and then install the new version of SigWeb from the following link: https://www.topazsystems.com/software/sigweb.exe';
        }
      }
    } else {
      //Older version of SigWeb installed that does not support retrieving the version of SigWeb (Version 1.6.0.2 and older)
      sigWebVersionNote.value =
        'A newer version of SigWeb is available. Please uninstall the currently installed version of SigWeb and then install the new version of SigWeb from the following link: https://www.topazsystems.com/software/sigweb.exe';
    }
  } else {
    alert('Unable to communicate with SigWeb. Please confirm that SigWeb is installed and running on this PC.');
  }
};

const recaptureSignature = ref(false);
const sign = () => {
  listener();
  if (IsSigWebInstalled()) {
    recaptureSignature.value = true;
    const ctx = document.getElementById('cnv').getContext('2d');
    SetDisplayXSize(713);
    SetDisplayYSize(250);
    SetTabletState(0, tmr.value);
    SetJustifyMode(0);
    ClearTablet();
    if (tmr.value == null) {
      tmr.value = SetTabletState(1, ctx, 50);
    } else {
      SetTabletState(0, tmr.value);
      tmr.value = null;
      tmr.value = SetTabletState(1, ctx, 50);
    }
  }
};
const saveSignature = () => {
  if (NumberOfTabletPoints() == 0) {
    alert('Please sign before continuing');
  } else {
    SetTabletState(0, tmr.value);
    //RETURN TOPAZ-FORMAT SIGSTRING
    SetSigCompressionMode(1);
    bioSigData.value = GetSigString();
    sigStringData.value = GetSigString();
    //this returns the signature in Topaz's own format, with biometric information

    //RETURN BMP BYTE ARRAY CONVERTED TO BASE64 STRING
    SetImageXSize(713);
    SetImageYSize(250);
    SetImagePenWidth(5);
    GetSigImageB64(SigImageCallback);
    emit('signature-captured', sigImageData.value);
  }
  recaptureSignature.value = false;
};
const SigImageCallback = str => {
  sigImageData.value = str;
  setImage(str);
};
const setImage = str => {
  const image = new Image();
  image.src = 'data:image/png;base64,' + str;
  sigCaptured.value = image;
};

onMounted(() => {
  if (props.signature) {
    setImage(props.signature);
  }
});
window.onbeforeunload = function (evt) {
  close();
  clearInterval(tmr.value);
  evt.preventDefault(); //For Firefox, needed for browser closure
};
</script>

<template>
  <section class="flex">
    <div v-if="recaptureSignature" class="relative">
      <canvas id="cnv" name="cnv" width="713" height="250" />
      <span class="signature-text">Signature</span>
    </div>
    <div v-else class="relative">
      <img v-if="imageSrc" :src="imageSrc" alt="Signature" />
      <div v-else class="signature-empty-container" />
      <span class="signature-text">Signature</span>
    </div>
    <div v-if="editMode" class="flex flex-col justify-end">
      <button class="ml-6 flex items-center" @click="sign">
        <i class="fa-regular fa-file-signature text-2xl" />
        <span class="underline ml-1">{{ imageSrc ? 'Recapture' : 'Capture' }} signature</span>
      </button>
      <button v-if="recaptureSignature" class="ml-6 flex items-center" @click="saveSignature">
        <i class="fa-regular fa-check text-2xl" />
        <span class="underline ml-1">Save</span>
      </button>
    </div>
  </section>
</template>

<style scoped>
.signature-text {
  position: absolute;
  left: 15px;
  bottom: 20px;
}

.signature-empty-container {
  width: 713px;
  height: 250px;
  border-radius: 5px;
  border: 2px solid #000000;
  background-color: #d9d9d9;
}

canvas {
  border-radius: 5px;
  border: 2px solid #000000;
  background-color: #d9d9d9;
}

img {
  border-radius: 5px;
  border: 2px solid #000000;
  background-color: #d9d9d9;
  width: 713px;
  height: 250px;
}
</style>
