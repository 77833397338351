<script setup lang="ts">
import { BaseInput, BaseDatePicker } from 'ui';
import ClaimSectionTitle from '@/components/admission/ClaimSectionTitle.vue';
import { onMounted, ref } from 'vue';

const admissionOn = ref('');

const props = defineProps({
  editMode: {
    type: Boolean,
    default: false
  },
  warnings: {
    type: Array,
    default: () => []
  },
  admission: {
    type: String,
    default: 'N/A'
  }
});
const formatDate = dateStr => {
  try {
    const date = new Date(dateStr);
    if (isNaN(date.getTime())) {
      throw new Error('Invalid date');
    }
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const year = date.getFullYear();
    return `${day}-${month}-${year}`;
  } catch (error) {
    return 'N/A';
  }
};

onMounted(() => {
  admissionOn.value = props.admission;
  admissionOn.value = formatDate(admissionOn.value);
});

const sectionHasWarnings = sectionId => {
  if (!props.warnings) return false;
  return props.warnings.some(warning => warning.id === sectionId);
};
</script>

<template>
  <div>
    <ClaimSectionTitle id="laya-hospital-details-title" title="Hospital Details" />
    <BaseInput
      id="hospital-name"
      name="hospitalName"
      class="mt-6"
      label="Hospital Name"
      :edit-mode="false"
      :is-warning="sectionHasWarnings('hospital-name')"
    />
    <!-- <BaseDatePicker
      id="first-treatment"
      :name="admission"
      class="mt-6 sm:mt-12"
      label="Date of Admission"
      placeholder="dd.mm.yyyy"
      :edit-mode="false"
    /> -->
    <div class="flex mt-6 mb-2">
      <label class="text-sm sm:text-base font-bold"> Date of Admission </label>
    </div>

    <p class="text-lg text-gray-500 ml-4 mr-6">
      {{ admissionOn === '' || admissionOn === undefined || admissionOn === null ? 'N/A' : admissionOn }}
    </p>
  </div>
</template>
