<script setup type="ts">
import { ref, computed, watch, defineComponent } from 'vue';
import { useField } from 'vee-validate';
import 'yup-phone-lite';
import parsePhoneNumber from 'libphonenumber-js';
//import countryCodes from '../../../helper/countryCodes';
import InputErrorMessage from './InputErrorMessage.vue';

defineComponent({ InputErrorMessage });

const props = defineProps({
  id: {
    type: String,
    required: true
  },
  name: {
    type: String,
    required: true
  },
  label: {
    type: String,
    default: ''
  },
  editMode: {
    type: Boolean,
    default: true,
  },
  isWarning: {
    type: Boolean,
    default: false,
  },
  enableValidation: {
    type: Boolean,
    default: true
  }
});

const { value, errorMessage, meta } = useField(() => props.name);
const phoneNumber = ref('');

const showError = computed(() => {
  return props.enableValidation && errorMessage.value;
});

const setInitialValue = () => {
  if (meta.initialValue) {
    const parsedPhoneNumber = parsePhoneNumber(meta.initialValue);
    if (parsedPhoneNumber) {
      phoneNumber.value = parsedPhoneNumber.number;
    }
  }
};

setInitialValue();
</script>

<template>
  <div :id="id + '-container'" :name="name">
    <label
      :for="id"
      class="text-sm sm:text-base font-bold"
      :class="{
        'text-medosync-dark-orange': isWarning && editMode,
        'text-medosync-red': showError
      }"
    >
      {{ label }}
      <i v-if="isWarning && editMode" class="fal fa-circle-exclamation mx-1 text-medosync-dark-orange"></i>
    </label>
    <div v-if="editMode" class="flex mt-1">
      <input
        v-model="phoneNumber"
        :id="id"
        type="text"
        class="focus:ring-medosync-blue focus:border-medosync-blue block w-full rounded-md h-12 max-w-[583px]"
        :class="{
          'border-medosync-dark-orange': isWarning && editMode,
          'border-medosync-red focus:ring-medosync-red focus:border-medosync-red': showError
        }"
        @focusout="() => (value = phoneNumber)"
      />
    </div>
    <div v-else class="mt-1">
      <p :id="id + '-readonly'" class="text-lg text-gray-500 ml-4 mr-6">
        {{ value === '' || value === undefined || value === null ? 'N/A' : value }}
      </p>
    </div>
    <InputErrorMessage v-if="showError">
      Please enter a valid phone number including country code (+xxx).
    </InputErrorMessage>
  </div>
</template>

<style scoped>
#country-code-choice::-webkit-calendar-picker-indicator {
  border-left: 1px solid black;
  padding-bottom: 10px;
  cursor: pointer;
}
</style>
