<template>
  <div class="px-2 pt-5">
    <h2 class="ml-1 title medo-secondary">Add Procedure Code</h2>

    <div class="col-12 mt-6">
      <span>Procedure Code Number</span>
      <ProcedureCodeTypeahead
        v-model="procedure_code"
        placeholder="Search procedure code"
        :insurer="selectedContract.insurer_id"
        :contract_id="selectedContract.contract_id"
        :min-input-length="1"
        @selected-procedure="selected_procedure($event)"
        @cleared-procedure="cleared_procedure()"
      />
    </div>

    <div class="col-12 mt-6">
      <span>Procedure Code Description</span>
      <input v-model="procedure_code_description" disabled
             type="text"
             class="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md custom-input " >
    </div>

    <div class="col-12 mt-6">
      <span>Participating Anaesthetist Cost</span>
      <input v-model="newParticipatingAnaesthetist" type="text" class="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md custom-input " >
    </div>

    <div class="col-12 mt-6">
      <span>Participating Consultant Cost</span>
      <input v-model="newParticipatingConsultant" type="text" class="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md custom-input " >
    </div>
  </div>

  <div style="clear: both"></div>

  <div class="mt-4 pr-2 flex justify-end">
    <button @click="validateInput" type="button" class="inline-flex items-center px-2.5 py-1.5 ml-auto border border-transparent text-xs font-medium rounded shadow-sm text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500">
      SAVE
    </button>
  </div>

  <Modal v-if="showWarningModal" @close-modal="closeWarningModal" :hide-expand-button="true">
    <template v-slot:header>
      <h3 class="mt-4">Warning!</h3>
    </template>
    <template v-slot:body>
      If you proceed, this code will be added to the existing contract
      <div class="col-12 mt-3 flex justify-end">
        <button @click="closeWarningModal" type="button" class="inline-flex items-center mr-2 px-2.5 py-1.5 border border-blue-600 text-blue-600 text-xs font-medium rounded shadow-sm bg-white-600 hover:bg-white-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-white-500">
          NO
        </button>
        <button @click="editProcedureCode" type="button" class="inline-flex items-center px-2.5 py-1.5 border border-transparent text-xs font-medium rounded shadow-sm text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500">
          YES
        </button>
      </div>
    </template>
  </Modal>
  <ToastComponent ref="toast"></ToastComponent>
</template>

<script>
import { OpenAPIJSON } from "api/openapi";
import '@/assets/css/typeahead.css';
import Modal from '@/components/modal/Modal.vue'
import ToastComponent from '@/components/claim-forms/components/ToastComponent.vue';
import ProcedureCodeTypeahead from "@/components/form-fields/ProcedureCodeTypeahead.vue";

export default {
  props: {selectedContract: null},
  emits: ['close-modal'],
  components: {ProcedureCodeTypeahead, Modal, ToastComponent},
  data () {
    return {
      oapi: new OpenAPIJSON(),
      procedure_code: "",
      procedure_code_description: null,
      newParticipatingAnaesthetist: null,
      newParticipatingConsultant: null,
      showWarningModal: false,
    }
  },
  methods: {
    mounted() {
      console.log("selectedContract = ", this.selectedContract)
    },
    selected_procedure(item) {
      this.procedure_code = item.procedure_code;
      this.procedure_code_description = item.procedure_code_description;
    },
    cleared_procedure() {
      this.procedure_code = '';
      this.procedure_code_description = '';
    },
    async validateInput () {
      if (this.procedure_code == null || this.procedure_code === "")
        return this.$refs.toast.showToast("Procedure Code Number invalid!", 'fa-solid fa-triangle-exclamation', 'error');

      const request = await this.oapi.searchProcedureCodes(
          null, null, null, this.selectedContract.insurer_id, null,
          this.selectedContract.contract_id, null, this.procedure_code, null, null,
          null, null, null, null
      );
      if (request.items.length > 1) {
        return this.$refs.toast.showToast("Selected procedure code is ambiguous", 'fa-solid fa-triangle-exclamation', 'error');
      }
      if (request.items.length === 1) {
        const price = request.items[0].price;
        if (price.participating_consultant_cost !== null && price.participating_anaesthetist_cost !== null) {
          return this.$refs.toast.showToast("Procedure Code is already defined in current contract!", 'fa-solid fa-triangle-exclamation', 'error');
        }
      }

      if (this.newParticipatingAnaesthetist == null)
        return this.$refs.toast.showToast("Participating Anaesthetist Cost is invalid!", 'fa-solid fa-triangle-exclamation', 'error');

      if (this.newParticipatingConsultant == null)
        return this.$refs.toast.showToast("Participating Consultant Cost is invalid!", 'fa-solid fa-triangle-exclamation', 'error');

      this.showWarningModal = true;
    },

    async editProcedureCode () {
      await this.set_contract_procedure_code();
      this.showWarningModal = false;
      this.$emit("close-modal");
    },

    closeWarningModal () {
      this.showWarningModal = false;
    },

    async set_contract_procedure_code() {
      const price_list = [{
        proc_code: this.procedure_code,
        price: {
          participating_consultant_cost: this.newParticipatingConsultant,
          participating_anaesthetist_cost: this.newParticipatingAnaesthetist
        }
      }];
      const request = await this.oapi.setContractInsuranceProcedureCode(this.selectedContract.insurer_id, null, this.selectedContract.contract_id, price_list);
      if (request === '"OK"')
        this.$refs.toast.showToast('Payment Added!', 'fas fa-check', 'success');
    },
  }
}
</script>

<style>
.title {
  font-size: 22px;
}
.simple-typeahead-input {
  width: 100%;
}
</style>