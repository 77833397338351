// https://medium.com/@robertsavian/javascript-case-converters-using-lodash-4f2f964091cc
import {
  camelCase,
  kebabCase,
  lowerCase,
  snakeCase,
  startCase,
  upperCase,
  upperFirst,
} from 'lodash';

export default class StringUtility {
  static toCamelCase(str) {
    return camelCase(str);
  }

  static toTitleCase(str) {
    return startCase(camelCase(str));
  }

  static toPascalCase(str) {
    return startCase(camelCase(str)).replace(/ /g, '');
  }

  static toConstantCase(str) {
    return upperCase(str).replace(/ /g, '_');
  }

  static toDotCase(str) {
    return lowerCase(str).replace(/ /g, '.');
  }

  static toKebabCase(str) {
    return kebabCase(str);
  }

  static toLowerCase(str) {
    return lowerCase(str).replace(/ /g, '');
  }

  static toPathCase(str) {
    return lowerCase(str).replace(/ /g, '/');
  }

  static toSnakeCase(str) {
    return snakeCase(str);
  }

  static toSentenceCase(str) {
    if (str === null) {
      return '';
    }
    return upperFirst(lowerCase(str));
  }

  // Converts a date into a format desirable by the staff
  // e.g. 2008-12-25 -> 25 Dec 2008
  static formatDate(date_str) {
    if (date_str === null || date_str === undefined) {
      return '';
    }
    let date = new Date(date_str);
    return date.toLocaleDateString('en-GB', {
      year: 'numeric',
      day: 'numeric',
      month: 'short',
    });
  }

  // Converts a Date object into a date format compatible with NaiveDate used by the backend i.e. YYYY-MM-DD.
  // If `date` is null, then the current time is chosen.
  static naiveDate(date = null) {
    if (date === null) {
      date = new Date(Date.now());
    }
    return date.toLocaleDateString('en-CA', {
      year: 'numeric',
      day: 'numeric',
      month: 'numeric',
    });
  }

  // Converts a date into a format desirable by the staff
  // e.g. 2008-12-25 -> 25 Dec 2008
  static getDateYear(date_str) {
    if (date_str === null || date_str === undefined) {
      return '';
    }
    let date = new Date(date_str);
    return date.toLocaleDateString('en-GB', { year: 'numeric' });
  }

  // Converts a date into a format desirable by the staff
  // e.g. 2008-12-25 -> 25 Dec 2008
  static getDateMonth(date_str) {
    if (date_str === null || date_str === undefined) {
      return '';
    }
    let date = new Date(date_str);
    return date.toLocaleDateString('en-GB', { month: 'short' });
  }

  // Convert a number string like "310.3300" to a two decimal place string "310.33"
  static to2dp(numeric_str) {
    return parseFloat(numeric_str).toFixed(2);
  }

  static naiveDateYYYYMMDD(date) {
    var d = new Date(date),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = '0' + month;
    if (day.length < 2) day = '0' + day;

    return [year, month, day].join('-');
  }
}
