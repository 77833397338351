<template>
  <!-- section -->
  <div class="col-12 mt-1">
    <div class="col-8 float-left">
      <div class="mt-2">
        <div class="text-left text-sm mb-1">
          <label for="value" class="font-medium text-gray-700 mr-2">Policy Membership Number:</label>
        </div>
        <div class="col-12">
          <span v-if="textBoxDisabled" class="text-sm text-gray-500">{{ localData ? localData : 'N/A' }}</span>
          <input
            v-else
            id="insurer-policy-no"
            type="text"
            class="custom-input shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
            v-model="localData"
            :disabled="isTextBoxDisabled"
            data-test-id="new-claim-membernum"
          />
        </div>
      </div>
    </div>
  </div>
  <ToastComponent ref="toast"></ToastComponent>
</template>

<script>
import { mapState } from 'vuex';
import ToastComponent from '@/components/claim-forms/components/ToastComponent.vue';

export default {
  name: 'InsuranceMembership',
  components: {
    ToastComponent
  },
  props: {
    modelValue: {},
    id: { require: true, type: String }
  },
  emits: ['update:modelValue'],
  data() {
    return {
      inputId: this.id || `insurance_memb_${(Math.random() * 1000).toFixed()}`,
      policies: []
    };
  },
  computed: {
    ...mapState(['textBoxDisabled']),
    isTextBoxDisabled() {
      return this.textBoxDisabled;
    },
    localData: {
      get() {
        return this.modelValue;
      },
      set(newValue) {
        this.$emit('update:modelValue', newValue);
      }
    }
  }
};
</script>
