<template>
  <Modal v-if="showManageModalClaims">
    <template v-slot:header>
      <h3 class="text-lg text-left leading-6 font-medium medo-grey-600">Save all changes made in Claim Form?</h3>
      <br />
      Are you sure you want to save all changes made? If you are unsure whether you want to save the changes made,
      please choose the button “Cancel”.
      <br />
      <br />
      You can then review the claim form again before making a decision.
      <br />

      <div class="modalDelete">
        <div class="ml-auto">
          <button class="file-upload-button-canceled py-2 px-4">Save</button>
          <button class="file-upload-button py-2 px-4" @click="showManageModalClaims = false">Cancel</button>
        </div>
      </div>
    </template>
  </Modal>
  <div class="p-4 bg-gray">
    <div class="bg-gray flex relative" v-if="claim_form_id">
      <ConsultantLaya
        v-if="claim.claim_form_type.toLowerCase() == 'laya_inpatient'"
        :class="claimHeight"
        :claim="claim"
        :claimDataPreviousState="claimDataPreviousState"
        :genders="genders"
        :diagnosisTypes="diagnosisTypes"
        :claim_form_id="claim_form_id"
        @form-loaded="formLoaded"
        @set-previous-form-state="handleChildEvent"
        @update-header-info="updateHeaderInfo"
      />

      <HospitalVhiHospital
        v-if="claim.claim_form_type.toLowerCase() == 'vhi_hospital' && claim_form_id"
        :class="claimHeight"
        :claim="claim"
        :claim_form_id="claim_form_id"
        :claimDataPreviousState="claimDataPreviousState"
        :genders="genders"
        :diagnosisTypes="diagnosisTypes"
        @form-loaded="formLoaded"
        @set-previous-form-state="handleChildEvent"
        @update-header-info="updateHeaderInfo"
      />
    </div>
  </div>
</template>

<script>
import { defineAsyncComponent } from 'vue';
import { mapState, mapMutations } from 'vuex';
import { OpenAPIJSON } from 'api/openapi';
import { getClaimContainerId } from '../../../util/claims/generateClaimIds';
import ClaimFormMenu from '../details/ClaimFormMenu.vue';
import Modal from '@/components/modal/Modal.vue';

export default {
  name: 'DetailsClaimForm',
  props: {
    claim: { required: true },
    claimDataPreviousState: { required: false }
  },
  components: {
    ClaimFormMenu,
    ConsultantLaya: defineAsyncComponent(() => import('../HospitalLaya.vue')),
    HospitalVhiHospital: defineAsyncComponent(() => import('../HospitalVhiHospital.vue')),
    Modal
  },
  data() {
    return {
      openApi: new OpenAPIJSON(),
      claimId: null,
      formLoading: true,
      showManageModalClaims: false,
      formOverlay: false,
      claim_form_id: null
    };
  },
  computed: {
    ...mapState(['textBoxDisabled']),
    isTextBoxDisabled() {
      return this.textBoxDisabled;
    },
    claimHeight() {
      return this.isTextBoxDisabled ? 'h-[920px]' : 'h-[1020px]';
    }
  },

  mounted() {
    this.init();
  },
  watch: {
    claim() {
      this.init();
    }
  },
  methods: {
    ...mapMutations(['setTextBoxDisabled']),
    async init() {
      this.formLoading = true;
      await this.claim_form_hospital_list();
    },

    handleChildEvent(value) {
      this.$emit('set-previous-form-state', value);
    },

    async claim_form_hospital_list() {
      try {
        const response = await this.openApi.claim_form_hospital_list({
          claim_ref: {
            type: 'claimid',
            claim_id: this.claim.claim_id
          }
        });

        if (response.claim_forms.length === 0) {
          this.claim_form_id = null;
          return;
        }

        this.claim_form_id = response.claim_forms[0].claim_form_id;
      } catch (e) {
        console.log(e);
      }
    },

    disableTextBox() {
      this.setTextBoxDisabled(false);
    },
    enableTextBox() {
      this.setTextBoxDisabled(true);
    },
    formLoaded() {
      this.formLoading = false;
    },
    getClaimIds() {
      this.containerId = getClaimContainerId(this.claim.claim_form_type);
    },
    setFormOverlay(bool) {
      this.formOverlay = bool;
    },
    updateHeaderInfo(data) {
      this.$emit('update-header-info', data);
    }
  }
};
</script>

<style>
.bg-gray {
  background-color: #d9d9d9;
}

.cancel-button,
.save-button {
  background: #00a7e1;
  border-radius: 50px;
  width: 95px;
  height: 45px;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 100%;
  align-items: center;
  margin: 10px;
  margin-top: 34px;
  margin-bottom: 34px;
}

.cancel-button {
  background-color: #cbd5e0;
  color: #fff;
  margin-left: 100px;
}

.file-upload-button {
  background: #00a7e1;
  border-radius: 50px;
  width: 95px;
  height: 45px;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 100%;
  align-items: center;
  margin: 10px;
}

.file-upload-button-canceled {
  border: 2px solid #00a7e1;
  border-radius: 50px;
  height: 45px;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 100%;
  align-items: center;
}

.form-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(25, 25, 25, 0.6);
  z-index: 10;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
