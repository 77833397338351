<script setup>
import { useForm } from 'vee-validate';
import { object, string, date, boolean } from 'yup';

import MembershipDetails from './sections/irish-life/MembershipDetails.vue';
import IllnessHistory from './sections/irish-life/IllnessHistory.vue';
import AccidentDetails from './sections/irish-life/AccidentDetails.vue';

const props = defineProps({
  editMode: {
    type: Boolean,
    default: false
  },
  warnings: {
    type: Array,
    default: () => []
  },
  initialData: {
    type: Object,
    default: () => ({})
  }
});

const { values, setValues, defineInputBinds } = useForm({
  validationSchema: object({
    policyNo: string(),
    patientName: string().required(),
    telephone: string().phone().required(),
    birthDate: date().required().min(new Date('1900-01-01')).max(new Date()),
    treatmentResultOfAccident: boolean(),
    privatePatient: boolean(),

    firstSymptomsDate: date(),
    firstConsultation: date(),
    doctorName: string(),
    doctorNumber: string().phone(),
    doctorAddress: string(),
    similarIllness: boolean(),
    similarIllnessDetails: string(),

    injuryDate: date(),
    injuryPlace: string(),
    injuryDescription: string(),
    thirdPartyDetails: string(),
    pursueLegalClaim: boolean(),
    solicitorName: string(),
    solicitorAddress: string()
  }),
  initialValues: {
    patientName: props.initialData?.patientName ?? undefined,
    policyNo: props.initialData?.policyNo ?? undefined,
    telephone: props.initialData?.telephone ?? undefined,
    birthDate: props.initialData?.birthDate ?? undefined,
    treatmentResultOfAccident: props.initialData?.treatmentResultOfAccident ?? undefined,
    privatePatient: props.initialData?.privatePatient ?? undefined,

    firstSymptomsDate: props.initialData?.firstSymptomsDate ?? undefined,
    firstConsultation: props.initialData?.firstConsultation ?? undefined,
    doctorName: props.initialData?.doctorName ?? undefined,
    doctorNumber: props.initialData?.doctorNumber ?? undefined,
    doctorAddress: props.initialData?.doctorAddress ?? undefined,
    similarIllness: props.initialData?.similarIllness ?? undefined,
    similarIllnessDetails: props.initialData?.similarIllnessDetails ?? undefined,

    injuryDate: props.initialData?.injuryDate ?? undefined,
    injuryPlace: props.initialData?.injuryPlace ?? undefined,
    injuryDescription: props.initialData?.injuryDescription ?? undefined,
    thirdPartyDetails: props.initialData?.thirdPartyDetails ?? undefined,
    pursueLegalClaim: props.initialData?.pursueLegalClaim ?? undefined,
    solicitorName: props.initialData?.solicitorName ?? undefined,
    solicitorAddress: props.initialData?.solicitorAddress ?? undefined
  }
});

const similarIllness = defineInputBinds('similarIllness');
defineExpose({ values, setValues });
</script>

<template>
  <MembershipDetails id="irish-life-membership-details" :edit-mode="editMode" :warnings="warnings" />
  <IllnessHistory
    id="irish-life-illness-history"
    :edit-mode="editMode"
    :warnings="warnings"
    :similar-illness="similarIllness.value"
  />
  <AccidentDetails id="irish-life-accident-details" :edit-mode="editMode" :warnings="warnings" />
</template>
