<template>
    <div>
      <div class="p-8">

        <div class="title medo-secondary">
          Reporting
        </div>

        <div v-if="loaded">
          <CustomDatePicker v-model="filteredYear" />

          <select v-model="filteredType" class="block w-full pl-3 mt-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md">
            <option value="value">
              Filter By Invoiced Amount
            </option>
            <option value="volume">
              Filter By Number of Invoices
            </option>
          </select>
        </div>

        <BarChart v-if="loaded" :data1="invoicedAmountByMonths" :data2="remittanceAmountByMonths" class="mb-20" id="1" type="patients" />

        <BarChart v-if="loaded" :data1="unbilledAmountByMonths" :data2="billedAmountByMonths" :data3="paidAmountByMonths" id="2" type="revenue" />
      </div>
      <ToastComponent ref="toast"></ToastComponent>
    </div>
</template>

<script>
import api from 'api/api'

import BarChart from '@/components/charts/BarChart.vue'
import CustomDatePicker from '@/components/claim-forms/components/Datepicker.vue'
import ToastComponent from '@/components/claim-forms/components/ToastComponent.vue';

export default {
  name: 'reportingBars',
  components: {
    BarChart,
    CustomDatePicker,
    ToastComponent
  },
  
  data () {
    return {
      collapsed: null,
      data: null,
      dates: {},
      filteredYear: null,
      years: null,
      loaded: false,
      invoicedAmountByMonths: [],
      remittanceAmountByMonths: [],
      filteredType: 'value',

      unbilledAmountByMonths: [],
      billedAmountByMonths: [],
      paidAmountByMonths: [],

    }
  },
  watch: {
    filteredType() {
      this.getInitChartData();
    },
    filteredYear() {
      this.init();
    }
  },

  methods: {
    async fetch_report(date = null) {
      await api.reportClaimsBilledState(date)
          .then((res) => {
            this.data = res;
          })
          .catch((error) => {
            this.$refs.toast.showToast(error, 'fa-solid fa-triangle-exclamation', 'error');
          });
    },

    async init() {
      await this.fetch_report(this.filteredYear);
      this.getInitChartData();
    },

    getInitChartData() {
      this.loaded = false;

      this.invoicedAmountByMonths = [];
      this.remittanceAmountByMonths = [];

      this.unbilledAmountByMonths = [];
      this.billedAmountByMonths = [];
      this.paidAmountByMonths = [];

      var unfilteredInvoiced = [];
      var unfilteredRemittance = [];

      this.data.forEach(item => {
        if (this.filteredType === 'value') {
          this.invoicedAmountByMonths.push({x: item.claimDate, y: parseInt(item.amountInvoiced)})
          this.remittanceAmountByMonths.push({x: item.claimDate, y: parseInt(item.amountRemittance)})
        } else {
          unfilteredInvoiced.push({x: item.claimDate, y: 1})
          unfilteredRemittance.push({x: item.claimDate, y: 1})
        }
      })

      if (this.filteredType === 'volume') {
        unfilteredInvoiced.forEach(item => {
          let count = unfilteredInvoiced.filter(filteredItem => filteredItem.x == item.x).length
          if (this.invoicedAmountByMonths.find(element => element.x == item.x)) return;
          this.invoicedAmountByMonths.push({x: item.x, y: count})
        })

        unfilteredRemittance.forEach(item => {
          let count = unfilteredRemittance.filter(filteredItem => filteredItem.x == item.x).length
          if (this.remittanceAmountByMonths.find(element => element.x == item.x)) return;
          this.remittanceAmountByMonths.push({x: item.x, y: count})
        })
      }

      var unfilteredUnbilled = [];
      var unfilteredBilled = [];
      var unfilteredPaid = [];

      this.data.forEach(item => {
        if (item.billedState == "Unbilled") {
          unfilteredUnbilled.push({x: item.claimDate, y: 1})
        }

        if (item.billedState == "Billed") {
          unfilteredBilled.push({x: item.claimDate, y: 1})
        }

        if (item.billedState == "Paid") {
          unfilteredPaid.push({x: item.claimDate, y: 1})
        }
      })

      unfilteredUnbilled.forEach(item => {
        let count = unfilteredUnbilled.filter(filteredItem => filteredItem.x == item.x).length
        if (this.unbilledAmountByMonths.find(element => element.x == item.x)) return;
        this.unbilledAmountByMonths.push({x: item.x, y: count})
      })

      unfilteredBilled.forEach(item => {
        let count = unfilteredBilled.filter(filteredItem => filteredItem.x == item.x).length
        if (this.billedAmountByMonths.find(element => element.x == item.x)) return;
        this.billedAmountByMonths.push({x: item.x, y: count})
      })

      unfilteredPaid.forEach(item => {
        let count = unfilteredPaid.filter(filteredItem => filteredItem.x == item.x).length
        if (this.paidAmountByMonths.find(element => element.x == item.x)) return;
        this.paidAmountByMonths.push({x: item.x, y: count})
      })

      this.loaded = true;
    }
  },
  mounted () {
    this.init();
  }
}
</script>

<style lang="scss" scoped>
  .title  {
    margin-bottom: 30px;
    font-size: 36px;
    font-weight: 600;
  }
</style>