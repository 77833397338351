<script setup>
import { ref } from 'vue';

const props = defineProps({
  action: {
    type: String,
    required: true
  }
});
const emit = defineEmits(['send-reminder']);

const text = ref('');
const icon = ref(null);
const style = ref('');
const tooltip = ref(null);

switch (props.action) {
  case 'reminder':
    text.value = 'Send form to patient';
    icon.value = 'fa-regular fa-user';
    break;
  case 'closeFile':
    text.value = 'Close patient file';
    icon.value = 'fa-solid fa-clipboard-check';
    style.value = 'bg-medosync-blue';
    break;
  case 'resubmission':
    text.value = 'Put on resubmission';
    icon.value = 'fa-solid fa-xmark';
    style.value = 'bg-medosync-blue';
    tooltip.value = 'The patient has to sign the form before you can continue.';
}

const onButtonClick = () => {
  if (props.action === 'reminder') {
    emit('send-reminder');
  }
};
</script>

<template>
  <div class="group flex relative mb-2">
    <button
      @click="onButtonClick"
      class="flex items-center px-5 h-12 w-[250px] border-2 border-medosync-blue rounded-full"
      :class="style"
    >
      <i :class="icon" class="mr-2 text-2xl"></i>
      <span class="w-full">{{ text }}</span>
    </button>
    <span
      v-if="tooltip"
      class="group-hover:opacity-100 transition-opacity bg-[#707070] px-1 text-sm text-white absolute left-1/2 -translate-x-1/2 translate-y-14 opacity-0 z-10"
    >
      {{ tooltip }}
    </span>
  </div>
</template>
