<script setup lang="ts">
import { defineComponent } from 'vue';
import { useField } from 'vee-validate';
import InputErrorMessage from './InputErrorMessage.vue';

defineComponent({ InputErrorMessage });

const props = defineProps({
  id: {
    type: String,
    required: true
  },
  name: {
    type: String,
    required: true
  },
  type: {
    type: String,
    default: 'text'
  },
  label: {
    type: String,
    default: ''
  },
  placeholder: {
    type: String,
    default: ''
  },
  editMode: {
    type: Boolean,
    default: true
  },
  isWarning: {
    type: Boolean,
    default: false
  },
  enableValidation: {
    type: Boolean,
    default: true
  },
  errorInfo: {
    type: String,
    default: ''
  }
});

const { value, errorMessage } = useField(() => props.name);
</script>

<template>
  <div :id="id + '-container'">
    <div class="flex">
      <label
        :for="id"
        class="text-sm sm:text-base font-bold"
        :class="{
          'text-medosync-dark-orange': isWarning && editMode,
          'text-medosync-red': enableValidation && errorMessage
        }"
      >
        {{ label }}
        <i v-if="isWarning && editMode" class="fal fa-circle-exclamation mx-1 text-medosync-dark-orange"></i>
      </label>
    </div>
    <div class="mt-1">
      <input
        v-if="editMode"
        :name="name"
        :id="id"
        v-model="value"
        class="focus:ring-medosync-blue focus:border-medosync-blue block w-full rounded-md h-12 max-input-width"
        :class="{
          'border-medosync-dark-orange': isWarning && editMode,
          'border-medosync-red focus:ring-medosync-red focus:border-medosync-red': enableValidation && errorMessage
        }"
        :type="type"
        :placeholder="placeholder"
      />
      <p v-else :id="id + '-readonly'" class="text-lg text-gray-500 ml-4 mr-6">
        {{ value === '' || value === undefined || value === null ? 'N/A' : value }}
      </p>
    </div>
    <InputErrorMessage v-if="enableValidation && errorMessage && errorInfo === ''"
      >Please enter your {{ label.toLowerCase().slice(0, -1) }}</InputErrorMessage
    >
    <InputErrorMessage v-if="enableValidation && errorMessage && errorInfo">{{ errorInfo }}</InputErrorMessage>
  </div>
</template>
