<template>
  <div :id="inputId" class="list-gp-section col-12 mt-3">
    <div style="clear: both"></div>

    <div v-if="question" class="sub-title text-sm font-medium text-gray-700">{{ question }}</div>

    <div style="clear: both"></div>

    <div class="float-left col-6 mt-1 relative">
      <span v-if="textBoxDisabled" class="text-sm text-gray-500">{{
        medicInput.ro.full_name ? medicInput.ro.full_name : 'N/A'
      }}</span>

      <!-- update:model-value is a workaround. Typeahead component needs refactoring -->
      <Typeahead
        v-else
        :id="inputId + '_fullname'"
        :modelValue="medicInput.ro.full_name"
        @update:model-value="
          newValue => {
            if (typeof newValue !== 'string' && typeof newValue?.ro?.full_name !== 'string') return;
            medicInput.ro.full_name = newValue;
          }
        "
        :items="medics"
        :placeholder="hint"
        :min-input-length="1"
        :item-projection="show_suggestions"
        :item-value="show_name"
        @select-item="select_by_fullname"
        :fullW="true"
      />
      <i
        v-if="!textBoxDisabled"
        class="fas fa-times deselect-btn"
        :class="{ 'cursor-pointer': !isTextBoxDisabled }"
        @click="deselect_item"
      ></i>
    </div>
  </div>
</template>

<script>
import Typeahead from '@/components/claim-forms/components/Typeahead.vue';
import { OpenAPIJSON } from 'api/openapi';
import { mapState } from 'vuex';

export default {
  name: 'ListGeneralPractitioners',
  props: {
    modelValue: {},
    question: { required: false, type: String },
    id: { required: true, type: String },
    enable_gp: { required: true, type: Boolean, default: false },
    enable_consultant: { required: true, type: Boolean, default: false },
    hint: { required: false, type: String, default: 'Search by full-name' }
  },
  components: {
    Typeahead
  },
  data() {
    return {
      medics: [],
      medicInput: {
        ro: {
          full_name: null
        }
      },
      inputId: this.id || `list_gps_${(Math.random() * 1000).toFixed()}`,
      api: new OpenAPIJSON()
    };
  },
  computed: {
    medicObj: {
      get() {
        return this.modelValue;
      }
    },
    ...mapState(['textBoxDisabled']),
    isTextBoxDisabled() {
      return this.textBoxDisabled;
    }
  },
  watch: {
    'medicInput.ro.full_name': async function (new_filter) {
      let search_term = new_filter?.trim();
      let results = [];
      if (search_term?.length > 0) results = await this.api.searchDoctors(new_filter);

      if (results.length > 0) {
        if (this.enable_gp && this.enable_consultant) {
          // General search over both GPs and Hospital Consultants.
          this.medics = results;
        } else if (this.enable_gp && !this.enable_consultant) {
          // General search over GPs only.
          this.medics = results.filter(item => item.medic.medic_type === 'gp');
        } else if (!this.enable_gp && this.enable_consultant) {
          this.medics = results.filter(item => item.medic.medic_type === 'consultant');
        }
      }
    }
  },
  methods: {
    show_suggestions(item) {
      if (this.enable_gp) {
        let name = item.medic.ro.full_name;
        if (item.hospital) {
          name += ' (' + item.hospital.ro.name + ')';
        }
        return name;
      } else {
        return item.fullname;
      }
    },
    show_name(item) {
      return item;
    },
    select_by_fullname(item) {
      this.$emit('update:modelValue', item.medic);
      this.medicInput = item.medic;
    },
    deselect_item() {
      if (this.isTextBoxDisabled) return;
      this.$emit('update:modelValue', null);
      this.medicInput = {
        ro: {
          full_name: null
        }
      };
    }
  },
  mounted() {
    if (this.modelValue) {
      this.medicInput = this.medicObj;
    }
  }
};
</script>

<style scoped>
.deselect-btn {
  position: absolute;
  top: 12px;
  right: 8px;
  color: #6b7280;
}
</style>
