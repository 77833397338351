<script setup>
import { PreAdmitState } from 'enums';
import { ref, watch } from 'vue';

const props = defineProps({
  status: {
    type: String,
    required: true
  }
});

const style = ref('');
const text = ref('');

watch(
  () => props.status,
  () => {
    setFormStatus();
  }
);

const setFormStatus = () => {
  switch (props.status) {
    case PreAdmitState.Start:
      text.value = 'Form ready';
      style.value = 'bg-medosync-dark-green border-medosync-dark-green text-white';
      break;
    case PreAdmitState.RegistrationMessageSent:
      text.value = 'Reminder sent';
      style.value = 'border-medosync-dark-green text-black';
      break;
    case PreAdmitState.ReadyForReview:
      text.value = 'Form ready for PAA review';
      style.value = 'border-medosync-orange text-black';
      break;
    case PreAdmitState.ReadyForAdmission:
      text.value = 'Form ready for admission';
      style.value = 'bg-medosync-dark-green bg-opacity-50 text-black';
      break;
    case PreAdmitState.NeedsRevision:
      text.value = 'Form needs patient revision';
      style.value = 'bg-medosync-dark-red border-medosync-dark-red text-white';
      break;
    case PreAdmitState.PatientAdmitted:
      text.value = 'Patient admitted';
      style.value = 'bg-medosync-dark-green border-medosync-dark-green text-white';
      break;
    case PreAdmitState.RequirementsAreNotMet:
      text.value = 'No form sent';
      style.value = 'bg-medosync-white border-2 border-medosync-black text-black';
      break;
    case PreAdmitState.AwaitingReplayForPatient:
      text.value = 'Form Sent ';
      style.value = 'bg-medosync-white border-medosync-dark-green border-2 text-black';
      break;
    case PreAdmitState.ReviewPatientResponse:
      text.value = 'Form ready for PAA review';
      style.value = 'bg-medosync-white border-medosync-orange border-2 text-black';
      break;
    case PreAdmitState.AllRequirementsMet:
      text.value = 'All requirements met';
      style.value = 'bg-medosync-dark-green bg-opacity-50  text-black';
      break;
  }
};

setFormStatus();
</script>

<template>
  <div
    v-if="text"
    class="flex items-center justify-center max-w-[164px] border px-7 text-center text-xs rounded-xl font-medium min-h-[30px] m-auto leading-3"
    :class="style"
  >
    {{ text }}
  </div>
</template>
