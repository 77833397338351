<template>
  <nav v-if="links.length > 0" aria-label="Breadcrumb">
    <ol class="flex flex-row">
      <li v-for="(link, index) in links" :key="index">
        <router-link v-if="link.to" :to="link.to" :aria-current="index == links.length - 1 ? 'page' : ''">
          <span class="capitalize cursor-pointer underline">{{ link.name }}</span>
        </router-link>

        <span v-else class="capitalize" :aria-current="index == links.length - 1 ? 'page' : ''">{{ link.name }}</span>
        
        <i v-if="index < links.length - 1" class="fas fa-chevron-right mx-3"></i>
      </li>
    </ol>
  </nav>
</template>

<script>
export default {
  name: 'BreadCrumbs',
  props: {
    links: {required: true}
  },
}
</script>

<style lang="scss" scoped>
.underline:hover {
  color: #0C276C;
}
</style>

