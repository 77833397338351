<script setup>
import { ref, onMounted } from 'vue';
defineProps({
  image: {
    type: Object,
    required: true
  }
});

const refImageContainer = ref(null);
const zoom = ref(1.0);
const zoomBy = ref(0.2);

const zoomIn = e => {
  e.preventDefault();
  zoom.value += zoomBy.value;
  zoom.value = parseFloat(zoom.value.toFixed(1));
};

const resetZoom = e => {
  e.preventDefault();
  zoom.value = 1;
};

const mouseDown = ref(false);
const startX = ref(0);
const startY = ref(0);
const scrollLeft = ref(0);
const scrollTop = ref(0);

const startDragging = e => {
  e.preventDefault();
  mouseDown.value = true;
  startX.value = e.pageX - refImageContainer.value.offsetLeft;
  startY.value = e.pageY - refImageContainer.value.offsetTop;
  scrollLeft.value = refImageContainer.value.scrollLeft;
  scrollTop.value = refImageContainer.value.scrollTop;
};

const stopDragging = e => {
  e.preventDefault();
  mouseDown.value = false;
};

const move = e => {
  e.preventDefault();
  if (!mouseDown.value) {
    return;
  }
  const x = e.pageX - refImageContainer.value.offsetLeft;
  const y = e.pageY - refImageContainer.value.offsetTop;
  const scrollX = x - startX.value;
  const scrollY = y - startY.value;
  refImageContainer.value.scrollLeft = scrollLeft.value - scrollX;
  refImageContainer.value.scrollTop = scrollTop.value - scrollY;
};

onMounted(() => {
  refImageContainer.value.addEventListener('mousemove', move, false);
  refImageContainer.value.addEventListener('mousedown', startDragging, false);
  refImageContainer.value.addEventListener('mouseup', stopDragging, false);
  refImageContainer.value.addEventListener('mouseleave', stopDragging, false);
});
</script>

<template>
  <div class="py-5 mb-1">
    <i
      class="fas fa-magnifying-glass-plus float-right fa-xl cursor-pointer"
      @click="zoomIn"
      data-test-id="notifications-image-zoom-in-button"
    />
    <i
      class="fas fa-magnifying-glass-minus float-right fa-xl cursor-pointer me-4"
      @click="resetZoom"
      data-test-id="notifications-image-zoom-out-button"
    />
  </div>
  <div ref="refImageContainer" class="max-w-full grow shrink overflow-auto pe-3">
    <img
      class="w-full origin-top-left"
      :style="{ transform: `scale(${zoom})` }"
      :src="'data:image/png;base64, ' + image.data"
      :alt="image.file_name"
    />
  </div>
</template>
