<template>
  <div class="nav-section">
    <span class="nav-title ml-3">Claims</span>

    <span v-for="(item, index) in menuItems" :key="index">
      <div
        v-if="item.active"
        @click="
          setActive(item);
          goTo();
        "
        class="nav-item"
        :class="{ 'nav-item-active': item.on }"
        :title="item.info"
      >
        <div class="item-name">
          {{ item.name }}
        </div>
        <div class="item-count">
          {{ item.count }}
        </div>
      </div>
    </span>

    <div v-if="claimTags" class="nav-tags">
      <div class="tag-title">
        <!-- Tags -->
      </div>

      <span v-for="(item, index) in claimTags" :key="index">
        <div v-if="item.active" @click="setTag(item), goTo()" class="nav-item" :class="{ 'tag-item-active': item.on }">
          <div class="item-name">
            {{ item.name }}
          </div>
          <div class="item-count" v-if="item.count > 0">
            {{ item.count }}
          </div>
        </div>
      </span>
    </div>
  </div>
</template>

<script>
import { ClaimPhase, CompositeClaimStateEnum } from 'enums';
import { OpenAPIJSON } from 'api/openapi';

export default {
  name: 'CompositeClaimsLeftNav',
  props: ['refresh'],
  emits: ['set-subtitle'],
  data() {
    return {
      menuItems: [
        {
          name: 'All Claims',
          link: 'all',
          count: 0,
          active: true,
          info: 'Claims in any state'
        },
        {
          name: 'Awaiting Data',
          link: CompositeClaimStateEnum.AWAITING_DATA,
          count: 0,
          active: true,
          info: 'Claims awaiting external data before they can be edited',
          phase: ClaimPhase.PRE_SUBMIT
        },
        {
          name: 'Draft Claims',
          link: CompositeClaimStateEnum.DRAFT,
          count: 0,
          active: true,
          info: 'New claims',
          phase: ClaimPhase.PRE_SUBMIT
        },
        {
          name: 'Ready for Approval',
          link: CompositeClaimStateEnum.READY_FOR_APPROVAL,
          count: 0,
          active: true,
          info: 'Prepared claims awaiting sign-off',
          phase: ClaimPhase.PRE_SUBMIT
        },
        {
          name: 'Rejected By Consultant',
          link: CompositeClaimStateEnum.REJECTED_FOR_AMENDMENT,
          count: 0,
          active: true,
          info: 'Claims that have been rejected for signing and may require further changes.',
          phase: ClaimPhase.PRE_SUBMIT
        },
        {
          name: 'Approved',
          link: CompositeClaimStateEnum.APPROVED,
          count: 0,
          active: true,
          info: 'Claims that have been signed-off.  A PDF preview will now display their signature and signing-date.',
          phase: ClaimPhase.PRE_SUBMIT
        },
        {
          name: 'Ready for submission',
          link: CompositeClaimStateEnum.READY_FOR_SEND_TO_INSURER,
          count: 0,
          active: true,
          info: 'Claims that have been signed-off by the consultant that have been marked as complete and as such now have a immutable invoice with real invoice number.  The claim-pack can be downloaded',
          phase: ClaimPhase.PRE_SUBMIT
        },
        {
          name: 'Submitted',
          link: CompositeClaimStateEnum.SENT_TO_INSURER,
          count: 0,
          active: true,
          info: 'Claims that have been submitted to the insurer/entered into Claimsure.',
          phase: ClaimPhase.POST_SUBMIT
        },
        {
          name: 'Pended',
          link: CompositeClaimStateEnum.PENDED_WITH_HOSPITAL,
          count: 0,
          active: true,
          info: 'Submitted claims returned by the insurance company for which it has asked for additional information before adjudication can be performed.',
          phase: ClaimPhase.POST_SUBMIT
        },
        {
          name: 'Rejected',
          link: CompositeClaimStateEnum.REJECTED,
          count: 0,
          active: true,
          info: 'Claims that have been adjudicated by insurance company and rejected for payment.',
          phase: ClaimPhase.POST_SUBMIT
        },
        {
          name: 'For Appeal',
          link: CompositeClaimStateEnum.FOR_APPEAL,
          count: 0,
          active: true,
          info: 'Claims rejected by the insurance company that the hospital is going to appeal.',
          phase: ClaimPhase.POST_SUBMIT
        },
        {
          name: 'Part Paid',
          link: CompositeClaimStateEnum.PART_PAID,
          count: 0,
          active: true,
          info: 'Claims that the insurer has not paid 100% of the invoice.',
          phase: ClaimPhase.POST_SUBMIT
        },
        {
          name: 'Over-paid',
          link: CompositeClaimStateEnum.OVER_PAID,
          count: 0,
          active: true,
          info: 'Claims where the remittance total is greater than the invoice total, which likely indicates an error in the remittances',
          phase: ClaimPhase.POST_SUBMIT
        },
        {
          name: 'Paid in Full',
          link: CompositeClaimStateEnum.PAID_IN_FULL,
          count: 0,
          active: true,
          info: 'Claims for which the sum of payments received from the insurer matches the invoiced total',
          phase: ClaimPhase.POST_SUBMIT
        },
        {
          name: 'Written Off - Appeal Denied',
          link: CompositeClaimStateEnum.WRITE_OFF_APPEAL_DENIED,
          count: 0,
          active: true,
          phase: ClaimPhase.FINISHED
        },
        {
          name: 'Written Off - Rejected',
          link: CompositeClaimStateEnum.WRITE_OFF_REJECTED,
          count: 0,
          active: true,
          phase: ClaimPhase.FINISHED
        },
        {
          name: 'Written Off - Billed Incorrectly',
          link: CompositeClaimStateEnum.WRITE_OFF_BILLED_INCORRECTLY,
          count: 0,
          active: true,
          phase: ClaimPhase.FINISHED
        },
        {
          name: 'Manually Rejected',
          link: CompositeClaimStateEnum.MANUAL_REJECTED,
          count: 0,
          active: true,
          info: 'Claims rejected for further processing and will not be submitted to the insurer.',
          phase: ClaimPhase.FINISHED
        }
      ],
      selectedItem: { name: 'All Claims', link: 'all', count: 0, active: true },
      selectedTag: {
        name: 'all'
      },
      claimStates: null,
      claimTags: null
    };
  },
  watch: {
    refresh: function () {
      this.fetch();
    }
  },
  methods: {
    setActive(el) {
      this.menuItems.forEach(item => {
        item.on = false;
      });
      el.on = true;
      this.selectedItem = el;
      this.$emit('set-subtitle', this.selectedItem?.name);
      // Refresh tag names & counts based on claim-type selected.
      this.selectedTag.name = 'all';
      this.getCompositeClaimTagsCount();
      setTimeout(this.listPreference, 500);
    },

    /* use for selecting multiple tags */
    setTag(tag) {
      this.selectedTag = tag;
      // reset all others to false
      this.claimTags.forEach(item => {
        item.on = false;
      });
      // set selected to true
      tag.on = true;
    },

    goTo() {
      this.$router.push({ path: '/navigator/compositeclaims/' + this.selectedItem.link + '/' + this.selectedTag.name });
    },

    listPreference() {
      let ls = JSON.parse(localStorage.getItem('preferences'));

      let tagsLayout = ls?.tagsLayout;
      let menuLayout = ls?.menuLayout;

      if (tagsLayout) {
        this.claimTags?.forEach(tag => {
          tagsLayout.forEach(item => {
            if (tag.name.toLowerCase() === item.name.toLowerCase()) {
              tag.active = item.active;
            }
          });
        });
      }

      if (menuLayout) {
        this.menuItems?.forEach(m => {
          menuLayout.forEach(item => {
            if (m.name.toLowerCase() === item.name.toLowerCase()) {
              m.active = item.active;
            }
          });
        });
      }
    },
    async getCompositeClaimCount() {
      const oapi = new OpenAPIJSON();
      let response = await oapi.getCompositeClaimCount();
      this.claimStates = response;
    },

    async getCompositeClaimTagsCount() {
      const claimState = this.selectedItem && this.selectedItem.link !== 'all' ? [this.selectedItem.link] : null;
      const oapi = new OpenAPIJSON();
      let response = await oapi.getCompositeClaimTagsCount(null, null, claimState);

      if (response.length > 0) {
        for (const item of response) {
          item.on = false;
        }
      }
      // add custom tag for filtering all
      let allTag = {
        name: 'all',
        count: -1,
        on: true,
        active: true
      };
      // add reset tag to claim tags
      response.splice(0, 0, allTag);

      this.claimTags = response;
    },

    async fetch() {
      await this.getCompositeClaimCount();
      await this.getCompositeClaimTagsCount();

      let object = {};
      let count = 0;
      object = this.claimStates;

      for (const property in object) {
        this.menuItems.forEach(item => {
          if (`${property}` === item.link) {
            item.count = parseInt(`${object[property]}`);
          }
        });
        count += parseInt(`${object[property]}`);
      }
      for (let i = 0; i < this.menuItems.length; i++) {
        if (this.menuItems[i].link === 'all') {
          this.menuItems[i].count = count;
          break;
        }
      }
    },

    setInitialItem() {
      const initialItem = this.menuItems.find(item => item.link === this.$route.params?.filter);
      this.selectedItem = initialItem ? initialItem : { link: this.$route.params?.filter };
      this.$emit('set-subtitle', this.selectedItem?.name);
    }
  },

  async mounted() {
    //console.log("this.route =", this.$route.params)
    // Ensure that if somebody goes directly to a route, from a saved link, then the
    // correct tags are shown.
    this.setInitialItem();
    await this.fetch();
    this.listPreference();
  }
};
</script>

<style lang="scss" scoped>
.nav-section {
  float: left;
  height: 100vh;
  padding-top: 50px;
  width: 100%;

  .nav-item {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 12px;
    margin-top: 12px;
    padding-top: 5px;
    padding-bottom: 5px;
    padding-left: 15px;
    font-family: 'Roboto';
    font-weight: 600;
    font-size: 14px;
    line-height: 12px;
    color: #0c276c;
    cursor: pointer;

    :hover {
      opacity: 0.75;
    }

    .item-name {
      font-size: 14px;
      float: left;
      width: 100px;

      &::first-letter {
        text-transform: uppercase;
      }
    }

    .item-count {
      display: flex;
      height: 100%;
      margin-right: 10px;
      font-size: 14px;
      font-weight: 400;
      align-items: center;
      align-self: center;
      padding: 3px 6px 1px 6px;
      border-radius: 20px;
      background: #00a7e1;
      color: #fff;
    }
  }

  .nav-item-active {
    background-color: #00a7e1;
  }

  .item-name {
    padding-top: 2px;
  }

  .tag-title {
    width: 200px;
    margin-bottom: 15px;
    padding-top: 0px;
    padding-bottom: 8px;
    padding-left: 15px;
    font-family: 'Roboto';
    font-weight: 600;
    font-size: 14px;
    line-height: 16px;
    text-transform: uppercase;
    border-left: 5px solid #ededed;
    border-top: 1px solid #dedede;
    color: #0c276c;
    cursor: pointer;
  }

  .tag-item-active {
    border-left: 5px solid #00a7e1;
    background: #00a7e1;
    color: #fff;

    .item-count {
      font-weight: 500;
      background: #fff;
      color: #000;
    }
  }

  .nav-tags {
    padding-bottom: 15px;
  }
}

.nav-title {
  font-size: 16px;
  font-family: 'Roboto';
  font-weight: 600;
  color: #0c276c;
}
</style>
