<template>
  <div>
    <label for="value" class="text-sm font-medium text-gray-700 mr-2">Severity</label>
    <div class="mt-1">
      <select
        :id="inputId"
        v-model="localData"
        class="block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
      >
        <option
          v-for="dtype in diagnosisSeverity"
          :key="dtype.type_id"
          :value="dtype.oapi_name"
          :disabled="isTextBoxDisabled"
        >
          {{ dtype.display_name }}
        </option>
      </select>
    </div>
  </div>
</template>

<script>
import { OpenAPIJSON } from 'api/openapi';
import { mapState } from 'vuex';

export default {
  name: 'DiagnosisSeverity',
  props: {
    modelValue: { default: '' },
    id: {}
  },
  emits: ['update:modelValue'],
  data() {
    return {
      openApi: new OpenAPIJSON(),
      inputId: this.id || `diag_sev_${(Math.random() * 1000).toFixed()}`,
      diagnosisSeverity: []
    };
  },
  computed: {
    ...mapState(['textBoxDisabled']),
    isTextBoxDisabled() {
      return this.textBoxDisabled;
    },
    localData: {
      get() {
        return this.modelValue;
      },
      set(newValue) {
        this.$emit('update:modelValue', newValue);
      }
    }
  },
  created() {
    this.listDiagnosisSeverity();
  },
  methods: {
    async listDiagnosisSeverity() {
      try {
        this.diagnosisSeverity = await this.openApi.listDiagnosisSeverity();
      } catch (e) {
        this.$refs?.toast?.showToast(e, 'fa-solid fa-triangle-exclamation', 'error');
      }
    }
  }
};
</script>
