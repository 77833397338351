<template>
  <div class="relative">
    <button @click="togglePalette" type="button" :style="[selectedColor ? {color: selectedColor, borderColor: selectedColor} : '']" class="btn btn-hollow-blue btn-small">
      <span class="text">{{getColorName(selectedColor)}}</span> <span><i class="fas fa-chevron-down ml-2"></i></span>
    </button>

    <div v-if="openPalette" v-click-outside="closePalette" class="color-container flex flex-wrap absolute bg-white justify-around shadow-lg px-3 py-2 gap-y-2">
      <div @click.stop="pickColor(color)" v-for="(color,index) in colors" :key="index" class="color-option" :style="{backgroundColor: color.color}"></div>
      <div @click.stop="pickColor(null)" class="none flex justify-center items-center"><i class="fas fa-ban mr-2"></i> NONE</div>
    </div>
  </div>
</template>

<script>
import vClickOutside from 'click-outside-vue3'
import { getColorName, colors } from "@/util/userPrefs"

export default {
  directives: {
    clickOutside: vClickOutside.directive
  },

  props: ['modelValue', 'defaultValue'],
  emits: ['update:modelValue'],

  data () {
    return {
      openPalette: false,
      selectedColor: this.modelValue,
      colors: colors
    }
  },

  watch: {
    modelValue () {
      this.selectedColor = this.modelValue;
    }
  },

  methods: {
    togglePalette () {
      this.openPalette = !this.openPalette;
    },
    closePalette () {
      this.openPalette = false;
    },
    pickColor (color) {
      this.selectedColor = color ? color.color : null;
      this.updateValue();
      this.closePalette();
    },
    updateValue: function () {
      this.$emit('update:modelValue', this.selectedColor)
    },
    getColorName
  },
}
</script>

<style lang='scss' scoped>
.text {
  display: inline-block;
  width: 85px;
}
.color-container {
  width: 145px;
  z-index: 500;
  border-radius: 10px;
  left: calc(50% - 72.5px);
}
.color-option {
  border-radius: 100%;
  height: 25px;
  width: 25px;
}
.color-option:hover {
  outline: 1px solid #101010;
}
.none {
  border-radius: 25px;
  padding: 4px 8px;
  cursor: pointer;
}
.none:hover {
  outline: 1px solid #101010;
}
.btn {
  border-radius: 25px;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 20px;
  padding-right: 20px;
}

.btn-small {
  font-size: 14px;
  font-weight: 600;
}

.btn-hollow-blue {
  background-color: rgba($color: #FFFFFF, $alpha: 0.0);
  color: #23A6F0;
  border: 1px solid #23A6F0;
}
</style>