<template>
  <div :id="inputId" class="cdiagnosis-section">
    <div class="col-12">
      <div class="flex flex-col">
        <div class="my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div class="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
            <div class="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
              <table class="min-w-full divide-y divide-gray-200">
                <thead class="bg-gray-50">
                  <tr>
                    <th
                      scope="col"
                      class="width-15 px-2 py-3 text-left text-xs font-bold text-gray-500 uppercase tracking-wider"
                    >
                      Diagnosis Type
                    </th>
                    <th
                      scope="col"
                      class="px-2 py-3 text-left text-xs font-bold text-gray-500 uppercase tracking-wider"
                    >
                      Description
                    </th>
                    <th
                      v-if="enable_severity"
                      scope="col"
                      class="width-20 px-6 py-3 w-90 text-left text-xs font-bold text-gray-500 uppercase tracking-wider"
                    >
                      Severity
                    </th>
                    <th
                      v-if="enable_icd9"
                      scope="col"
                      class="width-10 px-6 py-3 w-90 text-left text-xs font-bold text-gray-500 uppercase tracking-wider"
                    >
                      ICD 9 Code
                    </th>
                    <th
                      v-if="enable_icd10"
                      scope="col"
                      class="width-10 px-6 py-3 w-90 text-left text-xs font-bold text-gray-500 uppercase tracking-wider"
                    >
                      ICD 10 Code
                    </th>
                    <th
                      scope="col"
                      class="width-10 px-6 py-3 text-left text-xs font-bold text-gray-500 uppercase tracking-wider"
                    ></th>
                  </tr>
                </thead>

                <tbody>
                  <!-- Grey/white alternate rows -->
                  <tr
                    :id="inputId + '_row'"
                    :class="{ 'bg-white': idx % 2 !== 0, 'bg-gray-50': idx % 2 === 0 }"
                    v-for="(diag, idx) in diagnosis"
                    :key="diag.diagnosisId"
                  >
                    <td class="py-4 whitespace-nowrap text-sm text-gray-500">
                      <DiagnosisTypes
                        class="px-2 py-2 mt-2"
                        :id="inputId + '_type' + idx"
                        v-model="diag.diagnosis_type"
                        :diagnosisTypes="diagnosisTypes"
                      />
                    </td>
                    <td class="px-2 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                      <TextQuestion
                        :id="inputId + '_secondary_description' + idx"
                        v-model="diag.description"
                        :rows="2"
                        style="line-height: 1.5"
                      />
                    </td>
                    <td v-if="enable_severity" class="py-4 whitespace-nowrap text-sm text-gray-500">
                      <DiagnosisSeverity
                        class="px-6 py-2 mt-2"
                        :id="inputId + '_secondary_severity' + idx"
                        v-model="diag.severity"
                      />
                    </td>
                    <td v-if="enable_icd9" class="py-4 whitespace-nowrap text-sm text-gray-500">
                      <TextQuestion class="px-6" :id="inputId + '_secondary_icd9' + idx" v-model="diag.icd9_code" />
                    </td>
                    <td v-if="enable_icd10" class="py-4 whitespace-nowrap text-sm text-gray-500">
                      <TextQuestion class="px-6" :id="inputId + '_secondary_icd10' + idx" v-model="diag.icd10_code" />
                    </td>

                    <td
                      v-if="!isTextBoxDisabled"
                      :id="inputId + '_remove' + idx"
                      class="px-2 py-4 whitespace-nowrap text-sm text-gray-500 font-semibold"
                      @click="removeSecondaryDiagnosis(idx, diagnosis)"
                    >
                      <i class="fas fa-minus-circle text-red-600"></i> Remove
                    </td>
                    <td
                      v-else
                      :id="inputId + '_remove' + idx"
                      class="px-2 py-4 whitespace-nowrap text-sm text-gray-500 font-semibold"
                    >
                      <i class="fas fa-minus-circle text-grey-600"></i> Remove
                    </td>
                  </tr>
                </tbody>
              </table>

              <div class="col-6 mt-4 pl-5">
                <div
                  v-if="!textBoxDisabled"
                  :id="inputId + '_add_secondary_diagnosis'"
                  class="my-3 custom-click cursor-pointer font-semibold"
                  @click="addSecondaryDiagnosis()"
                >
                  <i class="fas fa-plus-circle text-green-600"></i> Add another diagnosis
                </div>
                <div v-else class="my-3 custom-click cursor-pointer font-semibold text-gray-200">
                  <i class="fas fa-plus-circle text-gray-200"></i> Add another diagnosis
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import DiagnosisSeverity from '@/components/form-fields/DiagnosisSeverity.vue';
import TextQuestion from '@/components/form-fields/TextQuestion.vue';
import DiagnosisTypes from '@/components/form-fields/DiagnosisType.vue';

/* Params 
  modelValue // objects with diagnosis fields
*/

export default {
  name: 'DiagnosisList',
  props: {
    modelValue: {},
    id: {},
    enable_severity: { required: false, type: Boolean },
    enable_icd: { required: false, type: Boolean },
    enable_icd9: { required: false, type: Boolean },
    enable_icd10: { required: false, type: Boolean },
    diagnosisTypes: { required: false, type: Array }
  },
  components: {
    DiagnosisTypes,
    TextQuestion,
    DiagnosisSeverity
  },
  data() {
    return {
      secIdx: -1,
      forceRender: 0,
      inputId: this.id || `diagnosis_list${(Math.random() * 1000).toFixed()}`
    };
  },
  computed: {
    ...mapState(['textBoxDisabled']),
    isTextBoxDisabled() {
      return this.textBoxDisabled;
    },
    diagnosis: {
      get() {
        return this.modelValue;
      },
      set(newValue) {
        this.$emit('update:modelValue', newValue);
      }
    }
  },
  methods: {
    addSecondaryDiagnosis() {
      // Fake, negative diagnosis ids created here to allow new entries to be removed.
      // Submission to server ignores this id and creates its own.
      let dtype;
      if (this.diagnosis.length === 0) {
        dtype = 'primary';
      } else if (this.diagnosis.length >= 1) {
        dtype = 'secondary';
      }
      this.diagnosis.push({
        diagnosisId: this.secIdx,
        diagnosis_type: dtype,
        severity: 'not_specified'
      });
      this.secIdx -= 1;
      this.forceRender++;
    },
    removeSecondaryDiagnosis(index, diagnosis) {
      const diagnosisIndex = diagnosis[index];
      if (diagnosisIndex) {
        this.diagnosis.splice(index, 1);
      }
    }
  },
  mounted() {}
};
</script>

<style lang="scss" scoped></style>
