<template>
  <div>
    <canvas :id="id"></canvas>
  </div>
</template>

<script>
import { Chart, registerables } from 'chart.js';
import 'chartjs-adapter-date-fns';
Chart.register(...registerables);

const data_patients = {
  datasets: [
    {
      label: "Total Invoiced",
      data: [0, 10, 5, 2, 20, 30, 45],
      backgroundColor: '#EFC662',
      borderColor: '#EFC662',
      stack: "Stack 0",
    },
    {
      label: "Total Payments",
      data: [0, 10, 5, 2, 20, 30, 45],
      backgroundColor: '#E76A80',
      borderColor: '#E76A80',
      stack: "Stack 0",
    },
  ],
};

const config_patients = {
  type: 'bar',
  data: data_patients,
  options: {
    plugins: {
      title: {
        display: true,
        text: "Revenue",
      },
    },
    responsive: true,
    interaction: {
      intersect: false,
    },
    scales: {
      y: {
        ticks: {
          min: 0,
          max: 200,
          stepSize: 50
        }
      },
      x: {
        type: 'time',
        time: {
            unit: 'day'
        }
      },
    }
  },
};

const data_revenue = {
  datasets: [
    {
      label: "Unbilled",
      data: [0, 10, 5, 2, 20, 30, 45],
      backgroundColor: '#E76A80',
      borderColor: '#E76A80',
      stack: "Stack 0",
    },
    {
      label: "Billed",
      data: [0, 10, 5, 2, 20, 30, 45],
      backgroundColor: '#EFC662',
      borderColor: '#EFC662',
      stack: "Stack 0",
    },
    {
      label: "Paid",
      data: [0, 10, 5, 2, 20, 30, 45],
      backgroundColor: '#70B2B6',
      borderColor: '#70B2B6',
      stack: "Stack 0",
    }
  ],
};

const config_revenue = {
  type: 'bar',
  data: data_revenue,
  options: {
    plugins: {
      tooltip: {
        formattedValue: 'YYYY MMM DD'
      },
      title: {
        display: true,
        text: "Patients",
      },
    },
    responsive: true,
    interaction: {
      intersect: false,
    },
    scales: {
      y: {
        stacked: true,
      },
      x: {
        type: 'time',
        time: {
            unit: 'week',
            displayFormats: {
                day: 'MMM yyyy'
            }
        }
      },
    }
  },
};

export default {
  name: 'BarChart',

  props: {
    type: String,
    id: String,
    data1: Array,
    data2: Array,
    data3: Array
  },

  data() {
    return {
    }
  },

  watch: {
    data1() {
      this.reinit();
    },
    data2() {
      this.reinit();
    },
    data3() {
      this.reinit();
    }
  },

  mounted() {
    this.init();
  },

  methods: {
    init() {
      if (this.type === 'patients') {
        data_patients.datasets[0].data = this.data1;
        data_patients.datasets[1].data = this.data2;
        const ctx = document.getElementById(this.id);
        this.chart = new Chart(ctx, config_patients);
      } else if (this.type === 'revenue') {
        data_revenue.datasets[0].data = this.data1;
        data_revenue.datasets[1].data = this.data2;
        data_revenue.datasets[2].data = this.data3;
        const ctx = document.getElementById(this.id);
        this.chart = new Chart(ctx, config_revenue);
      }
    },

    reinit() {
      this.chart.destroy();
      
      if (this.type === 'patients') {
        data_patients.datasets[0].data = this.data1;
        data_patients.datasets[1].data = this.data2;
        const ctx = document.getElementById(this.id);
        this.chart = new Chart(ctx, config_patients);
      } else if (this.type === 'revenue') {
        data_revenue.datasets[0].data = this.data1;
        data_revenue.datasets[1].data = this.data2;
        data_revenue.datasets[2].data = this.data3;
        const ctx = document.getElementById(this.id);
        this.chart = new Chart(ctx, config_revenue);
      }
    }
  }
}
</script>