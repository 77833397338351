<template>
  <div class="patientsearch-dropdown">
    <Typeahead
      :id="inputId"
      v-model="selectedPatient"
      :items="patients"
      :placeholder="this.placeholder"
      :min-input-length="this.min_input"
      :item-projection="showItem"
      :item-value="getItemValue"
      @select-item="selectItem"
      :fullW="true"
      :test-id="testId"
    />
  </div>
</template>

<script>
import { OpenAPIJSON } from 'api/openapi';
import Typeahead from '@/components/claim-forms/components/Typeahead.vue';

export default {
  name: 'PatientTypeahead',
  components: {
    Typeahead
  },
  props: {
    placeholder: { require: false, default: 'Search' },
    min_input: { require: false, default: 1 },
    testId: { type: String, required: false },
    id: {}
  },
  emits: ['selectPatient'],
  data() {
    return {
      patients: [],
      selectedPatient: '',
      inputId: this.id || `patient_th_${(Math.random() * 1000).toFixed()}`
    };
  },
  methods: {
    async fetch_patients() {
      let oapi = new OpenAPIJSON();
      let resp = await oapi.searchPatients();
      this.patients = resp.items;
    },
    showItem(item) {
      return item.full_name + ' (' + item.mrn + ')';
    },
    getItemValue(item) {
      this.$emit('selectPatient', item);
      this.selectedPatient = item.full_name;
      return item.first_name;
    },
    selectItem(item) {
      this.$emit('selectPatient', item);
      this.selectedPatient = item.full_name;
    }
  },
  mounted() {
    this.fetch_patients();
  }
};
</script>

<style scoped>
.patientsearch-dropdown {
  display: inline-block;
}
</style>
