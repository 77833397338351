import { createStore } from 'vuex';
import createCache from 'vuex-cache';
import api from 'api/api';
import { OpenAPIJSON } from 'api';

// The general store is for fixed constants obtained from the BE
// and can remain for many minutes.
const GENERAL_STORE = 10 * 60 * 1000;

// A store that is for something akin to constants defined by the backend that are
// rare to be to be updated.  The store still has a timeout because moving from
// claim-to-claim will need to invalidate appointment context sensitive
// data, eventually, to prevent too much application memory growth.
export const api_store = createStore({
  plugins: [createCache({ timeout: GENERAL_STORE })],
  actions: {
    CLONE_CONTRACT: async (_, payload) => {
      return await api
        .cloneInsurerContract(payload.contractId, payload.newContractName, payload.forInsurer, payload.startDate)
        .then(res => {
          return res.data.data.cloneInsurerContract;
        })
        .catch(error => {
          console.log('error', error);
          return [];
        });
    },
    LIST_MY_MEDICS: async () => {
      const openApi = new OpenAPIJSON();
      return await openApi
        .searchDoctors(null, null, null, true)
        .then(res => {
          return res;
        })
        .catch(error => {
          console.log('error', error);
          return [];
        });
    },
    LIST_MY_CONSULTANTS: async () => {
      const openApi = new OpenAPIJSON();
      return await openApi
        .searchDoctors(null, null, null, true, false)
        .then(res => {
          return res;
        })
        .catch(error => {
          console.log('error', error);
          return [];
        });
    }
  }
});

// Store for general application state.
export const state_store = createStore({
  state() {
    return {
      // If not-null, then it points to the active appointment that the user
      // selected for Manage Claim.
      dashboardModalClaimOpen: null
    };
  }
});
