<template>
  <div class="col-12 mt-4">
    <label for="value" class="text-sm font-medium text-gray-700 mr-2">Title:</label>
    <div class="mt-1">
      <span v-if="textBoxDisabled" class="block w-full text-sm text-gray-500">{{ modelValue ? modelValue : 'N/A' }}</span>
      <input
          v-else
          :id="inputId" v-model="localData"
          class="block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
          :placeholder="placeholder"
          :disabled="isTextBoxDisabled"
      />
    </div>
  </div>
</template>

<script>
import StringUtility from "utils/stringUtility";
import { mapState } from 'vuex';

export default {
  name: "FormTitle",
  props: {
    placeholder: { type: String, required: false, default: "Title" },
    modelValue: { default: "" },
    id: {},
  },
  emits: ['update:modelValue'],
  data() {
    return {
      inputId: this.id || `title_${(Math.random() * 1000).toFixed()}`,
    }
  },
  computed: {
    ...mapState(['textBoxDisabled']),
    isTextBoxDisabled() {
      return this.textBoxDisabled;
    },
    StringUtility() {
      return StringUtility
    },
    localData: {
      get() {
        return this.modelValue
      },
      set(newValue) {
        this.$emit('update:modelValue', newValue)
      }
    }
  },
}
</script>
