/**
 * Create the link to the claim-form from the item structure of an appointment.
 * Modifies the item structure to add the link.
 *
 * @param item
 */
 export function generateClaimLink(item) {
    const link_args = '/' + item.claim_consultant.person_id + '/' + item.app_id + '/' + item.claim_id
    item.insurer_cf = ''
    switch (item.claim_form_type.toLowerCase()) {
        case 'laya_inpatient':
            item.link = '/claim-form/laya' + link_args
            item.customLogo = {
                name: 'Laya',
                color: '#18B8EB'
            }
            break;
        case 'irish_life_hospital':
            item.link = '/claim-form/irish-life' + link_args
            item.customLogo = {
                name: 'Irish Life',
                color: '#61BCAC'
            }
            break;
        case 'irish_life_hospital2022':
            item.link = '/claim-form/irish-life-hospital-2022' + link_args
            item.customLogo = {
                name: 'Irish Life',
                color: '#61BCAC'
            }
            break;
        case 'irish_file_outpatient_scan':
            item.link = ''
            item.customLogo = {
                name: 'Irish Life Outpatient Scan',
                color: '#61BCAC'
            }
            break;
        case 'vhi_hospital':
            item.link = '/claim-form/vhi' + link_args
            item.customLogo = {
                name: 'Vhi',
                color: '#6E267A'
            }
            break;
        case 'prison_officers':
            item.link = '/claim-form/prison-officers' + link_args
            item.customLogo = {
                name: 'Prison Officers',
                color: '#e0931f'
            }
            break;
        case 'esb':
        case 'e_s_b':
            item.link = '/claim-form/esb' + link_args
            item.customLogo = {
                name: 'ESB',
                color: '#7DB04F'
            }
            break;
        case 'garda':
            item.link = '/claim-form/garda' + link_args
            item.customLogo = {
                name: 'Garda',
                color: '#e0931f'
            }
            break;
        case 'vhi_mri':
            item.insurer_cf = 'MRI'
            item.link = '/claim-form/vhi-mri' + link_args
            item.customLogo = {
                name: 'VHI MRI',
                color: '#6E267A'
            }
            break;
        case 'vhi_oncology':
            item.insurer_cf = 'Oncology'
            item.link = '/claim-form/vhi-oncology' + link_args
            item.customLogo = {
                name: 'VHI Oncology',
                color: '#6E267A'
            }
            break;
        case 'vhi_surgical':
            item.insurer_cf = 'Surgical'
            item.link = '/claim-form/vhi-surgical' + link_args
            item.customLogo = {
                name: 'VHI Surgical',
                color: '#6E267A'
            }
            break;
        case 'vhi_c_t':
            item.insurer_cf = 'CT'
            item.link = '/claim-form/vhi-ct' + link_args
            item.customLogo = {
                name: 'VHI CT',
                color: '#6E267A'
            }
            break;
        default:
            // There can be many other companies that provide insurance for their employees.
            // These will all default to self-pay.
            item.link = '#'
            item.customLogo = {
                name: item.insurance.insurerName,
                color: '#63813D'
            }
            break;
    }
}