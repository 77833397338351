<template>
  <transition v-if="rendered" name="modal">
    <div v-show="shouldShow" class="modal-mask">
      <div class="modal-wrapper">
        <div
          v-click-outside="onClickOutside"
          class="modal-container"
          :class="[expanded ? 'modal-container-width-expanded' : 'modal-container-width']"
        >
          <div class="relative">
            <i v-if="!hideCloseButton" @click="onClose" class="fas fa-times close-button"> </i>
            <i v-if="!hideExpandButton" @click="onExpand" class="fas fa-expand-alt expand-button"> </i>
          </div>
          <div v-if="!hideHeader" class="modal-header">
            <slot name="header" />
          </div>

          <div v-if="!hideBody" class="modal-body">
            <slot name="body" />
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import vClickOutside from 'click-outside-vue3';

export default {
  directives: {
    clickOutside: vClickOutside.directive
  },
  props: {
    closeOnClickOutside: {
      type: Boolean,
      default: false
    },
    hideHeader: {
      type: Boolean,
      default: false
    },
    hideBody: {
      type: Boolean,
      default: false
    },
    hideCloseButton: {
      type: Boolean,
      default: false
    },
    hideExpandButton: {
      type: Boolean,
      default: true
    }
  },
  name: 'ModalComponent',
  data() {
    return {
      rendered: false,
      shouldShow: false,
      expanded: false
    };
  },
  mounted() {
    setTimeout(() => {
      this.rendered = true;
      setTimeout(() => {
        this.shouldShow = true;
      }, 0);
    }, 0);
  },
  methods: {
    onClickOutside() {
      if (this.closeOnClickOutside) this.onClose();
    },
    onClose() {
      this.shouldShow = false;
      setTimeout(() => {
        this.$emit('close-modal');
      }, 200);
    },
    onConfirm() {
      this.$emit('on-confirm');
    },
    onCancel() {
      this.onClose();
    },
    onExpand() {
      this.expanded = !this.expanded;
    }
  }
};
</script>

<style lang="scss" scoped>
.modal-mask {
  position: fixed;
  z-index: 2000;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  min-height: -webkit-fill-available;
  background-color: rgba(0, 0, 0, 0.5);
  display: table;
  transition: opacity 0.3s ease;
}

.modal-wrapper {
  display: table-cell;
  vertical-align: middle;
}

.modal-container {
  max-width: 100%;
  margin: 0px auto;
  padding: 20px 30px;
  background-color: #fff;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
  transition: all 0.3s ease;
  max-height: 98vh;
  overflow: auto;
  position: relative;
}

.modal-container-width {
  width: 50%;
}

.modal-container-width-expanded {
  width: 90%;
}

.modal-header {
  border: none;
  position: relative;
}

.modal-enter {
  opacity: 0;
}

.modal-leave-active {
  opacity: 0;
}

.modal-enter .modal-container,
.modal-leave-active .modal-container {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}

.close-button {
  z-index: 9000;
  margin-top: 2px;
  cursor: pointer;
  right: 0;
  position: absolute;
  color: rgb(83, 83, 83);
  font-size: 24px;
}

.expand-button {
  z-index: 9000;
  margin-top: 2px;
  cursor: pointer;
  right: 35px;
  position: absolute;
  color: rgb(83, 83, 83);
  font-size: 20px;
}

@media only screen and (max-width: 1024px) {
  .modal-container {
    width: 90%;
  }
}
</style>
