<template>
  <div :id="inputId" class="list-gp-section col-12">
    <div style="clear: both"></div>

    <div v-if="question" class="sub-title text-sm font-medium text-gray-700">{{ question }}</div>

    <div style="clear: both"></div>

    <div class="float-left col-6">
      <!-- update:model-value is a workaround. Typeahead component needs refactoring -->
      <Typeahead
        :id="inputId + '_fullname'"
        :class="inputClass"
        :modelValue="medicInput.ro.full_name"
        @update:model-value="
          newValue => {
            if (typeof newValue !== 'string' && typeof newValue?.ro?.full_name !== 'string') return;
            medicInput.ro.full_name = newValue;
          }
        "
        :items="medics"
        :placeholder="hint"
        :min-input-length="1"
        :item-projection="show_suggestions"
        :item-value="show_name"
        @select-item="select_by_fullname"
        :fullW="true"
        :test-id="testId"
        :max-height="maxHeight"
      />
    </div>
  </div>
</template>

<script>
import Typeahead from '@/components/claim-forms/components/Typeahead.vue';
import { OpenAPIJSON } from 'api/openapi';
/* Params
  modelValue: the 'Doctor' data structure, consisting of at least personId, firstname, lastname
     fields.
*/

export default {
  name: 'ListMedics',
  props: {
    modelValue: { default: '' },
    question: { required: false, type: String },
    id: { required: true, type: String },
    enable_gp: { required: false, type: Boolean, default: false },
    enable_consultant: { required: true, type: Boolean, default: true },
    hint: { required: false, type: String, default: 'Search for Consultant' },
    inputClass: { required: false, type: String },
    testId: { required: false, type: String },
    maxHeight: { required: false, type: Number, default: 400 }
  },
  emits: ['update:modelValue'],

  components: {
    Typeahead
  },
  data() {
    return {
      medics: [],
      medicInput: {
        ro: {
          full_name: null
        }
      },
      inputId: this.id || `list_medics_${(Math.random() * 1000).toFixed()}`,
      api: new OpenAPIJSON()
    };
  },
  computed: {
    medicObj: {
      get() {
        return this.modelValue;
      }
    }
  },
  watch: {
    modelValue(newValue) {
      if (this.modelValue == '') return (this.medicInput = { ro: { full_name: null } });
      this.medicInput = newValue;
    },
    'medicInput.ro.full_name': async function (new_filter) {
      if (typeof new_filter === 'string' && !(new_filter instanceof Object)) {
        let search_term = new_filter.trim();
        let results = [];

        if (search_term.length > 0) {
          results = await this.api.searchDoctors(search_term);
        }

        if (results.length > 0) {
          if (this.enable_gp && this.enable_consultant) {
            // General search over both GPs and Hospital Consultants.
            this.medics = results;
          } else if (this.enable_gp && !this.enable_consultant) {
            // General search over GPs only.
            this.medics = results.filter(item => item.medic.medic_type === 'gp');
          } else if (!this.enable_gp && this.enable_consultant) {
            this.medics = results.filter(item => item.medic.medic_type === 'consultant');
          }
        }
      }
    }
  },
  methods: {
    // For display in the typeahead suggestions
    show_suggestions(item) {
      if (this.enable_gp) {
        let name = item.medic.ro.full_name;
        if (item.hospital) {
          name += ' (' + item.hospital.ro.name + ')';
        }
        return name;
      } else {
        return item.medic.ro.full_name;
      }
    },

    // Sets the field value to display the doctor and emits the data structure.
    // This is only called if the user input matches to something in the type-ahead list.
    show_name(item) {
      // Claim-form GQL query is in camelCase
      return item;
    },
    select_by_fullname(item) {
      this.$emit('update:modelValue', item.medic);
      this.medicInput = item.medic;
    },

    async search(new_filter) {
      if (typeof new_filter === 'string' && !(new_filter instanceof Object)) {
        let search_term = new_filter.trim();
        let results = [];

        if (search_term.length > 0) {
          results = await this.api.searchDoctors(search_term);
        }

        if (results.length > 0) {
          if (this.enable_gp && this.enable_consultant) {
            // General search over both GPs and Hospital Consultants.
            this.medics = results;
          } else if (this.enable_gp && !this.enable_consultant) {
            // General search over GPs only.
            this.medics = results.filter(item => item.medic.medic_type === 'gp');
          } else if (!this.enable_gp && this.enable_consultant) {
            this.medics = results.filter(item => item.medic.medic_type === 'consultant');
          }
        }
      }
    }
  },
  mounted() {
    if (this.modelValue) {
      this.medicInput = this.medicObj;
    }
    this.search();
  }
};
</script>
