<template>
    <div :id="inputId" class="address-fields mt-3">
        <PostalAddress :id="inputId + '_address'" v-model="localData.postal_address" :test-id="testId" />

        <!-- section -->
        <div class="col-12 mt-3">

            <div class='col-6 float-left pr-2' :class="{ 'full-width': fullWidth }">
                <div class="mt-2">
                    <div>
                        <div class="text-left text-sm mb-1">
                            <label for="value" class="font-medium text-gray-700 mr-2">Landline/Office:</label>
                        </div>
                        <div class="col-12">
                            <input :id="inputId + '_landline'" type="text"
                                class="custom-input shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                                v-model='localData.telephone_landline' :disabled="isTextBoxDisabled">
                        </div>
                    </div>
                </div>
            </div>

            <div class='col-6 float-left' :class="{ 'full-width': fullWidth }">
                <div class="mt-2">

                    <div>
                        <div class="text-left text-sm mb-1">
                            <label for="value" class="font-medium text-gray-700 mr-2">Mobile: <span
                                    class="required">*</span></label>
                        </div>
                        <div class="col-12">
                            <input :id="inputId + '_mobile'" type="text" :data-test-id="`${testId}mobile`"
                                class="custom-input shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                                v-model="localData.telephone_mobile" :disabled="isTextBoxDisabled">
                        </div>
                    </div>

                </div>
            </div>

        </div>
        <!-- section end -->

        <!-- section -->
        <div class="col-12 mt-3">

            <div class='col-6 float-left pr-2' :class="{ 'full-width': fullWidth }">
                <div class="mt-2">
                    <div>
                        <div class="text-left text-sm mb-1">
                            <label for="value" class="font-medium text-gray-700 mr-2">Fax:</label>
                        </div>
                        <div class="col-12">
                            <input :id="inputId + '_fax'" type="text"
                                class="custom-input shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                                v-model='localData.telephone_fax' :disabled="isTextBoxDisabled">
                        </div>
                    </div>
                </div>
            </div>

            <!-- section end -->

            <!-- section -->

            <div class='col-6 float-left pr-2' :class="{ 'full-width': fullWidth }">
                <div class="mt-2">
                    <div>
                        <div class="text-left text-sm mb-1">
                            <span v-if="emailError" class="error-message">{{ emailError }}</span>
                            <label v-else for="value" class="font-medium text-gray-700 mr-2">Email:</label>
                            
                        </div>
                        <div class="col-12">
                            <input :id="inputId + '_email'" type="text" :data-test-id="`${testId}email`" @input="validateEmail(localData.email)" 
                                class="custom-input shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                                v-model='localData.email' :disabled="isTextBoxDisabled">
                        </div>
                    </div>
                </div>
            </div>

        </div>
        <!-- section end -->
    </div>
</template>

<script>

/* Representation of the CommonAddress structure used by the backend to provide contact information
for most entity types.
 */
import PostalAddress from "@/components/form-fields/PostalAddress.vue";
import { mapState, mapMutations } from 'vuex';

export default {
    name: 'CommonAddress',
    props: ['modelValue', "fullWidth", "id", "testId"],
    components: {
        PostalAddress
    },
    data() {
        return {
            inputId: this.id || `common_address_${(Math.random() * 1000).toFixed()}`,
            email: '',
            emailError: ''
        }
    },
    computed: {
        localData: {
            get() {
                return this.modelValue
            },
            set(newValue) {
                this.$emit('update:modelValue', newValue)
            }
        },
        ...mapState(['textBoxDisabled']),
        isTextBoxDisabled() {
            return this.textBoxDisabled;
        },
    },



    methods: {
        validateEmail(value) {
            this.email = value;
            const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

            if (!this.email) {
                this.emailError = '';
            } else if (!emailRegex.test(this.email)) {
                this.emailError = 'Please check email';
               
            } else {
                this.emailError = '';
               
            }
        },

        ...mapMutations(['setTextBoxDisabled']),
    },
}
</script>

<style lang="scss" scoped>
.full-width {
    width: 100% !important;
    padding: 0;
}

.title {
    text-align: left;
    font-size: 18px;
    font-weight: 400;
    font-style: italic;
    color: #0054ab;
}

.required {
    color: red;
}

.error-message {
  color: red;
}
</style>