<template>
  <section class="break">
    <ClaimSection v-model="section_patient_details" title="Patient, Appointment & Policy Details" />
    <div class="transition px-5">
      <div class="flex flex-col grid-rows-1 bg-white pb-5">
        <div class="col-12">
          <div class="col-6">
            <div class="col-8">
              <TextQuestion id="mrn" v-model="localData.patient.mrn" question="MRN" />
            </div>
          </div>
          <div class="col-4">
            <TextQuestion id="appid" v-model="localData.hospital_appointment_id" question="Appointment ID" />
          </div>
        </div>
        <div class="col-12">
          <div class="col-6">
            <div class="col-8">
              <FormTitle id="title" v-model="localData.patient.title" />
            </div>
          </div>
          <div>
            <EnumQuestion
              v-if="localData.claim_form_type === 'prison_officers' && localData.is_composite"
              id="discharge_state"
              v-model="localData.insurance.policy_holder_relationship"
              question="Beneficiary"
              :options="[
                { name: 'Child', value: 'child' },
                { name: 'Spouse / Partner', value: 'spouse' },
                { name: 'N/A', value: null }
              ]"
            />
          </div>
          <div v-if="!localData.insurance.policy_holder_relationship">
            <div class="col-4 mr-4">
              <TextQuestion id="firstname" v-model="localData.patient.first_name" question="Patient first name" />
            </div>
            <div class="col-4">
              <TextQuestion id="lastname" v-model="localData.patient.last_name" question="Patient last name" />
            </div>
          </div>
          <div v-if="localData.insurance.policy_holder_relationship && localData.claim_form_type === 'prison_officers'">
            <div v-if="localData.insurance.policy_holder_relationship === 'child'" class="col-2 mr-4">
              <TextQuestion id="firstname" v-model="patientChildrenNamesString" question="Patient name" />
            </div>
            <div v-if="localData.insurance.policy_holder_relationship === 'spouse'" class="col-2 mr-4">
              <TextQuestion id="firstname" v-model="localData.questions.patient_partner_name" question="Patient name" />
            </div>

            <div class="col-4 mr-4">
              <TextQuestion
                id="policyhilderfirstname"
                v-model="localData.patient.first_name"
                question="Policy Holder's First Name"
              />
            </div>
            <div class="col-4">
              <TextQuestion
                id="policyholderlastname"
                v-model="localData.patient.last_name"
                question="Policy Holder's Surname"
              />
            </div>
          </div>
        </div>
        <div class="col-12">
          <div class="col-6">
            <div class="col-8">
              <Gender id="gender" :genders="genders" v-model="localData.patient.gender" />
            </div>
          </div>
          <div class="col-6 mt-6">
            <label class="block text-sm font-medium text-gray-700">Date of Birth</label>
            <span v-if="textBoxDisabled" class="text-sm text-gray-500">{{
              localData.patient.dob ? formatDate(localData.patient.dob) : 'N/A'
            }}</span>
            <date-picker
              v-else
              v-model:value="localData.patient.dob"
              value-type="YYYY-MM-DD"
              format="DD-MM-YYYY"
              :disabled-date="disabledBeforeToday"
              :disabled="isTextBoxDisabled"
              :append-to-body="false"
            ></date-picker>
          </div>
        </div>

        <div class="col-6 mt-6">
          <InsurancePolicy
            :insurer_id="localData.insurance.insurer.id"
            v-model="localData.insurance.policy_plan"
            :policy_name="policy_name"
          />
          <InsuranceMembership v-model="localData.insurance.membership_no1" />
        </div>

        <ListMedics
          class="col-8 mt-6"
          id="admitting_consultant"
          v-model="localData.admission.consultant"
          :enable_consultant="true"
          question="Admitting Consultant"
        />
        <ListMedics
          class="col-8 mt-6"
          id="discharging_consultant"
          v-model="localData.discharge.consultant"
          :enable_consultant="true"
          question="Discharging Consultant"
        />

        <!-- <DateTimeQuestion class="mt-8" id="admitted" v-model="localData.admission.on"
          question="Date & Time of Admission" /> -->

        <div class="col-6 mt-6">
          <label class="block text-sm font-medium text-gray-700">Date &amp; Time of Admission</label>
          <span v-if="textBoxDisabled" class="block text-sm text-gray-500">{{
            datePickerAdmi ? formatDate(datePickerAdmi) : 'N/A'
          }}</span>
          <date-picker
            v-else
            v-model:value="datePickerAdmi"
            value-type="YYYY-MM-DD"
            format="DD-MM-YYYY"
            :disabled-date="disabledBeforeToday"
            :disabled="isTextBoxDisabled"
            :append-to-body="false"
          ></date-picker>
          <span v-if="textBoxDisabled" class="block text-sm text-gray-500" style="margin-top: 10px">{{
            timerPickerAdmi ? timerPickerAdmi : 'N/A'
          }}</span>
          <date-picker
            v-else
            style="margin-top: 10px"
            v-model:value="timerPickerAdmi"
            :minute-step="1"
            format="HH:mm"
            showSecond="00"
            value-type="HH:mm:ss"
            type="time"
            placeholder="HH:mm"
            :disabled="isTextBoxDisabled"
            :append-to-body="false"
          ></date-picker>
        </div>

        <div class="col-6 mt-6">
          <label class="block text-sm font-medium text-gray-700">Date &amp; Time of Discharge</label>
          <span v-if="textBoxDisabled" class="block text-sm text-gray-500">{{
            datePickerDischar ? formatDate(datePickerDischar) : 'N/A'
          }}</span>
          <date-picker
            v-else
            v-model:value="datePickerDischar"
            value-type="YYYY-MM-DD"
            format="DD-MM-YYYY"
            :disabled-date="disabledBeforeToday"
            :disabled="isTextBoxDisabled"
            :append-to-body="false"
          ></date-picker>
          <span v-if="textBoxDisabled" class="block text-sm text-gray-500" style="margin-top: 10px">{{
            timerPickerDischar ? timerPickerDischar : 'N/A'
          }}</span>
          <date-picker
            v-else
            style="margin-top: 10px"
            v-model:value="timerPickerDischar"
            :minute-step="1"
            format="HH:mm"
            showSecond="00"
            value-type="HH:mm:ss"
            type="time"
            placeholder="HH:mm"
            :disabled="isTextBoxDisabled"
            :append-to-body="false"
          ></date-picker>
        </div>

        <!-- <DateTimeQuestion id="discharged" v-model="localData.discharge.on" question="Date & Time of Discharge" /> -->
      </div>
    </div>
  </section>
</template>

<script>
import ClaimSection from '@/components/claim-forms/components/ClaimSection.vue';
import TextQuestion from '@/components/form-fields/TextQuestion.vue';
import FormTitle from '@/components/form-fields/Title.vue';
import Gender from '@/components/form-fields/Gender.vue';
//import DateQuestion from "@/components/form-fields/DateQuestion.vue";
import InsurancePolicy from '@/components/form-fields/InsurancePolicy.vue';
//import DateTimeQuestion from "@/components/form-fields/DateTimeQuestion.vue";
import ListMedics from '@/components/form-fields/List-medics.vue';
import DatePicker from 'vue-datepicker-next';
import 'vue-datepicker-next/index.css';
import { mapState } from 'vuex';
import InsuranceMembership from '@/components/form-fields/InsuranceMembership.vue';
import EnumQuestion from '@/components/claim-forms/components/EnumQuestion.vue';

export default {
  name: 'PatientPolicyDetails',
  props: ['modelValue', 'genders'],
  computed: {
    patientChildrenNamesString: {
      get() {
        return Array.isArray(this.localData.questions.patient_children_names)
          ? this.localData.questions.patient_children_names.join(', ')
          : this.localData.questions.patient_children_names;
      },
      set(value) {
        this.localData.questions.patient_children_names = value ? [value] : [];
      }
    },
    ...mapState(['textBoxDisabled']),
    isTextBoxDisabled() {
      return this.textBoxDisabled;
    },
    localData: {
      get() {
        return this.modelValue;
      },
      set(newValue) {
        this.$emit('update:modelValue', newValue);
      }
    },
    admitDate() {
      return this.localData.admission.on;
    },
    dischargeDate() {
      return this.localData.discharge.on;
    }
  },
  watch: {
    admitDate() {
      this.admitDate_();
    },
    dischargeDate() {
      this.dischargeDate_();
    },
    datePickerAdmi(newval) {
      this.localData.admission.on = newval + 'T' + this.timerPickerAdmi;
    },
    timerPickerAdmi(newval) {
      this.localData.admission.on = this.datePickerAdmi + 'T' + newval;
    },

    datePickerDischar(newval) {
      this.localData.discharge.on = newval + 'T' + this.timerPickerDischar;
    },
    timerPickerDischar(newval) {
      this.localData.discharge.on = this.datePickerDischar + 'T' + newval;
    }
  },
  components: {
    InsuranceMembership,
    ListMedics,
    InsurancePolicy,
    Gender,
    FormTitle,
    TextQuestion,
    ClaimSection,
    DatePicker,
    EnumQuestion
  },
  data() {
    return {
      section_patient_details: false,
      datePickerAdmi: null,
      timerPickerAdmi: '00:00',
      datePickerDischar: null,
      timerPickerDischar: '00:00',
      policy_name: ''
    };
  },
  methods: {
    disabledBeforeToday(date) {
      const today = new Date();
      today.setHours(0, 0, 0, 0);
      return date > today;
    },
    formatDate(date) {
      if (!date) return 'N/A';
      const dateObj = new Date(date);
      if (isNaN(dateObj)) return 'N/A';
      const day = String(dateObj.getDate()).padStart(2, '0');
      const month = String(dateObj.getMonth() + 1).padStart(2, '0');
      const year = String(dateObj.getFullYear()).slice(-2);
      return `${day}-${month}-${year}`;
    },
    admitDate_() {
      if (this.localData.admission.on != null) {
        var dateOnly = this.localData.admission.on.split('T')[0];
        var timeOnly = this.localData.admission.on.split('T')[1];
        this.datePickerAdmi = dateOnly;
        this.timerPickerAdmi = timeOnly;
        this.localData.admission.on = dateOnly + 'T' + timeOnly;
      }
    },
    dischargeDate_() {
      if (this.localData.discharge.on != null) {
        var dateOnly = this.localData.discharge.on.split('T')[0];
        var timeOnly = this.localData.discharge.on.split('T')[1];
        this.datePickerDischar = dateOnly;
        this.timerPickerDischar = timeOnly;
        this.localData.discharge.on = dateOnly + 'T' + timeOnly;
      }
    }
  },
  mounted() {
    this.admitDate_();
    this.dischargeDate_();
    // This is a hack to setup the InsurancePolicy name internal attribute.
    // Otherwise editing the claim will cause the policy name to disappear.
    this.policy_name = this.localData.insurance.policy_plan.ro.name;
  }
};
</script>

<style lang="scss" scoped>
.patient-detail {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.transition {
  transition: all 0.5s;
}
</style>
