<script setup lang="ts">
import { BaseDatePicker, BaseInput, BasePhoneNumberInput, BaseRadioButton, BaseTextarea } from 'ui';
import ClaimSectionTitle from '@/components/admission/ClaimSectionTitle.vue';

const props = defineProps({
  editMode: {
    type: Boolean,
    default: false
  },
  warnings: {
    type: Array,
    default: () => []
  },
  similarIllness: {
    type: Boolean,
    default: null
  }
});

const sectionHasWarnings = sectionId => {
  if (!props.warnings) return false;
  return props.warnings.some(warning => warning.id === sectionId);
};
</script>

<template>
  <div>
    <ClaimSectionTitle id="irish-life-illness-history-title" title="Illness History" />
    <BaseDatePicker
      id="first-symptoms-date"
      name="firstSymptomsDate"
      class="mt-6"
      label="When did you first suffer from these symptoms or illness?"
      placeholder="dd.mm.yyyy"
      :edit-mode="editMode"
    />
    <BaseDatePicker
      id="first-consultation"
      name="firstConsultation"
      class="mt-6 sm:mt-12"
      label="When did you first visit your doctor with these symptoms? "
      placeholder="dd.mm.yyyy"
      :edit-mode="editMode"
    />
    <BaseInput
      id="doctor-name"
      name="doctorName"
      class="mt-6 sm:mt-12"
      label="Name of doctor first attended"
      :edit-mode="editMode"
      :is-warning="sectionHasWarnings('doctor-name')"
    />
    <BasePhoneNumberInput
      id="doctor-number"
      name="doctorNumber"
      class="mt-6 sm:mt-12"
      label="Contact number of doctor first attended"
      :edit-mode="editMode"
      :is-warning="sectionHasWarnings('doctor-number')"
    />
    <BaseTextarea
      id="doctor-address"
      name="doctorAddress"
      class="mt-6 sm:mt-12"
      label="Address of doctor first attended"
      :edit-mode="editMode"
      :is-warning="sectionHasWarnings('doctor-address')"
    />
    <BaseRadioButton
      name="similarIllness"
      class="mt-6 sm:mt-12"
      :options="{
        Yes: true,
        No: false,
        'N/A': null
      }"
      label="Have you ever made a claim for this or any other similar condition in the past with Irish Life Health or any other health insurer?"
      :edit-mode="editMode"
    />
    <BaseTextarea
      v-if="similarIllness"
      id="similar-illness-details"
      name="similarIllnessDetails"
      class="mt-6 sm:mt-12 max-input-width-conditional"
      :class="[editMode ? 'ml-10 sm:ml-20' : '']"
      label="Please provide details of the claim"
      :edit-mode="editMode"
      :is-warning="sectionHasWarnings('similar-illness-details')"
    />
  </div>
</template>
