<script setup>
import { ref, defineExpose } from 'vue';

const show = ref(false);
const message = ref('');
const icon = ref('');
const toastType = ref('');

const showToast = (messageText, iconText, type) => {
  message.value = messageText;
  icon.value = iconText;
  toastType.value = type;
  show.value = true;
};
const closeToast = () => {
  show.value = false;
};
const onEnter = el => {
  el.style.opacity = 0;
};
const onAfterEnter = el => {
  setTimeout(() => {
    closeToast();
  }, 2000);
  el.style.opacity = 1;
};
const onBeforeLeave = el => {
  el.style.opacity = 0;
};
const onLeave = el => {
  el.style.opacity = 0;
};

defineExpose({
  showToast,
  closeToast
});
</script>

<template>
  <div>
    <transition
      name="fade-in"
      @enter="onEnter"
      @after-enter="onAfterEnter"
      @before-leave="onBeforeLeave"
      @leave="onLeave"
    >
      <div :class="['toast', toastType]" v-if="show" data-test-id="toast-notification">
        <div class="toast-body">
          <div data-test-id="toast-icon" class="toast-icon"><i :class="icon"></i></div>
          <div data-test-id="toast-message" class="toast-text">{{ message }}</div>
          <button :class="['close', toastType]" @click="closeToast"><i class="fas fa-times"></i></button>
        </div>
      </div>
    </transition>
  </div>
</template>

<style>
.toast {
  position: fixed;
  top: 20px;
  left: 50%;
  transform: translateX(-50%);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  padding: 10px;
  color: #fff;
  font-weight: bold;
  display: flex;
  align-items: center;
  height: 51px;
  opacity: 0;
  z-index: 99999;
}

.fade-in-enter-active {
  animation: fade-in 1.2s;
}

@keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.toast.success {
  background-color: #006600;
}

.toast.error {
  background-color: #ad0000;
}

.toast.other {
  background-color: #00a7e1;
  color: black;
}

.toast-body {
  display: flex;
  align-items: center;
}

.toast-icon {
  margin-right: 10px;
  margin-left: 10px;
}

.toast-text {
  flex-grow: 1;
  margin-left: 10px;
}

.close.other {
  border: none;
  background: transparent;
  color: #000000;
  cursor: pointer;
  margin-left: 30px;
  margin-right: 20px;
}

.close.success {
  border: none;
  background: transparent;
  color: #ffffff;
  cursor: pointer;
  margin-left: 30px;
  margin-right: 20px;
}

.close.error {
  border: none;
  background: transparent;
  color: #ffffff;
  cursor: pointer;
  margin-left: 30px;
  margin-right: 20px;
}
</style>
