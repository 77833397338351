import { createStore } from 'vuex';

export default createStore({
  state() {
    return {
      textBoxDisabled: false,
      showRevertModal: false
    };
  },
  mutations: {
    setTextBoxDisabled(state, disabled) {
      state.textBoxDisabled = disabled;
    },
    setRevertModal(state, option) {
      state.showRevertModal = option;
    }
  }
});
