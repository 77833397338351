<template>
  <div
    class="flex flex-col menu-width bg-white menu-border menu-z-index menu-transition"
    :class="{ 'menu-width': !notesOpened || expanded, 'menu-width-closed': notesOpened && !expanded }"
  >
    <div
      v-if="notesOpened"
      class="border-b-1 px-4 py-3 flex"
      :class="{ 'justify-center': !expanded, 'justify-end': expanded }"
    >
      <i v-if="expanded" class="fas fa-close text-xl cursor-pointer" @click="collapseMenu()"></i>
      <i v-else class="fas fa-chevron-right cursor-pointer" @click="expandMenu()"></i>
    </div>

    <div class="p-4">
      <h5
        v-for="(section, index) in sections"
        :key="index"
        class="cursor-pointer my-1"
        :class="{ 'highlighted-section': visibleElements[index], 'text-center': notesOpened && !expanded }"
        @click="scrollToId(section.id)"
      >
        {{ getValueBetweenParentheses(section.name) }}
      </h5>
    </div>
  </div>
</template>

<script>
import _ from 'lodash';
import { notes_store } from '@/store/notes';

export default {
  props: {
    sections: {
      required: true
    },
    containerId: {
      required: true
    }
  },
  data() {
    return {
      expanded: false,
      visibleElements: [],
      skipListener: false
    };
  },
  computed: {
    notesOpened() {
      return notes_store.state.notesOpened;
    }
  },
  watch: {
    notesOpened() {
      this.expanded = false;
      this.$emit('set-form-overlay', false);
    },
    containerId() {
      this.addListeners();
    }
  },
  mounted() {
    this.addListeners();
  },
  beforeUnmount() {
    this.removeListeners();
  },
  methods: {
    scrollToId(id) {
      var el = document.getElementById(id);
      el.scrollIntoView({ behavior: 'smooth' });
      this.skipListener = true;
      this.visibleElements = Array(this.sections.length).fill(false, 0);
      this.sections.find((section, index) => {
        if (section.id === id) {
          this.visibleElements[index] = true;
          return true;
        }
      });
    },
    elementsVisibleInViewport: _.debounce(function () {
      if (this.skipListener) {
        this.skipListener = false;
        return;
      }

      this.visibleElements = Array(this.sections.length).fill(false, 0);
      if (!document.getElementById(this.containerId)) return;
      const { clientHeight } = document.getElementById(this.containerId);
      const clientTop = document.getElementById(this.containerId).getBoundingClientRect().top;
      for (let i = this.visibleElements.length - 1; i >= 0; i--) {
        const { top, bottom } = document.getElementById(this.sections[i].id).getBoundingClientRect();
        this.visibleElements[i] =
          (top >= clientTop && top <= clientHeight + clientTop) ||
          (bottom >= clientTop && bottom <= clientHeight + clientTop) ||
          (top <= clientTop && bottom >= clientHeight + clientTop);

        if (this.visibleElements[i]) {
          break;
        }
      }
    }, 300),
    addListeners() {
      this.visibleElements = Array(this.sections.length).fill(false, 0);
      this.elementsVisibleInViewport();
      document.getElementById(this.containerId).addEventListener('scroll', this.elementsVisibleInViewport);
    },
    removeListeners() {
      document.getElementById(this.containerId).removeEventListener('scroll', this.elementsVisibleInViewport);
    },
    getValueBetweenParentheses(str) {
      if (!this.notesOpened || (this.notesOpened && this.expanded)) return str;

      const regex = /\(([^)]+)\)/;
      const matches = regex.exec(str);
      return matches?.[0];
    },
    expandMenu() {
      this.expanded = true;
      this.$emit('set-form-overlay', true);
    },
    collapseMenu() {
      this.expanded = false;
      this.$emit('set-form-overlay', false);
    }
  }
};
</script>

<style scoped>
.menu-z-index {
  z-index: 100;
}
.menu-width {
  width: 80%;
  max-width: 400px;
}
.menu-width-closed {
  width: 70px;
}
.menu-border {
  border-right: 1px solid #000;
}
.border-b-1 {
  border-bottom: 1px solid #c8c8c8;
}
.highlighted-section {
  color: #002e7e;
  font-weight: 600;
}
</style>
