<template>
  <!-- Custom combobox container -->
  <div class="custom-combobox">
    <!-- Container for header and options -->
    <div>
      <!-- Header section -->
      <div :data-test-id="id + '-select'" class="combobox-header rounded mr-1" @click.stop="toggleDropdown">
        <!-- Display selected status or title -->
        <span class="combobox-text">
          {{ getText(selectedStatus) ? getText(selectedStatus) : title }}
        </span>

        <!-- Status icons -->
        <div class="status-icons">
          <!-- X-mark icon to reset sort if a status is selected -->
          <i @click.stop="resetSort()" class="fa-solid fa-xmark fa-sm m-1" v-if="selectedStatus !== ''"></i>
          <!-- Chevron-down icon to indicate the dropdown -->
          <i class="fa-solid fa-chevron-down fa-sm" v-else></i>
        </div>
      </div>
      <!-- Dropdown options -->
      <div
        class="combobox-options"
        :class="{
          show: isOpen
        }"
      >
        <!-- Iterate over unique statuses and display each as an option -->
        <div
          :data-test-id="id + '-option-' + index"
          v-for="(statusObj, index) in data"
          :key="index"
          @click="selectStatus(statusObj.state)"
          @mouseover="highlightStatus(index)"
          @mouseout="clearHighlight()"
          :class="{
            highlighted1: index === highlightedIndex1
          }"
        >
          {{ getText(statusObj.state) }}
          <!-- Display count of each status -->
          <div
            v-if="count && isOpen"
            class="status-count"
            :class="{
              bold: statusObj.count > 0
            }"
          >
            ({{ statusObj.count }})
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { OpenAPIJSON } from 'api/openapi';

export default {
  name: 'ComboBoxDash',

  props: {
    // Props for receiving data, headers, title, and count flag
    id: { default: 'combo-box-dash' },
    title: { default: null },
    count: { default: true },
    reset: { default: false },
    data: { default: [] }
  },
  data() {
    return {
      // Data properties
      openApi: new OpenAPIJSON(),
      selectedStatus: '',
      isOpen: false,
      highlightedIndex1: -1
    };
  },

  watch: {
    reset(newVal) {
      if (newVal) {
        this.resetSort();
      }
    },
    data(newData) {
      if (newData.length === 0) {
        this.clearComboBox();
      }
    }
  },

  mounted() {
    document.addEventListener('click', this.handleClickOutside);
  },

  beforeUnmount() {
    document.removeEventListener('click', this.handleClickOutside);
  },

  methods: {
    getText(tooltipText) {
      const tooltips = {
        start: 'Form ready',
        ready_for_admission: 'Form ready for admission',
        patient_signature: 'Missing Patient Signature',
        queued: 'Insurance Policy State is checked',
        valid_and_matching: 'Insurance Policy is valid',
        nearly_and_matching:
          'Insurance Policy does not match with insurance details Please check patient’s data in your hospital system.',
        does_not_match: 'Insurance Policy is invalid.',
        queued_service_unavailable: 'Insurance Policy State is checked if the service is available again.',
        needs_revision: 'Needs patient revision',
        requirements_are_not_met: 'No form sent',
        awaiting_reply_from_the_patient: 'Form sent',
        review_patient_response: 'Form ready for PAA review',
        all_requirements_met: 'All requirements met'

        // You can add more tooltip key-value pairs here
      };
      return tooltips[tooltipText] || '';
    },
    // Method to reset sorting and emit an event
    resetSort() {
      this.selectedStatus = '';
      this.$emit('get-filtered', '');
    },

    // Method to toggle the dropdown
    toggleDropdown() {
      this.isOpen = !this.isOpen;
    },

    // Method to select a status
    selectStatus(status) {
      this.selectedStatus = status;
      this.isOpen = false;
      this.$emit('get-filtered', status);
    },

    // Method to highlight a status on mouseover
    highlightStatus(index) {
      this.highlightedIndex1 = index;
    },

    // Method to clear the status highlight
    clearHighlight() {
      this.highlightedIndex1 = -1;
    },

    // Method to close the dropdown when clicking outside of it
    handleClickOutside(event) {
      if (!this.$el.contains(event.target)) {
        this.isOpen = false;
      }
    },

    // Method to clear the combobox
    clearComboBox() {
      this.selectedStatus = '';
      this.isOpen = false;
    }
  }
};
</script>

<style scoped>
/* Scoped CSS styles for the component */
.custom-combobox {
  display: inline-block;
  user-select: none;
  width: 200px;
  z-index: 999;
  position: relative;
}

.combobox-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #cacdd6;
  cursor: pointer;
  height: 34px;
  font-size: 13px;
  padding: 0 8px;
}

.combobox-text {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}

.status-icons {
  display: flex;
  align-items: center;
  height: 100%;
}

.status-count {
  font-weight: bold;
  color: #000000;
  line-height: 1.2;
}

.combobox-options {
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  background-color: #f9f9f9;
  border-radius: 12px;
  border: 1px solid #ccc;
  display: none;
  text-align: left;
  width: 100%;
}

/* Show the dropdown options when isOpen is true */
.combobox-options.show {
  display: block;
}

/* Styling for individual dropdown options */
.combobox-options div {
  font-weight: normal;
  padding: 5px;
  cursor: pointer;
  color: #000000;
  line-height: 1.5;
  font-size: 13px;
  display: flex;
  justify-content: space-between;
  align-items: center; /* Align items vertically */
}

/* Highlighted style on mouseover */
.combobox-options div:hover {
  background-color: #00a3f4;
}
</style>
