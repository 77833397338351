export default {
  data () {
    return {
      isLoadingButton: null,
    };
  },

  methods: {
    async executeAsyncWithLoading (newFunction) {
      this.isLoadingButton = true;
      this.$toast.info("Operation in progress...")
      await newFunction();
      this.isLoadingButton = false;
    }
  }
};
