<template>
  <div>
    <div class="px-8">
      <div class="title medo-secondary">Reporting</div>

      <div class="col-12 flex flex-row justify-end px-2">
        <div>
          <button
            @click="fetch_json_report_data"
            type="button"
            class="inline-flex items-center mx-2 px-2.5 py-1.5 ml-auto border border-transparent text-xs font-medium rounded shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          >
            RUN REPORT
          </button>
          <button
            @click="fetch_pdf_report_data"
            type="button"
            class="inline-flex items-center mx-2 px-2.5 py-1.5 ml-auto border border-transparent text-xs font-medium rounded shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          >
            DOWNLOAD PDF
          </button>
          <button
            @click="fetch_csv_report_data"
            type="button"
            class="inline-flex items-center mx-2 px-2.5 py-1.5 ml-auto border border-transparent text-xs font-medium rounded shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          >
            DOWNLOAD CSV
          </button>
        </div>
      </div>

      <div class="col-12 mb-5">
        <div class="mt-3 flex justify-start">
          <div v-if="medics" class="col-4">
            <h3 class="text-lg leading-6 font-medium text-gray-900 mr-5 mb-9">Filter by Consultant:</h3>
            <div class="px-2">
              <v-select
                label="username"
                placeholder="Select Consultant"
                :options="medics"
                v-model="selectedConsultant"
              ></v-select>
            </div>
          </div>

          <div class="col-6 flex flex-col px-2">
            <h3 class="text-lg leading-6 font-medium text-gray-900 mr-5 mb-9">Filter by Insurer:</h3>
            <div class="px-2">
              <v-select
                label="name"
                placeholder="Select Insurer"
                :options="insurers"
                v-model="selectedInsurer"
              ></v-select>
            </div>
          </div>

          <div class="col-4 flex flex-col px-2">
            <h3 class="text-lg leading-6 font-medium text-gray-900 mr-5">Submission Date Range:</h3>
            <div class="year mt-8">
              <span class="mr-2">From</span>
              <date-picker v-model:value="selectedDateFrom" value-type="YYYY-MM-DD" format="DD-MM-YYYY" />
              <!-- <datepicker v-model="selectedDateFrom" /> -->
              <span class="mx-2">To</span>
              <date-picker v-model:value="selectedDateTo" value-type="YYYY-MM-DD" format="DD-MM-YYYY" />
              <!-- <datepicker v-model="selectedDateTo" /> -->
            </div>
          </div>
        </div>
      </div>

      <!-- Needs fixing

      <div class="col-12 mb-5">
        <div class="flex justify-start">
          <div class="col-2 flex flex-col px-2">
            <ReportRollup v-model="rollup"></ReportRollup>
          </div>
        </div>
      </div> -->

      <div class="col-12">
        <h3 class="text-lg leading-6 font-medium text-gray-900">Insurer Summary</h3>
        <div class="flex flex-col">
          <div class="my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div class="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
              <div class="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
                <table class="min-w-full divide-y divide-gray-200">
                  <thead class="bg-gray-50">
                    <tr>
                      <th
                        scope="col"
                        class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                      >
                        Consultant
                      </th>
                      <th
                        scope="col"
                        class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                      >
                        Insurer
                      </th>
                      <th
                        scope="col"
                        class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                      >
                        Payment Date
                      </th>
                      <th
                        scope="col"
                        class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                      >
                        Invoiced Amount
                      </th>
                      <th
                        scope="col"
                        class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                      >
                        Paid Amount
                      </th>
                      <th
                        scope="col"
                        class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                      >
                        Withholding Tax Amount
                      </th>
                      <th
                        scope="col"
                        class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                      >
                        Actions
                      </th>
                    </tr>
                  </thead>

                  <tbody v-if="reportData">
                    <tr v-for="(item, index) in reportData" :key="index" class="bg-white">
                      <td class="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                        {{ item.consultant }}
                      </td>
                      <td class="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                        {{ item.insurer }}
                      </td>
                      <td class="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                        {{ to_nicedate(item.payment_date) }}
                      </td>
                      <td class="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                        {{ item.invoiced_amount }}
                      </td>
                      <td class="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                        {{ item.paid_amount }}
                      </td>
                      <td class="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                        {{ item.withholding_tax }}
                      </td>
                      <td class="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900"></td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <ToastComponent ref="toast"></ToastComponent>
  </div>
</template>

<script>
//import ReportRollup from '@/components/reporting/rollup'
//import Datepicker from 'vue3-datepicker'
import vSelect from 'vue-select';
import { OpenAPICSV, OpenAPIJSON, OpenAPIPDF } from 'api/openapi';
import { api_store } from '@/store';
import { userStore } from '@/store/user';
import StringUtility from 'utils/stringUtility';
import DatePicker from 'vue-datepicker-next';
import ToastComponent from '@/components/claim-forms/components/ToastComponent.vue';
import 'vue-datepicker-next/index.css';

export default {
  name: 'InsurerSummary',
  components: {
    //ReportRollup,
    vSelect,
    DatePicker,
    ToastComponent
  },

  data() {
    return {
      me: null,
      reportData: null,
      medics: null,
      isMedic: null,
      insurers: [],
      selectedInsurer: null,
      rollup: null,
      selectedConsultant: null,
      selectedDateFrom: null,
      selectedDateTo: null
    };
  },
  methods: {
    async init() {
      await this.listMedics();
      await this.listInsurerContracts();
    },

    to_nicedate(date_str) {
      return StringUtility.formatDate(date_str);
    },

    _build_request() {
      let requestData = {
        rollup: 'NONE'
      };
      if (this.selectedConsultant != null) {
        requestData.consultant = {
          id: parseInt(this.selectedConsultant.personId),
          type: 'personid'
        };
      }
      if (this.rollup != null) {
        requestData.rollup = this.rollup.value;
      }

      if (this.selectedInsurer != null) requestData.insurer = { name: this.selectedInsurer.name, type: 'name' };

      if (this.selectedDateFrom != null) requestData.start_date = this.selectedDateFrom.toLocaleDateString('en-CA');

      if (this.selectedDateTo != null) requestData.end_date = this.selectedDateTo.toLocaleDateString('en-CA');
      return requestData;
    },

    async fetch_json_report_data() {
      const request_data = this._build_request();
      const oapi = new OpenAPIJSON();
      this.reportData = await oapi.reportConsulantInsurerPayments(request_data);
    },

    async fetch_pdf_report_data() {
      const request_data = this._build_request();
      const oapi = new OpenAPIPDF();
      await oapi.reportConsulantInsurerPayments(request_data);
    },

    async fetch_csv_report_data() {
      const request_data = this._build_request();
      const oapi = new OpenAPICSV();
      await oapi.reportConsulantInsurerPayments(request_data);
    },

    async listInsurerContracts() {
      await api_store.cache
        .dispatch('INSURERS', {})
        .then(res => {
          this.insurers = res;
        })
        .catch(error => {
          this.$refs.toast.showToast(error, 'fa-solid fa-triangle-exclamation', 'error');
        });
    },

    async listMedics() {
      this.isMedic = userStore.getters.user?.is_medic;

      if (this.isMedic) {
        this.medics = [
          {
            personId: userStore.getters.user?.person_id,
            username: userStore.getters.user?.username
          }
        ];
        this.selectedConsultant = {
          personId: userStore.getters.user?.person_id,
          username: userStore.getters.user?.username
        };
      } else {
        await api_store.cache
          .dispatch('LIST_MY_CONSULTANTS', {})
          .then(res => {
            this.medics = res;
            this.medics.forEach(item => (item.consultant_id = item.medic.id));
            this.medics.forEach(item => (item.username = item.medic.ro.full_name));
          })
          .catch(error => {
            this.$refs.toast.showToast(error, 'fa-solid fa-triangle-exclamation', 'error');
          });
      }
    }
  },
  mounted() {
    this.init();
  }
};
</script>

<style lang="scss" scoped>
.year {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.main-content {
  float: left;
  height: 100vh;
  padding: 35px;
  overflow: auto;
}
.full-width {
  width: calc(100% - 110px);
}
.part-width {
  width: calc(100% - 350px);
}
.title {
  margin-bottom: 30px;
  font-size: 36px;
  font-weight: 600;
}
.subtitle {
  font-size: 28px;
  font-weight: 600;
}

.notes {
  float: left;
  width: 250px;
}
.hover-tooltip {
  position: relative;
  .tooltip {
    padding: 5px;
    bottom: 30px;
    right: 20px;
    visibility: hidden;
    position: absolute;
    font-size: 14px;
    line-height: 30px;
    background: #0081cd;
    border-radius: 8px;
    color: #fff;
  }
}
.hover-tooltip:hover {
  .tooltip {
    visibility: visible;
  }
}
.table-width-date {
  min-width: 380px;
}
</style>
