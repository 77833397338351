<script setup lang="ts">
import { BaseInput, BaseRadioButton } from 'ui';
import ClaimSectionTitle from '@/components/admission/ClaimSectionTitle.vue';

const props = defineProps({
  editMode: {
    type: Boolean,
    default: false
  },
  warnings: {
    type: Array,
    default: () => []
  }
});

const sectionHasWarnings = sectionId => {
  if (!props.warnings) return false;
  return props.warnings.some(warning => warning.id === sectionId);
};
</script>

<template>
  <div>
    <ClaimSectionTitle id="esb-injury-section-title" title="Injury Section" />
    <BaseRadioButton
      name="roadTrafficAccident"
      class="mt-6"
      :options="{
        Yes: true,
        No: false,
        'N/A': null
      }"
      label="Road Traffic Accident"
      :edit-mode="editMode"
    />
    <BaseRadioButton
      name="atWork"
      class="mt-6 sm:mt-12"
      :options="{
        Yes: true,
        No: false,
        'N/A': null
      }"
      label="Occupational Injury"
      :edit-mode="editMode"
    />
    <BaseRadioButton
      name="causedByThirdParty"
      class="mt-6 sm:mt-12"
      :options="{
        Yes: true,
        No: false,
        'N/A': null
      }"
      label="Third Party Injury"
      :edit-mode="editMode"
    />
    <BaseRadioButton
      name="sporting"
      class="mt-6 sm:mt-12"
      :options="{
        Yes: true,
        No: false,
        'N/A': null
      }"
      label="Sporting Injury"
      :edit-mode="editMode"
    />
    <BaseInput
      id="pursue-legal-claim"
      name="pursueLegalClaim"
      class="mt-6 sm:mt-12"
      label="Are you pursuing a claim for cost against another party?"
      :edit-mode="editMode"
      :is-warning="sectionHasWarnings('pursue-legal-claim')"
    />
  </div>
</template>
