<template>
  <div>
    <div class="claim-title medo-secondary d-flex">
      <span @click="goBack" class="mr-2 cursor-pointer"><i class="fas fa-chevron-left"></i></span>
      Manage Procedure Codes
    </div>

    <div style="clear: both"></div>
    <div class="col-12">
      <div v-if="procedureCodes" class="col-6">
        <input
          v-if="procedureCodes"
          v-model="searchString"
          type="text"
          class="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md custom-input"
          placeholder="Search by procedure code or description"
        />
      </div>

      <div class="col-6">
        <button
          type="button"
          @click="openAddModal()"
          class="inline-flex float-right mt-2 items-center px-2.5 py-1.5 border border-transparent text-xs font-medium rounded shadow-sm text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
        >
          ADD NEW PROCEDURE CODE
        </button>
        <button
          type="button"
          @click="fetch_json_procedure_codes_csv()"
          class="inline-flex float-right mt-2 items-center px-2.5 py-1.5 mr-2 border border-transparent text-xs font-medium rounded shadow-sm text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
        >
          DOWNLOAD CSV
        </button>
      </div>
    </div>

    <div v-if="procedureCodes" class="col-12 flex flex-col mt-5">
      Procedure Codes for {{ selectedContract.contract_name }}
      <div class="my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div class="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
          <div class="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
            <table class="min-w-full divide-y divide-gray-200">
              <thead class="bg-gray-50">
                <tr>
                  <th
                    scope="col"
                    class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    PROCEDURE CODE
                  </th>
                  <th
                    scope="col"
                    class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    PROCEDURE DESCRIPTION
                  </th>
                  <th
                    scope="col"
                    class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    CONTRACT ID
                  </th>
                  <th
                    scope="col"
                    class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    PARTICIPATING ANAESTHETIST COST
                  </th>
                  <th
                    scope="col"
                    class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    PARTICIPATING CONSULTANT COST
                  </th>
                  <!--  <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                          STANDARD ANAESTHETIST COST
                        </th>
                        <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                          STANDARD CONSULTANT COST
                        </th> -->
                  <th
                    scope="col"
                    class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    ACTION
                  </th>
                </tr>
              </thead>

              <tbody>
                <template v-for="(item, index) in procedureCodes" :key="index">
                  <tr class="bg-white">
                    <td class="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                      {{ item.procedure_code }}
                    </td>
                    <td class="px-6 py-4 text-sm font-medium text-gray-900">
                      <p class="max-width-desc">{{ item.procedure_code_description }}</p>
                    </td>
                    <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                      {{ item.contract_procedure_code_id }}
                    </td>
                    <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                      {{ item.price.participating_anaesthetist_cost }}
                    </td>
                    <td class="insurer-logo px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                      {{ item.price.participating_consultant_cost }}
                    </td>
                    <!--  <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                              {{item.price.standard_anaesthetist_cost}}
                            </td>
                            <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                              {{item.price.standard_consultant_cost}}
                            </td> -->
                    <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                      <button
                        type="button"
                        @click="openEditModal(item)"
                        class="inline-flex items-center px-2.5 py-1.5 ml-auto border border-transparent text-xs font-medium rounded shadow-sm text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                      >
                        EDIT
                      </button>
                    </td>
                  </tr>
                </template>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>

    <div v-if="procedureCodes != null" class="col-12 flex justify-end my-2">
      <button
        v-if="startIdx > 0"
        @click="lastPage"
        type="button"
        class="inline-flex mr-3 items-center px-2.5 py-1.5 border border-transparent text-xs font-medium rounded shadow-sm text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
      >
        Last page
      </button>
      <button
        v-if="procedureCodes.length > 49"
        type="button"
        @click="nextPage"
        class="inline-flex items-center px-2.5 py-1.5 border border-transparent text-xs font-medium rounded shadow-sm text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
      >
        Next page
      </button>
    </div>
  </div>

  <Modal v-if="showEditModal" @close-modal="closeEditModal" :hide-expand-button="false" :hide-header="true">
    <template v-slot:body>
      <EditProcedureCode
        :selected-contract="selectedContract"
        :procedure-code="selectedProcedureCode"
        @refresh-list="fetch_json_procedure_codes"
        @close-modal="closeEditModal"
      />
    </template>
  </Modal>

  <Modal v-if="showAddModal" @close-modal="closeAddModal" :hide-expand-button="false" :hide-header="true">
    <template v-slot:body>
      <AddProcedureCode :selected-contract="selectedContract" @close-modal="closeAddModal" />
    </template>
  </Modal>
</template>

<script>
import { OpenAPIJSON, OpenAPICSV } from 'api/openapi';

import Modal from '@/components/modal/Modal.vue';
import EditProcedureCode from '@/components/modal/ProcedureCodes/EditProcedureCode.vue';
import AddProcedureCode from '@/components/modal/ProcedureCodes/AddProcedureCode.vue';

export default {
  name: 'ManageContractProcedureCodes',
  components: {
    Modal,
    EditProcedureCode,
    AddProcedureCode
  },
  props: { selectedContract: null },
  data() {
    return {
      searchString: '',
      procedureCodes: null,
      startIdx: 0,
      endIdx: 50,
      step: 50,

      showEditModal: false,
      showAddModal: false,
      selectedProcedureCode: null
    };
  },
  watch: {
    searchString() {
      this.fetch_json_procedure_codes();
    }
  },
  methods: {
    goBack() {
      this.$emit('go-back');
    },

    async fetch_json_procedure_codes() {
      const pagination = { start_idx: this.startIdx, end_idx: this.endIdx };
      const oapi = new OpenAPIJSON();
      const request = await oapi.searchProcedureCodes(
        null,
        null,
        null,
        this.selectedContract.insurer_id,
        null,
        this.selectedContract.contract_id,
        null,
        null,
        null,
        this.searchString,
        null,
        null,
        null,
        pagination
      );
      this.procedureCodes = request.items;
    },

    async fetch_json_procedure_codes_csv() {
      const pagination = { start_idx: this.startIdx, end_idx: this.endIdx };
      const oapi = new OpenAPICSV();
      await oapi.searchProcedureCodes(
        null,
        null,
        null,
        this.selectedContract.insurer_id,
        null,
        this.selectedContract.contract_id,
        null,
        null,
        null,
        this.searchString,
        null,
        null,
        null,
        pagination
      );
    },

    closeEditModal() {
      this.showEditModal = false;
      this.selectedProcedureCode = null;
    },

    openEditModal(item) {
      this.selectedProcedureCode = item;
      this.showEditModal = true;
    },

    closeAddModal() {
      this.showAddModal = false;
      this.fetch_json_procedure_codes();
    },
    openAddModal() {
      this.showAddModal = true;
    },
    lastPage() {
      this.startIdx = this.startIdx - this.step;
      this.endIdx = this.endIdx - this.step;
      this.fetch_json_procedure_codes();
    },
    nextPage() {
      this.startIdx = this.startIdx + this.step;
      this.endIdx = this.endIdx + this.step;
      this.fetch_json_procedure_codes();
    }
  },
  mounted() {
    this.fetch_json_procedure_codes();
  }
};
</script>

<style lang="scss" scoped>
.custom-back {
  font-size: 28px;
  cursor: pointer;
  transition: all 0.25s;

  &:hover {
    opacity: 0.65;
  }
}

.float-right {
  float: right;
}

.claim-title {
  margin-bottom: 30px;
  font-size: 26px;
  font-weight: 600;
}

.custom-input {
  height: 40px;
  padding-left: 8px;
  line-height: 40px;
  border: 1px solid #dedede;
}

.custom-select {
  height: 40px;
  padding-left: 8px;
  line-height: 40px;
  border: 1px solid #dedede;
}

.max-width-desc {
  max-width: 500px;
}

.optional-nav {
  position: fixed;
  top: 0;
  left: 65px;
  height: 100vh;
  overflow: auto;
  background: #ededed;
}
</style>
