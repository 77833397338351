<template>
  <div class="add-claim-section">
    <div class="relative z-10">
      <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"></div>

      <div class="fixed z-10 inset-0 overflow-y-auto">
        <div class="flex items-end sm:items-center justify-center min-h-full p-4 text-center sm:p-0">
          <div
            class="custom-claim-modal relative bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-10 sm:max-w-2xl sm:w-full sm:p-6"
          >
            <SpinnerLoader v-if="isLoading" />
            <div>
              <div class="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-indigo-600">
                <!-- Heroicon name: outline/check -->
                <i class="fas fa-file-signature" style="padding-left: 6px; color: #fff"></i>
              </div>
              <div class="mt-3 text-center sm:mt-3">
                <h3 class="text-lg leading-6 font-medium text-gray-900">Add New Payment</h3>
                <div class="mt-2">
                  <!--
                    <p class="text-sm text-gray-500">Lorem ipsum, dolor sit amet consectetur adipisicing elit. Eius aliquam laudantium explicabo pariatur iste dolorem animi vitae error totam. At sapiente aliquam accusamus facere veritatis.</p>
                    -->

                  <!-- form fields -->

                  <div style="clear: both"></div>

                  <div
                    class="sub-title mt-3"
                    title="Search existing records to find the patient by MRN or name, which will auto-populate several fields on the form.  Use the MRN field directly if you cannot find the patient here."
                  >
                    Invoice
                  </div>

                  <div style="clear: both"></div>

                  <div class="float-left col-12 mt-2">
                    <!-- <Typeahead
                      v-if="!external"
                      v-model="selectedInvoiceId"
                      :items="invoices"
                      placeholder="Search for invoice"
                      :min-input-length="1"
                      :item-projection="showInvoiceItem"
                      :item-value="getItemValue"
                      :fullW="true"
                    /> -->
                    <input
                      type="text"
                      disabled
                      class="custom-input shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                      v-model="selectedInvoiceId"
                    />
                  </div>

                  <div style="clear: both"></div>

                  <div
                    class="sub-title mt-3"
                    title="Search existing records to find the patient by MRN or name, which will auto-populate several fields on the form.  Use the MRN field directly if you cannot find the patient here."
                  >
                    Allocate against:
                  </div>

                  <div style="clear: both"></div>

                  <div class="float-left col-12 mt-2">
                    <Typeahead
                      v-if="!external && newInvoices"
                      v-model="newInvoiceId"
                      :items="newInvoices"
                      placeholder="Search for invoice to allocate against"
                      :min-input-length="1"
                      :item-projection="showNewInvoiceItem"
                      :item-value="getNewItemValue"
                      :fullW="true"
                    />
                    <input
                      v-else
                      type="text"
                      class="custom-input shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                      v-model="selectedInvoiceId"
                    />
                  </div>

                  <div
                    class="sub-title mt-5"
                    title="Search existing records to find the patient by MRN or name, which will auto-populate several fields on the form.  Use the MRN field directly if you cannot find the patient here."
                  >
                    Patient Details
                  </div>

                  <div style="clear: both"></div>

                  <!-- section -->
                  <div
                    class="col-12 mt-3"
                    title="Enter an MRN for the patient.  Use this field if you cannot find the patient using the patient search."
                  >
                    <div class="col-12 float-left">
                      <div>
                        <div class="text-left text-sm mb-1">
                          <label for="value" class="font-medium text-gray-700 mr-2">MRN:</label>
                        </div>
                        <div class="col-12">
                          <input
                            :disabled="!external"
                            type="text"
                            class="custom-input shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                            v-model="mrn"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <!-- section end -->

                  <!-- section -->
                  <div class="col-12 mt-1">
                    <div class="col-6 float-left pr-2">
                      <div class="mt-2">
                        <div>
                          <div class="text-left text-sm mb-1">
                            <label for="value" class="font-medium text-gray-700 mr-2">First Name:</label>
                          </div>
                          <div class="col-12">
                            <input
                              :disabled="!external"
                              type="text"
                              v-model="firstName"
                              class="custom-input shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                            />
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="col-6 float-left">
                      <div class="mt-2">
                        <div>
                          <div class="text-left text-sm mb-1">
                            <label for="value" class="font-medium text-gray-700 mr-2">Last Name:</label>
                          </div>
                          <div class="col-12">
                            <input
                              :disabled="!external"
                              type="text"
                              v-model="lastName"
                              class="custom-input shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!-- section end -->

                  <!-- section -->
                  <div class="col-12 mt-1">
                    <div class="col-12 float-left pr-2">
                      <div class="mt-2">
                        <div>
                          <div class="text-left text-sm mb-1">
                            <label for="value" class="font-medium text-gray-700 mr-2">Date of Birth:</label>
                          </div>
                          <div class="col-12">
                            <input
                              :disabled="!external"
                              type="text"
                              v-model="dobFormatted"
                              class="custom-input shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!-- section end -->

                  <div style="clear: both"></div>

                  <!-- Appointment -->

                  <div style="clear: both"></div>

                  <!-- section -->
                  <div
                    class="col-12 mt-2"
                    title="If the patient appointment is not known in the system, you can manually specify it here."
                  >
                    <div class="col-12 float-left">
                      <div class="mt-2">
                        <div>
                          <div class="text-left text-sm mb-1">
                            <label for="value" class="font-medium text-gray-700 mr-2">Appointment ID:</label>
                          </div>
                          <div class="col-12">
                            <input
                              :disabled="!external"
                              v-model="appointmentId"
                              type="text"
                              class="custom-input shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!-- section end -->

                  <div style="clear: both"></div>

                  <div>
                    <div class="text-left text-sm mt-3 mb-1">
                      <label for="value" class="font-medium text-gray-700 mr-2">Insurer Name:</label>
                    </div>
                    <div class="col-12">
                      <input
                        :disabled="!external"
                        v-model="selectedInsurer"
                        type="text"
                        class="custom-input shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                      />
                    </div>
                  </div>

                  <div style="clear: both"></div>

                  <!-- start section -->
                  <div style="clear: both"></div>

                  <div
                    class="sub-title mt-3"
                    title="Search existing records to find the patient by MRN or name, which will auto-populate several fields on the form.  Use the MRN field directly if you cannot find the patient here."
                  >
                    Payment details
                  </div>

                  <div style="clear: both"></div>
                  <div class="flex my-3">
                    <div class="col-6 mx-3">
                      Amount Invoiced
                      <input
                        v-model="amountInvoiced"
                        :disabled="!external"
                        class="custom-input shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                      />
                    </div>

                    <div class="col-6 mx-3">
                      Amount Outstanding
                      <input
                        v-model="amountOutstanding"
                        :disabled="!external"
                        class="custom-input shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                      />
                    </div>
                  </div>
                  <div class="flex justify-center my-3">
                    <div class="col-4 mx-3">
                      Pay Amount
                      <input
                        v-model="payAmount"
                        class="custom-input shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                      />
                    </div>

                    <div class="col-4 mx-3">
                      Tax Amount
                      <input
                        v-model="taxAmount"
                        class="custom-input shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                      />
                    </div>

                    <div class="col-4 mx-3">
                      Adjustment Amount
                      <input
                        v-model="adjustmentAmount"
                        class="custom-input shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                      />
                    </div>
                  </div>

                  <!-- end section -->
                </div>
              </div>
            </div>
            <div class="mt-12 sm:mt-12 sm:grid sm:grid-cols-2 sm:gap-3 sm:grid-flow-row-dense">
              <button
                @click="definePayment"
                type="button"
                class="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-indigo-600 text-base font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:col-start-2 sm:text-sm"
              >
                Add Payment
              </button>
              <button
                @click="$emit('close-modal')"
                type="button"
                class="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:col-start-1 sm:text-sm"
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <ToastComponent ref="toast"></ToastComponent>
  </div>
</template>

<script>
import { OpenAPIJSON } from 'api';
// components
import Typeahead from '@/components/claim-forms/components/Typeahead.vue';
import '@/assets/css/typeahead.css';
import StringUtility from 'utils/stringUtility';
import ToastComponent from '@/components/claim-forms/components/ToastComponent.vue';
import SpinnerLoader from '@/components/misc/SpinnerLoader.vue';

export default {
  name: 'AllocateModal',
  props: {
    selectedConsultant: {
      required: true,
      default: null
    },
    external: {
      default: false
    },
    preselectedInvoiceId: {
      default: null
    },
    fileName: {
      type: String
    }
  },
  components: {
    Typeahead,
    ToastComponent,
    SpinnerLoader
  },
  data() {
    return {
      openApi: new OpenAPIJSON(),
      invoices: [],
      newInvoices: [],
      selectedInvoiceId: '',
      selectedInvoiceRef: null,
      newInvoiceId: '',
      newInvoiceRef: null,

      selectedInsurer: '',
      selectedInsurerId: null,

      mrn: null,
      firstName: null,
      lastName: null,
      dob: null,
      dobKey: 0,
      appointmentId: null,

      amountInvoiced: null,
      amountOutstanding: null,

      payAmount: null,
      taxAmount: null,
      adjustmentAmount: null,

      successClaim: false,
      isLoading: false
    };
  },
  computed: {
    dobFormatted() {
      return StringUtility.formatDate(this.dob);
    }
  },
  watch: {
    dob() {
      this.dobKey++;
    },
    selectedInvoiceId() {
      this.fetchData();
    }
  },
  async mounted() {
    await this.list_invoices_and_remittances();

    if (this.preselectedInvoiceId) {
      this.selectedInvoiceId = this.preselectedInvoiceId;
    }

    document.addEventListener('keyup', this.keyListener);
  },
  beforeUnmount() {
    document.removeEventListener('keyup', this.keyListener);
  },
  methods: {
    keyListener(event) {
      if (event.defaultPrevented) {
        return;
      }

      if (event.key === 'Enter') {
        this.definePayment();
      }
    },

    async list_invoices_and_remittances() {
      // Everything to current day
      this.isLoading = true;
      try {
        const { items } = await this.openApi.searchInvoice(
          ['sent_to_insurer', 'part_paid'],
          null,
          null,
          null,
          null,
          this.selectedConsultant?.consultant_id
        );
        this.invoices = items;
        this.isLoading = true;
      } catch (error) {
        this.$refs.toast.showToast(
          'Something went wrong getting invoices. Please try again later.',
          'fa-solid fa-triangle-exclamation',
          'error'
        );
        this.isLoading = false;
      }
      this.isLoading = false;
    },
    async list_invoices_and_remittances_by_invoiceRef() {
      this.isLoading = true;
      // Everything to current day
      try {
        const consultant = this.getConsultant();
        const { items } = await this.openApi.invoices_remittances_search({
          consultant: consultant,
          claim_state: ['sent_to_insurer', 'part_paid']
        });
        this.newInvoices = items;
        this.isLoading = false;
      } catch (error) {
        this.$refs.toast.showToast(
          'Something went wrong getting invoices. Please try again later.',
          'fa-solid fa-triangle-exclamation',
          'error'
        );
        this.isLoading = false;
      }
      this.isLoading = false;
    },
    async fetchData() {
      await this.list_invoices_and_remittances_by_invoiceRef();
      const selectedInvoice = this.invoices.find(item => item.invoice_ref == this.selectedInvoiceId);
      if (selectedInvoice) {
        this.populatePatientDataByRef(selectedInvoice.invoice_ref);
      }
    },
    populatePatientDataByRef(ref) {
      const selectedInvoice = this.newInvoices.find(item => item.invoice_ref === ref);
      if (selectedInvoice) {
        this.populateData(selectedInvoice);
      }
    },
    populatePatientDataById(id) {
      const selectedInvoice = this.newInvoices.find(item => item.invoice_id === id);
      if (selectedInvoice) {
        this.populateData(selectedInvoice);
      }
    },
    populateData(data) {
      this.mrn = data.patient?.mrn;
      this.selectedInsurer = data.insurer?.insurer_name;
      this.selectedInsurerId = data.insurer?.insurer_id;
      this.appointmentId = data.hospital_appointment_id;

      this.amountInvoiced = data.amount_invoiced;
      this.amountOutstanding = data.amount_outstanding;
      this.dataRef = data.invoice_ref;
      this.taxAmount = data.withholding_tax;
      this.payAmount = data.amount_paid;
      this.adjustmentAmount = data.adjustment_amount;

      if (data.patient) {
        this.firstName = data.patient?.first_name;
        this.lastName = data.patient?.last_name;
        this.dob = data.patient?.dob;
      }
    },
    showInvoiceItem(item) {
      return item.invoice_ref;
    },
    getItemValue(item) {
      this.selectedInvoiceId = item.invoice_id;
      return item.invoice_ref;
    },
    showNewInvoiceItem(item) {
      return item.invoice_ref + ', ' + item.patient.full_name;
    },
    getNewItemValue(item) {
      this.newInvoiceId = item.invoice_id;
      this.newInvoiceRef = item.invoice_ref;
      this.populatePatientDataById(item.invoice_id);
      return item.invoice_ref + ', ' + item.patient.full_name;
    },
    resetPayment() {
      window.scrollTo(0, 0);

      this.selectedInsurer = '';

      this.mrn = null;
      this.firstName = null;
      this.lastName = null;
      this.dob = null;
      this.appointmentId = null;

      this.amountInvoiced = null;
      this.amountOutstanding = null;

      this.payAmount = null;
      this.taxAmount = null;
      this.adjustmentAmount = null;

      this.successClaim = false;
    },
    async definePayment() {
      // validation
      if (this.selectedInvoiceId == '') {
        this.$refs.toast.showToast('Please select invoice first.', 'fa-solid fa-triangle-exclamation', 'error');
        return;
      }

      if (this.selectedInsurer == '') {
        this.$refs.toast.showToast('Please select insurer.', 'fa-solid fa-triangle-exclamation', 'error');
        return;
      }

      if (this.mrn == null) {
        this.$refs.toast.showToast('Please add MRN first.', 'fa-solid fa-triangle-exclamation', 'error');
        return;
      }

      if (this.firstName == null) {
        this.$refs.toast.showToast('Please add first name.', 'fa-solid fa-triangle-exclamation', 'error');
        return;
      }

      if (this.lastName == null) {
        this.$refs.toast.showToast('Please add last name', 'fa-solid fa-triangle-exclamation', 'error');
        return;
      }

      if (this.dob == '') {
        this.$refs.toast.showToast('Please add date of birth.', 'fa-solid fa-triangle-exclamation', 'error');
        return;
      }

      if (this.appointmentId == null) {
        this.$refs.toast.showToast('Please add appointment id.', 'fa-solid fa-triangle-exclamation', 'error');
        return;
      }

      if (this.payAmount == null) {
        this.$refs.toast.showToast('Please add pay amount.', 'fa-solid fa-triangle-exclamation', 'error');
        return;
      }

      if (this.taxAmount == null) {
        this.$refs.toast.showToast('Please add tax amount.', 'fa-solid fa-triangle-exclamation', 'error');
        return;
      }

      const selectedInvoice = this.newInvoices.find(item => item.invoice_ref == this.selectedInvoiceId);
      if (selectedInvoice == null) {
        this.$refs.toast.showToast('Error selecting this Invoice.', 'fa-solid fa-triangle-exclamation', 'error');
        return;
      }
      const newPaymentObj = {
        insurer: {
          type: 'id',
          id: this.selectedInsurerId
        },
        insurer_name: this.selectedInsurer,
        consultant_file: {
          file_name: this.fileName,
          assoc: this.getAssocType()
        },
        pricing: {
          amount_invoiced: selectedInvoice.amount_invoiced,
          amount_payable: this.payAmount.toString(),
          withholding_tax: this.taxAmount.toString(),
          adjustment_amount: this.adjustmentAmount.toString()
        },
        invoice_ref: this.dataRef,
        hospital_appointment_id: this.appointmentId.toString(),
        invoice_id: selectedInvoice.invoice_id,
        patient_name: this.firstName + ' ' + this.lastName,
        patient_dob: this.dob
      };

      try {
        await this.openApi.claim_statement_add(newPaymentObj);
        this.$refs.toast.showToast('Payment Added!', 'fas fa-check', 'success');
        this.resetPayment();
      } catch (error) {
        this.$refs.toast.showToast(
          'Something went wrong adding payment. Please try again later.',
          'fa-solid fa-triangle-exclamation',
          'error'
        );
      }
    },
    getConsultant() {
      if (this.selectedConsultant?.consultant_id) {
        return {
          id: this.selectedConsultant?.consultant_id,
          type: 'id'
        };
      } else if (this.selectedConsultant?.person_id) {
        return {
          id: this.selectedConsultant?.person_id,
          type: 'personid'
        };
      } else {
        return null;
      }
    },
    getAssocType() {
      if (this.selectedConsultant?.consultant_id) {
        return {
          id: this.selectedConsultant?.consultant_id,
          type: 'id',
          assoc_type: 'consultant'
        };
      } else if (this.selectedConsultant?.person_id) {
        return {
          id: this.selectedConsultant?.person_id,
          type: 'personid',
          assoc_type: 'consultant'
        };
      } else {
        return null;
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.custom-claim-modal {
  padding-bottom: 75px;
}

.app-table-head {
  padding-bottom: 3px;
  font-size: 14px;
  font-weight: 600;
  font-style: italic;
  border-bottom: 1px solid #eee;
  overflow: auto;
}

.app-table-li {
  overflow: auto;
  padding-top: 5px;
  padding-bottom: 3px;
  border-bottom: 1px solid #eee;
  font-weight: 600;
}

.sub-title {
  text-align: left;
  font-size: 18px;
  font-weight: 400;
  font-style: italic;
  color: #0054ab;
}

.required {
  color: red;
}
</style>
