<template>
  <div
    :class="[error ? 'red-bg' : 'green-bg']"
    class="flex flex-row text-white justify-between items-center py-2 px-3 mb-2"
  >
    <div>
      <i v-if="error" class="fas fa-triangle-exclamation text-xl mr-3"></i>
      <i v-else class="fas fa-check text-xl mr-3"></i>
      {{ message }}
    </div>
    <i class="fas fa-close text-xl cursor-pointer" @click="$emit('close-toast')"></i>
  </div>
</template>

<script>
export default {
  name: 'ToastClaims',
  props: {
    message: { required: true },
    error: { default: false }
  }
};
</script>

<style lang="scss" scoped>
.green-bg {
  background-color: #016700;
}
.red-bg {
  background-color: #ad0101;
}
</style>
