<template>
  <div
    class="custom-menu-section flex-1 flex flex-col min-h-0 pt-8 menu-transition"
    :class="{ 'custom-menu-collapse': !isActive }"
  >
    <div :class="isActive ? 'collapse-menu' : 'collapse-menu-off'" @click="toggleMenu">
      <i v-show="!isActive" class="fas fa-chevron-right py-2"></i>
      <i v-show="isActive" class="fas fa-chevron-left"></i>
      <span v-if="isActive" class="ml-3 text-base">Credit Control</span>
    </div>

    <div v-show="isActive" class="flex-1 flex flex-col overflow-y-auto">
      <nav class="mt-5 flex-1 pr-2 pt-0 space-y-1" aria-label="Sidebar">
        <router-link to="/navigator/finance" class="flex items-center p-2 pl-4 nav-active-bg">
          <span class="flex flex-1 justify-between items-center">
            Payments and Reconciliation<i class="fas fa-money-check-dollar nav-icon-size"></i>
          </span>
        </router-link>
      </nav>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SecondMenuFinance',
  data() {
    return {
      isActive: true
    };
  },
  methods: {
    toggleMenu() {
      this.isActive = !this.isActive;
      this.$emit('set-menu-active', this.isActive);
    }
  }
};
</script>

<style lang="scss" scoped>
.custom-menu-section {
  float: left;
  width: 210px;
  height: 100vh;
  background-color: #ededed;
  color: #0c276c;
  font-family: 'Roboto';
  font-size: 14px;
  font-weight: 600;
}

.custom-menu-collapse {
  width: 30px;
}

.collapse-menu {
  margin-top: 20px;
  padding-left: 10px;
  text-align: left;
  cursor: pointer;

  &:hover {
    opacity: 0.65;
  }
}

.collapse-menu-off {
  margin-top: 20px;
  padding-left: 10px;
  text-align: left;
  cursor: pointer;
  background-color: #00a7e1;
  &:hover {
    opacity: 0.65;
  }
}
</style>
