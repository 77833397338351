<script setup>
const emit = defineEmits(['openEditMode']);

const props = defineProps({
  warnings: Array,
  show: Boolean,
  editMode: Boolean
});

// Check if the current URL contains "navigator"
const showEditButton = !window.location.href.includes('navigator');

const scrollToFirstWarning = () => {
  emit('openEditMode');

  setTimeout(() => {
    var el = document.getElementById(props.warnings[0].id + '-container');
    el.scrollIntoView({ behavior: 'smooth' });
  }, 600);
};
</script>

<template>
  <div v-show="show" class="flex flex-col justify-center items-center border-2 border-medosync-dark-orange p-8">
    <div class="flex flex-col">
      <h2 class="text-xl font-semibold">
        This form contains some problems which may result in the claim being rejected.
      </h2>
      <div class="px-2 my-4 text-start">
        <div class="text-medosync-dark-orange py-1" v-for="(warning, index) in props.warnings" :key="index">
          <i class="fal fa-circle-exclamation fa-lg pl-4 pr-8"></i>
          <span class="underline font-bold">{{ warning.title }}</span>
        </div>
      </div>
      <button
        v-if="!editMode && showEditButton"
        class="self-center flex justify-center items-center px-5 h-12 w-[230px] bg-medosync-blue rounded-full"
        @click="scrollToFirstWarning"
      >
        Edit Form
      </button>
    </div>
  </div>
</template>
