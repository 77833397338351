<template>
  <div :id="inputId">
    <div class='col-12 mt-5 pr-5 float-left'>
      <div v-if="question" class="flex justify-between">
        <label class="block text-sm font-medium text-gray-700">{{ question }}</label>
      </div>
      <div class="mt-0">
        <div v-if="enableHours" class="custom-col float-left">
          <NumberQuestion :id="inputId + '_hours'" class="float-left mr-5" question="Hours" v-model="localData.duration_hours" />
        </div>
        <div v-if="enableDays" class="custom-col float-left">
          <NumberQuestion :id="inputId + '_days'" class="float-left mr-5" question="Days" v-model="localData.duration_days" />
        </div>
        <div v-if="enableWeeks" class="custom-col float-left">
          <NumberQuestion :id="inputId + '_weeks'" class="float-left mr-5" question="Weeks" v-model="localData.duration_weeks" />
        </div>
        <div v-if="enableMonths" class="custom-col float-left">
          <NumberQuestion :id="inputId + '_months'" class="float-left mr-5" question="Months" v-model="localData.duration_months" />
        </div>
        <div v-if="enableYears" class="custom-col float-left">
          <NumberQuestion :id="inputId + '_years'" class="float-left mr-5" question="Years" v-model="localData.duration_years" />
        </div>
      </div>
    </div>
    <div v-if="enableOther" class='col-12 mt-0 pr-5 float-left'>
        <TextQuestion
            question='Please specify'
            placeholder='Enter text that will override any numeric value for any vague terms of duration of symptoms'
            :id="inputId + '_other'" class="float-left mr-5" v-model="localData.duration_vague"
        />
    </div>
  </div>

</template>

<script>
import NumberQuestion from "@/components/form-fields/NumberQuestion.vue";
import { mapState } from 'vuex';
import TextQuestion from "@/components/form-fields/TextQuestion.vue";

export default {
  name: "DurationOfSymptoms",
  components: {TextQuestion, NumberQuestion},
  props: {
    modelValue: {default: ""},
    question: { type: String, required: false },
    enableHours: { type: Boolean, default: false, required: false },
    enableDays: { type: Boolean, default: false, required: false },
    enableWeeks: { type: Boolean, default: false, required: false },
    enableMonths: { type: Boolean, default: false, required: false },
    enableYears: { type: Boolean, default: false, required: false },
    enableOther: { type: Boolean, default: false, required: false },
    id: { type: String, required: false }
  },
  computed: {
    ...mapState(['textBoxDisabled']),
      isTextBoxDisabled() {
        return this.textBoxDisabled;
      },
      
    localData: {
      get() {
        return this.modelValue
      },
      set(newValue) {
        this.$emit('update:modelValue', newValue)
      }
    }
  },
  data() {
    return {
      inputId: this.id || `durations${(Math.random() * 1000).toFixed()}`,
    }
  }
}
</script>

<style scoped>

</style>