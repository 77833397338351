<script setup>
import { ref, onMounted, onBeforeUnmount } from 'vue';

const emit = defineEmits(['on-trigger']);

const refObserver = ref(null);
const observer = ref(null);

onMounted(() => {
  observer.value = new IntersectionObserver(onElementObserved, {
    threshold: 0.5
  });

  observer.value.observe(refObserver.value);
});

onBeforeUnmount(() => {
  observer.value.unobserve(refObserver.value);
});

const onElementObserved = entries => {
  entries.forEach(entry => {
    if (entry.isIntersecting) {
      emit('on-trigger');
    }
  });
};
</script>

<template>
  <div ref="refObserver" class="h-[1px]"></div>
</template>
