import { createApp } from 'vue';
import App from './App.vue';
import router from './router';

// General application cache to reduce number of BE queries.
import { api_store, state_store } from '@/store/index';
import { hospitalConfigStore } from '@/store/config';
import { userStore } from '@/store/user';

// addons
import Toaster from '@meforma/vue-toaster';
import VueSignature from 'vue-signature-pad';
import vClickOutside from 'click-outside-vue3';
import VuePapaParse from 'vue-papa-parse';
import createStore from '@/store/claims';
import DatePicker from 'vue-datepicker-next';
import 'vue-datepicker-next/index.css';
import VueAwesomePaginate from 'vue-awesome-paginate';
import SentryPlugin from './util/sentry-plugin';

// import the necessary css file
import 'vue-awesome-paginate/dist/style.css';

import axios from 'axios';

axios.interceptors.request.use(
  config => {
    // Check if the token has expired before each request
    const tokenExpiration = localStorage.getItem('tokenExpiration');
    const token = localStorage.getItem('_token');

    if (!token && tokenExpiration) {
      console.info(
        `LOGOUT(interceptors): No _token in localStorage but tokenExpiration defined ${userStore?.getters?.user?.email}, resetting.`
      );
      sessionStorage.clear();
      localStorage.removeItem('_token');
      localStorage.removeItem('tokenExpiration');
      window.location.href = '/';
    } else if (token && tokenExpiration) {
      const now = new Date();
      const expiration = Date.parse(tokenExpiration); // Parse the date string manually for Safari compatibility

      if (now.getTime() > expiration) {
        console.info(
          `LOGOUT(interceptors): Current time ${now} is greater than token expiration time ${expiration} ${userStore?.getters?.user?.email}, resetting.`
        );
        sessionStorage.clear();
        localStorage.removeItem('_token');
        localStorage.removeItem('tokenExpiration');
        window.location.href = '/';
      }
    }
    return config;
  },
  error => Promise.reject(error)
);

axios.interceptors.response.use(
  response => response,
  error => {
    if (error.response && error.response.data.status === 'error' && error.response.data.reason.includes('AUTH-')) {
      console.error(
        `LOGOUT(interceptors): Backend indicated AUTH error ${userStore?.getters?.user?.email}, resetting session. ${error.response?.data}`
      );
      sessionStorage.clear();
      localStorage.removeItem('_token');
      localStorage.removeItem('tokenExpiration');
      window.location.href = '/';
    }

    return Promise.reject(error);
  }
);

createApp(App)
  .use(router)
  .use(SentryPlugin, {
    dsn: import.meta.env.VITE_SENTRY_NAVIGATOR_DSN,
    environment: import.meta.env.VITE_ENV,
    release: import.meta.env.VITE_NAVIGATOR_APP_VERSION,
    tracePropagationTargets: import.meta.env.VITE_SENTRY_NAVIGATOR_PROPAGATION_TARGETS,
    replaysSessionSampleRate: import.meta.env.VITE_SENTRY_NAVIGATOR_REPLY_SESSION_SAMPLE_RATE
  })
  .use(Toaster)
  .use(VueSignature)
  .use(vClickOutside)
  .use(VuePapaParse)
  .use(hospitalConfigStore)
  .use(userStore)
  .use(api_store)
  .use(state_store)
  .use(createStore)
  .use(DatePicker)
  .use(VueAwesomePaginate)
  .mount('#app');
