
export const PreAdmitState = Object.freeze({
  Start: 'start',
  RegistrationMessageSent: 'registration_message_sent',
  ReadyForReview: 'ready_for_review',
  ReadyForAdmission: 'ready_for_admission',
  NeedsRevision: 'needs_revision',
  PatientAdmitted: 'patient_admitted',
  RequirementsAreNotMet: 'requirements_are_not_met',
  AwaitingReplayForPatient: 'awaiting_reply_from_the_patient',
  ReviewPatientResponse: 'review_patient_response',
  AllRequirementsMet: 'all_requirements_met',
  NeverWentThroughRegistrationProcess: 'never_went_through_registration_process'
});
