<template>
  <div class="col-12 mt-4">
    <label for="value" class="text-sm font-medium text-gray-700 mr-2">Gender:</label>
    <div v-if="textBoxDisabled" class="mt-1">
      <span class="text-sm text-gray-500">{{ modelValue ? modelValue : 'N/A' }}</span>
    </div>
    <div v-else class="mt-1">
      <select
        :id="inputId"
        v-model="localData"
        :disabled="isTextBoxDisabled"
        class="block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
      >
        <option
          v-for="(gender, index) in genders"
          :key="index"
          :value="gender.oapi_name"
          :disabled="isTextBoxDisabled"
        >
          {{ gender.display_name }}
        </option>
      </select>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import StringUtility from 'utils/stringUtility';

export default {
  name: 'FormGender',
  props: ['modelValue', 'id', 'genders'],
  emits: ['update:modelValue'],
  data() {
    return {
      inputId: this.id || `gender_${(Math.random() * 1000).toFixed()}`
    };
  },
  computed: {
    ...mapState(['textBoxDisabled']),
    isTextBoxDisabled() {
      return this.textBoxDisabled;
    },
    StringUtility() {
      return StringUtility;
    },
    localData: {
      get() {
        return this.modelValue;
      },
      set(newValue) {
        this.$emit('update:modelValue', newValue);
      }
    }
  }
};
</script>
