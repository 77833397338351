<template>
  <div class="signature-box">
    <canvas
      ref="canvas"
      @mousedown="startDrawing"
      @mousemove="draw"
      @mouseup="stopDrawing"
      @touchstart="startDrawingTouch"
      @touchmove="drawTouch"
      @touchend="stopDrawing"
    >
    </canvas>
    <button
      type="button"
      class="m-2 p-1 flex items-center justify-center text-medosync-black text-base rounded-full transition duration-150 ease-in-out bg-medosync-white hover:border hover:shadow-sm hover:shadow-medosync-black h-12 font-medium"
      @click="clearSignature"
    >
      Clear
    </button>
    <button
      type="button"
      v-if="!signatureImage"
      class="m-2 p-1 flex items-center justify-center text-medosync-black text-base rounded-full transition duration-150 ease-in-out bg-medosync-blue hover:border hover:shadow-sm hover:shadow-medosync-black h-12 font-medium"
      @click="saveSignature"
    >
      Save
    </button>
    <span v-if="signatureImage" class="m-2 p-2 bg-medosync-blue">Please turn vertically to finalise</span>
    <div v-if="alert" class="bg-medosync-red">
      <span class="text-medosync-white m-2">Signature too small. Please redo!</span>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    signature: String
  },
  data() {
    return {
      isDrawing: false,
      context: null,
      signaturePixelCount: 0,
      alert: false,
      signatureImage: null
    };
  },
  computed: {
    showOkButton() {
      return this.signaturePixelCount >= 40;
    }
  },
  mounted() {
    this.initializeCanvas();
    this.setInitialSignature();
  },
  methods: {
    initializeCanvas() {
      const canvas = this.$refs.canvas;
      this.context = canvas.getContext('2d');
      canvas.width = window.innerWidth;
      canvas.height = window.innerHeight;
    },
    setInitialSignature() {
      if (this.signature) {
        const img = new Image();
        img.onload = () => {
          if (this.context) {
            this.context.drawImage(img, 0, 0);
          }
        };
        img.src = `data:image/png;base64, ${this.signature}`;
      }
    },
    startDrawing(e) {
      this.isDrawing = true;
      const rect = this.$refs.canvas.getBoundingClientRect();
      const x = e.clientX - rect.left;
      const y = e.clientY - rect.top;
      this.context.beginPath();
      this.context.moveTo(x, y);
    },
    startDrawingTouch(e) {
      this.startDrawing(e.touches[0]);
    },
    draw(e) {
      if (!this.isDrawing) return;
      const rect = this.$refs.canvas.getBoundingClientRect();
      const x = e.clientX - rect.left;
      const y = e.clientY - rect.top;
      this.context.lineTo(x, y);
      this.context.stroke();

      // Update the signature pixel count
      this.signaturePixelCount += 1;
    },
    drawTouch(e) {
      this.draw(e.touches[0]);
    },
    stopDrawing() {
      this.isDrawing = false;
      this.context.closePath();

      // Check the size of the signature in pixels
      if (this.signaturePixelCount < 40) {
        this.alert = true;
      } else {
        this.alert = false;
        this.saveSignature();
      }
    },
    clearSignature() {
      const canvas = this.$refs.canvas;
      this.context.clearRect(0, 0, canvas.width, canvas.height);
      this.signatureImage = null;

      this.signaturePixelCount = 0;
    },
    saveSignature() {
      const canvas = this.$refs.canvas;
      this.signatureImage = canvas.toDataURL('image/png'); // Convert canvas to data URL
      this.$emit('signatureSaved', this.signatureImage); // Emit the signature image
    }
  }
};
</script>

<style>
.signature-box {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-start;
  overflow: hidden;

  background-color: rgb(240, 239, 239);
}

canvas {
  touch-action: none;
  z-index: 1000;
  border-bottom: 2px solid black;
}

.clear-button {
  margin-top: 10px;
}

.ok-button {
  margin-top: 10px;
}
</style>
