<template>
  <!-- start -->
  <div class='col-6 float-left mt-4 flex items-center'>
    <div class="float-left mr-2" :title="title">
      <input
        v-if="!textBoxDisabled || (textBoxDisabled && localData)"
        :id="inputId" 
        :readonly="readonly === true" 
        v-model="localData" 
        type="checkbox"
        :class="[isTextBoxDisabled ? 'important-bg-white border-none' : 'border-2 cursor-pointer']"
        class="h-8 w-8 text-medosync-blue rounded-lg" 
        :placeholder="placeholder" 
        :disabled="isTextBoxDisabled">
        <div v-if="isTextBoxDisabled && (localData == null || localData == false)" class="dash-size flex justify-center items-center">
          <span>-</span>
        </div>
    </div>
    <div v-if="question" class="float-left">
      <label class="block text-sm font-medium text-gray-700">{{ question }}</label>
    </div>
  </div>
  <!-- end -->
</template>

<script>
import { mapState, mapMutations } from 'vuex';
/**
 * Component for a simple question with checkbox
 *
 * Args:
 *   - question: a sentence to describe why there is a Yes/No
 *   - title: if defined then this will be hover-over text
 */
export default {
  name: "CheckboxQuestion",
  props: {
    modelValue: { default: "" },
    question: { type: String, required: false },
    title: { type: String, required: false },
    placeholder: { type: String, required: false, default: "" },
    rows: { type: Number, required: false, default: 1 },
    readonly: { type: Boolean, required: false, default: false },
    id: {}
  },
  emits: ['update:modelValue'],
  data() {
    return {
      inputId: this.id || `checkbox_question${(Math.random() * 1000).toFixed()}`,
      readonlyAttr: this.readonly ? "readonly" : "",
    }
  },
  computed: {
    ...mapState(['textBoxDisabled']),
    isTextBoxDisabled() {
      return this.textBoxDisabled;
    },
    localData: {
      get() {
        return this.modelValue
      },
      set (newValue) {
        this.$emit('update:modelValue', newValue)
      }
    }
  },

  methods: {
    ...mapMutations(['setTextBoxDisabled']),

  },
}
</script>

<style scoped>

textarea {
  width: 100%;
  border: 1px solid #dedede;
}

[type='checkbox']:checked {
  padding: 0.2rem 0.2rem;
  background-origin: content-box;
  background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMzciIGhlaWdodD0iMjYiIHZpZXdCb3g9IjAgMCAzNyAyNiIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPGcgaWQ9ImNoZWNrIj4KPGxpbmUgaWQ9IkxpbmUgMzMiIHgxPSIxLjQxNDIxIiB5MT0iMTMuMTA0NSIgeDI9IjEyLjAwNSIgeTI9IjIzLjY5NTMiIHN0cm9rZT0iYmxhY2siIHN0cm9rZS13aWR0aD0iMiIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIi8+CjxsaW5lIGlkPSJMaW5lIDM0IiB4MT0iMTIuMTQwNiIgeTE9IjIzLjU5NTYiIHgyPSIzNC43MzY0IiB5Mj0iMC45OTk3NjUiIHN0cm9rZT0iYmxhY2siIHN0cm9rZS13aWR0aD0iMiIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIi8+CjwvZz4KPC9zdmc+Cg==);
}

.dash-size {
  font-size: 32px;
  width: 32px;
  height: 32px;
}

.important-bg-white {
  background-color: #fff !important;
}

[type='checkbox']:focus, [type='radio']:focus {
  outline: none;
  box-shadow: none;
}

</style>