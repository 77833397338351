<template>
  <div>
    <div class="p-8">
      <div class="flex s-report-title">
        <div class="col">
          <span class="cursor-pointer" @click="goBack()">
            <i class="fas fa-chevron-left mr-3"></i>
          </span>
          Claims Awaiting Sign-off by Consultant
        </div>
        <div class="col flex flex-row justify-end px-2 mb-2">
          <div>
            <button
              @click="fetch_json_report_data"
              type="button"
              class="inline-flex items-center mx-2 px-2.5 py-1.5 ml-auto border border-transparent text-xs font-medium rounded shadow-sm text-white bg-blue focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            >
              RUN REPORT
            </button>
            <button
              @click="fetch_pdf_report_data"
              type="button"
              class="inline-flex items-center mx-2 px-2.5 py-1.5 ml-auto border border-transparent text-xs font-medium rounded shadow-sm text-white bg-blue focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            >
              DOWNLOAD PDF
            </button>
            <button
              @click="fetch_csv_report_data"
              type="button"
              class="inline-flex items-center mx-2 px-2.5 py-1.5 ml-auto border border-transparent text-xs font-medium rounded shadow-sm text-white bg-blue focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            >
              DOWNLOAD CSV
            </button>
          </div>
        </div>
      </div>
      <div class="col-12 mb-5">
        <div class="mt-3 flex justify-start">
          <div v-if="medics && !isMedic" class="col-4">
            <h3 class="text-lg leading-6 font-medium text-gray-900 mr-5 mb-9">Filter by Consultant:</h3>
            <div class="px-2">
              <v-select
                label="username"
                placeholder="Select Consultant"
                :options="medics"
                v-model="selectedConsultant"
              ></v-select>
            </div>
          </div>
          <div class="col-6 flex flex-col px-2">
            <h3 class="text-lg leading-6 font-medium text-gray-900 mr-5 mb-9">Filter by Insurer:</h3>
            <div class="px-2">
              <v-select
                label="insurer_name"
                placeholder="Select Insurer"
                :options="insurers"
                v-model="selectedInsurer"
              ></v-select>
            </div>
          </div>
        </div>
      </div>
      <div class="col-12">
        <h3 class="text-lg leading-6 font-medium text-gray-900">Claims awaiting sign off</h3>
        <SpinnerLoader v-if="isLoading" />
        <div v-else>
          <div class="flex flex-col">
            <TableComponent :claimsProp="reportData" :tableNames="tableNames" />
          </div>
        </div>
      </div>
    </div>
    <ToastComponent ref="toast"></ToastComponent>
  </div>
</template>

<script>
import { OpenAPIJSON } from 'api';
import { api_store } from '@/store';
import { userStore } from '@/store/user';
import { OpenAPICSV, OpenAPIPDF } from 'api/openapi';
import vSelect from 'vue-select';
import SpinnerLoader from '@/components/misc/SpinnerLoader.vue';
import TableComponent from '@/components/claim-forms/components/TableComponent.vue';
import ToastComponent from '@/components/claim-forms/components/ToastComponent.vue';

export default {
  name: 'ClaimSignOff',
  components: {
    vSelect,
    SpinnerLoader,
    TableComponent,
    ToastComponent
  },
  data() {
    return {
      openApi: new OpenAPIJSON(),
      isLoading: false,
      me: null,
      reportData: null,
      medics: null,
      isMedic: null,
      insurers: [],
      selectedInsurer: null,
      selectedConsultant: null,
      isSortOrderDESC: true,
      sortedProperty: null,
      tableNames: [
        { TableTitle: 'Consultant', firstIndex: 'consultant', secondIndex: '', width: '175', money: false },
        {
          TableTitle: 'Insurer',
          firstIndex: 'insurer',
          secondIndex: '',
          width: '175',
          money: false,
          selectedBox: 'insurer'
        },
        { TableTitle: 'Claim Id', firstIndex: 'hospital_claim_ref', secondIndex: '', width: '175', money: false },
        { TableTitle: 'Patient Name', firstIndex: 'patient_name', secondIndex: '', width: '175', money: false },
        {
          TableTitle: 'Admission Date',
          firstIndex: 'admission_date',
          secondIndex: '',
          width: '175',
          money: false,
          dateF: true
        },
        {
          TableTitle: 'Discharge Date',
          firstIndex: 'discharge_date',
          secondIndex: '',
          width: '175',
          money: false,
          dateF: true
        },
        { TableTitle: 'Procedure', firstIndex: 'procedure', secondIndex: '', width: '175', money: false, regex: true }
      ]
    };
  },
  watch: {
    selectedConsultant(newVal) {
      if (!this.selectedConsultant) return (this.selectedConsultant = { consultant_id: null, username: '' });
      if (this.selectedConsultant?.consultant_id == null && this.selectedConsultant?.person_id == null) return;
      localStorage.setItem('selectedConsultantReport', JSON.stringify(newVal));
      this.fetch_json_report_data();
    },
    selectedInsurer() {
      this.fetch_json_report_data();
    }
  },
  async mounted() {
    await this.listMedics();
    await this.listInsurerContracts();
    try {
      const selectedConsultantReport = JSON.parse(localStorage.getItem('selectedConsultantReport'));
      this.selectedConsultant = selectedConsultantReport;
    } catch {
      this.fetch_json_report_data();
    }
  },
  methods: {
    _build_request() {
      let requestData = {};
      if (this.selectedConsultant?.consultant_id) {
        requestData = {
          consultant_id: this.selectedConsultant?.consultant_id
        };
      } else if (this.selectedConsultant?.person_id) {
        requestData = {
          consultant_person_id: this.selectedConsultant?.person_id
        };
      }
      if (this.selectedInsurer) requestData.insurer_name = this.selectedInsurer.insurer_name;

      return requestData;
    },
    async fetch_json_report_data() {
      this.isLoading = true;
      if (this.selectedConsultant?.consultant_id == null && this.selectedConsultant?.person_id == null) {
        this.isLoading = false;
        return this.$refs.toast.showToast('No consultant selected', 'fa-solid fa-triangle-exclamation', 'error');
      }

      try {
        const request_data = this._build_request();
        const { items } = await this.openApi.reportConsulantClaimOutstandingAwaitingSignoff(
          request_data?.consultant_id,
          null,
          request_data?.consultant_person_id,
          null,
          request_data?.insurer_name
        );
        this.reportData = items;
        this.isLoading = false;
      } catch (error) {
        this.$toast.error('Something went wrong getting the report data. Please try again later.');
        this.isLoading = false;
      }
    },
    async fetch_pdf_report_data() {
      if (this.selectedConsultant?.consultant_id == null)
        return this.$refs.toast.showToast('No consultant selected', 'fa-solid fa-triangle-exclamation', 'error');

      const request_data = this._build_request();
      const oapi = new OpenAPIPDF();
      this.pdf = await oapi.reportConsulantClaimOutstandingAwaitingSignoff(
        request_data?.consultant_id,
        null,
        request_data?.consultant_person_id,
        null,
        request_data?.insurer_name
      );
    },
    async fetch_csv_report_data() {
      if (this.selectedConsultant?.consultant_id == null)
        return this.$refs.toast.showToast('No consultant selected', 'fa-solid fa-triangle-exclamation', 'error');

      const request_data = this._build_request();
      const oapi = new OpenAPICSV();
      await oapi.reportConsulantClaimOutstandingAwaitingSignoff(
        request_data?.consultant_id,
        null,
        request_data?.consultant_person_id,
        null,
        request_data?.insurer_name
      );
    },
    async listInsurerContracts() {
      try {
        const { items } = await this.openApi.searchInsurances();
        this.insurers = items;
      } catch (error) {
        this.$refs.toast.showToast(error, 'fa-solid fa-triangle-exclamation', 'error');
      }
    },
    async listMedics() {
      this.isMedic = userStore.getters.user?.is_medic;
      if (this.isMedic) {
        this.medics = [
          {
            person_id: userStore.getters.user?.person_id,
            username: userStore.getters.user?.username
          }
        ];
        this.selectedConsultant = {
          person_id: userStore.getters.user?.person_id,
          username: userStore.getters.user?.username
        };
      } else {
        await api_store.cache
          .dispatch('LIST_MY_CONSULTANTS', {})
          .then(res => {
            this.medics = res;
            this.medics.forEach(item => (item.consultant_id = item.medic.id));
            this.medics.forEach(item => (item.username = item.medic.ro.full_name));
          })
          .catch(error => {
            this.$refs.toast.showToast(error, 'fa-solid fa-triangle-exclamation', 'error');
          });
      }
    },
    async sortBy(property) {
      this.sortedProperty == property ? (this.isSortOrderDESC = !this.isSortOrderDESC) : (this.isSortOrderDESC = true);
      this.sortedProperty = property;
      this.reportData.sort((a, b) => {
        var first;
        var second;
        try {
          first = a[property].toUpperCase();
        } catch {
          first = '-1';
        }
        try {
          second = b[property].toUpperCase();
        } catch {
          second = '-1';
        }

        if (this.isSortOrderDESC) return first < second ? -1 : first > second ? 1 : 0;
        else return first > second ? -1 : first < second ? 1 : 0;
      });
    },
    goBack() {
      this.$router.push({ path: '/navigator/finance/reporting' });
    }
  }
};
</script>

<style lang="scss" scoped>
.year {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.bg-blue {
  background-color: #00a7e1;
}
</style>
