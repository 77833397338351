<template>
    <div class="custom-menu-section flex-1 flex flex-col min-h-0 bg-indigo-700 medo-bg-primary" :class="{'custom-menu-collapse': !isActive}">

      <div v-show='!isActive' class="collapse-menu-off" @click="open()">
        <i class="fas fa-chevron-right text-indigo-100"></i>
      </div>

      <div v-show="isActive" class="flex-1 flex flex-col pt-5 pb-4 overflow-y-auto">

        <div class="text-indigo-100 font-bold flex items-center flex-shrink-0 px-4">
          Menu List
        </div>

        <div class="collapse-menu" @click="close()">
          <i class="fas fa-chevron-left text-indigo-100"></i>
        </div>

        <nav class="mt-4 flex-1 px-2 pt-0 space-y-1" aria-label="Sidebar">
          
            <router-link to='/profile/edit' class="flex items-center p-2 pl-4 rounded-lg text-indigo-200 hover:bg-indigo-700">
            <span class="flex-1">
              <i class="fas fa-pencil mr-3 text-xl"></i> Edit Profile
            </span>
            </router-link>
        </nav>
      </div>

    </div>
</template>

<script>

export default {
  name: 'SecondMenuProfile',
  props: ['modelValue'],
  emits: ['update:modelValue'],
  components: {
    
  },
  data () {
    return {
      isActive: true
    }
  },
  methods: {
    open () {
      this.isActive = !this.isActive
      this.$emit('update:modelValue', false)
    },
    close () {
      this.isActive = !this.isActive
      this.$emit('update:modelValue', true)
    }
  },
  mounted () {

  }
}
</script>

<style lang='scss' scoped>

  .custom-menu-section {
    float: left;
    width: 270px;
    height: 100vh;
    /*transition: all 0.25s;*/
  }
  
  .custom-menu-collapse {
    width: 30px;
  }

  .collapse-menu {
    padding-top: 20px;
    padding-right: 15px;
    text-align: right;
    cursor: pointer;
    transition: all 0.25s;

    &:hover {
      opacity: 0.65;
    }
  }

  .collapse-menu-off {
    padding-top: 65px;
    padding-right: 10px;
    text-align: right;
    cursor: pointer;
    transition: all 0.25s;

    &:hover {
      opacity: 0.65;
    }
  }
</style>