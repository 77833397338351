<template>
  <div>
    <div class="py-8 px-6">
      <div class="col-12">
        <div class="col-6 s-report-title mt-1">Select Report</div>

        <!-- <div v-if="!isMedic && medics" class="col-6">
              <div class="subtitle medo-secondary">
                <v-select label="username" placeholder='Select Consultant' :options="medics" v-model='selectedConsultant'></v-select>
              </div>
          </div> -->
      </div>

      <div class="col-12 mt-8 rounded">
        <div class="flex items-center border shadow-sm border-solid border-gray-100 py-2 rounded-t">
          <div class="col-4 px-4">Claims Payment Report</div>
          <div class="col px-4"></div>
          <div class="col-auto px-4">
            <router-link
              v-if="selectedConsultant?.personId != null"
              :to="{
                path: '/navigator/finance/reporting/payment-summary',
                query: { personId: selectedConsultant?.personId }
              }"
              type="button"
              class="select-btn text-white bg-btn-blue"
            >
              VIEW
            </router-link>
            <router-link
              v-else
              :to="{ path: '/navigator/finance/reporting/payment-summary' }"
              type="button"
              class="select-btn text-white bg-btn-blue"
            >
              VIEW
            </router-link>
          </div>
        </div>

        <div class="flex items-center border shadow-sm border-solid border-gray-100 py-2">
          <div class="col-4 px-4">Claims Submitted Report</div>
          <div class="col px-4"></div>
          <div class="col-auto px-4">
            <router-link
              to="/navigator/finance/reporting/submitted"
              type="button"
              class="select-btn text-white bg-btn-blue"
            >
              VIEW
            </router-link>
          </div>
        </div>

        <div class="flex items-center border shadow-sm border-solid border-gray-100 py-2">
          <div class="col-4 px-4">Claims Awaiting Sign-off by Consultant</div>
          <div class="col px-4"></div>
          <div class="col-auto px-4">
            <router-link
              to="/navigator/finance/reporting/claims-signoff"
              type="button"
              class="select-btn text-white bg-btn-blue"
            >
              VIEW
            </router-link>
          </div>
        </div>

        <div class="flex items-center border shadow-sm border-solid border-gray-100 py-2 rounded-b">
          <div class="col-4 px-4">Outstanding Claims</div>
          <div class="col px-4"></div>
          <div class="col-auto px-4">
            <router-link
              to="/navigator/finance/reporting/outstanding"
              type="button"
              class="select-btn text-white bg-btn-blue"
            >
              VIEW
            </router-link>
          </div>
        </div>

        <div class="flex items-center border shadow-sm border-solid border-gray-100 py-2 rounded-b">
          <div class="col-4 px-4">Outstanding Claims by insurer</div>
          <div class="col px-4"></div>
          <div class="col-auto px-4">
            <router-link
              to="/navigator/finance/reporting/outstanding-insurer"
              type="button"
              class="select-btn text-white bg-btn-blue"
            >
              VIEW
            </router-link>
          </div>
        </div>

        <div class="flex items-center border shadow-sm border-solid border-gray-100 py-2 rounded-b">
          <div class="col-4 px-4">Outstanding Claims by procedure</div>
          <div class="col px-4"></div>
          <div class="col-auto px-4">
            <router-link
              to="/navigator/finance/reporting/outstanding-procedure"
              type="button"
              class="select-btn text-white bg-btn-blue"
            >
              VIEW
            </router-link>
          </div>
        </div>

        <div class="flex items-center border shadow-sm border-solid border-gray-100 py-2 rounded-b">
          <div class="col-4 px-4">Outstanding Pended Claims</div>
          <div class="col px-4"></div>
          <div class="col-auto px-4">
            <router-link
              to="/navigator/finance/reporting/outstanding-pended"
              type="button"
              class="select-btn text-white bg-btn-blue"
            >
              VIEW
            </router-link>
          </div>
        </div>

        <div class="flex items-center border shadow-sm border-solid border-gray-100 py-2 rounded-b">
          <div class="col-4 px-4">Outstanding Rejected Claims</div>
          <div class="col px-4"></div>
          <div class="col-auto px-4">
            <router-link
              to="/navigator/finance/reporting/outstanding-rejected"
              type="button"
              class="select-btn text-white bg-btn-blue"
            >
              VIEW
            </router-link>
          </div>
        </div>
      </div>

      <div></div>
    </div>
    <ToastComponent ref="toast"></ToastComponent>
  </div>
</template>

<script>
import { api_store } from '@/store';
import { userStore } from '@/store/user';

import ToastComponent from '@/components/claim-forms/components/ToastComponent.vue';

export default {
  name: 'reportingDashboard',
  components: {
    ToastComponent
  },

  data() {
    return {
      personId: null,
      username: null,
      isMedic: null,
      medics: null,
      selectedConsultant: null
    };
  },
  watch: {},

  methods: {
    async init() {
      this.personId = userStore.getters.user?.person_id;
      this.isMedic = userStore.getters.user?.is_medic;
      this.username = userStore.getters.user?.username;

      if (this.isMedic) {
        this.medics = [
          {
            personId: userStore.getters.user?.person_id,
            username: userStore.getters.user?.username
          }
        ];
        this.selectedConsultant = {
          personId: userStore.getters.user?.person_id
        };
      } else {
        await api_store.cache
          .dispatch('LIST_MY_CONSULTANTS', {})
          .then(res => {
            this.medics = res;
            this.medics.forEach(item => (item.consultant_id = item.medic.id));
            this.medics.forEach(item => (item.username = item.medic.ro.full_name));
          })
          .catch(error => {
            this.$refs.toast.showToast(error, 'fa-solid fa-triangle-exclamation', 'error');
          });

        this.selectedConsultant = { personId: null, username: 'Select Consultant' };

        if (this.$route.query?.personId) {
          this.selectedConsultant = this.medics.find(medic => medic.personId == this.$route.query?.personId);
        }
      }
    }
  },
  mounted() {
    this.init();
  }
};
</script>

<style lang="scss" scoped>
.select-btn {
  padding: 8px 14px;
  border-radius: 6px;
  font-size: 12px;
}

.bg-btn-blue {
  background-color: #00a7e1;
}
</style>
