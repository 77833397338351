<script setup>
import { useForm } from 'vee-validate';
import { object, string, date, boolean } from 'yup';

import MembershipDetails from './sections/irish-life/MembershipDetails.vue';
import IllnessHistory from './sections/irish-life/IllnessHistory.vue';
import AccidentDetails from './sections/irish-life/AccidentDetails.vue';
import ClaimSectionTitle from '@/components/admission/ClaimSectionTitle.vue';

const props = defineProps({
  editMode: {
    type: Boolean,
    default: false
  },
  warnings: {
    type: Array,
    default: () => []
  },
  initialData: {
    type: Object,
    default: () => ({})
  }
});

const { values, setValues, defineInputBinds } = useForm({
  validationSchema: object({
    policyNo: string(),
    patientName: string().required(),
    telephone: string().phone().required(),
    birthDate: date().required().min(new Date('1900-01-01')).max(new Date()),
    treatmentResultOfAccident: boolean(),
    privatePatient: boolean(),

    firstSymptomsDate: date(),
    firstConsultation: date(),
    doctorName: string(),
    doctorNumber: string().phone(),
    doctorAddress: string(),
    similarIllness: boolean(),
    similarIllnessDetails: string(),

    injuryDate: date(),
    injuryPlace: string(),
    injuryDescription: string(),
    thirdPartyDetails: string(),
    pursueLegalClaim: boolean(),
    solicitorName: string(),
    solicitorAddress: string()
  }),
  initialValues: {
    patientName: props.initialData?.patientName ?? undefined,
    policyNo: props.initialData?.policyNo ?? undefined,
    telephone: props.initialData?.telephone ?? undefined,
    birthDate: props.initialData?.birthDate ?? undefined,
    treatmentResultOfAccident: props.initialData?.treatmentResultOfAccident ?? undefined,
    privatePatient: props.initialData?.privatePatient ?? undefined,

    firstSymptomsDate: props.initialData?.firstSymptomsDate ?? undefined,
    firstConsultation: props.initialData?.firstConsultation ?? undefined,
    doctorName: props.initialData?.doctorName ?? undefined,
    doctorNumber: props.initialData?.doctorNumber ?? undefined,
    doctorAddress: props.initialData?.doctorAddress ?? undefined,
    similarIllness: props.initialData?.similarIllness ?? undefined,
    similarIllnessDetails: props.initialData?.similarIllnessDetails ?? undefined,

    injuryDate: props.initialData?.injuryDate ?? undefined,
    injuryPlace: props.initialData?.injuryPlace ?? undefined,
    injuryDescription: props.initialData?.injuryDescription ?? undefined,
    thirdPartyDetails: props.initialData?.thirdPartyDetails ?? undefined,
    pursueLegalClaim: props.initialData?.pursueLegalClaim ?? undefined,
    solicitorName: props.initialData?.solicitorName ?? undefined,
    solicitorAddress: props.initialData?.solicitorAddress ?? undefined
  }
});

const similarIllness = defineInputBinds('similarIllness');
defineExpose({ values, setValues });
</script>

<template>
  <MembershipDetails id="irish-life-membership-details" :edit-mode="editMode" :warnings="warnings" />
  <IllnessHistory
    id="irish-life-illness-history"
    :edit-mode="editMode"
    :warnings="warnings"
    :similar-illness="similarIllness.value"
  />
  <AccidentDetails id="irish-life-accident-details" :edit-mode="editMode" :warnings="warnings" />
  <ClaimSectionTitle id="laya-data-protection" title="Consent" />
  <div class="container mx-auto px-4 py-6">
    <p>
      I declare that at the time I underwent medical treatment I was a party to a health insurance contract and was
      entitled to treatment under my Irish Life Health plan. I declare that my doctor, including accident and emergency
      referral, recommended the treatment and referred me to the appropriate consultant for further treatment. I declare
      that to the best of my knowledge, the information provided in Part 1 of this form is accurate, true and complete.
      I authorise the doctors, consultant or hospital to furnish Irish Life Health, or any authorised agent it may
      appoint to act on its behalf, with any information requested, including access to my hospital/medical records,
      where this is necessary in relation to treatment or services received by me or my named dependants in respect of
      this claim. I understand that only medical information relating to my claim will be requested by Irish Life
      Health. I authorise the direct payment by Irish Life Health to the doctors/consultant/ hospital as appropriate for
      the services set out on this claim form to the extent provided for under my Irish Life Health plan. I verify the
      details of the accounts submitted on my behalf by the doctor/ hospital/consultant as an accurate reflection of the
      treatment I received. I understand that the details of these amounts will be included in my Irish Life Health
      statement of payment and I will have the opportunity to contact Irish Life Health directly with any queries.
      Charges not covered under the Irish Life Health plan to which I subscribe will remain my responsibility or that of
      the named dependant who received the treatment to settle directly with the doctors, consultant or hospital
      concerned. In consideration of Irish Life Health discharging my hospital and medical expenses to the extent of
      cover limits, I undertake to Irish Life Health to include these expenses as part of my claim (including any future
      claim) against a third party and to inform my solicitor or Personal Injury Assessment Board of the medical/
      hospital expenses and claims made when pursuing any third party claim. In the event that my claim is adjudicated
      upon, and subject to any order/award to the contrary, I further undertake to repay Irish Life Health the amounts
      due and owing to them out of the proceeds of any settlement received. In the event that a reduced settlement is
      made, I undertake to provide Irish Life Health with verification of the award made from my legal representatives
      and a certificate from counsel, confirming the amounts recovered.
    </p>
    <br />
    <p>
      I understand and authorise that to process my claim Irish Life Health will seek further information and/or share
      relevant information with my solicitor, PIAB or other similar source which Irish Life Health deem necessary in
      relation to the assessment and management of this claim.
    </p>
  </div>
  <ClaimSectionTitle id="laya-data-protection" title="Declaration" />
  <div class="container mx-auto px-4 py-6">
    <p>
      I/we confirm that all the details, answers and information given in this form are true, accurate and complete.
      I/we confirm that I/we am/are giving my/our permission to you to use the information I/ we have given on this form
      for the purposes set out in the Irish Life Health Privacy Notice which can be found at
      <a href="http://www.irishlifehealth.ie/privacy-and-legal/privacy-statement/" class="font-bold" target="_blank">
        http://www.irishlifehealth.ie/privacy-and-legal/privacy-statement/
      </a>
    </p>
  </div>
</template>
