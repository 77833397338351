<template>
  <div>
    <div class="title mt-5">Policy Details</div>

    <!-- start -->
    <div class="col-12 mt-5 overflow-auto">
      <div class="col-12">
        <div class="flex justify-between">
          <label class="block text-sm font-medium text-gray-700">Mobile Phone Number:</label>
          <!--<span class="text-sm text-gray-500" id="email-optional">Optional</span>-->
        </div>
        <div class="mt-1">
          <input
            type="text"
            value="0881234567"
            class="custom-input shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
            placeholder=""
            readonly
          />
        </div>
      </div>
    </div>
    <!-- end -->

    <!-- start -->
    <div class="col-12 mt-5 overflow-auto">
      <div class="col-12">
        <div class="flex justify-between">
          <label class="block text-sm font-medium text-gray-700">Email:</label>
          <!--<span class="text-sm text-gray-500" id="email-optional">Optional</span>-->
        </div>
        <div class="mt-1">
          <input
            type="text"
            value="john@email.com"
            class="custom-input shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
            placeholder=""
            readonly
          />
        </div>
      </div>
    </div>
    <!-- end -->

    <!-- start -->
    <div class="col-12 mt-5">
      <div class="col-12 float-left">
        <div class="flex justify-between">
          <label class="block text-sm font-medium text-gray-700">Date of Birth:</label>
          <!--<span class="text-sm text-gray-500" id="email-optional">Optional</span>-->
        </div>
        <div class="mt-1">
          <CustomDatePicker v-model="dummyDate" readonly />
        </div>
      </div>
    </div>
    <!-- end -->

    <!-- start -->
    <div class="col-12 mt-5 overflow-auto">
      <div class="col-12">
        <div class="flex justify-between">
          <label class="block text-sm font-medium text-gray-700">Title:</label>
          <!--<span class="text-sm text-gray-500" id="email-optional">Optional</span>-->
        </div>
        <div class="mt-1">
          <input
            type="text"
            value="Mr."
            class="custom-input shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
            placeholder=""
            readonly
          />
        </div>
      </div>
    </div>
    <!-- end -->

    <!-- start -->
    <div class="col-12 mt-5 overflow-auto">
      <div class="col-12">
        <div class="flex justify-between">
          <label class="block text-sm font-medium text-gray-700">Name:</label>
          <!--<span class="text-sm text-gray-500" id="email-optional">Optional</span>-->
        </div>
        <div class="mt-1">
          <input
            type="text"
            value="John"
            class="custom-input shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
            placeholder=""
            readonly
          />
        </div>
      </div>
    </div>
    <!-- end -->

    <!-- start -->
    <div class="col-12 mt-5 overflow-auto">
      <div class="col-12">
        <div class="flex justify-between">
          <label class="block text-sm font-medium text-gray-700">Surname:</label>
          <!--<span class="text-sm text-gray-500" id="email-optional">Optional</span>-->
        </div>
        <div class="mt-1">
          <input
            type="text"
            value="Doe"
            class="custom-input shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
            placeholder=""
            readonly
          />
        </div>
      </div>
    </div>
    <!-- end -->

    <!-- start -->
    <div class="col-12 mt-5 overflow-auto">
      <div class="col-12">
        <div class="flex justify-between">
          <label class="block text-sm font-medium text-gray-700">Gender:</label>
          <!--<span class="text-sm text-gray-500" id="email-optional">Optional</span>-->
        </div>
        <div class="mt-1">
          <input
            type="text"
            value="Male"
            class="custom-input shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
            placeholder=""
            readonly
          />
        </div>
      </div>
    </div>
    <!-- end -->

    <!-- start -->
    <div class="col-12 mt-5 overflow-auto">
      <div class="col-12">
        <div class="flex justify-between">
          <label class="block text-sm font-medium text-gray-700">Address:</label>
          <!--<span class="text-sm text-gray-500" id="email-optional">Optional</span>-->
        </div>
        <div class="mt-1">
          <input
            type="text"
            value="Address 1"
            class="custom-input shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
            placeholder=""
            readonly
          />
        </div>
      </div>
    </div>
    <!-- end -->

    <!-- start -->
    <div class="col-12 mt-5 overflow-auto">
      <div class="col-12">
        <div class="flex justify-between">
          <label class="block text-sm font-medium text-gray-700">City:</label>
          <!--<span class="text-sm text-gray-500" id="email-optional">Optional</span>-->
        </div>
        <div class="mt-1">
          <input
            type="text"
            value="Dublin"
            class="custom-input shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
            placeholder=""
            readonly
          />
        </div>
      </div>
    </div>
    <!-- end -->

    <!-- start -->
    <div class="col-12 mt-5 overflow-auto">
      <div class="col-12">
        <div class="flex justify-between">
          <label class="block text-sm font-medium text-gray-700">Eircode:</label>
          <!--<span class="text-sm text-gray-500" id="email-optional">Optional</span>-->
        </div>
        <div class="mt-1">
          <input
            type="text"
            value="D00R56"
            class="custom-input shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
            placeholder=""
            readonly
          />
        </div>
      </div>
    </div>
    <!-- end -->

    <!-- start -->
    <div class="col-12 mt-5 overflow-auto">
      <div class="col-12">
        <div class="flex justify-between">
          <label class="block text-sm font-medium text-gray-700">Country:</label>
          <!--<span class="text-sm text-gray-500" id="email-optional">Optional</span>-->
        </div>
        <div class="mt-1">
          <input
            type="text"
            value="Ireland"
            class="custom-input shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
            placeholder=""
            readonly
          />
        </div>
      </div>
    </div>
    <!-- end -->

    <!-- start -->
    <div class="col-12 mt-5 overflow-auto">
      <div class="col-12">
        <div class="flex justify-between">
          <label class="block text-sm font-medium text-gray-700">County:</label>
          <!--<span class="text-sm text-gray-500" id="email-optional">Optional</span>-->
        </div>
        <div class="mt-1">
          <input
            type="text"
            value="Co. Dublin"
            class="custom-input shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
            placeholder=""
            readonly
          />
        </div>
      </div>
    </div>
    <!-- end -->

    <!-- start -->
    <div class="col-12 mt-5">
      <div class="col-12 float-left">
        <div class="flex justify-between">
          <label class="block text-sm font-medium text-gray-700"
            >Did you elect to be a private patient of the Consultant?</label
          >
          <!--<span class="text-sm text-gray-500" id="email-optional">Optional</span>-->
        </div>
        <div class="mt-1">
          <div class="float-left mr-5">
            <div class="float-left mr-1 text-sm">
              <label class="font-medium text-gray-700">Yes</label>
            </div>
            <div class="float-left">
              <input
                :value="true"
                checked
                type="radio"
                class="custom-checkbox focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded"
              />
            </div>
          </div>

          <div class="float-left mr-5">
            <div class="float-left mr-1 text-sm">
              <label class="font-medium text-gray-700">No</label>
            </div>
            <div class="float-left">
              <input
                :value="false"
                type="radio"
                class="custom-checkbox focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded"
              />
            </div>
          </div>

          <div class="float-left mr-5">
            <div class="float-left mr-1 text-sm">
              <label class="font-medium text-gray-700">N/A</label>
            </div>
            <div class="float-left">
              <input
                :value="null"
                type="radio"
                class="custom-checkbox focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- end -->

    <!-- start -->
    <div class="col-12 mt-5">
      <div class="col-12 float-left">
        <div class="flex justify-between">
          <label class="block text-sm font-medium text-gray-700"
            >I give my permission to share my medical records with my GP. I understand that I can revoke this consent by
            contacting the clinic.</label
          >
          <!--<span class="text-sm text-gray-500" id="email-optional">Optional</span>-->
        </div>
        <div class="mt-1">
          <div class="float-left mr-5">
            <div class="float-left mr-1 text-sm">
              <label class="font-medium text-gray-700">Yes</label>
            </div>
            <div class="float-left">
              <input
                :value="true"
                type="radio"
                class="custom-checkbox focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded"
              />
            </div>
          </div>

          <div class="float-left mr-5">
            <div class="float-left mr-1 text-sm">
              <label class="font-medium text-gray-700">No</label>
            </div>
            <div class="float-left">
              <input
                :value="false"
                checked
                type="radio"
                class="custom-checkbox focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded"
              />
            </div>
          </div>

          <div class="float-left mr-5">
            <div class="float-left mr-1 text-sm">
              <label class="font-medium text-gray-700">N/A</label>
            </div>
            <div class="float-left">
              <input
                :value="null"
                type="radio"
                class="custom-checkbox focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- end -->

    <!-- start -->
    <div class="col-12 mt-5 overflow-auto">
      <div class="col-12">
        <div class="flex justify-between">
          <label class="block text-sm font-medium text-gray-700">Emergency Contact First Name:</label>
          <!--<span class="text-sm text-gray-500" id="email-optional">Optional</span>-->
        </div>
        <div class="mt-1">
          <input
            type="text"
            value="Jane"
            class="custom-input shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
            placeholder=""
            readonly
          />
        </div>
      </div>
    </div>
    <!-- end -->

    <!-- start -->
    <div class="col-12 mt-5 overflow-auto">
      <div class="col-12">
        <div class="flex justify-between">
          <label class="block text-sm font-medium text-gray-700">Emergency Contact Last Name:</label>
          <!--<span class="text-sm text-gray-500" id="email-optional">Optional</span>-->
        </div>
        <div class="mt-1">
          <input
            type="text"
            value="Doe"
            class="custom-input shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
            placeholder=""
            readonly
          />
        </div>
      </div>
    </div>
    <!-- end -->

    <!-- start -->
    <div class="col-12 mt-5 overflow-auto">
      <div class="col-12">
        <div class="flex justify-between">
          <label class="block text-sm font-medium text-gray-700">Emergency contact phone number:</label>
          <!--<span class="text-sm text-gray-500" id="email-optional">Optional</span>-->
        </div>
        <div class="mt-1">
          <input
            type="text"
            value="0881234566"
            class="custom-input shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
            placeholder=""
            readonly
          />
        </div>
      </div>
    </div>
    <!-- end -->

    <div style="clear: both"></div>

    <div class="title mt-5">History of Illness Section</div>

    <!-- start -->
    <div class="col-12 mt-5">
      <div class="col-12 float-left">
        <div class="flex justify-between">
          <label class="block text-sm font-medium text-gray-700">When did you/the patient first notice symptoms?</label>
          <!--<span class="text-sm text-gray-500" id="email-optional">Optional</span>-->
        </div>
        <div class="mt-1">
          <CustomDatePicker v-model="dummyDate2" readonly />
        </div>
      </div>
    </div>
    <!-- end -->

    <!-- start -->
    <div class="col-12 mt-5">
      <div class="col-12 float-left">
        <div class="flex justify-between">
          <label class="block text-sm font-medium text-gray-700"
            >When did you/the patient first consult with a doctor for this condition?</label
          >
          <!--<span class="text-sm text-gray-500" id="email-optional">Optional</span>-->
        </div>
        <div class="mt-1">
          <CustomDatePicker v-model="dummyDate2" readonly />
        </div>
      </div>
    </div>
    <!-- end -->

    <!-- start -->
    <div class="col-12 mt-5">
      <div class="col-12 float-left">
        <div class="flex justify-between">
          <label class="block text-sm font-medium text-gray-700"
            >Have you/the patient claimed for this or related conditions before?</label
          >
          <!--<span class="text-sm text-gray-500" id="email-optional">Optional</span>-->
        </div>
        <div class="mt-1">
          <div class="float-left mr-5">
            <div class="float-left mr-1 text-sm">
              <label class="font-medium text-gray-700">Yes</label>
            </div>
            <div class="float-left">
              <input
                :value="true"
                type="radio"
                class="custom-checkbox focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded"
              />
            </div>
          </div>

          <div class="float-left mr-5">
            <div class="float-left mr-1 text-sm">
              <label class="font-medium text-gray-700">No</label>
            </div>
            <div class="float-left">
              <input
                :value="false"
                checked
                type="radio"
                class="custom-checkbox focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded"
              />
            </div>
          </div>

          <div class="float-left mr-5">
            <div class="float-left mr-1 text-sm">
              <label class="font-medium text-gray-700">N/A</label>
            </div>
            <div class="float-left">
              <input
                :value="null"
                type="radio"
                class="custom-checkbox focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- end -->

    <!-- start -->
    <div class="col-12 mt-5">
      <div class="col-12 float-left">
        <div class="flex justify-between">
          <label class="block text-sm font-medium text-gray-700"
            >Was treatment received directly as a result of an accident?</label
          >
          <!--<span class="text-sm text-gray-500" id="email-optional">Optional</span>-->
        </div>
        <div class="mt-1">
          <div class="float-left mr-5">
            <div class="float-left mr-1 text-sm">
              <label class="font-medium text-gray-700">Yes</label>
            </div>
            <div class="float-left">
              <input
                :value="true"
                type="radio"
                class="custom-checkbox focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded"
              />
            </div>
          </div>

          <div class="float-left mr-5">
            <div class="float-left mr-1 text-sm">
              <label class="font-medium text-gray-700">No</label>
            </div>
            <div class="float-left">
              <input
                :value="false"
                checked
                type="radio"
                class="custom-checkbox focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded"
              />
            </div>
          </div>

          <div class="float-left mr-5">
            <div class="float-left mr-1 text-sm">
              <label class="font-medium text-gray-700">N/A</label>
            </div>
            <div class="float-left">
              <input
                :value="null"
                type="radio"
                class="custom-checkbox focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- end -->

    <div class="col-12 text mt-5">
      <b>Data Protection Statement</b> <br />
      The information you provide will be used to manage the administration of your policy and is held in accordance
      with the Data Protection Acts 1988 and 2003 (as amended). We may need to collect sensitive information (such as
      medical information) about you and others named on the insurance policy. By providing this information you will be
      agreeing to us or our agents or other insurers processing that information for the purpose outlined above. In the
      event that your treatment has involved another person, or if their details are likely to be documented in your
      Medical Notes/File, then their express consent should be acquired in advance of sharing sensitive data. Medical
      information will be kept confidential and may be disclosed, on a strictly confidential basis to those involved
      with your treatment or care or their health professional agents. Information may also be shared with other
      insurers, either directly or through people acting for the insurer such as Investigators and where we are entitled
      to do so under the Data Protection Acts. However, anonymised data – that is, information which does not identify
      an individual – may be used by laya healthcare, or disclosed to others, for research or statistical purposes.
      Access to non-medical information may be granted by laya healthcare to others on a strictly confidential basis in
      the course of and for the purpose of the efficient administration of laya healthcare (for example in connection
      with audit, systems development, managing and improving our services). You have a right to apply for a copy of the
      information held by us about you (for which a small charge, not exceeding €6.35, may apply) and you have a right
      to have any inaccuracies in your information corrected. Please send your request in writing to the Information
      Protection Manager, at laya healthcare, Eastgate Road, Eastgate Business Park, Little Island, Co Cork.
      <div>
        <div class="float-left mr-5 mt-3">
          <div class="float-left mr-2">
            <input
              :value="yes"
              checked
              type="checkbox"
              class="custom-checkbox focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded"
            />
          </div>
          <div class="float-left mr-1 text-sm">
            <label class="font-medium text-gray-700">I agree</label>
          </div>
        </div>
      </div>
    </div>

    <div class="col-12 text mt-5 mb-5">
      <b>Declaration and Consent</b> <br />
      I declare that at the time the expenses were incurred I/the patient was entitled to private medical insurance
      benefits under my/the patient’s chosen laya healthcare scheme. I declare that my/the patient’s doctor recommended
      the specialist treatment and to the best of my knowledge and belief the information given on this form is true and
      complete. I authorise and request the hospital/specialist/ consultant/physician/health provider concerned to
      furnish laya healthcare or its duly authorised agents acting on its behalf (including, but not limited to, medical
      professionals whose services are retained by laya healthcare for the purpose of assessing claims) with all
      necessary information as laya healthcare or its authorised agents may seek in connection with any treatment or
      other services provided to me or my dependant(s) for the purpose of laya healthcare considering this claim. This
      includes copies of hospital/medical records related to a claim made by me, by which I mean the following in
      particular: records of physical or mental illness or ill-health medical histories records of treatments obtained
      by me length of any stay in a hospital discharge summaries previous insurance details other treatments or services
      received by me or my dependant(s) I confirm that I have read and understood the Data Protection Notice above. I
      confirm that I give explicit consent within the meaning of the Data Protection Acts 1988 & 2003 (as amended) to
      my/the patient’s sensitive personal information (including my/the patient’s hospital/medical records) being
      collected by Laya Healthcare or its authorised agents. Laya Healthcare may use this information that I have
      provided: For managing and administering my insurance policy For underwriting and claims handling To analyse,
      examine or clinically audit the care, claims processes and treatment/ overnight-stay/convalescence/care pathway
      options applied/utilised by medical service providers To examine the handling of claims by a medical service
      provider. Medical service provider means any hospital or doctor (or other healthcare professional service which is
      relevant) I confirm that I give explicit consent to this sensitive personal data being held, used and processed
      for the above purposes and for undertaking investigations into, and to adjudicate on, my/the patient’s claim
      (including investigations into the length of my/ the patient’s hospital stay and the treatment I/the patient
      received whilst in hospital). I have examined and accept the accounts submitted in respect of this claim. Charges
      not eligible for benefit remain my responsibility to settle directly with the hospital and doctors concerned. I
      direct and authorise that all medical expenses (paid out by laya healthcare) recovered from the third party
      responsible for my/the patient’s injuries shall be refunded by my solicitor directly to laya healthcare. I further
      direct my solicitor to deduct these amounts from my settlement cheque and reimburse laya healthcare directly. In
      the event that medical expenses recovered from the third party are refunded directly to me the member I agree to
      refund these monies directly to laya healthcare.
      <div>
        <div class="float-left mr-5 mt-3">
          <div class="float-left mr-2">
            <input
              :value="yes"
              checked
              type="checkbox"
              class="custom-checkbox focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded"
            />
          </div>
          <div class="float-left mr-1 text-sm">
            <label class="font-medium text-gray-700">I agree</label>
          </div>
        </div>
      </div>
    </div>

    <div style="clear: both"></div>

    <div class="signature">
      <img src="@/assets/img/testsignature.png" />
    </div>

    <div style="clear: both"></div>

    <div class="col-12 text mt-5 mb-5">
      I confirm that I have given explicit consent for MedoSync Limited to process this claim on behalf of you the
      patient, the clinic where you were treated and laya healthcare.
      <div>
        <div class="float-left mr-5 mt-3">
          <div class="float-left mr-2">
            <input
              :value="yes"
              checked
              type="checkbox"
              class="custom-checkbox focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded"
            />
          </div>
          <div class="float-left mr-1 text-sm">
            <label class="font-medium text-gray-700">I agree</label>
          </div>
        </div>
      </div>
    </div>

    <div style="clear: both"></div>
  </div>
</template>

<script>
import CustomDatePicker from '@/components/claim-forms/components/Datepicker.vue';

export default {
  name: 'TestForms',
  components: {
    CustomDatePicker
  },
  data() {
    return {
      dummyDate: '1996-06-16',
      dummyDate2: '2022-08-28'
    };
  },
  methods: {},
  mounted() {}
};
</script>

<style lang="scss" scoped>
.custom-input {
  height: 40px;
  padding-left: 8px;
  line-height: 40px;
  border: 1px solid #dedede;
}

textarea {
  width: 100%;
  border: 1px solid #dedede;
}

select {
  width: 100%;
  border: 1px solid #dedede;
}

.custom-input:focus {
  outline: 1px solid #4338ca;
  outline: none;
}

.form-title {
  width: 100%;
  height: 50px;
  padding-left: 15px;
  line-height: 50px;
  font-weight: 600;
  border-radius: 5px;
  color: #fff;
  background: #4338ca;
  cursor: pointer;
}

.custom-icon {
  float: right;
  margin-right: 15px;
  line-height: 50px;
  cursor: pointer;
  transition: all 0.25s;
}

.custom-icon:hover {
  opacity: 0.65;
}

.custom-check {
  float: right;
  margin-right: 15px;
  line-height: 50px;
  cursor: pointer;
  transition: all 0.25s;
  color: #70e000;
}

.custom-check:hover {
  opacity: 0.65;
}

.custom-checkbox {
  width: 20px;
  height: 20px;
  cursor: pointer;
}

.admission-li-header {
  height: 50px;
  font-weight: 600;
  color: #4338ca;
}

.admission-li {
  height: 50px;
}

.admission-checkbox {
  margin-top: 15px;
}

.custom-text {
  font-size: 14px;
  line-height: 18px;
}

.section-completed {
  background: #3ab795 !important;
}

.custom-click {
  color: #e76f51;
  cursor: pointer;
  transition: 0.25s all;

  &:hover {
    opacity: 0.6;
  }
}

.title {
  font-size: 22px;
  font-weight: 600;
  font-style: italic;
  color: #2563eb;
  clear: both;
}

.text {
  font-size: 14px;
}

.signature {
  padding: 15px;
  border: 1px solid #eee;
}
</style>
