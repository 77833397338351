<template>
  <div>
    <div class="p-8">
      <div class="flex s-report-title">
        <div class="col">
          <span class="cursor-pointer" @click="goBack()">
            <i class="fas fa-chevron-left mr-3"></i>
          </span>
          Payment summary
        </div>
        <div class="col-auto flex mb-1">
          <select
            id="location"
            name="location"
            v-model="selectedYear"
            class="year-select block w-full rounded-md border-gray-300 py-2 pl-3 pr-10 text-base focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
          >
            <option v-for="year in years" :key="year">{{ year }}</option>
          </select>
          <v-select
            class="year-select text-base ml-2 p-0 focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
            v-if="!isMedic && medics"
            label="username"
            placeholder="Select Consultant"
            :options="medics"
            v-model="selectedConsultant"
          ></v-select>
        </div>
      </div>
      <div class="mt-8">
        <dl
          class="mt-5 grid grid-cols-1 divide-y divide-gray-200 overflow-hidden rounded-lg bg-white shadow md:grid-cols-2 md:divide-y-0 md:divide-x"
        >
          <div class="px-4 py-5 sm:p-6">
            <dt class="text-base font-normal text-gray-900">Paid Amount</dt>
            <dd class="mt-1 flex items-baseline justify-between md:block lg:flex">
              <div class="flex items-baseline text-2xl font-semibold text-blue">
                {{ paid_gross.toLocaleString(language, { minimumFractionDigits: 2, maximumFractionDigits: 2 }) }} €
              </div>
            </dd>
          </div>
          <div class="px-4 py-5 sm:p-6">
            <dt class="text-base font-normal text-gray-900">Withholding Tax</dt>
            <dd class="mt-1 flex items-baseline justify-between md:block lg:flex">
              <div class="flex items-baseline text-2xl font-semibold text-blue">
                {{ withholding_tax.toLocaleString(language, { minimumFractionDigits: 2, maximumFractionDigits: 2 }) }} €
              </div>
            </dd>
          </div>
        </dl>
      </div>
      <div class="listing">
        <div class="flex flex-col">
          <div class="-ml-6 overflow-x-auto">
            <div class="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
              <SpinnerLoader v-if="isLoading" />
              <div v-else>
                <TableComponent
                  :claimsProp="calculatedData"
                  :tableNames="tableNames"
                  :optionSearch="false"
                  :optionResultPerPage="false"
                  @sentValue="handleTableEmits"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <ToastComponent ref="toast"></ToastComponent>
  </div>
</template>

<script>
import { OpenAPIJSON, OpenAPIPDF, OpenAPICSV, OpenAPIDateRange } from 'api';
import { api_store } from '@/store';
import { userStore } from '@/store/user';
import vSelect from 'vue-select';
import SpinnerLoader from '@/components/misc/SpinnerLoader.vue';
import TableComponent from '@/components/claim-forms/components/TableComponent.vue';
import SortedArrayMap from 'collections/sorted-array-map';
import ToastComponent from '@/components/claim-forms/components/ToastComponent.vue';

export default {
  name: 'Payment-summary',
  components: {
    vSelect,
    SpinnerLoader,
    TableComponent,
    ToastComponent
  },
  data() {
    return {
      openApi: new OpenAPIJSON(),
      isLoading: false,
      selectedYear: new Date().getFullYear(),
      years: [],
      calculatedData: new SortedArrayMap(),
      paid_gross: 0.0,
      withholding_tax: 0.0,
      username: null,
      isMedic: null,
      medics: null,
      selectedConsultant: null,
      tableNames: [
        { TableTitle: 'Year', firstIndex: 'year', secondIndex: '', width: '155', money: false },
        { TableTitle: 'Month', firstIndex: 'monthName', secondIndex: '', data: true, width: '155', money: false },
        { TableTitle: 'Paid', firstIndex: 'total', secondIndex: 'paid_gross', width: '155', money: true },
        { TableTitle: 'Tax', firstIndex: 'total', secondIndex: 'withholding_tax', width: '155', money: true },
        {
          TableTitle: 'Amount Paid less Tax',
          firstIndex: 'total',
          secondIndex: 'paid_net',
          width: '255',
          money: true
        },
        {
          TableTitle: 'PDF',
          firstIndex: 'month',
          secondIndex: null,
          buttons: [
            {
              class:
                'hover-tooltip inline-flex items-center px-2.5 py-1.5 ml-auto border border-transparent text-xs font-medium rounded shadow-sm text-white bg-blue-500 hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-400 fa fa-download',
              command: 'downloadReportPDF',
              name: ''
            }
          ],
          width: '100'
        },
        {
          TableTitle: 'CSV',
          firstIndex: 'month',
          secondIndex: null,
          buttons: [
            {
              class:
                'hover-tooltip inline-flex items-center px-2.5 py-1.5 ml-auto border border-transparent text-xs font-medium rounded shadow-sm text-white bg-blue-500 hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-400 fa fa-download',
              command: 'downloadReportCSV',
              name: ''
            }
          ],
          width: '100'
        }
      ]
    };
  },
  watch: {
    selectedYear() {
      this.getInvoices();
    },
    selectedConsultant(newVal) {
      if (!this.selectedConsultant) return (this.selectedConsultant = { consultant_id: null, username: '' });
      if (this.selectedConsultant.consultant_id == null) return;
      localStorage.setItem('selectedConsultantReport', JSON.stringify(newVal));
      this.getInvoices();
    }
  },
  async mounted() {
    this.language = navigator.language || navigator.userLanguage;
    await this.getConsultant();
    try {
      const selectedConsultantReport = JSON.parse(localStorage.getItem('selectedConsultantReport'));
      this.selectedConsultant = selectedConsultantReport;
      if (this.selectedConsultant?.person_id) this.getInvoices();
    } catch {
      this.getInvoices();
    }
    this.fillYears();
  },
  methods: {
    dateFunction(value) {
      const year = value.getFullYear();
      const month = ('0' + (value.getMonth() + 1)).slice(-2);
      const day = ('0' + value.getDate()).slice(-2);
      value = `${year}-${month}-${day}`;
      value = new Date(value);
      return value;
    },
    fillYears() {
      // fills array from 2021 till current year
      let currentYear = new Date().getFullYear();
      for (let i = 2021; i <= currentYear; i++) {
        this.years.push(i);
      }
    },
    async getInvoices() {
      if (this.selectedConsultant?.consultant_id == null) {
        this.isLoading = false;
        return this.$toast.error('NO CONSULTANT SELECTED');
      }

      this.isLoading = true;

      const data = {
        consultant_id: this.selectedConsultant?.consultant_id,
        payment_date: OpenAPIDateRange.from(this.selectedYear, null)
      };

      try {
        const resp = await this.openApi.paidInvoices(
          data.consultant_id,
          null,
          null,
          null,
          null,
          'MONTH',
          null,
          data.payment_date
        );
        this.calculateReports(resp.summary);
      } catch (error) {
        console.log('error ', error);
      }
      this.isLoading = false;
    },
    calculateReports(reportData) {
      if (reportData.items.length == null) return;

      let compare = function (a, b) {
        if (a.year < b.year) {
          return -1;
        }
        if (a.year > b.year) {
          return 1;
        }
        if (a.month < b.month) {
          return -1;
        }
        if (a.month > b.month) {
          return 1;
        }
        return 0;
      };

      this.paid_gross = reportData.total.paid_gross;
      this.withholding_tax = reportData.total.withholding_tax;
      // BE provides summary out-of-order
      reportData.items.sort(compare);
      for (let j = 0; j < reportData.items.length; j++) {
        const item = reportData.items[j];
        item.monthName = new Date(item.year, item.month - 1).toLocaleString(this.language, { month: 'long' });
      }
      this.calculatedData = reportData.items;
    },
    async getConsultant() {
      this.isLoading = true;
      this.isMedic = userStore.getters.user?.is_medic;
      this.username = userStore.getters.user?.username;

      if (this.isMedic) {
        this.medics = [
          {
            person_id: userStore.getters.user?.person_id,
            username: userStore.getters.user?.username
          }
        ];
        this.selectedConsultant = {
          person_id: userStore.getters.user?.person_id
        };
        this.isLoading = false;
      } else {
        await api_store.cache
          .dispatch('LIST_MY_CONSULTANTS', {})
          .then(res => {
            this.medics = res;
            this.medics.forEach(item => (item.consultant_id = item.medic.id));
            this.medics.forEach(item => (item.username = item.medic.ro.full_name));
            this.selectedConsultant = { consultant_id: null, username: 'Select Consultant' };
            this.setDoctorFromUrl();
          })
          .catch(error => {
            this.$refs.toast.showToast(error, 'fa-solid fa-triangle-exclamation', 'error');
          });
        this.isLoading = false;
      }
    },
    setDoctorFromUrl() {
      if (this.$route.query?.personId) {
        const doctor = this.medics.find(medic => medic.person_id === parseInt(this.$route.query?.personId));
        if (doctor) {
          this.selectedConsultant = {
            consultant_id: doctor.consultant_id,
            username: doctor.firstname + ' ' + doctor.lastname
          };
        }
      }
    },
    goBack() {
      this.$router.push({ path: '/navigator/finance/reporting' });
    },
    async downloadReport(pdf = true, tableData) {
      if (this.selectedConsultant?.consultant_id == null) {
        this.isLoading = false;
        return this.$toast.error('NO CONSULTANT SELECTED');
      }

      const openApi = pdf ? new OpenAPIPDF() : new OpenAPICSV();
      const req = {
        consultant_id: this.selectedConsultant.consultant_id,
        payment_date: OpenAPIDateRange.from(tableData.year, tableData.month - 1)
      };
      try {
        await openApi.paidInvoices(req.consultant_id, null, null, null, null, null, null, req.payment_date);
      } catch (error) {
        console.log(error);
      }
    },
    handleTableEmits(newValue, newValueSecond) {
      if (newValueSecond === 'downloadReportPDF') {
        this.downloadReport(true, newValue);
      }

      if (newValueSecond === 'downloadReportCSV') {
        this.downloadReport(false, newValue);
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.year-select {
  width: 200px;
}

.even-background {
  background: rgba(17, 28, 78, 0.07);
}

.text-blue {
  color: #00a7e1;
}

.bg-blue {
  background-color: #00a7e1;
}

.price {
  display: flex;
  justify-content: flex-end;
}
</style>
