<template>
  <div class="menu-transition h-screen py-8 px-6">
    <div class="col-12 flex">
      <div class="col text-4xl font-bold text-medosync-violet-highlight mb-10">
        {{ titleText }}
      </div>
      <div v-if="disableConsultant" class="col-6 mb-5"></div>
      <div v-else-if="!isMedic && medics" class="col-6 mb-5">
        <div class="subtitle medo-secondary">
          <v-select
            label="username"
            placeholder="Select Consultant"
            :options="medics"
            v-model="selectedConsultant"
            data-test-id="consultant-select"
          ></v-select>
        </div>
      </div>
    </div>

    <PaymentAndTaxFiles
      v-show="mode === 'paymentAndTaxFiles'"
      :mode="mode"
      :selected-consultant="selectedConsultant"
      :insurers="insurers"
      @change-mode="changeMode"
      @set-filters="setFilters"
      @disableConsultantSelect="disableConsultantSelect"
    />
    <Payments
      v-if="mode === 'payments'"
      :selected-consultant="selectedConsultant"
      :file-id-prop="fileId"
      :file-name-prop="fileName"
      :filter-type-prop="filterType"
      :insurers="insurers"
      @change-mode="changeMode"
    />
  </div>
  <ToastComponent ref="toast"></ToastComponent>
</template>

<script>
import { OpenAPIJSON } from 'api';
import { api_store } from '@/store';
import { userStore } from '@/store/user';
import vSelect from 'vue-select';
import PaymentAndTaxFiles from '@/views/finance/PaymentAndTaxFiles.vue';
import Payments from '@/views/finance/Payments.vue';
import ToastComponent from '@/components/claim-forms/components/ToastComponent.vue';

export default {
  components: {
    PaymentAndTaxFiles,
    Payments,
    ToastComponent,
    vSelect
  },
  name: 'FinanceMain',
  data() {
    return {
      openApi: new OpenAPIJSON(),
      selectedConsultant: { consultant_id: null, username: '' },
      medics: null,
      isMedic: null,
      insurers: [],
      mode: 'paymentAndTaxFiles',
      collapsed: false,
      fileId: null,
      fileName: null,
      filterType: null,
      isActive: true,
      disableConsultant: false
    };
  },
  watch: {
    selectedConsultant: function (newVal) {
      if (!this.selectedConsultant) return (this.selectedConsultant = { consultant_id: null, username: '' });
      if (this.selectedConsultant?.medic?.id === null) {
        localStorage.setItem('selectedConsultantReport', null);
        return;
      }
      this.selectedConsultant = newVal;
      localStorage.setItem('selectedConsultantReport', JSON.stringify(this.selectedConsultant));
    }
  },
  computed: {
    titleText() {
      return this.mode == 'paymentAndTaxFiles' ? 'Payments and Tax Summary' : 'Payments';
    }
  },
  mounted() {
    this.init();
    this.setInsurers();
  },
  methods: {
    disableConsultantSelect(value) {
      this.disableConsultant = value;
    },

    async init() {
      this.isMedic = userStore.getters.user?.is_medic;
      this.username = userStore.getters.user?.username;

      if (this.isMedic) {
        this.medics = [
          {
            person_id: userStore.getters.user?.person_id,
            username: userStore.getters.user?.username
          }
        ];
        this.selectedConsultant = {
          person_id: userStore.getters.user?.person_id
        };
      } else {
        await api_store.cache
          .dispatch('LIST_MY_CONSULTANTS', {})
          .then(res => {
            this.medics = res;
            this.medics.forEach(item => (item.consultant_id = item.medic.id));
            this.medics.forEach(item => (item.username = item.medic.ro.full_name));
          })
          .catch(error => {
            this.$refs.toast.showToast(error, 'fa-solid fa-triangle-exclamation', 'error');
          });

        if (localStorage.getItem('selectedConsultantReport') === '') return;
        const selectedConsultantReport = JSON.parse(localStorage.getItem('selectedConsultantReport'));

        if (selectedConsultantReport === 'null')
          return (this.selectedConsultant = { consultant_id: null, username: 'Select Consultant' });
        this.selectedConsultant = selectedConsultantReport;
      }

      if (localStorage.getItem('selectedConsultantReport') === '') return;
      const selectedConsultantReport = JSON.parse(localStorage.getItem('selectedConsultantReport'));

      if (selectedConsultantReport === 'null')
        return (this.selectedConsultant = { consultant_id: null, username: 'Select Consultant' });
      this.selectedConsultant = selectedConsultantReport;
    },
    async setInsurers() {
      await this.openApi
        .list_insurance()
        .then(res => {
          this.insurers = res.items;
        })
        .catch(error => {
          this.$refs.toast.showToast(error, 'fa-solid fa-triangle-exclamation', 'error');
          this.insurers = [];
        });
    },
    changeMode(mode) {
      this.mode = mode;
    },
    setFilters(fileId, fileName, filterType) {
      this.fileId = fileId;
      this.fileName = fileName;
      this.filterType = filterType;
    },
    setMenuActive(bool) {
      this.isActive = bool;
    }
  }
};
</script>

<style>
.main-content {
  float: left;
  height: 100vh;
  padding: 35px;
  overflow: auto;
}

.full-width {
  width: calc(100% - 110px);
}

.part-width {
  width: calc(100% - 350px);
}

.no-sidebar {
  width: calc(100% - 80px);
}

.selected {
  width: 400px;
}
</style>
