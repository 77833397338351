<template>
  <div v-if="user && !loading" class="navigation">
    <side-nav />
  </div>

  <div v-if="!loading" class="content-section">
    <router-view />
  </div>
</template>

<script>
import { userStore } from '@/store/user';
import { hospitalConfigStore } from '@/store/config';
import SideNav from '@/components/navigation/SideNav.vue';
import { OpenAPIJSON } from 'api/openapi';

// global scss
import '@/assets/scss/variables.scss';
import '@/assets/scss/global.scss';
import '@/assets/scss/claims.scss';
import '@/assets/scss/custom.scss';
import '@/assets/scss/transitions.scss';
import '@/assets/scss/additional-attachments.scss';

// redesign 2022 css
import './assets/scss/redesign-components.scss';

export default {
  name: 'AppMain',
  components: {
    SideNav
  },
  data() {
    return {
      user: null,
      loading: true
    };
  },
  methods: {
    async me() {
      if (localStorage.getItem('_token') === null) {
        // User not logged in.
        return;
      }

      try {
        const oapi = new OpenAPIJSON();
        let configRoute = await oapi.getConfig();
        localStorage.setItem('configRoute', JSON.stringify(configRoute));
        let me = await oapi.me();
        localStorage.setItem('me', JSON.stringify(me));
        await userStore.dispatch('getUserInfo');
        await hospitalConfigStore.dispatch('getConfig');
      } catch (error) {
        console.error(`LOGOUT(me): Can not get initial user data ${userStore?.getters?.user?.email}. `, error);
        // Auth token is invalid.
        sessionStorage.clear();
        localStorage.removeItem('_token');
        localStorage.removeItem('tokenExpiration');
        window.location.href = '/';
      }
    }
  },
  async created() {
    await this.me();
    this.user = localStorage.getItem('_token');
    this.loading = false;
  }
};
</script>

<style lang="scss">
/* font roboto */
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700&display=swap');

.content-section {
  float: left;
  margin-left: 65px;
  width: calc(100% - 65px);
}
</style>
