<template>
    <div>
      <div class="min-h-full mt-4">

        <!-- Page heading -->
        <header class="bg-gray-50 py-6 overflow-auto">
          <div class="w-full mx-auto px-4 xl:flex xl:items-center xl:justify-between text-right float-right">

            <li class="flow-root float-left">
              <div class="relative flex items-center space-x-4 rounded-xl hover:bg-gray-50 focus-within:ring-2 focus-within:ring-indigo-500">
                <div class="flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full zima-blue-bg">
                  <i class="fas fa-envelope text-white text-lg"></i>
                </div>
                <div>
                  <p class="mt-1 text-sm text-gray-500 font-semibold">Emails</p>
                </div>
              </div>
            </li>

            <div class="mt-5 flex xl:mt-0 xl:ml-4">
              <span class="hidden sm:block">
                <button type="button" class="float-right inline-flex items-center px-4 py-2 border border-blue-300 rounded-md shadow-sm text-sm font-medium text-blue-500 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-50 focus:ring-purple-500">
                  Send Email
                  <i class="fas fa-paper-plane ml-1"></i>
                </button>
              </span>
            </div>
          </div>
        </header>

        <main class="pt-0 pb-16">
          <div class="max-w-7xl mx-auto sm:px-5 lg:px-5">

            <!-- Stacked list -->
            <ul role="list" class="mt-5 border-t border-gray-200 divide-y divide-gray-200 sm:mt-0 sm:border-t-0">
              <li>

                <a href="#" class="group block mb-3" style="border-bottom: 1px solid #eee;">
                  <div class="flex items-center py-5 px-4 sm:py-6 sm:px-0">
                    <div class="min-w-0 flex-1 flex items-center">
                      <div class="min-w-0 flex-1  md:grid md:grid-cols-2 md:gap-4">
                        <div>
                          <p class="text-sm font-medium text-purple-600 truncate">Emily Selman</p>
                          <p class="mt-2 flex items-center text-sm text-gray-500">
                            <!-- Heroicon name: mini/envelope -->
                            <svg class="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                              <path d="M3 4a2 2 0 00-2 2v1.161l8.441 4.221a1.25 1.25 0 001.118 0L19 7.162V6a2 2 0 00-2-2H3z" />
                              <path d="M19 8.839l-7.77 3.885a2.75 2.75 0 01-2.46 0L1 8.839V14a2 2 0 002 2h14a2 2 0 002-2V8.839z" />
                            </svg>
                            <span class="truncate">emily.selman@example.com</span>
                          </p>
                        </div>
                        <div class="hidden md:block">
                          <div>
                            <p class="text-sm text-gray-400 mt-2">
                              Hey please send me invove...
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div>
                      <!-- Heroicon name: mini/chevron-right -->
                      <svg class="h-5 w-5 text-gray-400 group-hover:text-gray-700" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                        <path fill-rule="evenodd" d="M7.21 14.77a.75.75 0 01.02-1.06L11.168 10 7.23 6.29a.75.75 0 111.04-1.08l4.5 4.25a.75.75 0 010 1.08l-4.5 4.25a.75.75 0 01-1.06-.02z" clip-rule="evenodd" />
                      </svg>
                    </div>
                  </div>
                </a>

                <a href="#" class="group block">
                  <div class="flex items-center">
                    <div class="min-w-0 flex-1 flex items-center">
                      <div class="min-w-0 flex-1  md:grid md:grid-cols-2 md:gap-4">
                        <div>
                          <p class="text-sm font-medium text-purple-600 truncate">John Doe</p>
                          <p class="mt-2 flex items-center text-sm text-gray-500">
                            <!-- Heroicon name: mini/envelope -->
                            <svg class="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                              <path d="M3 4a2 2 0 00-2 2v1.161l8.441 4.221a1.25 1.25 0 001.118 0L19 7.162V6a2 2 0 00-2-2H3z" />
                              <path d="M19 8.839l-7.77 3.885a2.75 2.75 0 01-2.46 0L1 8.839V14a2 2 0 002 2h14a2 2 0 002-2V8.839z" />
                            </svg>
                            <span class="truncate">john.doe@example.com</span>
                          </p>
                        </div>
                        <div class="hidden md:block">
                          <div>
                            <p class="text-sm text-gray-400 mt-2">
                              Please see my attachments...
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div>
                      <!-- Heroicon name: mini/chevron-right -->
                      <svg class="h-5 w-5 text-gray-400 group-hover:text-gray-700" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                        <path fill-rule="evenodd" d="M7.21 14.77a.75.75 0 01.02-1.06L11.168 10 7.23 6.29a.75.75 0 111.04-1.08l4.5 4.25a.75.75 0 010 1.08l-4.5 4.25a.75.75 0 01-1.06-.02z" clip-rule="evenodd" />
                      </svg>
                    </div>
                  </div>
                </a>
              </li>

              <!-- More candidates... -->
            </ul>

          </div>
        </main>
      </div>

    </div>
</template>

<script>

export default {
  name: 'TestEmails',
  components: {

  },
  data () {
    return {
        
    }
  },
  methods: {

  },
  mounted () {

  }
}
</script>

<style lang="scss" scoped>

  .zima-blue-bg {
    background: #0ea5e9;
  }

  .zima-blue {
    color: #0ea5e9;
  }

</style>