<template>
  <div class="grid grid-cols-12 mt-5 relative">
      <div class="col-span-12 mb-5">
          <h4 class="text-center medo-secondary text-xl font-medium">
              {{this.title}}
          </h4>
      </div>
      <div class="col-span-5">
          <h4 :class="{'text-indigo-500': selectedArrayName == 'group1'}" class="text-center text-lg font-medium">{{group1Title}}</h4>

          <div class="mt-2 px-1">
              <input v-model='searchGroup1' type="text" class="custom-input shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md" placeholder="search list..." aria-describedby="email-optional">
          </div>

          <div :class="[selectedArrayName == 'group1' ? 'border-indigo-300 border-2' : 'border-gray-300 border']" class="user-menu overflow-auto rounded-lg mt-2 p-4">
              <div v-for="groupItem in filteredGroup1" :key="groupItem[objectIdentifier]" @click="selectGroupItem(groupItem, 'group1')" :class="[selectedArray.some(item => item[objectIdentifier] == groupItem[objectIdentifier]) ? 'bg-indigo-400 text-white' : 'hover:bg-indigo-100', this.hoverProperty ? 'hover-bubble' : '']" class="border border-gray-300 rounded-lg my-2 text-center cursor-pointer">
                  {{groupItem[displayProperty]}}
                  <div class="bubble">
                    description
                  </div>
              </div>
          </div>
      </div>

      <div class="col-span-2 flex flex-col justify-center items-center">
          <i @click="$emit('remove-from-group', selectedArray)" :class="[selectedArrayName == 'group2' ? 'text-indigo-600 cursor-pointer' : 'text-gray-600 cursor-not-allowed']" class="fas fa-arrow-alt-circle-left arrow-icon"></i>
          <i @click="$emit('add-to-group', selectedArray)" :class="[selectedArrayName == 'group1' ? 'text-indigo-600 cursor-pointer' : 'text-gray-600 cursor-not-allowed']" class="fas fa-arrow-alt-circle-right arrow-icon mt-5"></i>
      </div>

      <div class="col-span-5">
          <h4 :class="{'text-indigo-500': selectedArrayName == 'group2'}" class="text-center text-lg font-medium">{{group2Title}}</h4>

          <div class="mt-2 px-1">
              <input v-model='searchGroup2' type="text" class="custom-input shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md" placeholder="search list..." aria-describedby="email-optional">
          </div>

          <div :class="[selectedArrayName == 'group2' ? 'border-indigo-300 border-2' : 'border-gray-300 border']" class="user-menu overflow-auto rounded-lg mt-2 p-4">
              <div v-for="groupItem in filteredGroup2" :key="groupItem[objectIdentifier]" @click="selectGroupItem(groupItem, 'group2')" :class="[selectedArray.some(item => item[objectIdentifier] == groupItem[objectIdentifier]) ? 'bg-indigo-400 text-white' : 'hover:bg-indigo-100']" class="border border-gray-300 rounded-lg my-2 text-center cursor-pointer">
                  {{groupItem[displayProperty]}}
              </div>
          </div>
      </div>

      <div class="col-span-12 mb-5">
          <h4 class="text-center text-lg font-medium mt-4">
              Selected: {{selectedArray.length}}
          </h4>
      </div>
  </div>
</template>

<script>
export default {

  props: ['group1', 'group2', 'objectIdentifier', 'displayProperty', 'title', 'group1Title', 'group2Title', 'hoverProperty'],

  data () {
    return {
        searchGroup1: "",
        searchGroup2: "",

        selectedArray: [],
        selectedArrayName: ""
    }
  },
  computed: {
    filteredGroup1 () {
        if (this.searchGroup1.toLowerCase() == "") {
            return this.group1
        } else {
            return this.group1.filter((item) => {
            if ((item[this.displayProperty]).toLowerCase().includes(this.searchGroup1.toLowerCase())) {
                return item
            }
            })
        }
    },

    filteredGroup2 () {
        if (this.searchGroup2.toLowerCase() == "") {
            return this.group2
        } else {
            return this.group2.filter((item) => {
            if ((item[this.displayProperty]).toLowerCase().includes(this.searchGroup2.toLowerCase())) {
                return item
            }
            })
        }
    }
  },

  methods: {
    selectGroupItem (selectedItem, arrayName) {
        if (this.selectedArrayName != arrayName) this.selectedArray = [];

        this.selectedArray.some(item => item.aclId == selectedItem.aclId) ? this.selectedArray = this.selectedArray.filter(item => item.aclId != selectedItem.aclId) : this.selectedArray.push(selectedItem);

        this.selectedArray.length == 0 ? this.selectedArrayName = "" : this.selectedArrayName = arrayName;
    }
  }
}
</script>

<style lang='scss' scoped>
    .user-menu {
        height: 250px;
    }

    .arrow-icon {
        font-size: 2rem;
    }

  .custom-back {
      font-size: 28px;
      cursor: pointer;
      transition: all 0.25s;

      &:hover {
          opacity: 0.65;
      }
  }

  .hover-bubble {
    .bubble {
        display:none;
    }
    &:hover {
        .bubble {
            display: block;
            position: relative;
        }
    }
  }

  .claim-title  {
    margin-bottom: 30px;
    font-size: 26px;
    font-weight: 600;
  }

  .custom-input {
    height: 40px;
    padding-left: 8px;
    line-height: 40px;
    border: 1px solid #dedede;
  }

  .custom-select {
    height: 40px;
    padding-left: 8px;
    line-height: 40px;
    border: 1px solid #dedede;
  }
</style>