<template>
  <div class="flex">
    <div class="col s-view-title">Tags Settings / <span>View</span></div>

    <router-link class="col-auto inline-flex no-underline" to="/settings/create-tag">
      <button type="button" class="btn btn-lightblue btn-small">+ Add Tag</button>
      <button type="button" class="btn btn-lightblue btn-small ml-10" @click="resetTags()">Reset</button>
    </router-link>
  </div>

  <div v-if="tags" class="custom-table">
    <div class="table-header">
      <div class="dashboard-item overflow-auto">
        <div class="item-grip">#</div>
        <div class="item-name" style="text-align: left">Field Name</div>
        <div class="item-color text-center">Color</div>
        <div class="item-active">Show</div>
      </div>
    </div>

    <div v-for="(tag, index) in tags" :key="index" class="dashboard-item overflow-auto">
      <div class="item-grip">
        <i class="fas fa-tag pl-1"></i>
      </div>
      <div class="item-name">
        {{ tag.name }}
      </div>
      <div class="item-color text-center pt-4">
        <div v-if="tag.color" class="tag-color" :style="{ background: tag.color }"></div>
        <div v-if="!tag.color">No Color</div>
      </div>
      <div class="item-active">
        <Toggle v-model:checked="tag.active" labelFalse="Hidden" labelTrue="Showing" />
      </div>
    </div>
  </div>

  <div class="s-btn-primary mt-5" @click="updatePreferences()">SAVE</div>
  <ToastComponent ref="toast"></ToastComponent>
</template>

<script>
import { OpenAPIJSON } from 'api/openapi';
import { userStore } from '@/store/user';

import ToastComponent from '@/components/claim-forms/components/ToastComponent.vue';
import Toggle from '@/components/elements/Toggle.vue';

export default {
  name: 'Dashboard-settings',
  components: {
    Toggle,
    ToastComponent
  },
  data() {
    return {
      drag: false,
      tags: null,
      dbPreferences: null
    };
  },
  methods: {
    async loadTags() {
      let ls = JSON.parse(localStorage.getItem('preferences'));
      console.log('ls', ls);
      this.tags = ls.tagsLayout;
    },

    async saveUserPreferences(data) {
      const oapi = new OpenAPIJSON();

      let response = await oapi.saveUserPreferences(JSON.stringify(data));

      if (response && response.status && response.status >= 300) {
        this.$refs.toast.showToast(response.data.status.reason, 'fa-solid fa-triangle-exclamation', 'error');
      } else {
        this.$refs.toast.showToast('Tags preference successfully saved!', 'fas fa-check', 'success');
      }
    },

    updatePreferences() {
      let ls = JSON.parse(localStorage.getItem('preferences'));
      ls.tagsLayout = this.tags;
      localStorage.preferences = JSON.stringify(ls);
      this.saveUserPreferences(ls);
    },

    async _build_delete_request(tag, type) {
      let requestData = {};

      const email = userStore.getters.user?.email;

      requestData.name = tag.name;
      if (type == 'user') {
        requestData.namespace = { email: email, user_type: 'email', namespace: 'user' };
      }
      return requestData;
    },

    async deleteTag(tag, type = 'user', index) {
      const requestData = await this._build_delete_request(tag, type);
      const oapi = new OpenAPIJSON();
      try {
        await oapi.tagDelete(requestData);
        if (type == 'user') {
          this.userTags.splice(index, 1);
        } else {
          this.tags.splice(index, 1);
        }
        this.$refs.toast.showToast('Tag Deleted!', 'fas fa-check', 'success');

        let ls = JSON.parse(localStorage.getItem('preferences'));
        ls.tagsLayout.forEach((item, index) => {
          if (item.name == tag.name) {
            ls.tagsLayout.splice(index, 1);
          }
        });
        localStorage.preferences = JSON.stringify(ls);
        await this.saveUserPreferences(ls);
      } catch (err) {
        return this.$refs.toast.showToast(err.message, 'fa-solid fa-triangle-exclamation', 'error');
      }
      this.init();
    },

    async resetTags() {
      let ls = JSON.parse(localStorage.getItem('preferences'));
      ls.tagsLayout = null;
      localStorage.preferences = JSON.stringify(ls);
      await this.saveUserPreferences(ls);
    }
  },
  mounted() {
    this.loadTags();
  }
};
</script>

<style lang="scss" scoped>
.optional-nav {
  position: fixed;
  top: 0;
  left: 65px;
  height: 100vh;
  overflow: auto;
  background: #ededed;
}

.item-grip {
  float: left;
  width: 30px;
  padding-left: 10px;
  cursor: pointer;
  transition: all 0.25s;

  &:hover {
    opacity: 0.5;
  }
}

.item-name {
  float: left;
  width: 200px;
  padding-left: 45px;
}

.item-color {
  float: left;
  width: 200px;
  padding-left: 45px;
}

.item-active {
  float: right;
  width: 150px;
}

.custom-table {
  width: 100%;
  margin-top: 30px;
  border: 1px solid #d1d1d1;
  border-radius: 8px 8px 0px 0px;
  overflow: auto;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  color: #707070;

  .table-header {
    height: 60px;
    line-height: 60px;
    text-align: center;
    border-radius: 7px 7px 0px 0px;
    background: #c3c6d2;
  }

  .dashboard-item {
    width: 100%;
    height: 60px;
    line-height: 60px;
    border-bottom: 1px solid #d1d1d1;
    overflow: hidden;
  }
}

.tag-color {
  width: 30px;
  height: 30px;
  margin: auto;
  border-radius: 50%;
}

.btn {
  border-radius: 25px;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 20px;
  padding-right: 20px;
}
.btn-small {
  font-size: 14px;
  font-weight: 600;
}

.btn-lightblue {
  background-color: #00a7e1;
  color: #ffffff;
}
</style>
