<template>
  <div class="invoice" v-if="invoice">
    <div class="form-title_" @click="invoiceDetailsActive = !invoiceDetailsActive">
      <i v-show="invoiceDetailsActive" class="fas fa-chevron-up custom-icon"></i>
      <i v-show="!invoiceDetailsActive" class="fas fa-chevron-down custom-icon"></i>
      <template v-if="invoice.invoice_ref !== 'DRAFT'"> Invoice </template>
      <template v-else> Invoice (draft) </template>
    </div>

    <div v-if="invoiceDetailsActive" class="form-wraper_">
      <div class="col-12 mt-3 overflow-auto">
        <div class="col-6 px-3">
          <div class="flex justify-between">
            <label for="email" class="block text-sm font-medium text-gray-700">Bill to</label>
            <!--<span class="text-sm text-gray-500" id="email-optional">Optional</span>-->
          </div>
          <div class="mt-1">
            <input
              type="text"
              v-model="patientName"
              class="custom-input shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
              placeholder=""
              :disabled="isTextBoxDisabled"
            />
          </div>
        </div>
      </div>

      <div style="clear: both"></div>

      <CommonAddress id="address" v-model="invoice.patient.contact" title="Address" :full-width="false" />

      <div style="clear: both"></div>

      <div class="col-12 mt-3">
        <div class="col-6 px-3">
          <div class="flex justify-between">
            <label for="email" class="block text-sm font-medium text-gray-700">Invoice Date</label>
            <!--<span class="text-sm text-gray-500" id="email-optional">Optional</span>-->
          </div>
          <div class="mt-1">
            <date-picker
              v-model:value="invoice.invoice_date"
              value-type="YYYY-MM-DD"
              format="DD-MM-YYYY"
              :disabled="isTextBoxDisabled"
            />
            <!-- <CustomDatePicker v-model="invoice.invoiceDate" /> -->
          </div>
        </div>
      </div>

      <div style="clear: both"></div>

      <div class="col-12 mt-3 overflow-auto">
        <div class="col-6 px-3">
          <div class="flex justify-between">
            <label for="email" class="block text-sm font-medium text-gray-700">MRN</label>
            <!--<span class="text-sm text-gray-500" id="email-optional">Optional</span>-->
          </div>
          <div class="mt-1">
            <input
              type="text"
              v-model="invoice.patient.mrn"
              class="custom-input shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
              placeholder=""
              :disabled="isTextBoxDisabled"
            />
          </div>
        </div>

        <div class="col-6 px-3">
          <div class="flex justify-between">
            <label for="email" class="block text-sm font-medium text-gray-700">Our Ref.</label>
            <!--<span class="text-sm text-gray-500" id="email-optional">Optional</span>-->
          </div>
          <div class="mt-1">
            <input
              type="text"
              v-model="invoice.invoice_ref"
              class="custom-input shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
              placeholder=""
              :disabled="isTextBoxDisabled"
            />
          </div>
        </div>
      </div>

      <div style="clear: both"></div>

      <div class="col-12 mt-3">
        <div class="col-6 px-3">
          <div class="flex justify-between">
            <label for="email" class="block text-sm font-medium text-gray-700">Date of Service</label>
            <!--<span class="text-sm text-gray-500" id="email-optional">Optional</span>-->
          </div>
          <div class="mt-1">
            <date-picker
              v-model:value="invoice.date_of_service"
              value-type="YYYY-MM-DD"
              format="DD-MM-YYYY"
              :disabled="isTextBoxDisabled"
            />
            <!-- <CustomDatePicker v-model="invoice.dateOfService" /> -->
          </div>
        </div>

        <div class="col-6 px-3">
          <div class="flex justify-between">
            <label for="email" class="block text-sm font-medium text-gray-700">Date Reported</label>
            <!--<span class="text-sm text-gray-500" id="email-optional">Optional</span>-->
          </div>
          <div class="mt-1">
            <!-- <CustomDatePicker v-model="invoice.dateReported" /> -->
            <date-picker
              v-model:value="invoice.date_reported"
              value-type="YYYY-MM-DD"
              format="DD-MM-YYYY"
              :disabled="isTextBoxDisabled"
            />
          </div>
        </div>
      </div>

      <div style="clear: both"></div>

      <div class="col-12 mt-3">
        <div class="col-6 px-3">
          <div class="flex justify-between">
            <label for="email" class="block text-sm font-medium text-gray-700">Date Requested</label>
            <!--<span class="text-sm text-gray-500" id="email-optional">Optional</span>-->
          </div>
          <div class="mt-1">
            <!-- <CustomDatePicker v-model="invoice.dateRequested" /> -->
            <date-picker
              v-model:value="invoice.date_requested"
              value-type="YYYY-MM-DD"
              format="DD-MM-YYYY"
              :disabled="isTextBoxDisabled"
            />
          </div>
        </div>
      </div>
      <br />

      <div style="clear: both"></div>
    </div>

    <div class="form-title_ mt-5" @click="procedureCodesActive = !procedureCodesActive">
      Procedure Codes
      <i v-show="procedureCodesActive" class="fas fa-chevron-up custom-icon"></i>
      <i v-show="!procedureCodesActive" class="fas fa-chevron-down custom-icon"></i>
    </div>

    <div v-if="procedureCodesActive" class="form-wraper_">
      <div v-for="(item, index) in invoice.items" :key="index" class="col-12 mt-3">
        <div class="col-3 px-3">
          <div class="flex justify-between">
            <label for="email" class="block text-sm font-medium text-gray-700">Procedure Code</label>
            <!--<span class="text-sm text-gray-500" id="email-optional">Optional</span>-->
          </div>
          <div class="mt-1">
            <ProcedureCodeTypeahead
              v-model="item.procedure_code"
              :claim_id="claim.claim_id"
              :appointment_id="claim.appointment_id"
              :insurer="claim.insurance.insurer_id"
              placeholder="Search procedure code"
              :min-input-length="1"
              @selected-procedure="selected_procedure(index, $event)"
              @cleared-procedure="cleared_procedure(index)"
            />
            <!--
                  <select v-model="item.procedureCode" @change="fillDescription(item.procedureCode, item, index)" class="mt-1 custom-input block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md">
                    <option :value="null"/>
                    <option v-for="(pro, pidx) in procedureCodes" :key='pidx' :value="pro.procedureCode">{{pro.procedureCode}} {{pro.procedureCodeDescription}}</option>
                  </select> -->
            <!-- <input type="text" v-model='item.procedureCode' class="custom-input shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md" placeholder="">
              -->
          </div>
        </div>

        <div class="col-4 px-3">
          <div class="flex justify-between">
            <label for="email" class="block text-sm font-medium text-gray-700">Description</label>
            <!--<span class="text-sm text-gray-500" id="email-optional">Optional</span>-->
          </div>
          <div class="mt-1">
            <textarea
              v-model="item.description"
              rows="4"
              class="p-2 shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
              :disabled="isTextBoxDisabled"
              placeholder="Description"
            ></textarea>
          </div>
        </div>

        <div class="col-4 px-3">
          <div class="flex justify-between ml-2">
            <label for="email" class="block text-sm font-medium text-gray-700 ml-3">Amount</label>
            <!--<span class="text-sm text-gray-500" id="email-optional">Optional</span>-->
          </div>
          <div class="mt-1">
            <span class="mr-2">€</span>
            <input
              type="text"
              class="custom-input"
              :value="getFormattedAmount(item.amount)"
              @blur="handleInput($event, index)"
              :disabled="isTextBoxDisabled"
              placeholder="Amount"
            />

            <i
              class="fas fa-minus-circle custom-delete mt-10"
              :class="{ 'text-gray-600': isTextBoxDisabled, 'text-red-600': !isTextBoxDisabled }"
              @click="removeItem(index)"
            ></i>
          </div>
        </div>
      </div>

      <div style="clear: both"></div>

      <div class="col-12">
        <div class="col-8"></div>

        <div v-if="totalAmount != '0'" class="col-10 border border-sky-500 m-4 p-2">Total: € {{ totalAmount }}</div>
      </div>

      <div v-if="!isTextBoxDisabled" class="my-3 custom-click cursor-pointer font-semibold m-4" @click="addItem()">
        <i class="fas fa-plus-circle text-green-600"></i> Add new
      </div>

      <div style="clear: both"></div>
    </div>
  </div>

  <div v-else>Invoice (loading)</div>

  <!-- <SaveButtonsEditForm v-if="!isTextBoxDisabled" @save="this.showManageModalClaims = true" @cancel="init()" /> -->
  <SaveButtonsEditForm
    v-if="!isTextBoxDisabled"
    @save="this.showManageModalClaims = true"
    @cancel="updateSaveModal()"
  />

  <!-- <EditClaimsModal v-if="showManageModalClaims" :showManageModal="true" @setModalOff="showManageModalClaims = false"
    titleProps='Save all changes made in Invoice form?' @saveChanges="updateDraftInvoice()"></EditClaimsModal> -->

  <EditClaimsModal
    v-if="showManageModalClaims"
    :showManageModal="true"
    @setModalOff="showManageModalClaims = false"
    titleProps="Save all changes made in Invoice form?"
    @saveChanges="consultant_invoice_update(this.invoice.patient.contact.telephone_mobile)"
  ></EditClaimsModal>

  <EditClaimsModal
    v-if="revertModal"
    :titleProps="titleText"
    :titlePropsSub="titleText2"
    :titlePropsFooter="titleText3"
    :tittleSaveReplace="saveReplace"
    :showManageModal="true"
    @setModalOff="revertModal = false"
    @saveChanges="init()"
  >
  </EditClaimsModal>

  <ToastComponent ref="toast"></ToastComponent>
</template>

<script>
import '@/assets/css/typeahead.css';

import CommonAddress from '@/components/form-fields/CommonAddress.vue';
import _ from 'lodash';
import { OpenAPIJSON } from 'api/openapi';
import DatePicker from 'vue-datepicker-next';
import 'vue-datepicker-next/index.css';
import SaveButtonsEditForm from '@/components/claim-forms/components/SaveButtonsEditForm.vue';
import { mapState, mapMutations } from 'vuex';
import EditClaimsModal from '@/components/claim-forms/components/EditClaimsModal.vue';
import ToastComponent from '@/components/claim-forms/components/ToastComponent.vue';
import ProcedureCodeTypeahead from '@/components/form-fields/ProcedureCodeTypeahead.vue';

export default {
  name: 'InvoiceClaims',
  components: {
    // CustomDatePicker,

    CommonAddress,
    DatePicker,
    SaveButtonsEditForm,
    EditClaimsModal,
    ToastComponent,
    ProcedureCodeTypeahead
  },
  props: {
    claim: { required: true, type: Object },
    openForm: { required: false, default: true }
  },
  data() {
    return {
      pdfOptions: {
        margin: 0,
        image: {
          type: 'jpeg',
          quality: 1
        },
        html2canvas: { scale: 3 },
        jsPDF: {
          unit: 'mm',
          format: 'a4',
          orientation: 'p'
        }
      },
      exportFilename: 'my-custom-file.pdf',
      // appointmentId: this.$route.params.appointmentId,
      // consultantId: this.$route.params.consultantId,
      // claimId: this.$route.params.claimId,
      invoice: null,
      invoiceDetailsActive: false,
      procedureCodesActive: false,
      procedures: null,
      immutable: false,
      showManageModalClaims: false,
      revertModal: false
    };
  },
  computed: {
    getFormattedAmount() {
      return function (amount) {
        if (amount === '') {
          return '';
        }
        const numericValue = parseFloat(amount);
        if (isNaN(numericValue)) {
          return '';
        }
        return numericValue.toFixed(2);
      };
    },
    ...mapState(['textBoxDisabled']),
    isTextBoxDisabled() {
      return this.textBoxDisabled;
    },

    totalAmount: {
      get() {
        let t = 0;
        this.invoice.items.forEach(item => {
          if (item.amount == '') return;
          t += parseFloat(item.amount);
        });
        return t.toFixed(2);
      }
    },
    patientName: {
      get() {
        return this.invoice.patient ? this.invoice.patient.first_name + ' ' + this.invoice.patient.last_name : '';
      },
      set(val) {
        let words = val.split(' ');
        this.invoice.patient.first_name = words[0];
        this.invoice.patient.last_name = words[1];
      }
    }
  },

  watch: {
    textBoxDisabled(val) {
      if (!val) this.invoiceDetailsActive = true;
    }
  },

  methods: {
    selected_procedure(index, item) {
      //console.log("selected_procedure idx=", index, " item", item);
      this.invoice.items[index].procedure_code = item.procedure_code;
      this.invoice.items[index].description = item.procedure_code_description;
      this.invoice.items[index].amount =
        item.price.participating_consultant_cost === null ? '0' : item.price.participating_consultant_cost;
    },
    cleared_procedure(index) {
      this.invoice.items[index].procedureCode = '';
      this.invoice.items[index].description = '';
    },

    handleInput(event, index) {
      const inputValue = event.target.value;
      this.invoice.items[index].amount = inputValue;
    },

    updateSaveModal() {
      this.revertModal = true;
      this.titleText = 'Revert all changes from Claim Form?';
      this.titleText2 = 'Are you sure you want to leave this page and revert all changes you made in this claim form?';
      this.titleText3 = 'Please choose the button “Cancel” to review the claim form again.';
      this.saveReplace = 'Revert changes';
    },

    disableTextBox() {
      this.setTextBoxDisabled(false);
      this.invoiceDetailsActive = true;
    },
    enableTextBox() {
      this.setTextBoxDisabled(true);
      //this.invoiceDetailsActive = true;
    },

    ...mapMutations(['setTextBoxDisabled']),
    showItem(item) {
      return item.procedure_code + ' ' + item.procedure_code_description;
    },
    getItemValue(item) {
      return item.procedure_code;
    },
    selectItem(item, field) {
      this.procedures.forEach(p => {
        if (p.procedure_code === item.procedure_code) {
          field.description = item.procedure_code_description;
          field.amount =
            item.price.participating_consultant_cost === null ? '0' : item.price.participating_consultant_cost;
        }
      });
    },

    async consultant_invoice_get() {
      const oapi = new OpenAPIJSON();

      await oapi
        .invoice_get(this.claim)
        .then(res => {
          if (res) {
            this.invoice = res;
            this.invoice.patient.contact = _.mapKeys(this.invoice.patient.contact, (v, k) => _.snakeCase(k));
          } else {
            this.$refs.toast.showToast('Api Error', 'fa-solid fa-triangle-exclamation', 'error');
          }
        })
        .catch(error => {
          this.$refs.toast.showToast(error, 'fa-solid fa-triangle-exclamation', 'error');
        });
    },

    async consultant_invoice_update() {
      try {
        const oapi = new OpenAPIJSON();
        await oapi.invoice_update(this.invoice);
        this.$refs.toast.showToast('Update save', 'fa-solid fa-triangle-exclamation', 'success');
        this.showManageModalClaims = false;
        this.enableTextBox();
      } catch (error) {
        this.$refs.toast.showToast(error, 'fa-solid fa-triangle-exclamation', 'error');
      }
    },

    addItem() {
      let item = {
        entry_date: this.invoice.invoice_date,
        procedure_code: '',
        description: '',
        amount: ''
      };
      this.invoice.items.push(item);
    },

    removeItem(index) {
      if (this.isTextBoxDisabled) return;
      this.invoice.items.splice(index, 1);
    },
    init() {
      (this.revertModal = false), this.$refs.toast.showToast('Changes were reverted .', 'fas fa-check', 'other');

      // this.listProcedureCodes();
      this.enableTextBox();
      this.consultant_invoice_get();
    }
  },
  mounted() {
    // this.listProcedureCodes();
    this.enableTextBox();
    this.consultant_invoice_get();
  }
};
</script>

<style lang="scss" scoped>
.custom-input {
  height: 40px;
  padding-left: 8px;
  line-height: 40px;
  border: 1px solid #dedede;
}

.custom-input:focus {
  outline: 1px solid #4338ca;
  outline: none;
}

.form-wraper_ {
  transition: all 0.5s;
  padding: 0 20px;
  background-color: #ffffff;
  padding-bottom: 1.25rem;
}

.form-title_ {
  width: 100%;
  height: 50px;
  padding-left: 15px;
  line-height: 50px;
  font-weight: 600;
  color: #000000;
  background: #ffffff;
  cursor: pointer;
}

.custom-icon {
  float: left;
  margin-right: 15px;
  line-height: 50px;
  cursor: pointer;
  transition: all 0.25s;
}

.custom-icon:hover {
  opacity: 0.65;
}

//old scss

.claim-title {
  width: 100%;
  height: 75px;
  margin-bottom: 20px;
  padding-left: 15px;
  font-size: 40px;
  line-height: 75px;
  font-weight: 600;
  border-bottom: 2px solid #002e7e;
  color: #4f46e5;
  background: #fff;
}

.footer-section {
  margin-top: 15px;
  width: 100%;
  font-weight: 500;
}

.custom-delete {
  position: absolute;
  margin-top: 10px;
  margin-left: 10px;
  font-size: 12px;
  //color: #e76f51;
  cursor: pointer;
  transition: all 0.25s;

  &:hover {
    opacity: 0.65;
  }
}

.invoice {
  padding: 16px;
  background-color: #d9d9d9;
  margin-bottom: 40px;
}
</style>
