<script setup lang="ts">
import { useField } from 'vee-validate';

const props = defineProps({
  id: {
    type: String,
    required: true
  },
  defaultValue: {
    type: Boolean,
    default: false
  },
  name: {
    type: String,
    required: true
  },
  label: {
    type: String,
    default: ''
  },
  editMode: {
    type: Boolean,
    default: true
  }
});

const { meta, checked, handleChange } = useField(props.name, undefined, {
  type: 'checkbox',
  checkedValue: true,
  uncheckedValue: false
});
</script>
<template>
  <div :id="id + '-container'">
    <div class="flex items-center">
      <input
        v-if="editMode"
        :id="id"
        :name="name"
        v-model="meta.initialValue"
        type="checkbox"
        class="h-10 w-10 sm:h-12 sm:w-12 cursor-pointer text-medosync-blue rounded-lg border-2"
        @change="handleChange"
      />
      <div v-else class="h-10 w-10 sm:h-12 sm:w-12 flex items-center justify-center">
        <img
          v-if="defaultValue"
          :id="id"
          :name="name"
          src="data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMzciIGhlaWdodD0iMjYiIHZpZXdCb3g9IjAgMCAzNyAyNiIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPGcgaWQ9ImNoZWNrIj4KPGxpbmUgaWQ9IkxpbmUgMzMiIHgxPSIxLjQxNDIxIiB5MT0iMTMuMTA0NSIgeDI9IjEyLjAwNSIgeTI9IjIzLjY5NTMiIHN0cm9rZT0iYmxhY2siIHN0cm9rZS13aWR0aD0iMiIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIi8+CjxsaW5lIGlkPSJMaW5lIDM0IiB4MT0iMTIuMTQwNiIgeTE9IjIzLjU5NTYiIHgyPSIzNC43MzY0IiB5Mj0iMC45OTk3NjUiIHN0cm9rZT0iYmxhY2siIHN0cm9rZS13aWR0aD0iMiIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIi8+CjwvZz4KPC9zdmc+Cg=="
          class="h-6 w-6 sm:h-8 sm:w-8"
          alt="Checked"
        />
      </div>
      <div class="ml-2">
        <label :for="id" class="text-lg text-gray-500 ml-4 mr-6">{{ label }}</label>
      </div>
    </div>
  </div>
</template>
<style scoped lang="scss">
[type='checkbox']:checked {
  padding: 0.375rem 0.25rem;
  background-origin: content-box;
  background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMzciIGhlaWdodD0iMjYiIHZpZXdCb3g9IjAgMCAzNyAyNiIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPGcgaWQ9ImNoZWNrIj4KPGxpbmUgaWQ9IkxpbmUgMzMiIHgxPSIxLjQxNDIxIiB5MT0iMTMuMTA0NSIgeDI9IjEyLjAwNSIgeTI9IjIzLjY5NTMiIHN0cm9rZT0iYmxhY2siIHN0cm9rZS13aWR0aD0iMiIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIi8+CjxsaW5lIGlkPSJMaW5lIDM0IiB4MT0iMTIuMTQwNiIgeTE9IjIzLjU5NTYiIHgyPSIzNC43MzY0IiB5Mj0iMC45OTk3NjUiIHN0cm9rZT0iYmxhY2siIHN0cm9rZS13aWR0aD0iMiIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIi8+CjwvZz4KPC9zdmc+Cg==);
  @screen sm {
    padding: 0.75rem 0.375rem;
  }
}
</style>
