<script setup>
import { computed } from 'vue';
import NotificationsImage from '@/components/notifications/NotificationsImage.vue';

const emit = defineEmits(['close-message']);

const props = defineProps({
  message: {
    type: Object,
    required: true
  },
  showCloseButton: {
    type: Boolean,
    default: true
  }
});

const imageMessage = computed(() => {
  return props.message?.attachments?.find(attachment => attachment.data);
});
</script>

<template>
  <div class="flex flex-col h-full relative border-2 p-3 pe-5">
    <h5 class="text-xl font-semibold mb-5">Message from {{ message.author }}</h5>
    <div class="flex gap-x-5 mb-3">
      <span class="text-lg">Note: </span>
      <p class="bg-medosync-grey text-lg grow px-3" :class="{ 'py-1': message.content.content }">
        {{ message.content.content ? message.content.content : '-' }}
      </p>
    </div>
    <NotificationsImage v-if="imageMessage" :image="imageMessage" />
    <i
      v-if="showCloseButton"
      class="fas fa-xmark absolute text-3xl top-3 right-3 cursor-pointer"
      @click="emit('close-message')"
      data-test-id="notification-message-close-button"
    />
  </div>
</template>
