// The claim-lifecycle cam be split into phases, of which there are several states.
// The phases will affect the actions that can be taken in the frontend.
export const ClaimPhase = Object.freeze({
  // Claim is being prepared for submission to the insurance company.
  PRE_SUBMIT: "pre_submit",
  // Claim has been submitted to the insurance company and is awaiting payment or
  // is receiving payments.
  POST_SUBMIT: "post_submit",
  // Claim has received all payments, was cancelled or completed, or entered into some
  // other non-actionable state.
  FINISHED: "finished",
})
