<script setup>
import { useForm } from 'vee-validate';
import { object, string, date, boolean, array } from 'yup';

import MembershipDetails from './sections/esb/MembershipDetails.vue';
import InjurySection from './sections/esb/InjurySection.vue';

const props = defineProps({
  editMode: {
    type: Boolean,
    default: false
  },
  warnings: {
    type: Array,
    default: () => []
  },
  initialData: {
    type: Object,
    default: () => ({})
  }
});

const { values, setValues } = useForm({
  validationSchema: object({
    policyNo: string(),
    patientSurname: string().required(),
    patientForename: string().required(),
    patientAddress: array().of(string()).required(),
    birthDate: date().required().min(new Date('1900-01-01')).max(new Date()),
    telephone: string().phone().required(),

    roadTrafficAccident: boolean(),
    atWork: boolean(),
    causedByThirdParty: boolean(),
    sporting: boolean(),
    pursueLegalClaim: string()
  }),
  initialValues: {
    policyNo: props.initialData?.policyNo ?? undefined,
    patientSurname: props.initialData?.patientSurname ?? undefined,
    patientForename: props.initialData?.patientForename ?? undefined,
    patientAddress: props.initialData?.patientAddress ?? undefined,
    birthDate: props.initialData?.birthDate ?? undefined,
    telephone: props.initialData?.telephone ?? undefined,

    roadTrafficAccident: props.initialData?.roadTrafficAccident ?? undefined,
    atWork: props.initialData?.atWork ?? undefined,
    causedByThirdParty: props.initialData?.causedByThirdParty ?? undefined,
    sporting: props.initialData?.sporting ?? undefined,
    pursueLegalClaim: props.initialData?.pursueLegalClaim ?? undefined
  }
});

defineExpose({ values, setValues });
</script>

<template>
  <MembershipDetails id="esb-membership-details" :edit-mode="editMode" :warnings="warnings" />
  <InjurySection id="esb-injury-section" :edit-mode="editMode" :warnings="warnings" />
</template>
