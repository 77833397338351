<script setup>
import { useForm } from 'vee-validate';
import { object, string, date, boolean, array } from 'yup';

import PolicyDetails from './sections/laya/PolicyDetails.vue';
import HospitalDetails from './sections/laya/HospitalDetails.vue';
import IllnessHistory from './sections/laya/IllnessHistory.vue';
import ReferralDetails from './sections/laya/ReferralDetails.vue';
import AccidentDetails from './sections/laya/AccidentDetails.vue';
import ClaimSectionTitle from '@/components/admission/ClaimSectionTitle.vue';

const props = defineProps({
  editMode: {
    type: Boolean,
    default: false
  },
  warnings: {
    type: Array,
    default: () => []
  },
  initialData: {
    type: Object,
    default: () => ({})
  },
  admission: string
});

const { values, setValues, defineInputBinds } = useForm({
  validationSchema: object({
    policyNo: string(),
    patientTitle: string(),
    patientSurname: string().required(),
    patientForename: string().required(),
    birthDate: date().required().min(new Date('1900-01-01')).max(new Date()),
    patientAddress: array().of(string()).required(),
    telephone: string().phone().required(),
    treatmentResultOfAccident: boolean(),
    privatePatient: boolean(),

    doctorAddress: string(),
    firstTreatment: date(),

    firstSymptomsDate: date(),
    firstConsultation: date(),
    similarIllness: boolean(),
    similarIllnessDate: date(),

    doctorName: string(),
    //firstConsultation: date(),
    //doctorAddress: string(),

    injuryDate: date(),
    injuryPlace: string(),
    injuryDescription: string(),
    causedByThirdParty: boolean(),
    thirdPartyDetails: string(),
    thirdPartyLiability: string(),
    thirdPartyInsurer: string(),
    solicitor: boolean(),
    solicitorName: string(),
    solicitorAddress: string(),
    pursuePiabClaim: boolean(),
    pursueLegalClaim: boolean()
  }),
  initialValues: {
    policyNo: props.initialData?.policyNo ?? undefined,
    patientTitle: props.initialData?.patientTitle ?? undefined,
    patientSurname: props.initialData?.patientSurname ?? undefined,
    patientForename: props.initialData?.patientForename ?? undefined,
    birthDate: props.initialData?.birthDate ?? undefined,
    patientAddress: props.initialData?.patientAddress ?? undefined,
    telephone: props.initialData?.telephone ?? undefined,
    treatmentResultOfAccident: props.initialData?.treatmentResultOfAccident ?? undefined,
    privatePatient: props.initialData?.privatePatient ?? undefined,
    hospitalName: props.initialData?.hospitalName ?? undefined,

    doctorAddress: props.initialData?.doctorAddress ?? undefined,
    firstTreatment: props.initialData?.firstTreatment ?? undefined,

    firstSymptomsDate: props.initialData?.firstSymptomsDate ?? undefined,
    firstConsultation: props.initialData?.firstConsultation ?? undefined,
    similarIllness: props.initialData?.similarIllness ?? undefined,
    similarIllnessDate: props.initialData?.similarIllnessDate ?? undefined,

    doctorName: props.initialData?.doctorName ?? undefined,
    //firstConsultation: props.initialData?.firstConsultation ?? undefined,
    //doctorAddress: props.initialData?.doctorAddress ?? undefined,

    injuryDate: props.initialData?.injuryDate ?? undefined,
    injuryPlace: props.initialData?.injuryPlace ?? undefined,
    injuryDescription: props.initialData?.injuryDescription ?? undefined,
    causedByThirdParty: props.initialData?.causedByThirdParty ?? undefined,
    thirdPartyDetails: props.initialData?.thirdPartyDetails ?? undefined,
    thirdPartyLiability: props.initialData?.thirdPartyLiability ?? undefined,
    thirdPartyInsurer: props.initialData?.thirdPartyInsurer ?? undefined,
    solicitor: props.initialData?.solicitor ?? undefined,
    solicitorName: props.initialData?.solicitorName ?? undefined,
    solicitorAddress: props.initialData?.solicitorAddress ?? undefined,
    pursuePiabClaim: props.initialData?.pursuePiabClaim ?? undefined,
    pursueLegalClaim: props.initialData?.pursueLegalClaim ?? undefined
  }
});

const treatmentResultOfAccident = defineInputBinds('treatmentResultOfAccident');
const similarIllness = defineInputBinds('similarIllness');
const accidentFault = defineInputBinds('causedByThirdParty');
const solicitor = defineInputBinds('solicitor');
const pursueLegalClaim = defineInputBinds('pursueLegalClaim');
defineExpose({ values, setValues, treatmentResultOfAccident, similarIllness });
</script>

<template>
  <PolicyDetails id="laya-policy-details" :edit-mode="editMode" :warnings="warnings" />
  <HospitalDetails id="laya-hospital-details" :edit-mode="editMode" :warnings="warnings" :admission="admission" />
  <IllnessHistory
    id="laya-illness-history"
    :edit-mode="editMode"
    :warnings="warnings"
    :similar-illness="similarIllness.value"
  />
  <ReferralDetails id="laya-referral-details" :edit-mode="editMode" :warnings="warnings" />
  <AccidentDetails
    v-if="treatmentResultOfAccident.value"
    id="laya-accident-details"
    :edit-mode="editMode"
    :warnings="warnings"
    :accident-fault="accidentFault.value"
    :solicitor="pursueLegalClaim.value"
  />
  <ClaimSectionTitle id="laya-data-protection" title="Declaration" />
  <div class="container mx-auto px-4 py-6">
    <div class="columns-3 gap-6">
      <div class="break-inside-avoid">
        <h2 class="text-xl font-bold">Data Protection Statement</h2>
        <p>
          <strong>“Personal Information”</strong> identifies and relates to you or other individuals (e.g. your partner
          or other members of your family). If you provide Personal Information about another individual, you must
          (unless we agree otherwise) inform the individual about the content of this notice and our Privacy Policy and
          obtain their permission (where possible) to share their Personal Information with us. Depending on our
          relationship with you, Personal Information collected may include: contact information, financial information
          and account details, sensitive information about health or medical conditions (collected with your consent
          where required by applicable law) or (where we require it and are legally permitted to collect it). Personal
          Information may be used for the following purposes:
        </p>
        <ul class="list-disc list-inside">
          <li>Insurance administration, e.g. communications, claims processing and payment</li>
          <li>
            Assessments and decisions about the provision and terms of insurance and the settlement of claims including
            but not limited to: a) analyse, examine or clinically audit the care, claims processes and treatment/
            overnight-stay/ convalescence /care pathway options applied/utilised by medical service providers; b) to
            undertake investigations into, and to adjudicate on, patient’s claim (including
          </li>
        </ul>
      </div>
      <div class="break-inside-avoid">
        <ul class="list-disc list-inside">
          <span
            >investigations into the length of the patient’s hospital stay and the treatment received whilst in
            hospital)</span
          >
          <li>Assistance and advice on medical and travel matters</li>
          <li>Management of our business operations and IT infrastructure</li>
          <li>Prevention, detection and investigation of crime, e.g. fraud and money laundering</li>
          <li>Establishment and defence of legal rights</li>
          <li>
            Legal and regulatory compliance (including compliance with laws and regulations outside your country of
            residence)
          </li>
          <li>Monitoring and recording of telephone calls for quality, training and security purposes</li>
          <li>Audit of medical service providers and the handling of claims by a medical services provider</li>
          <li>Marketing, market research and analysis</li>
        </ul>
        <p>
          For the above purposes, Personal Information may be shared with our group companies and third parties (such as
          insurance distribution parties, healthcare professionals and other service providers). Personal Information
          will be shared with other third parties (including government authorities) if required by laws or regulations.
          Appropriate technical and physical security measures are used to keep your Personal
        </p>
      </div>
      <div class="break-inside-avoid">
        <p>
          Information safe and secure. When we provide Personal Information to a third party (including our service
          providers) or engage a third party to collect Personal Information on our behalf, the third party will be
          selected carefully and required to use appropriate security measures. You have a number of rights under data
          protection law in connection with our use of your Personal Information. These rights may only apply in certain
          circumstances and are subject to certain exemptions.
        </p>
        <p>
          These rights may include a right to access Personal Information, a right to request that we correct inaccurate
          data, erase data, or suspend our use of data. These rights may also include a right to transfer your data to
          another organisation, a right to object to our use of your Personal Information, a right to request that
          certain automated decisions we make have human involvement, a right to withdraw consent and a right to
          complain to the data protection regulator in your country. Further information about your rights and how you
          may exercise them is set out in full in our Privacy Policy (see below). More details about your rights and how
          we collect, use and disclose your Personal Information can be found in our full Privacy Policy which is
          available at
          <a href="https://www.layahealthcare.ie/privacypolicy" class="text-blue-500 underline"
            >https://www.layahealthcare.ie/privacypolicy</a
          >
          or upon request by writing to Privacy Lead, LayaHealthcare, Eastgate Road, Eastgate Business Park, Little
          Island, Co Cork, T45 E181 or by emailing
          <a href="mailto:info@layahealthcare.ie" class="text-blue-500 underline">info@layahealthcare.ie</a>.
        </p>
      </div>
    </div>
  </div>
  <hr class="my-4 border-gray-300" />
  <div class="container mx-auto px-4 py-6">
    <div class="columns-3 gap-6">
      <div class="break-inside-avoid">
        <h2 class="text-xl font-bold">Declaration</h2>
        <p>
          I declare that at the time the expenses were incurred, I/the patient was entitled to private medical insurance
          benefits under my/the patient’s chosen laya healthcare scheme. I declare that my/the patient’s doctor
          recommended the specialist treatment and to the best of my knowledge and belief the information given on this
          form is true and complete.
        </p>
        <p>
          For the purpose of considering and determining the eligibility/appropriateness of claims laya healthcare may
          request the hospital/specialist/consultant/physician/health provider concerned to furnish laya healthcare or
          its duly authorised agents acting on its behalf (including, but not limited to, medical professionals whose
          services are retained by laya healthcare) with all necessary information as laya healthcare or its authorised
          agents may seek in connection
        </p>
      </div>
      <div class="break-inside-avoid">
        <p>
          with any treatment or other services provided to you or your dependant(s). This includes copies of hospital/
          medical records related to a claim made by you or your dependant(s), by which I mean the following in
          particular:
        </p>
        <ul class="list-disc list-inside">
          <li>records of physical or mental illness or ill-health;</li>
          <li>medical histories;</li>
          <li>records of treatments obtained by you;</li>
          <li>length of any stay in a hospital;</li>
          <li>discharge summaries;</li>
          <li>previous insurance details;</li>
          <li>other treatments or services received by you or your dependant(s);</li>
        </ul>
      </div>
      <div class="break-inside-avoid">
        <p>
          Charges not eligible for benefit remain my responsibility to settle directly with the hospital and doctors
          concerned. I direct and authorise that all medical expenses (paid out by laya healthcare) recovered from the
          third party responsible for my/the patient’s injuries shall be refunded by my solicitor directly to laya
          healthcare. I further direct my solicitor to deduct these amounts from my settlement cheque and reimburse laya
          healthcare directly. In the event that medical expenses recovered from the third party are refunded directly
          to me, the member, I agree to refund these monies directly to laya healthcare.
        </p>
      </div>
    </div>
  </div>
</template>
