<template>
  <div class="px-2 pt-5">
    <h2 class="ml-1 title medo-secondary">Edit Procedure Code</h2>
    <div class="col-12 mt-6">
      <span>New Procedure Code Number</span>
      <input v-model="newProcNum" :disabled="true" type="number" class="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md custom-input " >
    </div>

    <div class="col-12 mt-4">
      <span>New Procedure Code Description</span>
      <input v-model="newProcDesc" :disabled="true" type="text" class="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md custom-input " >
    </div>

    <div class="col-12">
      <div class="col-6 pr-2 mt-4">
        <span>New Participating Anaesthetist Cost</span>
        <input v-model="newParticipatingAnaesthetist" type="text" class="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md custom-input " >
      </div>

      <div class="col-6 pl-2 mt-4">
        <span>New Participating Consultant Cost</span>
        <input v-model="newParticipatingConsultant" type="text" class="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md custom-input " >
      </div>
    </div>
  </div>

  <div style="clear: both"></div>

  <div class="mt-4 pr-2 flex justify-end">
    <button @click="validateInput" type="button" class="inline-flex items-center px-2.5 py-1.5 ml-auto border border-transparent text-xs font-medium rounded shadow-sm text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500">
      SAVE
    </button>
  </div>

  <Modal v-if="showWarningModal" @close-modal="closeWarningModal" :hide-expand-button="true">
    <template v-slot:header>
      <h3 class="mt-4">Warning!</h3>
    </template>
    <template v-slot:body>
      If you proceed, this change will be applied to all draft invoices
      <div class="col-12 mt-3 flex justify-end">
        <button @click="closeWarningModal" type="button" class="inline-flex items-center mr-2 px-2.5 py-1.5 border border-blue-600 text-blue-600 text-xs font-medium rounded shadow-sm bg-white-600 hover:bg-white-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-white-500">
          NO
        </button>
        <button @click="editProcedureCode" type="button" class="inline-flex items-center px-2.5 py-1.5 border border-transparent text-xs font-medium rounded shadow-sm text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500">
          YES
        </button>
      </div>
    </template>
  </Modal>

  <ToastComponent ref="toast"></ToastComponent>
</template>

<script>
import { OpenAPIJSON } from "api/openapi";
import Modal from '@/components/modal/Modal.vue'
import ToastComponent from '@/components/claim-forms/components/ToastComponent.vue';

export default {
  props: {procedureCode: null, selectedContract: null},
  emits: ['close-modal', 'refresh-list'],
  components: {Modal, ToastComponent},
  data () {
    return {
      newProcNum: null,
      newProcDesc: null,
      newParticipatingAnaesthetist: null,
      newParticipatingConsultant: null,
      //standardParticipatingAnaesthetist: null,
      //standardParticipatingConsultant: null,

      showWarningModal: false,
    }
  },
  mounted () {
    this.newProcNum = this.procedureCode.procedure_code;
    this.newProcDesc = this.procedureCode.procedure_code_description;
    this.newParticipatingAnaesthetist = this.procedureCode.price.participating_anaesthetist_cost;
    this.newParticipatingConsultant = this.procedureCode.price.participating_consultant_cost;
    //this.standardParticipatingAnaesthetist = this.procedureCode.price.standard_anaesthetist_cost;
    //this.standardParticipatingConsultant = this.procedureCode.price.standard_consultant_cost;
  },
  methods: {
    validateInput () {
      if (this.newProcNum == null || this.newProcNum == "")
        return this.$refs.toast.showToast("Procedure Code Number invalid!", 'fa-solid fa-triangle-exclamation', 'error');
      if (this.newProcDesc == null || this.newProcDesc == "")
        return this.$refs.toast.showToast("Procedure Code Description is empty!", 'fa-solid fa-triangle-exclamation', 'error');
      if (this.newParticipatingAnaesthetist == null)
        return this.$refs.toast.showToast("Participating Anaesthetist Cost is invalid!", 'fa-solid fa-triangle-exclamation', 'error');
      if (this.newParticipatingConsultant == null)
        return this.$refs.toast.showToast("Participating Consultant Cost is invalid!", 'fa-solid fa-triangle-exclamation', 'error');

      this.showWarningModal = true;
    },

    async editProcedureCode () {
      await this.set_contract_procedure_code();
      this.showWarningModal = false;
      this.$emit("close-modal");
      this.$emit("refresh-list");
    },

    closeWarningModal () {
      this.showWarningModal = false;
    },

    _build_request_setProcedureCode() {
      let requestData = {};
      requestData.insurer_contract = {type: "id", id: this.selectedContract.contract_id};
      requestData.insurer = {type: "id", id: this.selectedContract.insurer_id};
      requestData.price_list = [{
        proc_code: this.newProcNum,
        price: {
          description: this.newProcDesc,
          participating_consultant_cost: this.newParticipatingConsultant,
          participating_anaesthetist_cost: this.newParticipatingAnaesthetist
        }
      }]
      return requestData;
    },

    async set_contract_procedure_code() {
      const price_list = [{
        proc_code: this.newProcNum,
        price: {
          description: this.newProcDesc,
          participating_consultant_cost: this.newParticipatingConsultant,
          participating_anaesthetist_cost: this.newParticipatingAnaesthetist
        }
      }]
      const oapi = new OpenAPIJSON();
      await oapi.setContractInsuranceProcedureCode(this.selectedContract.insurer_id, null, this.selectedContract.contract_id, price_list);
    },
  }
}
</script>

<style>
.title {
  font-size: 22px;
}
</style>