<template>
    <div>
      <div :id="inputId" class="custom-datepicker" :class="{'readonly': readonly}">
        <div class="day pr-2">
          <div class="label">Day</div>
          <v-select :id="inputId + '_day'" label="Day" placeholder='Day' :options="days" v-model='day'></v-select>
        </div>

        <div class="month pr-2">
          <div class="label">Month</div>
          <v-select :id="inputId + '_month'" label="Month" placeholder='Month' :options="months" v-model='month'></v-select>
        </div>

        <div class="year pr-2" v-if='years'>
          <div class="label">Year</div>
          <v-select :id="inputId + '_year'" label="Year" placeholder='Year' :options="years" v-model='year'></v-select>
        </div>
      </div>

      <div style="clear: both"></div>
    </div>
</template>

<script>
import vSelect from 'vue-select'
import 'vue-select/dist/vue-select.css';

/**
 * For numbers lower than two digits in magnitude, the value is prefixed with a 0.
 * @param {number} val
 * @return {string} "23" or "04" etc.
 */
function zero_prefixed_num(val) {
  return (val < 10) ? "0" + val : val;
}

/**
 * Convert a Date struct into a format compatible with the GraphQL type NaiveDate and NaiveDateTime
 *
 * @param {Date} d
 * @return If hours, minutes, seconds are all 0, then YYYY-MM-DD is returned, otherwise YYYY-MM-DDTHH:MM:SS
 *
 */
function to_naive_date(d) {
  return d.getFullYear() + "-" + zero_prefixed_num(d.getMonth() + 1) + "-" + zero_prefixed_num(d.getDate());
}

/**
 * Convert a Date struct into a format compatible with the GraphQL type NaiveDateTime
 *
 * @param {Date} d
 * @return Creates YYYY-MM-DDTHH:MM:SS
 *
 */
function to_naive_datetime(d) {
  return to_naive_date(d)
      + "T" + zero_prefixed_num(d.getHours())
      + ":" + zero_prefixed_num(d.getMinutes())
      + ":" + zero_prefixed_num(d.getSeconds());
}
/**
 * CustomDatePicker component.
 *
 * If the date is backed by an object that uses DateTime format, then instantiate with
 *   :value-is-date-time="true"
 *
 * The time data will not appear in the form field, but will be preserved.
 */
export default {
  name: 'CustomDatepicker',
  props: {
    modelValue: { },
    readonly: { type: Boolean },
    valueIsDateTime: { type: Boolean, default: false },
    id: { type: String },
  },
  emits: ['update:modelValue'],
  components: {
    vSelect
  },
  data () {
    return {
        days: [1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31],
        months: [1,2,3,4,5,6,7,8,9,10,11,12],
        years: [],
        currentYear: new Date().getFullYear(),
        day: null,
        month: null,
        year: null,
        hours: null,
        minutes: null,
        seconds: null,
        customDate: this.modelValue,
        inputId: this.id || `customdatepicker_${(Math.random() * 1000).toFixed()}`,
      }
  },
  computed: {

  },
  watch: {

    year () {
      if (this.year === null) {
        this.customDate = null;
      } else {
        const d = new Date(this.year, this.month - 1, this.day, this.hours, this.minutes, this.seconds);
        this.customDate = (this.valueIsDateTime === true) ? to_naive_datetime(d) : to_naive_date(d);
      }
      this.updateValue()
    },
    month () {
      if (this.year === null) {
        this.customDate = null;
      } else {
        const d = new Date(this.year, this.month - 1, this.day, this.hours, this.minutes, this.seconds);
        this.customDate = (this.valueIsDateTime === true) ? to_naive_datetime(d) : to_naive_date(d);
      }
      this.updateValue()
    },
    day () {
      if (this.year === null) {
        this.customDate = null;
      } else {
        const d = new Date(this.year, this.month - 1, this.day, this.hours, this.minutes, this.seconds);
        this.customDate = (this.valueIsDateTime === true) ? to_naive_datetime(d) : to_naive_date(d);
      }
      this.updateValue()
    }
  },
  methods: {
    updateValue: function () {
      this.$emit('update:modelValue', this.customDate)
    }
  },
  mounted () {
    // range of years
    for(let i=this.currentYear; i>= 1900; i--) {
      this.years.push(i)
    }
    // parse date
    if (this.customDate) {
      const d = new Date(this.customDate);
      this.year = d.getFullYear();
      this.month = d.getMonth() + 1;
      this.day = d.getDate();
      this.hours = d.getHours();
      this.minutes = d.getMinutes();
      this.seconds = d.getSeconds();
    }
  }
}
</script>

<style lang="scss" scoped>

  .custom-datepicker {

  }

  .day {
    float: left;
    width: 30%;
  }

  .day ::placeholder {
    color: #b9b9b9;
  }

  .month {
    float: left;
    width: 30%;
  }

  .month ::placeholder {
    color: #b9b9b9;
  }

  .year {
    float: left;
    width: 36%;
  }

  .year ::placeholder {
    color: #b9b9b9;
  }

  .label {
    font-size: 14px;
    font-style: italic;
    color: #bfbfbf;
  }

  .readonly {
    pointer-events: none;
  }
</style>