<template>
  <div :id="inputId" class="radiology-investigations-section">
    <div class="col-12" :key="'forceRender'" v-if="dataLoaded">
      <div class="flex flex-col">
        <div class="my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div class="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
            <div class="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
              <table class="min-w-full divide-y divide-gray-200">
                <thead class="bg-gray-50">
                  <tr>
                    <th
                      scope="col"
                      class="px-2 py-3 text-left text-xs font-bold text-gray-500 uppercase tracking-wider"
                    >
                      Radiology Investigation
                    </th>
                    <th
                      scope="col"
                      class="px-2 py-3 text-left text-xs font-bold text-gray-500 uppercase tracking-wider"
                    >
                      Procedure Code
                    </th>
                    <th
                      scope="col"
                      class="px-2 py-3 text-left text-xs font-bold text-gray-500 uppercase tracking-wider"
                    >
                      Other Facility Name
                    </th>
                    <th
                      scope="col"
                      class="px-2 py-3 text-left text-xs font-bold text-gray-500 uppercase tracking-wider"
                    ></th>
                  </tr>
                </thead>

                <tbody>
                  <!-- Grey/white alternate rows -->
                  <tr
                    :id="inputId + '_radio_row'"
                    :class="{ 'bg-white': idx % 2 === 0, 'bg-gray-50': idx % 2 !== 0 }"
                    v-for="(radio, idx) in investigations"
                    :key="radio.investigationId"
                  >
                    <td class="px-2 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                      <select
                        :id="inputId + '_testtype' + idx"
                        :disabled="isTextBoxDisabled"
                        class="custom-input !leading-normal"
                        v-model="radio.test_type"
                      >
                        <option v-for="labtest in testTypes" :key="labtest.name" :value="labtest.value">
                          {{ labtest.name }}
                        </option>
                      </select>
                    </td>
                    <td class="px-2 py-4 whitespace-nowrap text-sm text-gray-500">
                      <input
                        :id="inputId + '_code' + idx"
                        :disabled="isTextBoxDisabled"
                        type="text"
                        class="custom-input shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                        placeholder="Optional procedure code"
                        :value="getProcedureCode(radio)"
                        @input="setProcedureCode(radio, $event.target.value)"
                      />
                    </td>
                    <td class="px-2 py-4 whitespace-nowrap text-sm text-gray-500">
                      <Typeahead
                        :id="inputId + '_facility' + idx"
                        v-model="radio.other_facility_name.name"
                        :items="labFacilities"
                        placeholder="Facility name, if performed elsewhere"
                        :min-input-length="1"
                        :item-projection="showFacilityItem"
                        :item-value="getFacilityItem"
                        @selectItem="selectFacilityItem"
                        :field="radio"
                      />
                    </td>
                    <td
                      v-if="!isTextBoxDisabled"
                      :id="inputId + '_remove_radio' + idx"
                      class="px-2 py-4 whitespace-nowrap text-sm text-gray-500 font-semibold"
                      @click="removeRadio(idx, investigations)"
                    >
                      <i class="fas fa-minus-circle text-red-600"></i> Remove
                    </td>
                    <td
                      v-else
                      :id="inputId + '_remove_radio' + idx"
                      class="px-2 py-4 whitespace-nowrap text-sm text-gray-500 font-semibold"
                    >
                      <i class="fas fa-minus-circle text-gray-600"></i> Remove
                    </td>
                  </tr>
                </tbody>
              </table>

              <div class="col-6 mt-4 pl-5">
                <div
                  v-if="!textBoxDisabled"
                  :id="inputId + '_add_radio'"
                  class="my-3 custom-click cursor-pointer font-semibold"
                  @click="addRadio(investigations)"
                >
                  <i class="fas fa-plus-circle text-green-600"></i> Add another Radiology Investigation (non MRI/PETCT)
                </div>
                <div v-else class="text-gray-200">
                  <i class="fas fa-plus-circle text-gray-200"></i> Add another Radiology Investigation (non MRI/PETCT)
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/* Params
  modelValue // objects with lab investigation fields:
*/
import Typeahead from '@/components/claim-forms/components/Typeahead.vue';
import { mapState } from 'vuex';

export default {
  name: 'RadiologyInvestigations',
  props: {
    modelValue: {},
    id: { type: String },
    ct: { required: false, type: Boolean, default: false },
    labFacilities: { type: Array }
  },
  components: {
    Typeahead
  },
  data() {
    return {
      testTypes: [
        { name: 'X-Ray', value: 'x_ray' },
        { name: 'Ultrasound', value: 'ultrasound' },
        { name: 'Other', value: 'other' }
      ],
      radioIdx: -1,
      forceRender: 0,
      inputId: this.id || `radio_investigation${(Math.random() * 1000).toFixed()}`,
      dataLoaded: false
    };
  },
  watch: {
    modelValue: {
      handler(newValue) {
        this.convertFacilityToName(newValue);
      },
      deep: true
    }
  },
  computed: {
    ...mapState(['textBoxDisabled']),
    isTextBoxDisabled() {
      return this.textBoxDisabled;
    },
    investigations: {
      get() {
        return this.modelValue;
      },
      set(newValue) {
        this.$emit('update:modelValue', newValue);
      }
    }
  },
  methods: {
    addRadio(radio_obj) {
      // Fake, negative investigation ids created here to allow new entries to be removed.
      // Submission to server ignores this id and creates its own.
      radio_obj.push({
        investigationId: this.radioIdx,
        test_type: null,
        procedure: {
          procedure_code: ''
        },
        other_facility_name: {
          type: 'name',
          name: ''
        }
      });
      this.radioIdx -= 1;
      this.forceRender += 1;
    },
    removeRadio(index, treatments) {
      const treatmentIndex = treatments[index];
      if (treatmentIndex) {
        treatments.splice(index, 1);
      }
    },
    convertFacilityToName(investigations) {
      if (investigations.length === 0) return;
      for (const investigation of investigations) {
        if (investigation.other_facility_name === null) {
          investigation.other_facility_name = {
            name: '',
            type: 'name'
          };
        }
        if (investigation.other_facility_name?.ro?.name) {
          investigation.other_facility_name.name = investigation.other_facility_name.ro.name;
        }
        if (investigation.other_facility_name?.type === 'id') {
          investigation.other_facility_name.type = 'name';
        }
        if (!investigation.other_facility_name?.name) {
          investigation.other_facility_name.name = '';
        }
        delete investigation.other_facility_name.ro;
        delete investigation.other_facility_name.id;
      }
    },
    getProcedureCode(radio) {
      return radio?.procedure?.procedure_code;
    },
    setProcedureCode(radio, value) {
      radio.procedure = {
        procedure_code: value
      };
    },
    showFacilityItem(item) {
      if (item.facility_name) {
        return item.facility_name;
      }
      return null;
    },
    getFacilityItem(item) {
      if (item.facility_name) {
        return item.facility_name;
      }
      return null;
    },
    selectFacilityItem(item, field) {
      field.other_facility_name.name = item.facility_name;
    }
  },
  mounted() {
    this.dataLoaded = false;
    if (this.ct) {
      this.testTypes.push({ name: 'CT', value: 'c_t' });
    }
    this.convertFacilityToName(this.investigations);
    this.dataLoaded = true;
  }
};
</script>

<style lang="scss" scoped></style>
