<script setup>
import { ref } from 'vue';
import { userStore } from '@/store/user';
import { api_store } from '@/store';
import NotificationsSortByDate from '@/components/notifications/NotificationsSortByDate.vue';
import NotificationsFilter from '@/components/notifications/NotificationsFilter.vue';
import NotificationsFilterPreview from '@/components/notifications/NotificationsFilterPreview.vue';

defineProps({
  messageParams: {
    type: Object,
    required: true
  }
});
defineEmits(['sort-by-date', 'filter-messages']);

const isMedic = userStore.getters.user?.is_medic;
const medics = ref([]);
const setMedics = async () => {
  if (!isMedic?.value) {
    try {
      const response = await api_store.cache.dispatch('LIST_MY_CONSULTANTS', {});
      medics.value = response;
      medics.value.forEach(item => {
        item.consultant_id = item.medic.id;
        item.username = item.medic.ro.full_name;
      });
    } catch (error) {
      this.$refs.toast.showToast(error, 'fa-solid fa-triangle-exclamation', 'error');
    }
  }
};
setMedics();
</script>

<template>
  <section>
    <div class="flex justify-between items-center">
      <NotificationsSortByDate @sort-by-date="value => $emit('sort-by-date', value)" />
      <NotificationsFilter
        :messageParams="messageParams"
        :medics="medics"
        @filter-messages="value => $emit('filter-messages', value)"
      />
    </div>
    <NotificationsFilterPreview
      :messageParams="messageParams"
      :medics="medics"
      @filter-messages="value => $emit('filter-messages', value)"
    />
  </section>
</template>
