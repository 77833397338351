<script setup>
defineProps({
  buttonAction: {
    type: String,
    default: 'Assign message to this claim',
    required: false
  }
});

const emit = defineEmits(['cancel', 'create']);

const cancelAction = () => {
  emit('cancel');
};

const createAction = () => {
  emit('create');
};
</script>

<template>
  <div
    class="fixed inset-x-0 bottom-0 bg-white px-4 py-3 flex justify-between items-center shadow-md border border-black"
  >
    <button
      class="rounded-full shadow-md bg-gray-300 text-xs w-[130px] h-[50px] px-2 py-1 ml-8"
      @click="cancelAction"
      data-test-id="notifications-footer-modal-cancel-button"
    >
      Cancel
    </button>
    <button
      class="rounded-full shadow-md bg-medosync-blue text-xs w-[230px] h-[50px] px-2 py-1 mr-8"
      @click="createAction"
      data-test-id="notifications-footer-modal-action-button"
    >
      {{ buttonAction }}
    </button>
  </div>
</template>
