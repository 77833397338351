<script setup>
import NotificationsListItem from '@/components/notifications/NotificationsListItem.vue';
import ListObserver from '@/components/misc/ListObserver.vue';

defineProps({
  messages: {
    type: Array,
    required: true
  },
  selectedMessage: {
    type: Object,
    required: false
  }
});
const emit = defineEmits(['select-message', 'load-more-messages', 'detach-claim']);

const selectMessage = (id, index) => {
  emit('select-message', id, index, false);
};

const loadMoreMessages = () => {
  emit('load-more-messages');
};

const openModal = (id, index) => {
  emit('select-message', id, index, true);
};

const detachClaim = (assocs, messageId) => {
  emit('detach-claim', assocs, messageId);
};
</script>

<template>
  <div class="h-full overflow-y-auto pe-3">
    <NotificationsListItem
      v-for="(message, index) in messages"
      :class="{
        'border-4 border-b-4 border-medosync-blue hover:border-medosync-light-blue':
          selectedMessage?.message.id === message.message.id
      }"
      :key="message.message.id"
      :index="index"
      :message="message"
      @click="selectMessage(message.message.id, index)"
      @modal-module="openModal(message.message.id, index)"
      @detach-claim="detachClaim"
      :data-test-id="`notification-item-${index}`"
    />
    <ListObserver v-if="messages.length" @on-trigger="loadMoreMessages" class="mb-1" />
  </div>
</template>
