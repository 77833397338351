<script setup>
import { ref, watch } from 'vue';
import { OpenAPIJSON } from 'api/openapi';
import SpinnerLoader from '@/components/misc/SpinnerLoader.vue';
import ToastComponent from '@/components/claim-forms/components/ToastComponent.vue';

const searchQuery = ref('');
const refToast = ref(null);
const isLoading = ref(false);
const emit = defineEmits(['searchResults']);
const createOpenAPIJSON = () => new OpenAPIJSON();

const props = defineProps({
  startIdx: { type: Number, default: 1 },
  endIdx: { type: Number, default: 20 },
  pageSize: { type: Number, default: null },
  consultantId: { type: Number, default: null }
});

const getPaginationConfig = () => ({
  start_idx: props.startIdx,
  end_idx: props.endIdx,
  page_size: props.pageSize
});

const apiSearch = async () => {
  isLoading.value = true;
  const oapi = createOpenAPIJSON();
  const pagination = getPaginationConfig();
  try {
    const rest = await oapi.searchClaims(
      null,
      null,
      null,
      null,
      null,
      searchQuery.value,
      null,
      null,
      null,
      null,
      props.consultantId,
      null,
      null,
      null,
      pagination
    );

    return rest;
  } catch (error) {
    refToast.value.showToast(error, 'fa-solid fa-triangle-exclamation', 'error');
  } finally {
    isLoading.value = false;
  }
};

watch(searchQuery, async newValue => {
  if (newValue.length > 0) {
    try {
      const results = await apiSearch(newValue);
      emit('searchResults', results);
      if (results.total_items === 0) {
        emit('searchResults', []);
      }
    } catch (error) {
      refToast.value.showToast(error, 'fa-solid fa-triangle-exclamation', 'error');
    }
  }
});

const clearSearch = () => {
  emit('listAgain');
  searchQuery.value = '';
};
</script>
<template>
  <SpinnerLoader v-if="isLoading" />
  <div>
    <div class="mb-2 text-medosync-black">Search for MRN, patient name or Appointment ID to assign this message:</div>
    <div class="flex justify-between items-center pb-8">
      <div class="relative w-full">
        <input
          v-model="searchQuery"
          class="w-full pl-2 pr-10 py-1 border-2 border-medosync-black rounded-md"
          data-test-id="notification-message-overlay-search-mrn-input"
        />
        <button @click="clearSearch" class="absolute right-8 top-1/2 transform -translate-y-1/2 text-medosync-black">
          <i class="fa fa-times" data-test-id="notification-message-overlay-search-reset-button"></i>
        </button>
        <i
          class="fa-sharp fa-solid fa-magnifying-glass absolute right-3 top-1/2 transform -translate-y-1/2"
          data-test-id="notification-message-overlay-search-mrn-button"
        ></i>
      </div>
    </div>
  </div>
  <ToastComponent ref="refToast"></ToastComponent>
</template>
