<script setup lang="ts">
import { defineComponent } from 'vue';
import { useField } from 'vee-validate';
import InputErrorMessage from '../inputs/InputErrorMessage.vue';

defineComponent({ InputErrorMessage });

const props = defineProps({
  id: {
    type: String,
    required: true
  },
  name: {
    type: String,
    required: true
  },
  label: {
    type: String,
    default: ''
  },
  options: {
    type: [Array, Object],
    required: true
  },
  editMode: {
    type: Boolean,
    default: true
  },
  isWarning: {
    type: Boolean,
    default: false
  }
});

const { handleChange, errorMessage, meta } = useField(() => props.name);
if (meta.initialValue) {
  handleChange(meta.initialValue);
} else {
  handleChange(Object.values(props.options)[0]);
}

const selectedItem = (option: string) => {
  return option === meta?.initialValue || option === Object.values(props.options)[0];
};
</script>

<template>
  <div :id="id + '-container'">
    <div class="flex">
      <label
        :for="id"
        class="text-sm sm:text-base font-bold"
        :class="{
          'text-medosync-dark-orange': isWarning && editMode,
          'text-medosync-red': errorMessage
        }"
      >
        {{ label }}
      </label>
    </div>
    <div v-if="editMode" class="mt-1">
      <select
        :id="id"
        :name="name"
        class="shadow-sm focus:ring-medosync-blue focus:border-medosync-blue block w-full rounded-md h-12 border cursor-pointer max-input-width"
        :class="{
          'border-medosync-dark-orange': isWarning && editMode,
          'border-medosync-red focus:ring-medosync-red focus:border-medosync-red': errorMessage
        }"
        @change="handleChange(($event.target as HTMLInputElement).value)"
      >
        <option v-for="(option, index) in options" :key="index" :value="option" :selected="selectedItem(option)">
          {{ option }}
        </option>
      </select>
    </div>
    <div v-else class="mt-1">
      <p :id="id + '-readonly'" class="text-lg text-gray-500 ml-4 mr-6">
        {{ meta?.value || 'N/A' }}
      </p>
    </div>
    <InputErrorMessage v-if="errorMessage">This field is required</InputErrorMessage>
  </div>
</template>
