<script setup lang="ts">
import { BaseNumber, BaseInput, BasePhoneNumberInput, BaseDateInput, BaseRadioButton } from 'ui';
import ClaimSectionTitle from '@/components/admission/ClaimSectionTitle.vue';

const props = defineProps({
  editMode: {
    type: Boolean,
    default: false
  },
  warnings: {
    type: Array,
    default: () => []
  }
});

const sectionHasWarnings = sectionId => {
  if (!props.warnings) return false;
  return props.warnings.some(warning => warning.id === sectionId);
};
</script>

<template>
  <div>
    <ClaimSectionTitle id="irish-life-membership-details-title" title="Membership Details" />
    <BaseNumber
      id="policy-no"
      name="policyNo"
      class="mt-6"
      label="Patient's membership number"
      helper-text="This can be found on you membership card and on your membership certificate"
      :max-length="10"
      :edit-mode="editMode"
      :is-warning="sectionHasWarnings('policy-no')"
    />
    <BaseInput
      id="patient-name"
      name="patientName"
      class="mt-6 sm:mt-12"
      label="Patient's Name*"
      :edit-mode="editMode"
      :is-warning="sectionHasWarnings('patient-name')"
    />
    <BasePhoneNumberInput
      id="telephone"
      name="telephone"
      class="mt-6 sm:mt-12"
      label="Daytime contact number"
      :edit-mode="editMode"
      :is-warning="sectionHasWarnings('telephone')"
    />
    <BaseDateInput
      id="patient-dob"
      name="birthDate"
      class="mt-6 sm:mt-12"
      label="Date of Birth*"
      :edit-mode="editMode"
    />
    <BaseRadioButton
      name="treatmentResultOfAccident"
      class="mt-6 sm:mt-12"
      :options="{
        Yes: true,
        No: false,
        'N/A': null
      }"
      label="Was treatment received directly as a result of an accident?"
      :edit-mode="editMode"
    />
    <BaseRadioButton
      name="privatePatient"
      class="mt-6 sm:mt-12"
      :options="{
        Yes: true,
        No: false,
        'N/A': null
      }"
      label="Did you elect to be a private patient of the consultant?"
      :edit-mode="editMode"
    />
  </div>
</template>
